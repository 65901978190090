import { useFormik, yupToFormErrors } from "formik"
import moment from "moment"
import Multiselect from "multiselect-react-dropdown"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { toast } from "react-toastify"
import { Col, Form, FormFeedback, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { getAlternativeYears } from "utils/alternativeHelper"
import isNullOrEmpty from "utils/isNullOrEmpty"
import * as Yup from "yup"

const CreateContractModal = ({
  alternative,
  scenario,
  enterprise,
  contractEntityOptions,
  strategyOptions,
  isOpen,
  toggleModal,
  reloadContracts,
  // preselectedData,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [costPoolOptions, setCostPoolOptions] = useState([])
  const [resourceGroupOptions, setResourceGroupOptions] = useState([])
  const [selectedResourceGroupId, setSelectedResourceGroupId] = useState("-1")
  const [contractOptions, setContractOptions] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const [selectedCostPools, setSelectedCostPools] = useState([])
  const [costPoolsWithAmounts, setCostPoolWithAmounts] = useState([])

  useEffect(() => {
    if (isOpen) {
      setSelectedCostPools([])
      setCostPoolWithAmounts([])
      setSelectedResourceGroupId("-1")
      loadCostPools()
      loadContracts()
      validation.resetForm()
    }
  }, [isOpen])

  useEffect(() => {
    if (selectedCostPools.length > 0) {
      loadResourceGroups()
      loadContractAmounts()
    }
  }, [selectedCostPools])

  useEffect(() => {
    if (selectedResourceGroupId != "-1") {
      loadContracts()
    }
  }, [selectedResourceGroupId])

  const loadResourceGroups = async () => {
    let tasks = selectedCostPools.map(x => async () => {
      return await api.getResourceGroupsByBcCostPool(x.id)
    })
    let res = await Promise.all(tasks.map(t => t()))
    setResourceGroupOptions(res.flat())
  }

  const loadContracts = async () => {
    let cs = await api.getContractsForResource(
      alternative.scenarioID,
      selectedResourceGroupId
    )
    setContractOptions(cs)
  }

  const loadCostPools = async () => {
    let cp = await api.getContractsCostPools(alternative.alternativeID)
    setCostPoolOptions(cp)
  }

  const loadContractAmounts = async () => {
    let tasks = selectedCostPools.map(x => async () => {
      return await api.getRemainingContractAmounts(x.id)
    })
    let res = await Promise.all(tasks.map(t => t()))
    if (!isNullOrEmpty(res) && res.length > 0) {
      let amounts = res.flat()

      setCostPoolWithAmounts(
        selectedCostPools.map((c, idx) => {
          c.amounts = amounts.length > idx ? amounts[idx] : null
          return c
        })
      )

      if (amounts !== undefined && amounts !== null && amounts.length > 0) {
        alternativeYears.forEach(y => {
          let amount = 0
          amounts.forEach(a => {
            amount += parseFloat(a[`c-${y}`])
          })
          validation.setFieldValue(`c-${y}`, amount)
        })
      }
    }
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      bcCostPoolID: -1,
      resourceGroupID: -1,
      contractID: -1,
      contractEntityType: "-1",
      contractEntityName: "-1",
      strategyID: -1,
    },
    validationSchema: Yup.object({
      // bcCostPoolID: Yup.number()
      //   .required("Please select a cost pool")
      //   .moreThan(-1, "Please select a cost pool"),
      resourceGroupID: Yup.number(),
      contractID: Yup.number()
        .required("Please select a contract")
        .moreThan(0, "Please select a contract"),
      contractEntityType: Yup.string(),
      contractEntityName: Yup.string(),
      strategyID: Yup.number()
        .required("Please select an acquisition approach")
        .notOneOf([-1], "Please select an acquisition approach"),
    }),
    onSubmit: async values => {
      if (selectedCostPools.length == 1) {
        let newContract = { ...values }
        let contractEntity = contractEntityOptions.find(
          x =>
            x.contractEntityName == newContract.contractEntityName &&
            x.contractEntityType == newContract.contractEntityType
        )
        if (contractEntity !== undefined) {
          newContract.contractEntityID = contractEntity.contractEntityID
        }
        newContract.startDate = startDate
        newContract.endDate = endDate

        newContract.contractItemYears = []
        let yearProps = Object.keys(newContract).filter(
          x => x.startsWith("c-") && !isNaN(x.replace("c-", ""))
        )
        if (yearProps.length > 0) {
          yearProps.forEach(yp => {
            newContract.contractItemYears.push({
              yearID: yp.replace("c-", ""),
              amount: newContract[yp],
            })
          })
        }

        try {
          await api.createContractItem(currentUser.userID, newContract)
          toast.success("Contract added successfully")
          reloadContracts()
          toggleModal()
        } catch (err) {
          toast.error("An error occured while saving the contract")
        }
      } else {
        let newContracts = costPoolsWithAmounts.map(c => {
          let newContract = { ...values }
          newContract.bcCostPoolID = c.id
          let contractEntity = contractEntityOptions.find(
            x =>
              x.contractEntityName == newContract.contractEntityName &&
              x.contractEntityType == newContract.contractEntityType
          )
          if (contractEntity !== undefined) {
            newContract.contractEntityID = contractEntity.contractEntityID
          }
          newContract.startDate = startDate
          newContract.endDate = endDate

          newContract.contractItemYears = []
          let yearProps = Object.keys(c.amounts).filter(
            x => x.startsWith("c-") && !isNaN(x.replace("c-", ""))
          )
          if (yearProps.length > 0) {
            yearProps.forEach(yp => {
              newContract.contractItemYears.push({
                yearID: yp.replace("c-", ""),
                amount: c.amounts[yp],
              })
            })
          }

          return newContract
        })

        let tasks = newContracts.map(x => async () => {
          return await api.createContractItem(currentUser.userID, x)
        })

        try {
          // do the api call for each costPool
          await Promise.all(tasks.map(t => t()))
          toast.success("Contracts added successfully")
          reloadContracts()
          toggleModal()
        } catch (err) {
          toast.error("An error occured while saving the contract")
        }
      }
    },
  })

  const alternativeYears = getAlternativeYears(alternative)

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Contract Item
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            <Label>Cost Pool</Label>
            <Multiselect
              options={costPoolOptions.map(x => {
                return {
                  name: x.costPoolName,
                  id: x.bcCostPoolID,
                }
              })}
              selectedValues={selectedCostPools}
              onSelect={values => {
                setSelectedCostPools(values)
              }}
              onRemove={values => {
                setSelectedCostPools(values)
              }}
              displayValue="name"
              showCheckbox={true}
            />

            {/* <select
              className="form-control form-select select2 mb-xxl-0"
              value={validation.values.bcCostPoolID || "-1"}
              onChange={e => {
                console.log(e.target.value)
                validation.setFieldValue("bcCostPoolID", e.target.value)
                setSelectedCostPoolId(e.target.value)
              }}
            >
              <option value={"-1"}>Select Cost Pool</option>
              {costPoolOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.bcCostPoolID}`}>
                    {a.costPoolName}
                  </option>
                )
              })}
            </select>
            {validation.touched.bcCostPoolID &&
            validation.errors.bcCostPoolID ? (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {validation.errors.bcCostPoolID}
              </div>
            ) : null} */}
          </div>
          {scenario?.resourceTabID != 0 && (
            <div className="mb-3">
              <Label>Resource</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={validation.values.resourceGroupID || "-1"}
                onChange={e => {
                  console.log(e.target.value)
                  validation.setFieldValue("resourceGroupID", e.target.value)
                  setSelectedResourceGroupId(e.target.value)
                }}
              >
                <option value={"-1"}>Select Resource</option>
                {resourceGroupOptions.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.resourceGroupID}`}>
                      {a.resourceGroupName}
                    </option>
                  )
                })}
              </select>
              {/* {validation.touched.resourceGroupID &&
            validation.errors.resourceGroupID ? (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {validation.errors.resourceGroupID}
              </div>
            ) : null} */}
            </div>
          )}
          <div className="mb-3">
            <Label>{enterprise?.contractLabel ?? "Contract"}</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={validation.values.contractID || -1}
              onChange={e => {
                validation.setFieldValue("contractID", e.target.value)

                let contract = contractOptions.find(
                  x => x.contractID == e.target.value
                )
                if (contract != undefined && contract != null) {
                  setStartDate(
                    isNullOrEmpty(contract.periodStart)
                      ? moment().format("YYYY-MM-DDThh:mm:ss")
                      : contract.periodStart
                  )
                  setEndDate(
                    isNullOrEmpty(contract.periodEnd)
                      ? moment().format("YYYY-MM-DDThh:mm:ss")
                      : contract.periodEnd
                  )
                }
              }}
            >
              <option value={-1}>Select...</option>
              {contractOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.contractID}`}>
                    {a.contractName}
                  </option>
                )
              })}
            </select>
            {validation.touched.contractID && validation.errors.contractID ? (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {validation.errors.contractID}
              </div>
            ) : null}
          </div>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label>PoP Start</Label>
                <Input
                  name="startDate"
                  type="date"
                  onChange={e =>
                    setStartDate(
                      moment(e.target.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DDThh:mm:ss"
                      )
                    )
                  }
                  value={moment(startDate).format("YYYY-MM-DD")}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label>PoP End</Label>
                <Input
                  name="endDate"
                  type="date"
                  onChange={e =>
                    setEndDate(
                      moment(e.target.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DDThh:mm:ss"
                      )
                    )
                  }
                  value={moment(endDate).format("YYYY-MM-DD")}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label>Entity Type</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={validation.values.contractEntityType || "-1"}
                  onChange={e => {
                    validation.setFieldValue(
                      "contractEntityType",
                      e.target.value
                    )
                  }}
                >
                  <option value={-1}>Select...</option>
                  {[
                    ...new Set(
                      contractEntityOptions.map(item => item.contractEntityType)
                    ),
                  ].map((a, idx) => {
                    return (
                      <option key={idx} value={`${a}`}>
                        {a}
                      </option>
                    )
                  })}
                </select>
                {validation.touched.contractEntityType &&
                validation.errors.contractEntityType ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.contractEntityType}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label>Entity Name</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={validation.values.contractEntityName || "-1"}
                  onChange={e => {
                    validation.setFieldValue(
                      "contractEntityName",
                      e.target.value
                    )
                  }}
                >
                  <option value={-1}>Select...</option>
                  {contractEntityOptions
                    .filter(
                      x =>
                        x.contractEntityType ==
                        validation.values.contractEntityType
                    )
                    .map((a, idx) => {
                      return (
                        <option key={idx} value={`${a.contractEntityName}`}>
                          {a.contractEntityName}
                        </option>
                      )
                    })}
                </select>
                {validation.touched.contractEntityName &&
                validation.errors.contractEntityName ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.contractEntityName}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>

          <div className="mb-3">
            <Label>Acquisition Approach</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={validation.values.strategyID}
              onChange={e => {
                validation.setFieldValue("strategyID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {strategyOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
            {validation.touched.strategyID && validation.errors.strategyID ? (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {validation.errors.strategyID}
              </div>
            ) : null}
          </div>
          {selectedCostPools.length > 0 && (
            <div className="flex-row-space">
              {alternativeYears.map((y, idx) => (
                <div key={idx}>
                  <Label>FY {y}</Label>
                  <CurrencyInput
                    className="form-control align-end"
                    value={validation.values[`c-${y}`] || 0}
                    onValueChange={(value, name, values) => {
                      if (selectedCostPools.length >= 2) {
                        if (values.float == 0) {
                          validation.setFieldValue(`c-${y}`, 0)
                          setCostPoolWithAmounts(
                            costPoolsWithAmounts.map(c => {
                              c.amounts[`c-${y}`] = 0
                              return c
                            })
                          )
                        }
                      } else {
                        validation.setFieldValue(`c-${y}`, values.float)
                      }
                    }}
                    prefix={"$"}
                  />
                </div>
              ))}
              {/* <div>
                <Label>FY {alternative.fyStart}</Label>
                <CurrencyInput
                  id={`year1Amountss`}
                  name={`year1Amountss`}
                  className="form-control align-end"
                  value={validation.values.year1Amount}
                  onValueChange={(value, name, values) => {
                    validation.setFieldValue("year1Amount", values.float)
                  }}
                  prefix={"$"}
                />
                {validation.touched.year1Amount &&
                validation.errors.year1Amount ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.year1Amount}
                  </div>
                ) : null}
              </div>
              <div>
                <Label>FY {alternative.fyStart + 1}</Label>
                <CurrencyInput
                  id={`year2Amountss`}
                  name={`year2Amountss`}
                  className="form-control align-end"
                  value={validation.values.year2Amount}
                  onValueChange={(value, name, values) => {
                    validation.setFieldValue("year2Amount", values.float)
                  }}
                  prefix={"$"}
                />
                 {validation.touched.year2Amount &&
                validation.errors.year2Amount ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.year2Amount}
                  </div>
                ) : null}
              </div>
              <div>
                <Label>FY {alternative.fyStart + 2}</Label>
                <CurrencyInput
                  id={`year3Amountss`}
                  name={`year3Amountss`}
                  className="form-control align-end"
                  value={validation.values.year3Amount}
                  onValueChange={(value, name, values) => {
                    validation.setFieldValue("year3Amount", values.float)
                  }}
                  prefix={"$"}
                />
                 {validation.touched.year3Amount &&
                validation.errors.year3Amount ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.year3Amount}
                  </div>
                ) : null}
              </div>
              <div>
                <Label>FY {alternative.fyStart + 3}</Label>
                <CurrencyInput
                  id={`year4Amountss`}
                  name={`year4Amountss`}
                  className="form-control align-end"
                  value={validation.values.year4Amount}
                  onValueChange={(value, name, values) => {
                    validation.setFieldValue("year4Amount", values.float)
                  }}
                  prefix={"$"}
                />
                 {validation.touched.year4Amount &&
                validation.errors.year4Amount ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.year4Amount}
                  </div>
                ) : null}
              </div>
              <div>
                <Label>FY {alternative.fyStart + 4}</Label>
                <CurrencyInput
                  id={`year5Amountss`}
                  name={`year5Amountss`}
                  className="form-control align-end"
                  value={validation.values.year5Amount}
                  onValueChange={(value, name, values) => {
                    validation.setFieldValue("year5Amount", values.float)
                  }}
                  prefix={"$"}
                />
                 {validation.touched.year5Amount &&
                validation.errors.year5Amount ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.year5Amount}
                  </div>
                ) : null}
              </div> */}
            </div>
          )}
          <div className="modal-footer">
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={toggleModal}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary save-user">
                Save
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default CreateContractModal
