import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const CopyScenarioModal = ({ isOpen, toggleModal, scenario }) => {
  const currentUser = userService.getLoggedInUser()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [checkboxes, setCheckboxes] = useState([])
  const defaultCheckboxes = {
    participants: true,
    pwcScores: true,
    tasks: true,
    documents: true,
    resourceCapacity: true,
    scenarios: true,
    alternatives: true,
    altOptimizationScenarios: true,
    altScores: true,
    altResourceEstimatesAndPlans: true,
    altDocuments: true,
    altLocations: true,
    altLookbacks: true,
    altForecast: true,
    forecastContracts: true,
  }

  useEffect(() => {
    if (isOpen) {
      setCheckboxes({ ...defaultCheckboxes })
      // loadData()
    }
  }, [isOpen])

  const save = async () => {
    setIsSubmitting(true)
    await api.copyScenario({
      scenarioID: scenario.scenarioID,
      userID: currentUser.userID,
      copyParticipants: checkboxes.participants,
      copyPWC: checkboxes.pwcScores,
      copyPortTasks: checkboxes.tasks,
      copyPortDocs: checkboxes.documents,
      copyPortResource: checkboxes.resourceCapacity,
      copyCFModels: checkboxes.scenarios,
      copyAlternatives: checkboxes.alternatives,
      copyOptimization: checkboxes.altOptimizationScenarios,
      copyScores: checkboxes.altScores,
      copyResources: checkboxes.altResourceEstimatesAndPlans,
      copyDocs: checkboxes.altDocuments,
      copyLocations: checkboxes.altLocations,
      copyLookbacks: checkboxes.altLookbacks,
      copyTasks: checkboxes.tasks,
      copyForecast: checkboxes.altForecast,
      copyContracts: checkboxes.forecastContracts,
      isRevision: false,
    })

    toast.success("Model copied successfully")
    setIsSubmitting(false)
    toggleModal()
    reload && reload()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Copy Decision
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">









      
<div className="form-check" style={{ marginLeft: "10px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="tasks"
            checked={checkboxes.tasks}
            onClick={e => {
              setCheckboxes({
                ...checkboxes,
                tasks: !checkboxes.tasks,
              })
            }}
          />
          <Label className="form-check-label" for="tasks">
            Decision Tracking items (Tasks, Issues and Risks)
          </Label>
        </div>

        <div className="form-check" style={{ marginLeft: "10px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="documents"
            checked={checkboxes.documents}
            onClick={e => {
              setCheckboxes({
                ...checkboxes,
                documents: !checkboxes.documents,
              })
            }}
          />
          <Label className="form-check-label" for="documents">
            Documents
          </Label>
        </div>  

        <div className="form-check" style={{ marginLeft: "10px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="participants"
            checked={checkboxes.participants}
            onClick={e => {
              setCheckboxes({
                ...checkboxes,
                participants: !checkboxes.participants,
                pwcScores:
                  !checkboxes.participants == false
                    ? false
                    : checkboxes.pwcScores,
                altScores:
                  !checkboxes.participants == false
                    ? false
                    : checkboxes.altScores,                   
              })
            }}
          />
          <Label className="form-check-label" for="participants">
            Participants
          </Label>
        </div>

        {checkboxes.participants && (
          <div className="form-check" style={{ marginLeft: "40px" }}>
            <Input
              type="checkbox"
              className="form-check-input"
              id="pwcScores"
              checked={checkboxes.pwcScores}
              onClick={e => {
                setCheckboxes({
                  ...checkboxes,
                  pwcScores: !checkboxes.pwcScores,
                })
              }}
            />
            <Label className="form-check-label" for="pwcScores">
              PWC Scores
            </Label>
          </div>
        )}   

        <div className="form-check" style={{ marginLeft: "10px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="resourceCapacity"
            checked={checkboxes.resourceCapacity}
            onClick={e => {
              setCheckboxes({
                ...checkboxes,
                resourceCapacity: !checkboxes.resourceCapacity,
                altResourceEstimatesAndPlans:
                !checkboxes.resourceCapacity == false
                  ? false
                  : checkboxes.altResourceEstimatesAndPlans,    
              })
            }}
          />
          <Label className="form-check-label" for="resourceCapacity">
            Resource Capacity
          </Label>
        </div>

        <div className="form-check" style={{ marginLeft: "10px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="scenarios"
            checked={checkboxes.scenarios}
            onClick={e => {
              setCheckboxes({
                ...checkboxes,
                scenarios: !checkboxes.scenarios,
              })
            }}
          />
          <Label className="form-check-label" for="scenarios">
            Scenarios
          </Label>
        </div>  


  <div className="form-check" style={{ marginLeft: "10px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="alternatives"
            checked={checkboxes.alternatives}
            onClick={e => {
              setCheckboxes({
                ...checkboxes,
                alternatives: !checkboxes.alternatives,
              })
            }}
          />
          <Label className="form-check-label" for="alternatives">
            Alternatives
          </Label>
        </div>

        {checkboxes.alternatives && (
          <>

{checkboxes.participants && (
              <div className="form-check" style={{ marginLeft: "40px" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="altScores"
                  checked={checkboxes.altScores}
                  onClick={e => {
                    setCheckboxes({
                      ...checkboxes,
                      altScores: !checkboxes.altScores,
                    })
                  }}
                />
                <Label className="form-check-label" for="altScores">
                  Alternative Scores
                </Label>
              </div>
            )}

<           div className="form-check" style={{ marginLeft: "40px" }}>
              <Input
                type="checkbox"
                className="form-check-input"
                id="altDocuments"
                checked={checkboxes.altDocuments}
                onClick={e => {
                  setCheckboxes({
                    ...checkboxes,
                    altDocuments: !checkboxes.altDocuments,
                  })
                }}
              />
              <Label className="form-check-label" for="altDocuments">
                Documents
              </Label>
            </div>

            <div className="form-check" style={{ marginLeft: "40px" }}>
              <Input
                type="checkbox"
                className="form-check-input"
                id="altForecast"
                checked={checkboxes.altForecast}
                onClick={e => {
                  setCheckboxes({
                    ...checkboxes,
                    altForecast: !checkboxes.altForecast,
                    forecastContracts:
                      !checkboxes.altForecast == false
                        ? false
                        : checkboxes.forecastContracts,
                  })
                }}
              />
              <Label className="form-check-label" for="altForecast">
                Forecast
              </Label>
            </div>
            {checkboxes.altForecast && (
              <div className="form-check" style={{ marginLeft: "70px" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="forecastContracts"
                  checked={checkboxes.forecastContracts}
                  onClick={e => {
                    setCheckboxes({
                      ...checkboxes,
                      forecastContracts: !checkboxes.forecastContracts,
                    })
                  }}
                />
                <Label className="form-check-label" for="forecastContracts">
                  Contracts
                </Label>
              </div>
            )}     

                       <div className="form-check" style={{ marginLeft: "40px" }}>
              <Input
                type="checkbox"
                className="form-check-input"
                id="altLocations"
                checked={checkboxes.altLocations}
                onClick={e => {
                  setCheckboxes({
                    ...checkboxes,
                    altLocations: !checkboxes.altLocations,
                  })
                }}
              />
              <Label className="form-check-label" for="altLocations">
                Locations
              </Label>
            </div>



            <div className="form-check" style={{ marginLeft: "40px" }}>
              <Input
                type="checkbox"
                className="form-check-input"
                id="altLookbacks"
                checked={checkboxes.altLookbacks}
                onClick={e => {
                  setCheckboxes({
                    ...checkboxes,
                    altLookbacks: !checkboxes.altLookbacks,
                  })
                }}
              />
              <Label className="form-check-label" for="altLookbacks">
                Lookbacks
              </Label>
            </div>        

            <div className="form-check" style={{ marginLeft: "40px" }}>
              <Input
                type="checkbox"
                className="form-check-input"
                id="altOptimizationScenarios"
                checked={checkboxes.altOptimizationScenarios}
                onClick={e => {
                  setCheckboxes({
                    ...checkboxes,
                    altOptimizationScenarios:
                      !checkboxes.altOptimizationScenarios,
                  })
                }}
              />
              <Label
                className="form-check-label"
                for="altOptimizationScenarios"
              >
                Optimization Scenarios
              </Label>
            </div>


            {checkboxes.resourceCapacity && (
              <div className="form-check" style={{ marginLeft: "40px" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="altResourceEstimatesAndPlans"
                  checked={checkboxes.altResourceEstimatesAndPlans}
                  onClick={e => {
                    setCheckboxes({
                      ...checkboxes,
                      altResourceEstimatesAndPlans:
                        !checkboxes.altResourceEstimatesAndPlans,
                    })
                  }}
                />
                <Label
                  className="form-check-label"
                  for="altResourceEstimatesAndPlans"
                >
                  Resource Estimates and Plans
                </Label>
              </div>
            )}

<div className="form-check" style={{ marginLeft: "40px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="tasks"
            checked={checkboxes.tasks}
            onClick={e => {
              setCheckboxes({
                ...checkboxes,
                tasks: !checkboxes.tasks,
              })
            }}
          />
          <Label className="form-check-label" for="tasks">
            Tasks, Change Requests, Issues and Risks
          </Label>
        </div>



          </>
        )}


      </div>





      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        {isSubmitting && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
          disabled={isSubmitting}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default CopyScenarioModal
