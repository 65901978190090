import { CHANGE_CHATGPT_SETTINGS, CHANGE_WIDGETS_SETTINGS } from "./actionTypes"

export const changeChatGptSettings = payload => {
  return {
    type: CHANGE_CHATGPT_SETTINGS,
    payload: payload,
  }
}


export const changeWidgetsSettings = payload => {
  return {
    type: CHANGE_WIDGETS_SETTINGS,
    payload: payload,
  }
}
