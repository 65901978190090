import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { withRouter } from "react-router-dom"
import {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction,
} from "../../store/actions"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import RightSidebar from "../CommonForBoth/RightSidebar"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Col, Row } from "reactstrap"
import ChatGPTDrawerGlobal from "components/custom/chatGPTDrawerGlobal"

const Layout = props => {
  const dispatch = useDispatch()

  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme,
    layoutModeType,
    isGptDrawerOpen,
  } = useSelector(state => ({
    isPreloader: state.Layout.isPreloader,
    layoutModeType: state.Layout.layoutModeType,
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    isGptDrawerOpen: state.appSettings.isGptDrawerOpen,
  }))

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }

  //hides right sidebar on body click
  const hideRightbar = event => {
    var rightbar = document.getElementById("right-bar")
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
      dispatch(showRightSidebarAction(false))
    }
  }

  return (
    <React.Fragment>
      <div id="layout-wrapper" style={{overflowX: "hidden"}}>
        <Header toggleMenuCallback={toggleMenuCallback} otherProps={props} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <Row>
          <Col md={isGptDrawerOpen ? "9" : "12"}>
            <div className="main-content">{props.children}</div>
          </Col>

          {isGptDrawerOpen && (
            <Col md="3">
              <ChatGPTDrawerGlobal />
            </Col>
          )}
        </Row>
        <Footer />
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
    </React.Fragment>
  )
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
}

export default withRouter(Layout)
