import React, { createContext, useContext, useEffect, useState } from "react"
import * as signalR from "@microsoft/signalr"
import { mainApiUrl } from "services/api.service"

// Create a context
const SignalRContext = createContext(null)

const HUB_URL = `${mainApiUrl}/alternativeHub`

export const AlternativeSignalRProvider = ({ children }) => {
  const [connection, setConnection] = useState(null)
  const [isConnected, setIsConnected] = useState(false)
  const [subscribedGroups, setSubscribedGroups] = useState([])
  const [alternativeUpdates, setAlternativeUpdates] = useState([])

  useEffect(() => {
    // Create a SignalR connection
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(HUB_URL, {
        transport: signalR.HttpTransportType.WebSockets,
        withCredentials: false,
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build()

    setConnection(newConnection)

    return () => {
      if (subscribedGroups.length > 0) {
        subscribedGroups.forEach(async group => {
          await unsubscribe(group)
        })
      }
      if (newConnection) {
        newConnection.stop()
      }
    }
  }, [])

  useEffect(() => {
    if (!connection) return

    connection
      .start()
      .then(() => {
        console.log("Connected to SignalR!")
        setIsConnected(true)

        connection.on("AlternativeUpdated", (groupName, message) => {
          console.log(`AlternativeUpdated for ${groupName}:`, message)
          setAlternativeUpdates(prev => [...prev, message])
        })
      })
      .catch(err => console.error("SignalR connection error:", err))

    return () => {
      connection.stop()
    }
  }, [connection])

  const subscribe = async group => {
    if (connection) {
      await connection.invoke("SubscribeToAlternative", group)
      setSubscribedGroups(prev => [...prev, group])
    }
  }

  const unsubscribe = async group => {
    if (connection) {
      await connection.invoke("UnsubscribeFromAlternative", group)
      setSubscribedGroups(prev => prev.filter(g => g !== group))
    }
  }

  return (
    <SignalRContext.Provider
      value={{
        isConnected,
        alternativeUpdates,
        subscribedGroups,
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </SignalRContext.Provider>
  )
}

// Hook to use the SignalR context
export const useAlternativeSignalR = () => useContext(SignalRContext)
