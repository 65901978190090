import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import { useFormik } from "formik"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Form, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import * as Yup from "yup"
import { currencyFormatter } from "utils/formatters"

const PaymentModal = ({
  isOpen,
  toggleModal,
  contractItems,
  paymentToEdit,
  reload,
  enterprise,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [enterpriseContracts, setEnterpriseContracts] = useState([])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      paymentID: -1,
      contractID: -1,
      alternativeID: -1,
      paymentNumber: 0,
      poNumber: "",
      approvalDate: "",
      paymentDate: "",
      requestDate: "",
      paymentStatus: "In Progress",
      paymentPerson: "",
      bcItemContractID: -1,
      notes: "",
      paymentAmount: 0,
    },
    validationSchema: Yup.object({
      paymentNumber: Yup.number()
        .required("Payment number is required")
        .moreThan(0, "Payment number is required"),
    }),
    onSubmit: async values => {
      let selectedContractItem = contractItems.find(
        x => x.contractItemID == values.bcItemContractID
      )
      if (selectedContractItem !== undefined) {
        if (
          !isCashFlow &&
          values.paymentAmount >
            selectedContractItem.totalAmount - selectedContractItem.amountPaid
        ) {
          swalWithConfirmButton.fire({
            title: `This amount is more than what is left in the ${
              enterprise && enterprise.obligationLabel
            }`,
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Ok",
          })

          return
        }
      }

      if (values.paymentID > -1) {
        await api.updatePayments(currentUser.userID, { ...values })
      } else {
        await api.createPayments(currentUser.userID, { ...values })
      }
      toggleModal()
      reload()
    },
  })

  useEffect(() => {
    if (isOpen && paymentToEdit != undefined && paymentToEdit != null) {
      loadData()
      validation.resetForm()
      validation.setFieldValue("contractID", paymentToEdit.contractID)
      validation.setFieldValue("paymentID", paymentToEdit.paymentID)
      validation.setFieldValue("alternativeID", paymentToEdit.alternativeID)
      validation.setFieldValue("paymentNumber", paymentToEdit.paymentNumber)
      validation.setFieldValue("poNumber", paymentToEdit.poNumber)
      validation.setFieldValue("approvalDate", paymentToEdit.approvalDate)
      validation.setFieldValue("paymentDate", paymentToEdit.paymentDate)
      validation.setFieldValue("requestDate", paymentToEdit.requestDate)
      validation.setFieldValue("paymentStatus", paymentToEdit.paymentStatus)
      validation.setFieldValue("paymentPerson", paymentToEdit.paymentPerson)
      validation.setFieldValue(
        "bcItemContractID",
        paymentToEdit.bcItemContractID
      )
      validation.setFieldValue("notes", paymentToEdit.notes)
      validation.setFieldValue("paymentAmount", paymentToEdit.paymentAmount)
    }
  }, [isOpen])

  const loadData = async () => {
    let e = await api.getContractsForEnterprise(enterprise.enterpriseID)
    setEnterpriseContracts(e)
  }

  const isCashFlow = enterprise && enterprise.paymentLabel === "Cash Flow"

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {enterprise && enterprise.paymentLabel}
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <div className="modal-body">
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">
                  {enterprise?.paymentLabel} #
                </Label>
                <Input
                  name="paymentNumber"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.paymentNumber || ""}
                  //   invalid={
                  //     validation.touched.paymentNumber &&
                  //     validation.errors.paymentNumber
                  //       ? true
                  //       : false
                  //   }
                />
              </div>
              {!isCashFlow && (
                <div className="mb-3">
                  <Label className="form-label">Approval Date</Label>
                  <Input
                    name="approvalDate"
                    type="date"
                    onChange={e =>
                      validation.setFieldValue(
                        "approvalDate",
                        moment(e.target.value, "YYYY-MM-DD").format(
                          "YYYY-MM-DDThh:mm:ss"
                        )
                      )
                    }
                    value={moment(validation.values.approvalDate).format(
                      "YYYY-MM-DD"
                    )}
                  />
                </div>
              )}
              <div className="mb-3">
                <Label className="form-label">Draw Date</Label>
                <Input
                  name="startDate"
                  type="date"
                  onChange={e =>
                    validation.setFieldValue(
                      "paymentDate",
                      moment(e.target.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DDThh:mm:ss"
                      )
                    )
                  }
                  value={moment(validation.values.paymentDate).format(
                    "YYYY-MM-DD"
                  )}
                />
              </div>
              {!isCashFlow && (
                <div className="mb-3">
                  <Label>Status</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={validation.values.paymentStatus}
                    onChange={e => {
                      validation.setFieldValue("paymentStatus", e.target.value)
                    }}
                  >
                    <option value={"In Progress"}>In Progress</option>
                    <option value={"Complete"}>Complete</option>
                  </select>
                </div>
              )}
              {/*               <div className="mb-3">
                <Label>Appropriation</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={validation.values.contractID}
                  onChange={e => {
                    validation.setFieldValue("contractID", e.target.value)
                  }}
                >
                  <option value={-1}>Select...</option>
                  {enterpriseContracts.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.contractID}`}>
                        {a.contractNumber}
                      </option>
                    )
                  })}
                </select>
                {validation.touched.contractID &&
                validation.errors.contractID ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.contractID}
                  </div>
                ) : null}
              </div> */}
            </Col>

            <Col md="6">
              <div className="mb-3">
                <Label>Amount</Label>
                <CurrencyInput
                  className="form-control"
                  value={validation.values.paymentAmount}
                  onValueChange={(value, name, values) => {
                    validation.setFieldValue("paymentAmount", values.float)
                  }}
                  prefix={"$"}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">PO #</Label>
                <Input
                  name="ponumber"
                  type="text"
                  onChange={e =>
                    validation.setFieldValue("poNumber", e.target.value)
                  }
                  value={validation.values.poNumber}
                />
              </div>
              {!isCashFlow && (
                <div className="mb-3">
                  <Label className="form-label">BOF</Label>
                  <Input
                    name="bofDate"
                    type="date"
                    onChange={e =>
                      validation.setFieldValue(
                        "requestDate",
                        moment(e.target.value, "YYYY-MM-DD").format(
                          "YYYY-MM-DDThh:mm:ss"
                        )
                      )
                    }
                    value={moment(validation.values.requestDate).format(
                      "YYYY-MM-DD"
                    )}
                  />
                </div>
              )}
              {/*               <div className="mb-3">
                <Label className="form-label">Person</Label>
                <Input
                  name="person"
                  type="text"
                  onChange={e =>
                    validation.setFieldValue("paymentPerson", e.target.value)
                  }
                  value={validation.values.paymentPerson}
                />
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label>{enterprise?.obligationLabel ?? "NOO"}</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={validation.values.bcItemContractID}
                  onChange={e => {
                    validation.setFieldValue("bcItemContractID", e.target.value)
                  }}
                >
                  <option value={-1}>Select...</option>
                  {contractItems.map((a, idx) => {
                    if (isCashFlow) {
                      return (
                        <option key={idx} value={`${a.contractItemID}`}>
                          {a.costPoolName}: {a.contractName}
                        </option>
                      )
                    }
                    return (
                      <option key={idx} value={`${a.contractItemID}`}>
                        {a.vendor}: {currencyFormatter.format(a.totalAmount)} -{" "}
                        {currencyFormatter.format(a.amountPaid)} ={" "}
                        {currencyFormatter.format(a.totalAmount - a.amountPaid)}
                      </option>
                    )
                  })}
                </select>
                {validation.touched.bcItemContractID &&
                validation.errors.bcItemContractID ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.bcItemContractID}
                  </div>
                ) : null}
              </div>

              <div className="mb-3">
                <Label>Notes</Label>
                <Input
                  name="paymentnotes"
                  type="textarea"
                  rows={3}
                  onChange={e =>
                    validation.setFieldValue("notes", e.target.value)
                  }
                  value={validation.values.notes}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary save-user">
            Save
          </button>
        </div>
      </Form>
    </Modal>
  )
}

export default PaymentModal
