import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import api from "services/api.service"
import colors from "utils/colors"
import { userService } from "services/user.service"
import { Modal } from "reactstrap"
import useModal from "hooks/useModalHook"
import { toast } from "react-toastify"

const AltChatGPTSummaryIconWithModal = ({ alternativeId, style }) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const { show: showModal, toggle: toggleModal } = useModal()
  const [summary, setSummary] = useState("")

  useEffect(() => {
    setSummary("")
  }, [alternativeId])

  const loadSummary = async () => {
    setIsLoading(true)
    let r = await api.getGPTSummary(alternativeId)
    setSummary(r)
    setIsLoading(false)
  }

  const generateSummary = async () => {
    setIsLoading(true)
    try {
      await api.updateGPTSummary(alternativeId)
      await loadSummary()
      toast.success("Summary generated")
    } catch (e) {
      console.log(e)
      toast.error("Failed to generate summary")
      setIsLoading(false)
    }
  }

  const iconStyle = { ...style, fontSize: "18px", cursor: "pointer" }

  if (!currentUser.useAI) {
    return null
  } else {
    return (
      <React.Fragment>
        <i
          className="mdi mdi-brain"
          style={iconStyle}
          onClick={() => {
            toggleModal()
            loadSummary()
          }}
        />
        <Modal
          backdrop="static"
          isOpen={showModal}
          size="lg"
          toggle={() => {
            toggleModal()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              GPT Summary
            </h5>
            <button
              type="button"
              onClick={() => {
                toggleModal()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: summary,
                }}
              ></div>
            </div>
          </div>
          <div
            className="modal-footer"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button
              onClick={generateSummary}
              className="btn btn-primary "
              data-dismiss="modal"
              disabled={isLoading}
            >
              Generate a Current Summary
            </button>
            <button
              onClick={() => {
                toggleModal()
              }}
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AltChatGPTSummaryIconWithModal
