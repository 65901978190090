import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row, Alert } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import EditFormFieldModal from "./EditFormFieldModal"
import useModal from "hooks/useModalHook"
import FormFieldTable from "./FormFieldTable"
import DynamicFormModal from "../View/DynamicFormModal"
import isNullOrEmpty, { hasFieldError } from "utils/isNullOrEmpty"

const FormDesignerModal = ({ isOpen, toggleModal, formId, reload }) => {
  const currentUser = userService.getLoggedInUser()
  const scopes = ["Alternatives"]
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formTypes, setFormTypes] = useState([])
  const [formFieldTypes, setFormFieldTypes] = useState([])
  const [formFieldSelectLists, setFormFieldSelectLists] = useState([])
  const [fields, setFields] = useState([])
  const [formToEdit, setFormToEdit] = useState(null)
  const { show: showEditFormFieldModal, toggle: toggleEditFormFieldModal } =
    useModal()
  const { show: showDynamicFormModal, toggle: toggleDynamicFormModal } =
    useModal()
  const [formFieldToEdit, setFormFieldToEdit] = useState(null)
  const [scenarios, setScenarios] = useState([])
  const [errorFields, setErrorFields] = useState([])
  const [enterprise, setEnterprise] = useState(null)
  const newForm = {
    formId: -1,
    enterpriseId: currentUser.enterpriseID,
    formName: "",
    formTypeId: -1,
    bcTabId: -1, //???
    taskTypeId: -1, //???
    scope: "-1",
    numColumns: 1,
    editRule: "",
    userId: currentUser.userID,
    updateDT: new Date(),
    inactive: false,
    formFields: [],
    testScenarioId: -1,
  }

  const newFormField = {
    formFieldId: -1,
    formId: -1,
    fieldId: -1,
    formFieldLabel: "",
    orderId: 0,
    formFieldTypeId: -1,
    formFieldPlaceholder: "",
    fullWidth: false,
    alwaysReadOnly: false,
    alwaysEditable: false,
    selectList: "",
    required: false,
    requiredMessage: "",
    formFieldListItems: [],
  }

  useEffect(() => {
    if (isOpen) {
      setErrorFields([])
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let e = await api.getEnterprise(currentUser.enterpriseID)
    setEnterprise(e)
    let scen = await api.getScenariosByUser(currentUser.userID, 0)
    setScenarios(scen)
    if (formId == -1) {
      setFormToEdit({ ...newForm })
    } else {
      let form = await api.getForm(formId)
      setFormToEdit(form)

      await loadFields(form.enterpriseId, form.scope)
    }

    let ft = await api.getFormTypes()
    setFormTypes(ft)
    let fft = await api.getFormFieldTypes()
    setFormFieldTypes(fft)
    let lists = await api.getFormFieldSelectLists()
    setFormFieldSelectLists(lists)

    setIsLoading(false)
  }

  const loadFields = async (enterpriseId, scope) => {
    let f = await api.getFieldsByEnterpriseScope(enterpriseId, scope)
    setFields(f)
  }

  const changeFormProp = (prop, val) => {
    setFormToEdit({ ...formToEdit, [prop]: val })
  }

  const changeFormFieldProp = (prop, val) => {
    setFormFieldToEdit({ ...formFieldToEdit, [prop]: val })
  }

  const changeMultipleFormFieldProps = (props, vals) => {
    let prev = { ...formFieldToEdit }
    for (let i = 0; i < props.length; i++) {
      prev[props[i]] = vals[i]
    }
    setFormFieldToEdit(prev)
  }

  const addFormField = () => {
    setFormFieldToEdit({
      ...newFormField,
      formFieldId: getNextFormFieldId(formToEdit.formFields),
    })
    toggleEditFormFieldModal()
  }

  const editFormField = formField => {
    setFormFieldToEdit(formField)
    toggleEditFormFieldModal()
  }

  function getNextFormFieldId(formFields) {
    if (formFields.length == 0) {
      return -1
    }

    const itemsWithNegativeId = formFields.filter(obj => obj.formFieldId < 0)
    if (itemsWithNegativeId.length === 0) {
      return -1
    }
    const minValue = Math.min(
      ...itemsWithNegativeId.map(obj => obj.formFieldId)
    )

    return minValue - 1
  }

  const onFormFieldSave = () => {
    let formCopy = JSON.parse(JSON.stringify(formToEdit))
    let existingFormField = formCopy.formFields.find(
      x => x.formFieldId == formFieldToEdit.formFieldId
    )

    let nextOrder = 1
    if (formCopy.formFields.length > 0 && formFieldToEdit.orderId == 0) {
      let maxOrder = Math.max(...formCopy.formFields.map(x => x.orderId))
      if (maxOrder >= 1) {
        nextOrder = maxOrder + 1
      }
    } else {
      nextOrder = formFieldToEdit.orderId
    }

    if (existingFormField !== undefined) {
      formCopy.formFields.splice(
        formCopy.formFields.indexOf(existingFormField),
        1,
        { ...formFieldToEdit, orderId: nextOrder }
      )
    } else {
      formCopy.formFields.push({ ...formFieldToEdit, orderId: nextOrder })
    }

    setFormToEdit(formCopy)
  }

  const updateFormFields = formFields => {
    let formCopy = JSON.parse(JSON.stringify(formToEdit))
    formCopy.formFields = formFields
    setFormToEdit(formCopy)
  }

  const onFieldSave = async fieldToSave => {
    let newFieldId = await api.createField(fieldToSave)
    await loadFields(formToEdit.enterpriseId, formToEdit.scope)
    changeFormFieldProp("fieldId", newFieldId)
  }

  const save = async shouldToggleModal => {
    let errors = []
    if (isNullOrEmpty(formToEdit.formName)) {
      errors.push("formName")
    }
    if (formToEdit.formTypeId == -1) {
      errors.push("formTypeId")
    }
    if (formToEdit.scope == -1) {
      errors.push("scope")
    }
    if (formToEdit.testScenarioId == -1) {
      errors.push("testScenarioId")
    }

    setErrorFields(errors)
    if (errors.length > 0) {
      return
    }

    setIsSubmitting(true)
    console.log(formToEdit)

    if (formToEdit.formId == -1) {
      let id = await api.createForm(formToEdit)
    } else {
      await api.updateForm(formToEdit, currentUser.userID)
    }

    if (shouldToggleModal == undefined || shouldToggleModal == true) {
      toggleModal()
    }
    reload && (await reload())
    setIsSubmitting(false)

    toast.success("Form Saved")
    toggleModal()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="xl"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Form Designer
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {!isLoading && formToEdit != null && (
          <div className="modal-body">
            <Row>
              <Alert
                id="helpView"
                variant="light"
                className="always-enabled"
                style={{ marginBottom: "10px", paddingBottom: "0px" }}
              >
                <div>
                  Give the form a Name, the number of columns, indicate the tpe
                  and also the scope of the form and click Save. Add controls
                  and fields to the form below.
                  <ol>
                    <li>
                      Use the number of columns to provide the form layout that
                      you want.
                    </li>
                    <li>
                      Indicating a "Test Portfolio" will allow you to test the
                      form, using dropdown values from that portfolio.
                    </li>
                    <li>
                      To apply the form to a portfolio go to the "Business Case"
                      tab of the Portfolio - Settings screen.
                    </li>
                  </ol>
                </div>
              </Alert>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Form Name</Label>
                  <Input
                    type="text"
                    onChange={e => changeFormProp("formName", e.target.value)}
                    value={formToEdit.formName}
                  />
                  {hasFieldError(errorFields, "formName") && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Name is required
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Form Type</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={formToEdit.formTypeId}
                    onChange={e => {
                      changeFormProp("formTypeId", e.target.value)
                    }}
                  >
                    <option value="-1">Select type...</option>
                    {formTypes.map((s, i) => (
                      <option key={i} value={s.formTypeId}>
                        {s.type}
                      </option>
                    ))}
                  </select>
                  {hasFieldError(errorFields, "formTypeId") && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Type is required
                    </div>
                  )}
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Columns</Label>
                  <Input
                    type="number"
                    onChange={e => changeFormProp("numColumns", e.target.value)}
                    value={formToEdit.numColumns}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Scope</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={formToEdit.scope}
                    onChange={e => {
                      changeFormProp("scope", e.target.value)
                      if (e.target.value != -1) {
                        loadFields(formToEdit.enterpriseId, e.target.value)
                      } else {
                        setFields([])
                      }
                    }}
                  >
                    <option value="-1">Select scope...</option>
                    {scopes.map((s, i) => (
                      <option key={i} value={s}>
                        {s}
                      </option>
                    ))}
                  </select>
                  {hasFieldError(errorFields, "scope") && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Scope is required
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-input"
                id="customCheck3"
                checked={formToEdit.inactive}
                onClick={e => {
                  changeFormProp("inactive", !formToEdit.inactive)
                }}
              />
              <Label className="form-check-label" for="customCheck3">
                Inactive
              </Label>
            </div>
            <div className="mb-3">
              <Label className="form-label">Test Decision</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={formToEdit.testScenarioId}
                onChange={e => {
                  changeFormProp("testScenarioId", e.target.value)
                }}
              >
                <option value="-1">Select decision...</option>
                {scenarios.map((s, i) => (
                  <option key={i} value={s.scenarioID}>
                    {s.scenarioName}
                  </option>
                ))}
              </select>
              {hasFieldError(errorFields, "testScenarioId") && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Test Decision is required
                </div>
              )}
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={addFormField}
            >
              <i className="fa fa-plus"></i> Add Control
            </button>
            <div style={{ paddingTop: "10px" }}></div>
            <FormFieldTable
              formFields={formToEdit.formFields}
              editFormField={editFormField}
              updateFormFields={updateFormFields}
            />
          </div>
        )}
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary save-user"
              onClick={async () => {
                await save(false)
                toggleDynamicFormModal()
              }}
              disabled={formToEdit?.testScenarioId <= 0 || isSubmitting}
            >
              Test Form
            </button>
          </div>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={save}
          >
            Save
          </button>
        </div>
      </Modal>
      {formToEdit != null && (
        <EditFormFieldModal
          isOpen={showEditFormFieldModal}
          toggleModal={toggleEditFormFieldModal}
          scope={formToEdit.scope}
          formFieldTypes={formFieldTypes}
          fields={fields}
          formFieldSelectLists={formFieldSelectLists}
          formFieldToEdit={formFieldToEdit}
          setFormFieldToEdit={setFormFieldToEdit}
          changeFormFieldProp={changeFormFieldProp}
          changeMultipleFormFieldProps={changeMultipleFormFieldProps}
          onFormFieldSave={onFormFieldSave}
          onFieldSave={onFieldSave}
          enterprise={enterprise}
        />
      )}
      {formToEdit != null && formToEdit.formId > 0 && (
        <DynamicFormModal
          isOpen={showDynamicFormModal}
          toggleModal={toggleDynamicFormModal}
          formId={formToEdit.formId}
          scenarioId={formToEdit.testScenarioId}
        />
      )}
    </>
  )
}

export default FormDesignerModal
