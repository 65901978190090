import React, { useRef } from "react"
import { toast } from "react-toastify"
import { Col, Input, Label, Row } from "reactstrap"

const ParticipantOptionsSection = ({ scenario, changeScenarioProp }) => {
  const viewIndyResultsRef1 = useRef()
  const viewResultsRef1 = useRef()
  const viewIndyPrescoresRef1 = useRef()
  const viewPrescoresRef1 = useRef()
  const viewIndyResultsRef2 = useRef()
  const viewResultsRef2 = useRef()
  const viewIndyPrescoresRef2 = useRef()
  const viewPrescoresRef2 = useRef()

  const handleCheckboxMultiResultsChange = (
    inputNumber,
    newVal,
    ref1,
    ref2,
    scenarioProp
  ) => {
    let res = 0
    if (inputNumber == 1) {
      if (!newVal && !ref2.current.props.checked) {
        res = 0
      } else if (newVal && ref2.current.props.checked) {
        res = 3
      } else if (newVal && !ref2.current.props.checked) {
        res = 1
      } else {
        res = 2
      }
    } else {
      if (!newVal && !ref1.current.props.checked) {
        res = 0
      } else if (newVal && ref1.current.props.checked) {
        res = 3
      } else if (newVal && !ref1.current.props.checked) {
        res = 2
      } else {
        res = 1
      }
    }

    changeScenarioProp(scenarioProp, res)
  }

  const viewParticipantReport = e => {
    e && e.preventDefault()
  }

  const clearParticipantData = e => {
    e && e.preventDefault()
  }

  return (
    <React.Fragment>
      <Row>
        <Col sm="4">
          <div className="mb-3">
            <Label className="form-label">Unique Identifier</Label>
            <div>
              <div className="form-check" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="anon1"
                  id="anon1"
                  value="0"
                  checked={scenario.anonymous == 0}
                  onClick={e => changeScenarioProp("anonymous", 0)}
                />
                <label
                  className="form-check-label"
                  htmlFor="anon1"
                  style={{ marginLeft: "10px" }}
                >
                  Email Address
                </label>
              </div>
              <div className="form-check" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="anon2"
                  id="anon2"
                  value="1"
                  style={{ marginLeft: "20px" }}
                  checked={scenario.anonymous == 1}
                  onClick={e => changeScenarioProp("anonymous", 1)}
                />
                <label
                  className="form-check-label"
                  htmlFor="anon2"
                  style={{ marginLeft: "10px" }}
                >
                  Code
                </label>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="4">
          <div className="mb-3">
            <Label className="form-label">
              Alternative Scoring Question Format
            </Label>
            <div>
              <div className="form-check" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="altScoreFormat1"
                  id="altScoreFormat1"
                  value="0"
                  checked={scenario.altScoreFormat == 0}
                  onClick={e => changeScenarioProp("altScoreFormat", 0)}
                />
                <label
                  className="form-check-label"
                  htmlFor="altScoreFormat1"
                  style={{ marginLeft: "10px" }}
                >
                  Default Version
                </label>
              </div>
              <div className="form-check" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="altScoreFormat2"
                  id="altScoreFormat2"
                  value="1"
                  style={{ marginLeft: "20px" }}
                  checked={scenario.altScoreFormat == 1}
                  onClick={e => changeScenarioProp("altScoreFormat", 1)}
                />
                <label
                  className="form-check-label"
                  htmlFor="altScoreFormat2"
                  style={{ marginLeft: "10px" }}
                >
                  Selector Version
                </label>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="4">
          <div className="mb-3">
            <Label className="form-label">
              Pairwise Comparison Question Format
            </Label>
            <div>
              <div className="form-check" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="pwcFormat1"
                  id="pwcFormat1"
                  value="0"
                  checked={scenario.pwcFormat == 0}
                  onClick={e => changeScenarioProp("pwcFormat", 0)}
                />
                <label
                  className="form-check-label"
                  htmlFor="pwcFormat1"
                  style={{ marginLeft: "10px" }}
                >
                  Default Version
                </label>
              </div>
              <div className="form-check" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="pwcFormat2"
                  id="pwcFormat2"
                  value="1"
                  style={{ marginLeft: "20px" }}
                  checked={scenario.pwcFormat == 1}
                  onClick={e => changeScenarioProp("pwcFormat", 1)}
                />
                <label
                  className="form-check-label"
                  htmlFor="pwcFormat2"
                  style={{ marginLeft: "10px" }}
                >
                  Selector Version
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Input
              ref={viewIndyResultsRef1}
              type="checkbox"
              className="form-check-input"
              id="pwcindi1"
              checked={
                scenario.viewIndyResults == 3 || scenario.viewIndyResults == 1
              }
              onClick={e => {
                handleCheckboxMultiResultsChange(
                  1,
                  !e.target.checked,
                  viewIndyResultsRef1,
                  viewIndyResultsRef2,
                  "viewIndyResults"
                )
              }}
            />
            <Label
              className="form-check-label"
              for="pwcindi1"
              style={{ marginLeft: "10px" }}
            >
              PwC Results - Individual
            </Label>
          </div>
          <div className="mb-3">
            <Input
              ref={viewResultsRef1}
              type="checkbox"
              className="form-check-input"
              id="viewresults1"
              checked={scenario.viewResults == 3 || scenario.viewResults == 1}
              onClick={e => {
                handleCheckboxMultiResultsChange(
                  1,
                  !e.target.checked,
                  viewResultsRef1,
                  viewResultsRef2,
                  "viewResults"
                )
              }}
            />
            <Label
              className="form-check-label"
              for="viewresults1"
              style={{ marginLeft: "10px" }}
            >
              PwC Results - Group
            </Label>
          </div>
          <div className="mb-3">
            <Input
              ref={viewIndyPrescoresRef1}
              type="checkbox"
              className="form-check-input"
              id="viewindiprescores1"
              checked={
                scenario.viewIndyPreScores == 3 ||
                scenario.viewIndyPreScores == 1
              }
              onClick={e => {
                handleCheckboxMultiResultsChange(
                  1,
                  !e.target.checked,
                  viewIndyPrescoresRef1,
                  viewIndyPrescoresRef2,
                  "viewIndyPreScores"
                )
              }}
            />
            <Label
              className="form-check-label"
              for="viewindiprescores1"
              style={{ marginLeft: "10px" }}
            >
              Ranked Alternatives - Individual (using individual PwC results)
            </Label>
          </div>
          <div className="mb-3">
            <Input
              ref={viewPrescoresRef1}
              type="checkbox"
              className="form-check-input"
              id="viewprescores1"
              checked={
                scenario.viewPreScores == 3 || scenario.viewPreScores == 1
              }
              onClick={e => {
                handleCheckboxMultiResultsChange(
                  1,
                  !e.target.checked,
                  viewPrescoresRef1,
                  viewPrescoresRef2,
                  "viewPreScores"
                )
              }}
            />
            <Label
              className="form-check-label"
              for="viewprescores1"
              style={{ marginLeft: "10px" }}
            >
              Ranked Alternatives - Group (using group PwC results)
            </Label>
          </div>
        </Col>
        <Col sm="6">
          <div className="mb-3">
            <Input
              ref={viewIndyResultsRef2}
              type="checkbox"
              className="form-check-input"
              id="pwcindi2"
              checked={
                scenario.viewIndyResults == 3 || scenario.viewIndyResults == 2
              }
              onClick={e => {
                handleCheckboxMultiResultsChange(
                  2,
                  !e.target.checked,
                  viewIndyResultsRef1,
                  viewIndyResultsRef1,
                  "viewIndyResults"
                )
              }}
            />
            <Label
              className="form-check-label"
              for="pwcindi2"
              style={{ marginLeft: "10px" }}
            >
              Email
            </Label>
          </div>
          <div className="mb-3">
            <Input
              ref={viewResultsRef2}
              type="checkbox"
              className="form-check-input"
              id="viewresults2"
              checked={scenario.viewResults == 3 || scenario.viewResults == 2}
              onClick={e => {
                handleCheckboxMultiResultsChange(
                  2,
                  !e.target.checked,
                  viewResultsRef1,
                  viewResultsRef2,
                  "viewResults"
                )
              }}
            />
            <Label
              className="form-check-label"
              for="viewresults2"
              style={{ marginLeft: "10px" }}
            >
              Email
            </Label>
          </div>
          <div className="mb-3">
            <Input
              ref={viewIndyPrescoresRef2}
              type="checkbox"
              className="form-check-input"
              id="viewindiprescores2"
              checked={
                scenario.viewIndyPreScores == 3 ||
                scenario.viewIndyPreScores == 2
              }
              onClick={e => {
                handleCheckboxMultiResultsChange(
                  2,
                  !e.target.checked,
                  viewIndyPrescoresRef1,
                  viewIndyPrescoresRef2,
                  "viewIndyPreScores"
                )
              }}
            />
            <Label
              className="form-check-label"
              for="viewindiprescores2"
              style={{ marginLeft: "10px" }}
            >
              Email
            </Label>
          </div>
          <div className="mb-3">
            <Input
              ref={viewPrescoresRef2}
              type="checkbox"
              className="form-check-input"
              id="viewprescores2"
              checked={
                scenario.viewPreScores == 3 || scenario.viewPreScores == 2
              }
              onClick={e => {
                handleCheckboxMultiResultsChange(
                  2,
                  !e.target.checked,
                  viewPrescoresRef1,
                  viewPrescoresRef2,
                  "viewPreScores"
                )
              }}
            />
            <Label
              className="form-check-label"
              for="viewprescores2"
              style={{ marginLeft: "10px" }}
            >
              Email
            </Label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <div style={{ display: "inline-block" }}>
            <Input
              type="checkbox"
              className="form-check-input"
              id="viewconsensus"
              defaultChecked={scenario.part_ViewConsensus}
              onChange={e => {
                changeScenarioProp(
                  "part_ViewConsensus",
                  !scenario.part_ViewConsensus
                )
              }}
            />
            <Label
              className="form-check-label"
              for="viewconsensus"
              style={{ marginLeft: "10px" }}
            >
              View Scoring Consensus
            </Label>
          </div>
          <div style={{ display: "inline-block", marginLeft: "20px" }}>
            <Input
              type="checkbox"
              className="form-check-input"
              id="viewhtmap"
              defaultChecked={scenario.part_ViewHeatmap}
              onChange={e => {
                changeScenarioProp(
                  "part_ViewHeatmap",
                  !scenario.part_ViewHeatmap
                )
              }}
            />
            <Label
              className="form-check-label"
              for="viewhtmap"
              style={{ marginLeft: "10px" }}
            >
              View Heatmap
            </Label>
          </div>
          <div style={{ display: "inline-block", marginLeft: "20px" }}>
            <Input
              type="checkbox"
              className="form-check-input"
              id="viewscens"
              defaultChecked={scenario.part_ViewScenarios}
              onChange={e => {
                changeScenarioProp(
                  "part_ViewScenarios",
                  !scenario.part_ViewScenarios
                )
              }}
            />
            <Label
              className="form-check-label"
              for="viewscens"
              style={{ marginLeft: "10px" }}
            >
              View Scenarios
            </Label>
          </div>
          <div style={{ display: "inline-block", marginLeft: "20px" }}>
            <Input
              type="checkbox"
              className="form-check-input"
              id="viewreps"
              defaultChecked={scenario.part_ViewReports}
              onChange={e => {
                changeScenarioProp(
                  "part_ViewReports",
                  !scenario.part_ViewReports
                )
              }}
            />
            <Label
              className="form-check-label"
              for="viewreps"
              style={{ marginLeft: "10px" }}
            >
              View Reports
            </Label>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <a href="#" onClick={e => viewParticipantReport(e)}>
            View Participant Actions Report
          </a>
          <br />
          <a href="#" onClick={e => clearParticipantData(e)}>
            Clear Participant Actions Data
          </a>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ParticipantOptionsSection
