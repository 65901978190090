import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import AlternativeRatingTable from "pages/_AlternativeShared/AlternativeRatingTable"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import SelectAlternativeModal from "./SelectAlternativeModal"
import { loadUsersAvatars } from "utils/avatar"
import isNullOrEmpty from "utils/isNullOrEmpty"

const AdminAlternativeScore = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [nextAltScore, setNextAltScore] = useState(null)
  let [userScores, setUserScores] = useState([])
  let [scenarioRatingScale, setScenarioRatingScale] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  let [scenario, setScenario] = useState(null)
  let [showAltModal, setShowAltModal] = useState(false)
  const [usersPhotos, setUsersPhotos] = useState([])

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "adminAlternativeScore",
      })
    )
    loadNextData(0, 1, "alt", true)
    loadScenario()
    loadScenarioRatingScale()
  }, [])

  const loadNextData = async (
    altScoreId,
    levelId,
    altOrObj,
    loadUsersPhotos = false
  ) => {
    let altScore = await loadNextAltScore(altScoreId, levelId, altOrObj)
    setNextAltScore(altScore)

    let userScores = await loadUserScores(altScore.altScoreID, altScore.levelID)
    if (loadUsersPhotos) {
      let avatars = await loadUsersAvatars(userScores.map(x => x.userID))
      setUsersPhotos(avatars)
    }
    setUserScores(userScores)
  }

  const loadPrevData = async (altScoreId, levelId, altOrObj) => {
    let altScore = await loadPrevAltScore(altScoreId, levelId, altOrObj)
    console.log("prev alt score")
    console.log(altScore)
    setNextAltScore(altScore)

    let userScores = await loadUserScores(altScore.altScoreID, altScore.levelID)
    setUserScores(userScores)
  }

  const loadAlternativeFromModal = async alternativeId => {
    try {
      setIsLoading(true)
      let altScore = await api.getAltScoreByAltId(
        scenarioId,
        alternativeId,
        nextAltScore.objMeasID,
        nextAltScore.levelID
      )
      console.log("alt by id")
      console.log(altScore)
      setNextAltScore(altScore)

      let userScores = await loadUserScores(
        altScore.altScoreID,
        altScore.levelID
      )
      setUserScores(userScores)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadNextAltScore = async (altScoreId, levelId, altOrObj) => {
    try {
      setIsLoading(true)
      let altScore = await api.getNextAltScore(
        altScoreId,
        levelId,
        scenarioId,
        altOrObj
      )
      setIsLoading(false)

      return altScore
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadPrevAltScore = async (altScoreId, levelId, altOrObj) => {
    try {
      setIsLoading(true)
      let altScore = await api.getPrevAltScore(
        altScoreId,
        levelId,
        scenarioId,
        altOrObj
      )
      setIsLoading(false)
      return altScore
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const reloadAltScore = async (altScoreId, levelId) => {
    try {
      setIsLoading(true)
      let altScore = await api.getAltScore(altScoreId, levelId)
      setNextAltScore(altScore)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadUserScores = async (altScoreId, levelId) => {
    try {
      let scores = await api.getAltUserScores(altScoreId, levelId)
      return scores
    } catch (err) {
      console.log(err)
    }
  }

  const loadScenario = async () => {
    try {
      var scenario = await api.getScenario(scenarioId)
      setScenario(scenario)
    } catch (err) {
      console.log(err)
    }
  }

  const loadScenarioRatingScale = async () => {
    try {
      var scenarioRatings = await api.getScenarioInputRatings(scenarioId)
      setScenarioRatingScale(
        scenarioRatings.sort(
          (a, b) => parseFloat(a.inputValue) - parseFloat(b.inputValue)
        )
      )
    } catch (err) {
      console.log(err)
    }
  }

  const recalculate = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title:
          "Recalculating the alternatives will aggregate and synthesize the responses provided by all participants, to include those participating in Individual mode. Click OK to continue, or cancel.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Continue",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          try {
            await api.recalculateAlternative(currentUser.userID, scenarioId)
          } catch (err) {
            console.log(err)
            alert(err)
          }
        }
      })
  }

  const toggleAltModal = () => {
    setShowAltModal(!showAltModal)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: "22%",
              marginRight: "0%",
            }}
          >
            {nextAltScore != null && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary "
                  style={{ marginRight: "10px" }}
                  onClick={async () =>
                    await loadPrevData(
                      nextAltScore.altScoreID,
                      nextAltScore.levelID,
                      "alt"
                    )
                  }
                >
                  <i className="fas fa-arrow-left"></i> Previous{" "}
                  {!isNullOrEmpty(scenario) && scenario.alternativeName}
                </button>
                <button
                  type="button"
                  className="btn btn-primary  mt-2"
                  style={{ marginRight: "10px" }}
                  onClick={async () =>
                    await loadPrevData(
                      nextAltScore.altScoreID,
                      nextAltScore.levelID,
                      "obj"
                    )
                  }
                >
                  <i className="fas fa-arrow-left"></i> Previous{" "}
                  {!isNullOrEmpty(scenario) && scenario.objectiveName}
                </button>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
              <button
                type="button"
                className="btn btn-primary"
                onClick={async () => toggleAltModal()}
              >
                Go To {!isNullOrEmpty(scenario) && scenario.alternativeName}
              </button>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
            </div>
            {nextAltScore != null && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={async () =>
                    await loadNextData(
                      nextAltScore.altScoreID,
                      nextAltScore.levelID,
                      "alt"
                    )
                  }
                >
                  Next {!isNullOrEmpty(scenario) && scenario.alternativeName}{" "}
                  <i className="fas fa-arrow-right"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-primary  mt-2"
                  onClick={async () =>
                    await loadNextData(
                      nextAltScore.altScoreID,
                      nextAltScore.levelID,
                      "obj"
                    )
                  }
                >
                  Next {!isNullOrEmpty(scenario) && scenario.objectiveName}{" "}
                  <i className="fas fa-arrow-right"></i>
                </button>
              </div>
            )}
          </div>

          <Row style={{ marginLeft: "22%", marginRight: "0%" }}>
            {nextAltScore != null && (
              <div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "5px",
                    //backgroundColor: "lightyellow",
                    borderRadius: "10px",
                    padding: "15px",
                  }}
                >
                  <h5>
                    <u>
                      <b>Score {nextAltScore.comparison}</b>
                    </u>
                  </h5>
                  <h5>
                    To what degree does the{" "}
                    <span style={{ color: "maroon", textAlign: "center" }}>
                      <b>{nextAltScore.alternativeName}</b>
                    </span>{" "}
                    project
                  </h5>
                  <h5>
                    contribute to the{" "}
                    {nextAltScore.levelID == 2
                      ? scenario?.objectiveName?.toLowerCase() +
                        " / " +
                        scenario?.subCriteriaName?.toLowerCase()
                      : !isNullOrEmpty(scenario) && scenario.objectiveName.toLowerCase()}{" "}
                    <span style={{ color: "maroon", textAlign: "center" }}>
                      <b>{nextAltScore.objMeasName}</b>
                    </span>
                  </h5>
                  {!isNullOrEmpty(nextAltScore.ratingNotes) && (
                    <>
                      <i
                        className="fas fa-info-circle"
                        style={{ cursor: "pointer", fontSize: '20px', color: colors.primary }}
                        onClick={() => {
                          setNextAltScore({
                            ...nextAltScore,
                            showRatingNotes: !nextAltScore.showRatingNotes,
                          })
                        }}
                      ></i>
                      {nextAltScore.showRatingNotes && (
                        <div className="alternative-rating-notes-container"
                          dangerouslySetInnerHTML={{
                            __html: nextAltScore.ratingNotes,
                          }}
                        ></div>
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "5px",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      border: "1px solid gray",
                      padding: "15px",
                    }}
                  >
                    <p style={{ marginBottom: "0px" }}>
                      <b>The mean score is: {nextAltScore.score}</b>
                    </p>
                    <p style={{ marginBottom: "0px" }}>
                      <b>The standard deviation is: {nextAltScore.standDev}</b>
                    </p>
                    <p style={{ marginBottom: "0px" }}>
                      <b>The group rating is: {nextAltScore.verbalScore}</b>
                    </p>
                    <p style={{ marginBottom: "0px" }}>
                      <b>
                        The overall {scenario.alternativeName.toLowerCase()}{" "}
                        benefit score is: {nextAltScore.saaScore}
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Row>

          {scenario != null &&
            userScores != null &&
            userScores.length > 0 &&
            scenarioRatingScale != null &&
            scenarioRatingScale.length > 0 && (
              <>
                <Row style={{ marginTop: "5px" }}>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={recalculate}
                    >
                      <i className="fas fa-calculator"></i> Recalculate
                    </button>
                  </div>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <AlternativeRatingTable
                    scenarioRatingScale={scenarioRatingScale}
                    userScores={userScores}
                    setUserScores={setUserScores}
                    altScore={nextAltScore}
                    hasWeight={true}
                    canChangeWeight={true}
                    canChangeRating={true}
                    reloadAltScore={reloadAltScore}
                    usersPhotos={usersPhotos}
                  />
                </Row>
              </>
            )}
        </Container>
        <SelectAlternativeModal
          isOpen={showAltModal}
          toggleModal={toggleAltModal}
          scenarioId={scenarioId}
          scenario={scenario}
          onSelect={loadAlternativeFromModal}
        />
      </div>
    </React.Fragment>
  )
}

export default AdminAlternativeScore
