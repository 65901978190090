import React, { useEffect, useRef, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import Geocode from "react-geocode"
import api from "services/api.service"
import { userService } from "services/user.service"
import { toast } from "react-toastify"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import { MapWrapped } from "../LocationSection"
import EditAddressLocation from "./EditAddressLocation"
import EditPolygonLocation from "./EditPolygonLocation"
import EditCircleLocation from "./EditCircleLocation"
import isNullOrEmpty from "utils/isNullOrEmpty"
import CustomColorPicker from "components/custom/CustomColorPicker"

const EditLocationModal = ({
  location,
  isOpen,
  toggleModal,
  reloadLocations,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [locationToEdit, setLocationToEdit] = useState(null)
  const [markers, setMarkers] = useState([])
  const [circles, setCircles] = useState([])
  const [addressValidated, setAddressValidated] = useState(false)
  const addressLocationRef = useRef()

  const [defaultCenter, setDefaultCenter] = useState(null)

  useEffect(() => {
    Geocode.setApiKey("AIzaSyA9GCVC7tKsdhlBU0HLkar9mlVSLKyWrgY")
    addressLocationRef.current = { onMapClick: () => {} }
  }, [])

  useEffect(() => {
    if (isOpen) {
      let locationData =
        location.locationData != undefined
          ? JSON.parse(location.locationData)
          : null
      setLocationToEdit({
        locationID: location.locationID,
        alternativeID: location.alternativeID,
        address: { value: location.address, isDirty: false },
        city: { value: location.city, isDirty: false },
        zip: { value: location.zip, isDirty: false },
        state: { value: location.state, isDirty: false },
        lat: { value: location.lat, isDirty: false },
        lon: { value: location.lon, isDirty: false },
        locationData: {
          value: locationData,
          isDirty: false,
        },
        locationTypeID: { value: location.locationTypeID, isDirty: false },
        color: { value: location.color, isDirty: false },
      })
      let isCenterSet = false

      // Address
      if (location.locationTypeID == 1) {
        if (!isNullOrEmpty(location.lat) && !isNullOrEmpty(location.lon)) {
          setMarkers([{ lat: location.lat, lng: location.lon }])
          setDefaultCenter({ lat: location.lat, lng: location.lon })
          isCenterSet = true
          setCircles([])
        } else {
          setMarkers([])
          setCircles([])
        }
      } else if (location.locationTypeID == 2) {
        // Polygon
        if (
          !isNullOrEmpty(locationData) &&
          !isNullOrEmpty(locationData.markers) &&
          locationData.markers.length > 0
        ) {
          setMarkers(locationData.markers)
          setDefaultCenter({
            lat: locationData.markers.lat,
            lng: locationData.markers.lng,
          })
          isCenterSet = true
          setCircles([])
        } else {
          setMarkers([])
          setCircles([])
        }
      } else if (location.locationTypeID == 3) {
        // Circle
        if (
          !isNullOrEmpty(locationData) &&
          !isNullOrEmpty(locationData.circles) &&
          locationData.circles.length > 0
        ) {
          setCircles(locationData.circles)
          setDefaultCenter({
            lat: locationData.circles.lat,
            lng: locationData.circles.lng,
          })
          isCenterSet = true
          setMarkers([])
        } else {
          setCircles([])
          setMarkers([])
        }
      } else {
        setMarkers([])
        setCircles([])
      }

      if (!isCenterSet) {
        loadDefaultCenter()
      }
    }
    // if (
    //   location.lat != undefined &&
    //   location.lat != "" &&
    //   location.lon != undefined &&
    //   location.lon != ""
    // ) {
    //   setMarkers([{ lat: location.lat, lng: location.lon }])
    // } else {
    //   setMarkers([])
    // }
  }, [isOpen])

  const loadDefaultCenter = () => {
    if (!isNullOrEmpty(navigator?.geolocation)) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords
          setDefaultCenter({ lat: latitude, lng: longitude })
        },
        error => {
          console.error("Error getting location", error)
          setDefaultCenter({ lat: 38.897, lng: -77.03985 }) // Default: Washington DC
        }
      )
    } else {
      setDefaultCenter({ lat: 38.897, lng: -77.03985 }) // Default: Washington DC
    }
  }

  const changeLocationProp = (prop, value) => {
    setAddressValidated(false)
    setLocationToEdit({
      ...locationToEdit,
      [prop]: { value: value, isDirty: true },
    })
  }

  const onMapClick = e => {
    addressLocationRef.current.onMapClick(e)
  }

  const saveLocation = async () => {
    if (
      locationToEdit.locationID == undefined ||
      locationToEdit.locationID < 0
    ) {
      await api.createAlternativeLocation(currentUser.userID, {
        locationID: -1,
        alternativeID: locationToEdit.alternativeID,
        locationTypeID: locationToEdit.locationTypeID.value,
        address: locationToEdit.address.value,
        city: locationToEdit.city.value,
        zip: locationToEdit.zip.value,
        state: locationToEdit.state.value,
        lat: locationToEdit.lat.value,
        lon: locationToEdit.lon.value,
        locationData: JSON.stringify({ circles, markers }),
      })
      toast.success("Location added successfully")
    } else {
      await api.updateAlternativeLocation(currentUser.userID, {
        locationID: locationToEdit.locationID,
        locationTypeID: locationToEdit.locationTypeID.value,
        alternativeID: locationToEdit.alternativeID,
        address: locationToEdit.address.value,
        city: locationToEdit.city.value,
        zip: locationToEdit.zip.value,
        state: locationToEdit.state.value,
        lat: locationToEdit.lat.value,
        lon: locationToEdit.lon.value,
        locationData: JSON.stringify({ circles, markers }),
      })
      toast.success("Location updated successfully")
    }
    toggleModal()
    reloadLocations()
  }

  const deleteLocation = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this location?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteAlternativeLocation(locationToEdit.locationID)
          toggleModal()
          reloadLocations()
        }
      })
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit Location
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {locationToEdit !== null && (
        <div className="modal-body">
          <div className="mb-3">
            <label>Location Type</label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={locationToEdit.locationTypeID.value}
              onChange={e => {
                changeLocationProp("locationTypeID", e.target.value)
                setMarkers([])
                setCircles([])
              }}
            >
              <option value="-1">Select ...</option>
              <option value="1">Address</option>
              <option value="2">Polygon</option>
              <option value="3">Circle</option>
            </select>
          </div>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">
                  {locationToEdit.locationTypeID.value == 1
                    ? "Street Address"
                    : "Location Name"}
                </Label>
                <Input
                  name="address"
                  type="text"
                  onChange={e => changeLocationProp("address", e.target.value)}
                  value={locationToEdit.address.value}
                />
              </div>
            </Col>
          </Row>
          {(locationToEdit.locationTypeID.value == 2 ||
            locationToEdit.locationTypeID.value == 3) && (
            <Row>
              <Col>
                <CustomColorPicker
                  color={locationToEdit.color?.value ?? "#ffffff"}
                  handleColorChange={colorObj => {
                    changeLocationProp("color", colorObj.hex)
                  }}
                />
              </Col>
            </Row>
          )}
          {locationToEdit.locationTypeID.value == 1 && (
            <EditAddressLocation
              addressLocationRef={addressLocationRef}
              changeLocationProp={changeLocationProp}
              setLocationToEdit={setLocationToEdit}
              locationToEdit={locationToEdit}
              setMarkers={setMarkers}
              setAddressValidated={setAddressValidated}
            />
          )}
          {locationToEdit.locationTypeID.value == 2 && (
            <EditPolygonLocation
              addressLocationRef={addressLocationRef}
              setMarkers={setMarkers}
            />
          )}
          {locationToEdit.locationTypeID.value == 3 && (
            <EditCircleLocation
              addressLocationRef={addressLocationRef}
              setCircles={setCircles}
            />
          )}
          <Row>
            <Col>
              {defaultCenter != null && (
                <MapWrapped
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyA9GCVC7tKsdhlBU0HLkar9mlVSLKyWrgY&v=3.exp&libraries=geometry,drawing,places}`}
                  loadingElement={
                    <div style={{ height: "400px", width: "100%" }} />
                  }
                  containerElement={
                    <div style={{ height: `400px`, width: "100%" }} />
                  }
                  mapElement={
                    <div style={{ height: `400px`, width: "100%" }} />
                  }
                  defaultCenter={defaultCenter}
                  markers={markers}
                  setMarkers={setMarkers}
                  circles={circles}
                  setCircles={setCircles}
                  onMapClick={e => {
                    onMapClick(e)
                  }}
                  editable={true}
                />
              )}
            </Col>
          </Row>
        </div>
      )}
      <div className="modal-footer">
        <div>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          {locationToEdit?.locationID > 0 && (
            <button
              type="button"
              className="btn btn-danger save-user"
              onClick={deleteLocation}
            >
              Delete
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={saveLocation}
            disabled={isNullOrEmpty(locationToEdit?.address?.value)}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default EditLocationModal
