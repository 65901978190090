import { TextareaAutosize, Tooltip } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { getAlternativeYears } from "utils/alternativeHelper"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import TShirtHoursAllocationModal from "./Modals/TShirtHoursAllocationModal"
import useModal from "hooks/useModalHook"
import isNullOrEmpty from "utils/isNullOrEmpty"
import { sortAsc, sortDesc } from "utils/sort"

const ResourceBudgetingEstimateTab = ({
  isActive,
  isEditMode,
  alternative,
  alternativeId,

  selectedResourceGroupId,
  selectedBCCostPoolId,
  selectedBcLineItemId,

  resourceGroups,
  setResourceEstimateLogModel,
  toggleResourceEstimateLogModal,

  triggerSave,
  setTriggerSave,
  showToastChanges,
  maxYear,
}) => {
  const currentUser = userService.getLoggedInUser()

  //   const [resourceGroups, setResourceGroups] = useState([])
  // const [bcCostPools, setBcCostPools] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [altResourceGroups, setAltResourceGroups] = useState([])
  const [bcLineItems, setBcLineItems] = useState([])
  const [altResourceGroupsView, setAltResourceGroupsView] = useState([])
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false)
  const [emailNotes, setEmailNotes] = useState("")
  const [showManagerModal, setShowManagerModal] = useState(false)

  const alternativeYears = getAlternativeYears(alternative)
  const hasResourceWithTShirt =
    altResourceGroups.find(x => x.hasTShirts) != undefined

  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("asc")

  const [
    resourceGroupBundleIdForTShirtModal,
    setResourceGroupBundleIdForTShirtModal,
  ] = useState(null)
  const { show: showTShirtModal, toggle: toggleTShirtModal } = useModal()

  useEffect(() => {
    if (isActive && resourceGroups.length > 0) {
      loadData()
    }
  }, [
    isActive,
    isEditMode,
    selectedResourceGroupId,
    selectedBCCostPoolId,
    resourceGroups,
  ])

  useEffect(() => {
    if (isActive && triggerSave) {
      saveAltRg()
    }
  }, [isActive, triggerSave])

  const loadData = () => {
    if (isEditMode) {
      loadTableData(
        selectedResourceGroupId,
        selectedBCCostPoolId,
        resourceGroups
      )
    } else {
      loadViewTableData(selectedResourceGroupId, selectedBcLineItemId)
    }
  }

  //   const loadData = async () => {
  //     setIsLoading(true)
  //     // let rg = await api.getResourceGroups(alternative.scenarioID, 0)
  //     // setResourceGroups(rg)
  //     let bcitems = await api.getScenarioList(
  //       alternative.scenarioID,
  //       "BCLineItems"
  //     )
  //     setBcLineItems(bcitems)
  //     await loadTableData(selectedResourceGroupId, selectedBCCostPoolId, resourceGroups)
  //   }

  const loadTableData = async (rgId, cpId, allResourceGroups) => {
    try {
      setIsLoading(true)
      let data = await api.getAlternativeResourceBudgetsGrid(
        alternativeId,
        rgId,
        cpId,
        currentUser.userID
      )

      let mappedData = data.map((d, i) => {
        d.id = i

        let rg = allResourceGroups.find(
          x => x.resourceGroupID == d.resourceGroupID
        )
        if (rg !== undefined && d["c-Cost Pool"].startsWith("Project -")) {
          d.hasTShirts = rg.hasTShirts
        }

        return d
      })

      if (sortBy !== "") {
        setAltResourceGroups(
          sortDirection == "asc"
            ? sortAsc(mappedData, sortBy)
            : sortDesc(mappedData, sortBy)
        )
      } else {
        setAltResourceGroups(mappedData)
      }

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const loadViewTableData = async (rgId, lineItemId) => {
    if (altResourceGroupsView.length == 0) {
      setIsLoading(true)
    }
    let summary = await api.getCostPoolSummary(
      currentUser.userID,
      alternativeId,
      rgId
    )
    let data = await api.getAlternativeResourceBudgetsView(
      currentUser.userID,
      alternativeId,
      rgId,
      lineItemId
    )
    setAltResourceGroupsView([
      ...summary.map(s => {
        s.backgroundColor = "lightyellow"
        s.fontWeight = "bold"
        s.formatAsCurrency = true
        return s
      }),
      ...data,
    ])
    setIsLoading(false)
  }

  const changeAltResourceGroupProp = (id, prop, value, updateTotal) => {
    let copy = [...altResourceGroups]
    let alt = copy.find(x => x.id == id)
    alt[prop] = value
    if (updateTotal === true) {
      alt["c-Total"] = getAltRGTotal(alt)
      if (alt.yearsToUpdate == undefined) {
        alt.yearsToUpdate = [prop.replace("c-", "")]
      } else {
        let found = alt.yearsToUpdate.find(x => x == prop.replace("c-", ""))
        if (found == undefined) alt.yearsToUpdate.push(prop.replace("c-", ""))
      }
    }
    alt.isDirty = true
    showToastChanges()
    setAltResourceGroups(copy)
  }

  const getAltRGTotal = altRG => {
    let sum = 0
    alternativeYears.forEach(y => {
      sum += altRG[`c-${y}`]
    })
    return sum
  }

  const saveAltRg = async () => {
    await handleSave(altResourceGroups)
  }

  const handleSave = async listToSave => {
    toast.dismiss()
    let itemsToUpdate = []
    listToSave.forEach(alt => {
      if (alt.yearsToUpdate !== undefined && alt.yearsToUpdate.length > 0) {
        alt.yearsToUpdate.forEach(i => {
          itemsToUpdate.push({
            resourceGroupID: alt.resourceGroupID,
            alternativeID: alternativeId,
            costPoolID: alt.costPoolID,
            yearID: i,
            numUnits: alt[`c-${i}`],
          })
        })
      }
    })

    let rowsToUpdate = listToSave
      .filter(x => x.isDirty)
      .map(x => {
        return {
          resourceGroupID: x.resourceGroupID,
          costPoolID: x.costPoolID,
          alternativeID: alternativeId,
          budgetNotes: x["c-Estimation Notes"],
          budgetStatusID: x.budgetStatusID,
          altRGValue: x["c-Total"],
        }
      })

    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternativeResourceBudget(currentUser.userID, x)
      })

      let itemsToUpdatePlans = rowsToUpdate.map(x => async () => {
        let item = itemsToUpdate.find(
          i =>
            i.resourceGroupID == x.resourceGroupID &&
            i.costPoolID == x.costPoolID &&
            i.alternativeID == x.alternativeID
        )
        if (item != undefined) {
          // return await api.updateResourceGroupFromBudget(
          //   x.resourceGroupID,
          //   x.alternativeID,
          //   x.costPoolID,
          //   item.yearID
          // )
        }
        return undefined
      })

      await Promise.all(
        [
          ...itemsToUpdateTasks,
          ...itemsToUpdatePlans.filter(x => x !== undefined),
        ].map(t => t())
      )
    }
    debugger
    if (rowsToUpdate.length > 0) {
      let rowsToUpdateTasks = rowsToUpdate.map(x => async () => {
        return await api.updateAlternativeResourceFromBudget(
          currentUser.userID,
          x
        )
      })
      // let itemsToUpdatePlans = rowsToUpdate.map(x => async () => {
      //   return await api.updateResourceGroupFromBudget(
      //     x.resourceGroupID,
      //     x.alternativeID,
      //     x.costPoolID,
      //     x.yearID
      //   )
      // })

      await Promise.all(rowsToUpdateTasks.map(t => t()))
    }

    if (alternative.useFlowThrough) {
      await api.updateAlternativeFlowThrough(alternativeId)
    }

    toast.success(`Budgets saved successfully`)
    loadTableData(selectedResourceGroupId, selectedBCCostPoolId, resourceGroups)

    setTriggerSave(false)
  }

  const applyTShirtHoursAllocation = updatedCostPools => {
    debugger
    let copy = [...altResourceGroups]
    copy.forEach(c => {
      let updatedItem = updatedCostPools.find(x => x.rgeid == c.rgeid)
      if (updatedItem != undefined) {
        c.isDirty = true
        alternativeYears.forEach(y => {
          c["c-" + y] = parseInt(updatedItem["updated-" + y])
        })
        c["c-Total"] = getAltRGTotal(c)
        c.yearsToUpdate = [...alternativeYears]
      }
    })

    showToastChanges()
    setAltResourceGroups(copy)
    handleSave(copy)
  }

  const getSortIcon = prop => {
    let className = ""
    if (sortBy == prop) {
      className =
        sortDirection == "asc" ? "fas fa-chevron-down" : "fas fa-chevron-up"
    } else {
      className = "fas fa-chevron-down"
    }
    return (
      <i
        className={className}
        style={{ padding: "0px", marginLeft: "5px" }}
      ></i>
    )
  }

  const handleSort = prop => {
    let direction = ""
    if (sortBy == prop) {
      direction = sortDirection == "asc" ? "desc" : "asc"
    } else {
      direction = "asc"
      setSortBy(prop)
    }
    setSortDirection(direction)

    setAltResourceGroups(
      direction == "asc"
        ? sortAsc(altResourceGroups, prop)
        : sortDesc(altResourceGroups, prop)
    )
  }

  return (
    <>
      <Row>
        <Col>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {isEditMode && (
            <table
              className="table table-bordered lower-padding-table fixed-table-header"
              style={{ backgroundColor: "white" }}
            >
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                }}
              >
                <tr>
                  <th
                    style={{ width: "150", cursor: "pointer" }}
                    onClick={() => handleSort("c-Resource")}
                  >
                    Resource {getSortIcon("c-Resource")}
                  </th>
                  <th
                    style={{ width: "350", cursor: "pointer" }}
                    onClick={() => handleSort("c-Cost Pool")}
                  >
                    Cost Pool {getSortIcon("c-Cost Pool")}
                  </th>
                  {hasResourceWithTShirt && <th style={{ width: "200" }}></th>}
                  <th style={{ width: "100" }}>Unit</th>
                  <th style={{ width: "100" }}>Complete</th>
                  {alternativeYears.map((y, idx) => (
                    <th
                      key={idx}
                      style={{
                        width: `${25 / alternativeYears.length}%`,
                      }}
                    >
                      FY {y}
                    </th>
                  ))}
                  <th
                    style={{ width: "6%", cursor: "pointer" }}
                    onClick={() => handleSort("c-Total")}
                  >
                    Total {getSortIcon("c-Total")}
                  </th>
                  <th
                    style={{ width: "10%", cursor: "pointer" }}
                    onClick={() => handleSort("lastUpdate")}
                  >
                    Last Update {getSortIcon("lastUpdate")}
                  </th>
                  <th style={{ width: "15%" }}>Estimation Notes</th>
                </tr>
              </thead>
              <tbody style={{ overflowX: "auto" }}>
                {altResourceGroups.map((r, idx) => (
                  <tr key={idx}>
                    <td>
                      <div className="one-line-elipsis">
                        <Tooltip title={<h6>{r["c-Resource"]}</h6>}>
                          <span>
                            <b>{r["c-Resource"]}</b>
                          </span>
                        </Tooltip>
                      </div>
                    </td>
                    <td>
                      <div className="one-line-elipsis">
                        <Tooltip title={<h6>{r["c-Cost Pool"]}</h6>}>
                          <span>
                            <b>{r["c-Cost Pool"]}</b>
                          </span>
                        </Tooltip>
                      </div>
                    </td>
                    {hasResourceWithTShirt && (
                      <td>
                        {r.hasTShirts && (
                          <div
                            style={{
                              margin: "0",
                              textAlign: "center",
                              display: "inline-block",
                              padding: "4px 10px",
                              borderRadius: "15px",
                              borderColor: "lightgray",
                              borderWidth: "1px",
                              borderStyle: "solid",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={e => {
                              e.preventDefault()
                              let rg = resourceGroups.find(
                                x => x.resourceGroupID == r.resourceGroupID
                              )
                              if (rg != undefined) {
                                setResourceGroupBundleIdForTShirtModal({
                                  resourceGroupId: r.resourceGroupID,
                                  tShirtBundleId: rg.tShirtBundleID,
                                })
                                toggleTShirtModal()
                              }
                            }}
                          >
                            <i className="fas fa-tshirt"></i>&nbsp;T-Shirt
                          </div>
                        )}
                      </td>
                    )}
                    <td>
                      <div className="one-line-elipsis">
                        <Tooltip title={<h6>{r["c-Unit"]}</h6>}>
                          <span>{r["c-Unit"]}</span>
                        </Tooltip>
                      </div>
                    </td>
                    <td className="text-center">
                      <Input
                        type="checkbox"
                        disabled={r.userEdit == "0" ? true : false}
                        checked={r.budgetStatusID}
                        className="form-check-input"
                        onClick={() =>
                          changeAltResourceGroupProp(
                            r.id,
                            "budgetStatusID",
                            !r.budgetStatusID
                          )
                        }
                      />
                    </td>
                    {alternativeYears.map((y, idx2) => (
                      <td key={idx2} style={{ padding: "0px" }}>
                        <CurrencyInput
                          className="form-control"
                          disabled={
                            y < maxYear
                              ? true
                              : r.userEdit == "2"
                              ? false
                              : true
                          }
                          value={r[`c-${y}`]}
                          style={{
                            textAlign: "right",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                          onValueChange={(value, name, values) => {
                            changeAltResourceGroupProp(
                              r.id,
                              `c-${y}`,
                              values.float,
                              true
                            )
                          }}
                          placeholder=""
                          prefix={""}
                        />
                      </td>
                    ))}

                    <td className="text-right">{r["c-Total"]}</td>
                    <td>
                      <a
                        href="#"
                        onClick={e => {
                          e && e.preventDefault()
                          setResourceEstimateLogModel({
                            alternativeId: alternativeId,
                            costPoolId: r.costPoolID,
                            resourceGroupId: r.resourceGroupID,
                          })
                          toggleResourceEstimateLogModal()
                        }}
                      >
                        {r.lastUpdate}
                      </a>
                    </td>
                    <td>
                      <TextareaAutosize
                        className="form-control"
                        disabled={r.userEdit == "0" ? true : false}
                        minRows={1}
                        maxRows={3}
                        onChange={e =>
                          changeAltResourceGroupProp(
                            r.id,
                            "c-Estimation Notes",
                            e.target.value
                          )
                        }
                        value={r["c-Estimation Notes"]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {!isEditMode && (
            <table
              className="table table-bordered lower-padding-table"
              style={{ backgroundColor: "white" }}
            >
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                }}
              >
                <tr>
                  <th style={{ width: "10%" }}>Resource</th>
                  <th style={{ width: "15%" }}>Cost Pool</th>
                  <th style={{ width: "6%" }}>Complete</th>
                  {alternativeYears.map((y, idx) => (
                    <th
                      key={idx}
                      style={{
                        width: `${50 / alternativeYears.length}%`,
                      }}
                    >
                      FY {y}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {altResourceGroupsView.map((a, idx) => (
                  <tr
                    key={idx}
                    style={{
                      backgroundColor: a.backgroundColor
                        ? a.backgroundColor
                        : "initial",
                      fontWeight: a.fontWeight ? a.fontWeight : "unset",
                    }}
                  >
                    <td>{a["c-Resource"]}</td>
                    <td>{a["c-Cost Pool"]}</td>
                    <td className="text-center">
                      {a.budgetStatusID !== undefined && (
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          checked={a.budgetStatusID}
                          readOnly={true}
                        />
                      )}
                    </td>
                    {alternativeYears.map((y, idx) => (
                      <td
                        key={idx}
                        className="text-right"
                        style={{
                          backgroundColor:
                            !a.formatAsCurrency && a[`c-${y}`] == "0"
                              ? "#ededed"
                              : "initial",
                        }}
                      >
                        {a.formatAsCurrency
                          ? currencyFormatter.format(a[`c-${y}`])
                          : a[`c-${y}`] == "0"
                          ? "-"
                          : a[`c-${y}`]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Col>
      </Row>

      {!isNullOrEmpty(alternative) && (
        <TShirtHoursAllocationModal
          isOpen={showTShirtModal}
          toggleModal={toggleTShirtModal}
          // resourceGroupCostPools={resourceGroupCostPoolsForTShirtModal}
          resourceGroupBundleId={resourceGroupBundleIdForTShirtModal}
          alternativeYears={alternativeYears}
          applyTShirtHoursAllocation={applyTShirtHoursAllocation}
          alternativeId={alternativeId}
          alternativeName={alternative.alternativeName}
          scenarioId={alternative.scenarioID}
        />
      )}
    </>
  )
}

export default ResourceBudgetingEstimateTab
