import { toast } from "react-toastify"
import { LOADED_PAGE_ITEM, LOAD_PAGE_ITEM } from "./actionTypes"

const INIT_STATE = {
  pageItem: {
    pageSubTitle: "",
    pageTitle: "",
    pageHelpStatus: "",
    pageHelp: "",
    userRight: "",
    unreadNotifications: 0,
  },
}

const pageItem = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADED_PAGE_ITEM:
      if (
        state.pageItem.unreadNotifications < action.payload.unreadNotifications
      ) {
        toast.success("You have new notifications")
      }
      return {
        ...state,
        pageItem: action.payload,
      }

    default:
      return state
  }
}

export default pageItem
