import Breadcrumbs from "../../components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import api from "services/api.service"
import AddEditObjectiveModal from "./AddEditObjectiveModal"
import ObjectivesGrid from "./ObjectivesGrid"
import { useDispatch, useSelector } from "react-redux"
import { loadPageItem } from "store/actions"
import { userService } from "services/user.service"
import useModal from "hooks/useModalHook"
import ObjectivesChatGPTDrawer from "./ObjectivesChatGPTDrawer"
import { toast } from "react-toastify"
import useFetcher from "hooks/useFetcher"

const Objectives = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const newObjective = {
    objectiveID: -1,
    description: "",
    ratingNotes1: "",
    ratingNotes2: "",
    ratingNotes3: "",
    ratingNotes4: "",
    ratingNotes5: "",
    ratingNotes6: "",
    ratingNotes7: "",
    scenarioID: scenarioId,
    objectiveName: "",
    objShortName: "",
    autoScore: false
  }
  let [objectives, setObjectives] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  let [objective, setObjective] = useState(newObjective)
  let [showEditModal, setShowEditModal] = useState(false)
  let [scenario, setScenario] = useState(null)
  let [scenarioInputRatings, setScenarioInputRatings] = useState([])
  const { show: showChatGptDrawer, toggle: toggleChatGptDrawer } = useModal()
  const [showActionsDropdown, setShowActionsDropdown] = useState(false)
  const [addAsOptions, setAddAsOptions] = useState([])
  const [selectedAddAsOption, setSelectedAddAsOption] = useState(-1)
  const [objTypes, isLoadingObjTypes] = useFetcher(
    () => api.getList(currentUser.userID, "ObjType"),
    []
  )

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "objectives",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    let objs = await loadObjectives(true)
    let scen = await loadScenario()

    if (scen != null) {
      let options = []
      options.push({ objectiveName: scen.objectiveName, objectiveId: -1 })
      objs.forEach(o => {
        options.push({
          objectiveName: o.objectiveName,
          objectiveId: o.objectiveID,
        })
      })
      setAddAsOptions(options)
    }

    loadScenarioInputRatings()
  }

  const toggleEditModal = () => {
    if (showEditModal) {
      setObjective({ ...newObjective })
    }
    setShowEditModal(!showEditModal)
  }

  const addObjective = () => {
    setObjective({ ...newObjective })
    toggleEditModal()
  }

  const addAnotherObjective = () => {
    setObjective({ ...newObjective })
  }

  const addMeasure = objectiveId => {
    setObjective({
      ...newObjective,
      objectiveID: objectiveId,
      measureID: -1,
      isMeasure: true,
    })
    toggleEditModal()
  }
  const addAnotherMeasure = objectiveId => {
    setObjective({
      ...newObjective,
      objectiveID: objectiveId,
      measureID: -1,
      isMeasure: true,
    })
  }

  const loadObjectives = async toggleLoading => {
    try {
      if (toggleLoading) setIsLoading(true)
      var obj = await api.getObjectives(scenarioId)
      obj = obj.map(o => {
        o._children =
          o.measures.length > 0
            ? o.measures.map(m => {
                return {
                  ...m,
                  objectiveName: m.measureName,
                  objectiveID: m.measureID,
                  isMeasure: true,
                }
              })
            : []
        return o
      })

      setObjectives(obj)
      setIsLoading(false)
      return obj
    } catch (err) {
      setIsLoading(false)
      console.log(err)
      return []
    }
  }

  const loadScenarioInputRatings = async () => {
    try {
      var ratings = await api.getScenarioInputRatings(scenarioId)
      setScenarioInputRatings(ratings)
    } catch (err) {
      console.log(err)
    }
  }

  const loadScenario = async () => {
    try {
      var scen = await api.getScenario(scenarioId)
      setScenario(scen)
      return scen
    } catch (err) {
      console.log(err)
      return null
    }
  }

  const onAdd = async (name, description) => {
    let obj = {
      objectiveName: name,
      description: description,
      scenarioID: scenarioId,
    }
    if (selectedAddAsOption == -1) {
      //objective
      await api.createObjective(currentUser.userID, obj)
      toast.success(`${name} created successfully`)
    } else {
      //measure
      obj.measureName = name
      obj.isMeasure = true
      obj.objectiveID = selectedAddAsOption

      await api.createMeasure(currentUser.userID, obj)
      toast.success(`${name} created successfully`)
    }

    loadObjectives()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            <Col
              xs={showChatGptDrawer ? 6 : 12}
              sm={showChatGptDrawer ? 8 : 12}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {scenario != null && (
                  <button
                    className="btn btn-primary"
                    style={{ marginBottom: "20px" }}
                    onClick={addObjective}
                  >
                    + Add {scenario.objectiveName}
                  </button>
                )}

                {currentUser.useAI && (
                  <Dropdown
                    isOpen={showActionsDropdown}
                    toggle={() => setShowActionsDropdown(!showActionsDropdown)}
                  >
                    <DropdownToggle
                      tag="button"
                      className="btn btn-outline-primary save-user always-enabled"
                    >
                      Actions <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>

                    <DropdownMenu>
                      <DropdownItem
                        className="always-enabled"
                        onClick={toggleChatGptDrawer}
                      >
                        

                        {scenario != null && (
                          <div>
                  <i className="mdi mdi-brain" /> Ask AI for {scenario.objectiveName}
                  </div>
                      )}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}
              </div>

              {scenario != null && !isLoadingObjTypes && (
                <ObjectivesGrid
                  objectives={objectives}
                  isLoading={isLoading}
                  setObjective={setObjective}
                  toggleEditModal={toggleEditModal}
                  reloadGrid={loadObjectives}
                  addMeasure={addMeasure}
                  scenario={scenario}
                  objTypes={objTypes}
                />
              )}
            </Col>
            {showChatGptDrawer && (
              <Col xs={6} sm={4}>
                <ObjectivesChatGPTDrawer
                  isOpen={showChatGptDrawer}
                  toggle={toggleChatGptDrawer}
                  scope="Scenario"
                  field="ObjectiveList"
                  itemId={scenarioId}
                  addAsOptions={addAsOptions}
                  selectedAddAsOption={selectedAddAsOption}
                  setSelectedAddAsOption={setSelectedAddAsOption}
                  onAdd={onAdd}
                />
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <AddEditObjectiveModal
        objective={objective}
        scenarioInputRatings={scenarioInputRatings}
        isOpen={showEditModal}
        toggleModal={toggleEditModal}
        reloadGrid={loadObjectives}
        scenario={scenario}
        addAnotherMeasure={addAnotherMeasure}
        addAnotherObjective={addAnotherObjective}
      />
    </React.Fragment>
  )
}

export default Objectives
