import { Tooltip } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import EditResourceModal from "./EditResourceModal"

const ResourceCapacity = props => {
  const currentUser = userService.getLoggedInUser()
  const scenarioId = props.match.params.scenarioId
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [resourceCapacityData, setResourceCapacityData] = useState([])
  const [scenario, setScenario] = useState(null)
  const [resourceToEditId, setResourceToEditId] = useState(-1)
  const [showEditModal, setShowEditModal] = useState(false)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "resourceCapacity",
      })
    )
    loadData()
  }, [])

  const toggleEditModal = () => setShowEditModal(!showEditModal)

  const loadData = async () => {
    setIsLoading(true)
    let sc = await loadScenario()
    await loadResourceCapacities()
    setIsLoading(false)
    loadModalData(sc)
  }

  const loadResourceCapacities = async () => {
    let rsc = await api.getResourceGroupCapacityData(
      scenarioId,
      currentUser.userID
    )

    setResourceCapacityData(rsc)
  }

  const loadScenario = async () => {
    let sc = await api.getScenario(scenarioId)
    setScenario(sc)
    return sc
  }

  const loadModalData = async scen => {
    

   
  }

  const updateScenario = async scen => {
    await api.updateScenario(currentUser.userID, scen)
    await loadResourceCapacities()
  }

  const changeResourceGroupProp = (resourceGroupID, prop, value) => {
    let copy = [...resourceCapacityData]
    let item = copy.find(x => x.resourceGroupID == resourceGroupID)
    item[prop] = value
    item.isDirty = true
    if (item.dirtyProps == undefined) {
      item.dirtyProps = [prop]
    } else {
      if (item.dirtyProps.find(x => x == prop) == undefined) {
        item.dirtyProps.push(prop)
      }
    }
    showToastChanges()
    setResourceCapacityData(copy)
  }

  const saveResources = async () => {
    toast.dismiss()
    let dirtyResources = resourceCapacityData.filter(x => x.isDirty)
    if (dirtyResources.length > 0) {
      let tasks = []
      for (let i = 0; i < dirtyResources.length; i++) {
        for (let j = 0; j < dirtyResources[i].dirtyProps.length; j++) {
          tasks.push(async () => {
            return await api.updateResourceGroupCapacity(currentUser.userID, {
              resourceGroupID: dirtyResources[i].resourceGroupID,
              yearID: dirtyResources[i].dirtyProps[j].split("-")[0],
              monthID: dirtyResources[i].dirtyProps[j].split("-")[1],
              maxUnits: dirtyResources[i][dirtyResources[i].dirtyProps[j]],
            })
          })
        }
      }
      await Promise.all(tasks.map(t => t()))
      setResourceCapacityData(
        resourceCapacityData.map(x => {
          x.isDirty = false
          x.dirtyProps = []
          return x
        })
      )
      toast.success("Resources saved successfully")
    }
  }

  const addResource = () => {
    setResourceToEditId(-1)
    toggleEditModal()
  }

  const editResource = resource => {
    setResourceToEditId(resource.resourceGroupID)
    toggleEditModal()
  }

  const clearResource = resource => {
    let copy = [...resourceCapacityData]
    let item = copy.find(x => x.resourceGroupID == resource.resourceGroupID)
    Object.keys(item)
      .filter(x => x.indexOf("-") > -1)
      .forEach(p => {
        item[p] = 0
      })
    item.dirtyProps = [...Object.keys(item).filter(x => x.indexOf("-") > -1)]
    item.isDirty = true
    showToastChanges()
    setResourceCapacityData(copy)
  }

  const fillResource = resource => {
    let copy = [...resourceCapacityData]
    let item = copy.find(x => x.resourceGroupID == resource.resourceGroupID)
    let value = item[Object.keys(item).filter(x => x.indexOf("-") > -1)[0]]
    Object.keys(item)
      .filter(x => x.indexOf("-") > -1)
      .forEach(p => {
        item[p] = value
      })
    item.dirtyProps = [...Object.keys(item).filter(x => x.indexOf("-") > -1)]
    item.isDirty = true
    showToastChanges()
    setResourceCapacityData(copy)
  }

  const showToastChanges = () => {
    toast.success(
      <div>
        <p className="mb-0">
          You have unsaved changes, don't forget to save them.
        </p>
        <button
          className="btn btn-primary"
          style={{ width: "100%" }}
          onClick={() => {
            saveResources()
          }}
        >
          <i className="fas fa-save"></i> Save
        </button>
      </div>,
      {
        toastId: "unsavedChanges",
        autoClose: false,
      }
    )
  }

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              <Row>
                <Col md="6">
                  {scenario !== null && (
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label>Manage capacity from</Label>
                          <Input
                            name="startDate"
                            type="date"
                            onChange={e => {
                              updateScenario({
                                ...scenario,
                                resourceStartDT: moment(
                                  e.target.value,
                                  "YYYY-MM-DD"
                                ).format("YYYY-MM-DDThh:mm:ss"),
                              })
                              setScenario({
                                ...scenario,
                                resourceStartDT: moment(
                                  e.target.value,
                                  "YYYY-MM-DD"
                                ).format("YYYY-MM-DDThh:mm:ss"),
                              })
                            }}
                            value={moment(scenario.resourceStartDT).format(
                              "YYYY-MM-DD"
                            )}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label>To</Label>
                          <Input
                            name="endDate"
                            type="date"
                            onChange={e => {
                              updateScenario({
                                ...scenario,
                                resourceEndDT: moment(
                                  e.target.value,
                                  "YYYY-MM-DD"
                                ).format("YYYY-MM-DDThh:mm:ss"),
                              })
                              setScenario({
                                ...scenario,
                                resourceEndDT: moment(
                                  e.target.value,
                                  "YYYY-MM-DD"
                                ).format("YYYY-MM-DDThh:mm:ss"),
                              })
                            }}
                            value={moment(scenario.resourceEndDT).format(
                              "YYYY-MM-DD"
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <button className="btn btn-primary" onClick={addResource}>
                    + Add Resource
                  </button>
                  {/* <button
                    className="btn btn-primary save-user"
                    onClick={() => loadResourceCapacities()}
                  >
                    Load Resource Capacity
                  </button> */}
                </Col>
              </Row>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && resourceCapacityData.length > 0 && (
                <div style={{ overflowX: "auto" }}>
                  <table
                    className="table table-bordered low-padding-table"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <tr>
                        <th className="sticky-th">Resource</th>
                        <th style={{ minWidth: "150px" }}>Action</th>
                        {Object.keys(resourceCapacityData[0])
                          .filter(x => x.indexOf("-") > 0)
                          .map((x, idx) => (
                            <th key={idx}>
                              {monthNames[parseInt(x.split("-")[1]) - 1]}{" "}
                              {x.split("-")[0]}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {resourceCapacityData.map((r, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="sticky-td one-line-elipsis">
                              <Tooltip title={<h6>{r.resourceGroupName}</h6>}>
                                <span onClick={() => editResource(r)}>
                                  <b>{r.resourceGroupName}</b>
                                </span>
                              </Tooltip>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                              
                                <button
                                  className="btn btn-primary py-0"
                                  style={{ fontSize: "0.8em" }}
                                  onClick={() => fillResource(r)}
                                >
                                  Fill <i className="fas fa-arrow-right"></i>
                                </button>
                                <button
                                  className="btn btn-danger py-0"
                                  style={{ fontSize: "0.8em" }}
                                  onClick={() => clearResource(r)}
                                >
                                  Clear <i className="fas fa-eraser"></i>
                                </button>
                              </div>
                            </td>
                            {Object.keys(resourceCapacityData[0])
                              .filter(x => x.indexOf("-") > 0)
                              .map((key, idx) => {
                                return (
                                  <td key={`key-${idx}`}>
                                    <CurrencyInput
                                      id={r.resourceGroupID}
                                      name={r.resourceGroupID}
                                      className="form-control align-end"
                                      style={{ padding: "1px" }}
                                      value={r[key]}
                                      onValueChange={(value, name, values) => {
                                        changeResourceGroupProp(
                                          r.resourceGroupID,
                                          key,
                                          values.float
                                        )
                                      }}
                                      prefix={""}
                                    />
                                  </td>
                                )
                              })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <EditResourceModal
        resourceToEditId={resourceToEditId}
        isOpen={showEditModal}
        toggleModal={toggleEditModal}
        // templates={templates}
        // strategies={strategies}
        scenarioId={scenarioId}
        // users={users}
        //scenarioContractEntities={scenarioContractEntities}
        reloadResources={loadResourceCapacities}
        // costPools={costPools}
        // contractsForScenario={contractsForScenario}
        // resourceUnits={resourceUnits}
        // resourceClasses={classes}
      />
    </React.Fragment>
  )
}

export default ResourceCapacity
