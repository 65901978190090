import useFetcher from "hooks/useFetcher"
import moment from "moment"
import Multiselect from "multiselect-react-dropdown"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import resourceService from "services/resource.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { currencyFormatter, extractInnerTextFromHtml } from "utils/formatters"
import isNullOrEmpty from "utils/isNullOrEmpty"

const ResourceAlternativesModal = ({
  isOpen,
  toggleModal,
  resourceGroup,
  alternativeName,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [startDate, setStartDate] = useState(
    moment().format("YYYY-MM-DDT00:00:00")
  )
  const [endDate, setEndDate] = useState(
    moment().add(2, "years").format("YYYY-MM-DDT00:00:00")
  )
  const [gridData, setGridData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [gridMonthProps, setGridMonthProps] = useState([])

  const [versions] = useFetcher(
    () => api.getList(currentUser.userID, "Version"),
    []
  )
  const [selectedVersions, setSelectedVersions] = useState([
    { name: "B", id: "B" },
    { name: "R", id: "R" },
  ])

  useEffect(() => {
    if (
      isOpen &&
      !isNullOrEmpty(resourceGroup) &&
      resourceGroup.resourceGroupID > -1 &&
      !isNullOrEmpty(startDate) &&
      !isNullOrEmpty(endDate)
    ) {
      loadData()
    }
  }, [isOpen, resourceGroup, startDate, endDate])

  const loadData = async () => {
    setIsLoading(true)
    let data = await api.getAlternativeResourceGroupMonths_ByResourceGroup_Grid(
      resourceGroup.resourceGroupID,
      startDate,
      endDate
    )
    let gridMonths = resourceService.getGridMonthProps(data[0])
    if (data.length > 0) {
      setGridMonthProps(gridMonths)
    } else {
      setGridMonthProps([])
    }

    setGridData(
      data.filter(x => {
        if (isNullOrEmpty(gridMonths) || gridMonths.length == 0) {
          return true
        }

        return gridMonths.some(m => !isNullOrEmpty(x[m]) && x[m] != 0)
      })
    )

    setIsLoading(false)
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {resourceGroup?.resourceGroupName} {alternativeName}s
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="d-flex-row ai-center mb-3 gap-3">
          <div>
            <Label className="form-label">Start Date</Label>
            <Input
              type="month"
              onChange={e => {
                if (
                  moment(e.target.value, "YYYY-MM").isBefore(moment(endDate))
                ) {
                  setStartDate(
                    moment(e.target.value, "YYYY-MM").format(
                      "YYYY-MM-DDT00:00:00"
                    )
                  )
                }
              }}
              value={moment(startDate).format("YYYY-MM")}
            />
          </div>
          <div>
            <Label className="form-label">End Date</Label>
            <Input
              type="month"
              onChange={e => {
                if (
                  moment(e.target.value, "YYYY-MM").isAfter(moment(startDate))
                ) {
                  setEndDate(
                    moment(e.target.value, "YYYY-MM").format(
                      "YYYY-MM-DDT00:00:00"
                    )
                  )
                }
              }}
              value={moment(endDate).format("YYYY-MM")}
            />
          </div>
          <div>
            <Label className="form-label">Version</Label>
            <Multiselect
              options={versions.map(a => {
                return {
                  name: a.listItemName,
                  id: a.listItemID,
                }
              })}
              selectedValues={selectedVersions}
              onSelect={setSelectedVersions}
              onRemove={setSelectedVersions}
              displayValue="name"
              showCheckbox={true}
            />
          </div>
        </div>
        {isLoading && gridData.length == 0 && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}

        {!isLoading && gridData.length == 0 && <p>No data</p>}

        {!isLoading && gridData.length > 0 && (
          <div
            className="mb-3 my-sticky-table"
            style={{ maxHeight: "500px", overflow: "auto" }}
          >
            <table
              className="table table-bordered low-padding-table"
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <tr>
                  <th data-fixed-column="1" style={{ minWidth: "300px" }}>
                    Alternative Name
                  </th>
                  <th style={{ minWidth: "20px" }}>Version</th>
                  {gridMonthProps.map((m, idx) => (
                    <th key={idx} style={{ minWidth: "100px" }}>
                      {resourceService.getMonthAndYearTextByMonthProp(
                        startDate,
                        m
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {gridData
                  .filter(
                    x =>
                      isNullOrEmpty(selectedVersions) ||
                      selectedVersions.length == 0 ||
                      selectedVersions.find(
                        v => v.name == extractInnerTextFromHtml(x['c-Version'])
                      ) != undefined
                  )
                  .map((r, idx) => (
                    <tr key={idx}>
                      <td data-fixed-column="1">{r["c-Alternative"]}</td>
                      <td>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: r["c-Version"],
                          }}
                        ></div>
                      </td>
                      {gridMonthProps.map((m, idx) => (
                        <td key={idx} className="text-end">
                          {r[m] || 0}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}

export default ResourceAlternativesModal
