import { TextareaAutosize } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"

const GeneralSectionSecondaryModelSection = ({
  scenario,
  setScenario,
  objectiveLabels,
  measureLabels,
  criteriaPrioritizationStates,
  ahpMeans,
  ahpRatings,

  inputRatingScale,
  outputRatingScale,
  alternativeScoreLabels,
  changeScenarioProp
}) => {
  return (
    <React.Fragment>
      <Row>
        <Col sm="12">
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              name="scenarioName"
              type="text"
              onChange={e => changeScenarioProp("scenarioName", e.target.value)}
              value={scenario.scenarioName}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Description</Label>
            <TextareaAutosize
              className="form-control"
              minRows={2}
              onChange={e => changeScenarioProp("description", e.target.value)}
              value={scenario.description}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Decision Goal</Label>
            <TextareaAutosize
              className="form-control"
              minRows={2}
              onChange={e => changeScenarioProp("decisionGoal", e.target.value)}
              value={scenario.decisionGoal}
            />
          </div>
        </Col>
      </Row>
      <div className="divider"></div>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Criteria Label</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.objectiveName || -1}
              onChange={e => {
                changeScenarioProp("objectiveName", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {objectiveLabels.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Sub-Criteria Label</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.subCriteriaName || -1}
              onChange={e => {
                changeScenarioProp("subCriteriaName", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {measureLabels.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
      </Row>
      <div className="divider"></div>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Criteria Prioritization State</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.pairwiseStatusID || -1}
              onChange={e => {
                changeScenarioProp("pairwiseStatusID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {criteriaPrioritizationStates.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">AHP Ratings</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.ahpScaleID || -1}
              onChange={e => {
                changeScenarioProp("ahpScaleID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {ahpRatings.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">AHP Mean Calculation</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.meanTypeID || -1}
              onChange={e => {
                changeScenarioProp("meanTypeID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {ahpMeans.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div className="mb-3">
            <Label className="form-label">Input Rating Scale</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.inputRatingScaleID || -1}
              onChange={e => {
                changeScenarioProp("inputRatingScaleID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {inputRatingScale.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Alternative Score Label</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.scoreName || -1}
              onChange={e => {
                changeScenarioProp("scoreName", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {alternativeScoreLabels.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
        <Col md="6">
          <div className="mb-3">
            <Label className="form-label">Output Rating Scale</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.outputRatingScaleID || -1}
              onChange={e => {
                changeScenarioProp("outputRatingScaleID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {outputRatingScale.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
      </Row>
      <div className="divider" />
    </React.Fragment>
  )
}

export default GeneralSectionSecondaryModelSection
