import React, { useEffect, useRef, useState } from "react"
import {
  GanttComponent,
  Inject,
  Filter,
  Sort,
  Toolbar,
  Selection,
  RowDD,
  Edit,
  Resize,
  HolidaysDirective,
  DayMarkers,
  HolidayDirective,
  EditDialogFieldsDirective,
  EditDialogFieldDirective,
  AddDialogFieldDirective,
  AddDialogFieldsDirective,
} from "@syncfusion/ej2-react-gantt"
import { ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-gantt"
import { DropDownList } from "@syncfusion/ej2-react-dropdowns"
import NameAvatar from "components/custom/nameAvatar"
import { userService } from "services/user.service"
import api from "services/api.service"
import moment from "moment"
import isNullOrEmpty from "utils/isNullOrEmpty"
import { loadUsersAvatars } from "utils/avatar"
import { TaskTypes } from "../Modals/EditTaskModal"

const GanttTaskTableNew = ({
  flatTaskList,
  setFlatTaskList,
  taskType,
  editTask,
  displayAs,
  ganttRef,
  holidays,
  alternative,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [tasks, setTasks] = useState([])

  const [ganttFields, setGanttFields] = useState(null)
  const [ganttColumns, setGanttColumns] = useState([])

  const toolbarOptions = ["Add", "ZoomIn", "ZoomOut", "ZoomToFit"]
  const formatDataOption = { type: "date", format: "MM/dd/yyyy" }

  const editSettings = {
    allowAdding: true,
    allowEditing: true,
    allowDeleting: true,
    allowTaskbarEditing: true,
    showDeleteConfirmDialog: true,
    mode: "Auto",
    // mode: "Dialog",
  }

  const tooltipSettings = {
    showTooltip: false,
  }

  useEffect(() => {
    if (taskType != null && displayAs == 0) {
      loadTasks()
    }
  }, [flatTaskList, taskType, displayAs])

  const loadTasks = async () => {
    let fields = {
      id: "taskNum",
      Title: "taskName",
      name: "taskName",
      startDate: "startDT",
      endDate: "endDT",
      duration: "durationDays",
      progress: "percComplete",
      parentTaskID: "parentTaskID",
      wbs: "wbs",
      dependency: "Predecessor",
      child: "subtasks",
      index: "index",
    }

    let cols = [
      { prop: "taskNum", header: "ID" },
      { prop: "taskName", header: "Task" },
      { prop: "startDT", header: "Start" },
      { prop: "endDT", header: "End" },
      { prop: "percComplete", header: "Progress" },
    ]

    taskType.taskTypeFields
      .filter(
        x =>
          x.fieldProperty !== "TaskNum" &&
          x.fieldProperty !== "AlternativeName" &&
          x.fieldProperty !== "PercComplete" &&
          x.fieldProperty !== "EndDT" &&
          x.fieldProperty !== "StartDT" &&
          x.fieldProperty !== "TaskName"
      )
      .forEach(type => {
        const prop = `${type.fieldProperty[0].toLowerCase()}${type.fieldProperty.slice(
          1
        )}`
        if (prop == "taskStatusID") {
          fields["taskStatus"] = "taskStatus"
          cols.push({
            prop: "taskStatus",
            header: type.fieldLabel,
            template: htmlTemplate,
            edit: null,
          })
        } else if (prop.toLowerCase().indexOf("user") > -1) {
          let modifiedProp = prop.replace("ID", "")

          fields[modifiedProp + "Obj"] = modifiedProp + "Obj"
          cols.push({
            prop: modifiedProp + "Obj",
            header: type.fieldLabel,
            template: props => {
              if (props[modifiedProp + "Obj"] == null) return <label></label>
              else
                return (
                  <NameAvatar
                    fullName={props[modifiedProp + "Obj"].text}
                    showName={true}
                  />
                )
            },
            edit: dropdownlist,
          })

          fields[prop] = prop
          cols.push({
            prop: prop,
            header: type.fieldLabel,
            template: props => (
              <NameAvatar fullName={props[modifiedProp]} showName={true} />
            ),
            visible: false,
            edit: null,
          })

          fields[modifiedProp] = modifiedProp
          cols.push({
            prop: modifiedProp,
            header: type.fieldLabel,
            template: props => (
              <NameAvatar fullName={props[modifiedProp]} showName={true} />
            ),
            visible: false,
            edit: null,
          })
        } else {
          fields[prop] = prop
          cols.push({
            prop: prop,
            header: type.fieldLabel,
            edit: null,
            template:
              type.fieldType == "Date" || type.fieldType == "ReadOnlyDate"
                ? props => (
                    <span>
                      {props[prop] == undefined || props[prop] == null
                        ? ""
                        : moment(props[prop]).format("MM/DD/YYYY")}
                    </span>
                  )
                : undefined,
          })
        }
      })

    setGanttFields(fields)
    setGanttColumns(cols)

    await loadAvatarsForChangedOwner(flatTaskList)

    setTasks(buildTaskHierarchy(flatTaskList))

    setTimeout(() => {
      let allRows = document.querySelectorAll("tr.e-row")
      for (var i = 0; i < allRows.length; ++i) {
        allRows[i].classList.remove("row-with-children")
      }
      let rowsWithChildren = document.querySelectorAll(
        "tr.e-row[aria-expanded]"
      )
      for (var i = 0; i < rowsWithChildren.length; ++i) {
        rowsWithChildren[i].classList.add("row-with-children")
      }

      // ganttRef.sortModule.sortColumn("index", 'Ascending', false)
    }, 1000)
  }

  const loadAvatarsForChangedOwner = async flatTaskList => {
    let tasksWithChangedOwner = flatTaskList.filter(x => x.ownerUserChanged)
    if (tasksWithChangedOwner.length > 0) {
      let avatars = await loadUsersAvatars(
        tasksWithChangedOwner.map(x => x.ownerUserID)
      )
      let userList = taskType.taskTypeFields.find(
        x => x.listName == "UserList"
      )?.listItems
      tasksWithChangedOwner.forEach(t => {
        let newUserName = t.ownerUser
        let newOwnerUserObj = t.ownerUserObj
        if (userList != undefined && userList.length > 0) {
          let user = userList.find(x => x.listItemID == t.ownerUserID)
          if (user != undefined) {
            newUserName = user.listItemName
            newOwnerUserObj = {
              text: user.listItemName,
              value: user.listItemID,
            }
          }
        }
        t.ownerUser = newUserName
        t.ownerUserObj = newOwnerUserObj
        t.ownerUserPhotoBase64 = avatars.find(
          x => x.userID == t.ownerUserID
        )?.photoBase64
        t.ownerUserChanged = false
      })
    }
  }

  function buildTaskHierarchy(flatArray) {
    let flatArrayCopy = [...flatArray]

    flatArrayCopy.forEach(task => {
      task.subtasks = flatArrayCopy
        .filter(x => x.parentTaskID == task.taskNum)
        .sort((a, b) => a.index - b.index)
    })

    let parentTasks = flatArrayCopy
      .filter(x => x.parentTaskID == 0 || x.parentTaskID == -1)
      .sort((a, b) => a.index - b.index)

    console.log(parentTasks)

    return parentTasks
  }

  function findTaskById(taskHierarchy, taskNum) {
    for (const task of taskHierarchy) {
      if (task.taskNum === taskNum) {
        return task
      }
      if (task.subtasks && task.subtasks.length > 0) {
        const foundTask = findTaskById(task.subtasks, taskNum)
        if (foundTask) {
          return foundTask
        }
      }
    }
    return null
  }

  const handleActionComplete = async args => {
    console.log("----------------------- ACTION")
    console.log(args)
    if (args.requestType == "add") {
      let newTask = { ...args.data }
      newTask.ownerUser = newTask.ownerUserObj?.text
      newTask.ownerUserID = newTask.ownerUserObj?.value
      newTask.startDT = moment(newTask.startDT).format("YYYY-MM-DDThh:mm:ss")
      newTask.endDT = moment(newTask.endDT).format("YYYY-MM-DDThh:mm:ss")
      newTask.scenarioID = alternative.scenarioID
      newTask.alternativeID = alternative.alternativeID
      newTask.taskTypeID = TaskTypes.projectTask
      newTask.requestTypeID = -1
      newTask.taskStatusID = -1
      newTask.taskDescription = ""
      newTask.taskDisposition = ""
      newTask.taskRisk = ""
      newTask.calcResult = "TBD"
      newTask.createDT = moment(new Date()).format("MM/DD/YYYY")
      newTask.startDT = moment(new Date()).format("MM/DD/YYYY")
      newTask.endDT = moment(new Date()).format("MM/DD/YYYY")
      newTask.createUser = currentUser.firstName + " " + currentUser.lastName
      newTask.parentTaskID = 0
      newTask.predTaskIDs = []
      newTask.subtasks = []

      await api.createTask(currentUser.userID, newTask)
    } else if (args.requestType == "save") {
      console.log(
        "Edited record values available in the 'args' variable as 'modifiedTaskData'",
        args
      )
      // console.log(args.modifiedTaskData)
      let updatedTasks = []
      let itemsToUpdateTasks = args.modifiedTaskData.map(x => async () => {
        const predecessors = x.Predecessor.split(",")

        if (predecessors.length > 0) {
          x.predTaskIDs = []
          predecessors.forEach(p => {
            // const relation = p.split(p.indexOf("+") > -1 ? "+" : "-")[0]
            x.predTaskIDs.push(p.trim())
          })
        }
        let updatedTask = {
          ...x,
          ownerUser: x.ownerUserObj?.text,
          ownerUserID: x.ownerUserObj?.value,
          startDT: moment(x.startDT).format("YYYY-MM-DDThh:mm:ss"),
          endDT: moment(x.endDT).format("YYYY-MM-DDThh:mm:ss"),
          // durationDays: moment(x.endDT, "YYYY-MM-DD").diff(
          //   moment(x.startDT, "YYYY-MM-DD"),
          //   "days"
          // ),
        }
        updatedTasks.push(updatedTask)
        return await api.updateTask(currentUser.userID, updatedTask)
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      if (updatedTasks.length > 0) {
        let copy = [...flatTaskList]
        updatedTasks.forEach(t => {
          let existing = copy.find(x => x.taskID == t.taskID)
          copy.splice(copy.indexOf(existing), 1, { ...t })
        })
        setFlatTaskList(copy)
      }
    }
  }

  const htmlTemplate = props => {
    return (
      <div
        style={{}}
        dangerouslySetInnerHTML={{
          __html: props.taskStatus,
        }}
      ></div>
    )
  }

  let elem
  let dropdownlistObj
  let dropdownlist = {
    create: () => {
      elem = document.createElement("input")
      return elem
    },
    read: () => {
      return {
        value: dropdownlistObj.value,
        text: dropdownlistObj.text,
      }
    },
    destroy: () => {
      dropdownlistObj.destroy()
    },
    write: args => {
      var itm = taskType.taskTypeFields.filter(
        x =>
          x.fieldProperty.toLowerCase() ===
          args.column.field.replace("Obj", "ID").toLowerCase()
      )[0]

      var selectedValue = null
      if (args.rowData[args.column.field] != null)
        selectedValue = String(args.rowData[args.column.field].value)

      dropdownlistObj = new DropDownList({
        dataSource: itm.listItems,
        fields: { value: "listItemID", text: "listItemName" },
        value: selectedValue,
        floatLabelType: "Auto",
        placeholder: "Select an Owner",
        popupWidth: "250px",
      })
      dropdownlistObj.appendTo(elem)
    },
  }

  const rowDropAction = async args => {
    console.log("----------------------- ROW DROP ACTION")
    console.log(args)
    await args.data.forEach(async element => {
      var taskToEdit = findTaskById(tasks, element.taskNum) // find in hierarchy

      console.log("original")
      console.log(flatTaskList)

      taskToEdit.index = args.dropIndex
      let otherTasksToUpdate = []
      if (args.fromIndex > args.dropIndex) {
        for (let i = args.dropIndex; i < args.fromIndex; i++) {
          let taskToUpdateIndex = flatTaskList.find(
            x => x.index == i && x.taskID != taskToEdit.taskID
          )
          if (taskToUpdateIndex != undefined) {
            let taskCopy = { ...taskToUpdateIndex }
            taskCopy.index = i + 1
            otherTasksToUpdate.push(taskCopy)
          }
        }
      } else if (args.fromIndex < args.dropIndex) {
        for (let i = args.fromIndex + 1; i <= args.dropIndex; i++) {
          let taskToUpdateIndex = flatTaskList.find(
            x => x.index == i && x.taskID != taskToEdit.taskID
          )
          if (taskToUpdateIndex != undefined) {
            let taskCopy = { ...taskToUpdateIndex }
            taskCopy.index = i - 1
            otherTasksToUpdate.push(taskCopy)
          }
        }
      }

      let flatTaskCopy = [...flatTaskList]
      otherTasksToUpdate.forEach(t => {
        let item = flatTaskCopy.find(x => x.taskID == t.taskID)
        if (item != undefined) {
          item.index = t.index
        }
      })
      let editedTask = flatTaskCopy.find(x => x.taskID == taskToEdit.taskID)
      if (editedTask != undefined) {
        editedTask.index = taskToEdit.index
      }

      setFlatTaskList(flatTaskCopy)

      console.log("to update")
      console.log(otherTasksToUpdate)

      if (args.dropPosition == "middleSegment")
        taskToEdit.parentTaskID = args.dropRecord.taskNum
      else if (!isNullOrEmpty(args.dropRecord)) {
        if (!isNullOrEmpty(args.dropRecord.parentItem)) {
          taskToEdit.parentTaskID = args.dropRecord.parentItem.taskId
        } else {
          taskToEdit.parentTaskID = 0
        }
      }

      let updateTasks = otherTasksToUpdate.map(x => async () => {
        return await api.updateTask(currentUser.userID, x)
      })
      if (updateTasks.length > 0) {
        await Promise.all(updateTasks.map(t => t()))
      }

      await api.updateTask(currentUser.userID, { ...taskToEdit })
      // args.cancel = true;
    })
  }

  function idTemplate(props) {
    return (
      <a href="#" onClick={e => openEditModal(e, props.taskData)}>
        {props.taskNum}
      </a>
    )
  }

  const openEditModal = (e, task) => {
    e.preventDefault()
    ganttRef.current.openEditDialog(task.taskNum)
  }

  function wbsTemplate(props) {
    return (
      <a href="#" onClick={e => openEditModal(e, props.taskData)}>
        {props.wbs}
      </a>
    )
  }

  const onDataBound = () => {
    if (ganttRef.current) {
      ganttRef.current.fitToProject()
    }
  }

  return (
    <React.Fragment>
      {ganttFields !== null &&
        ganttColumns.length > 0 &&
        // tasks.length > 0 &&
        holidays.length > 0 && (
          <GanttComponent
            tooltipSettings={tooltipSettings}
            dataSource={tasks}
            ref={ganttRef}
            taskFields={ganttFields}
            allowFiltering={false}
            allowRowDragAndDrop={true}
            allowResizing={true}
            allowSelection={false}
            allowSorting={true}
            allowReordering={true}
            actionComplete={handleActionComplete}
            editSettings={editSettings}
            toolbar={toolbarOptions}
            rowDrop={rowDropAction}
            // actionBegin={handleActionBegin}
            showSpinner={() => false}
            dataBound={onDataBound}
            sortSettings={{
              columns: [{ field: "index", direction: "Ascending" }],
            }}
            includeWeekend={true}
            splitterSettings={{ position: "30%" }}
            rowHeight={25}
            treeColumnIndex={3}
          >
            <HolidaysDirective>
              {holidays.map((w, idx) => (
                <HolidayDirective
                  key={idx}
                  from={moment(w.date).format("MM/DD/yyyy")}
                  label=""
                  cssClass="non-working-day"
                ></HolidayDirective>
              ))}
            </HolidaysDirective>

            <ColumnsDirective>
              <ColumnDirective
                field="taskNum"
                headerText="ID"
                visible={true}
                template={idTemplate}
                width={70}
              />

              <ColumnDirective
                field="wbs"
                headerText="WBS"
                allowFiltering={false}
                template={wbsTemplate}
                width={70}
                allowEditing={false}
                type="General"
                // editType="stringedit"
              />

              <ColumnDirective
                field="percComplete"
                headerText="%"
                width={70}
                editType="stringedit"
                type="General"
              />

              <ColumnDirective
                field="taskName"
                headerText="Task Name"
                minWidth={300}
                editType="stringedit"
                type="General"
              />
              {/* 
              <ColumnDirective
                field="durationDays"
                headerText="Days"
                // allowEditing={false}
                width={90}
                template={props => {
                  return props.durationDays
                }}
                edit={durationEditor}
                editType="stringedit"
              /> */}

              <ColumnDirective
                field="startDT"
                headerText="Start"
                format={formatDataOption}
                type="General"
              />

              <ColumnDirective
                field="endDT"
                headerText="Finish"
                format={formatDataOption}
                type="General"
              />

              <ColumnDirective
                field="ownerUserObj"
                headerText="Responsible"
                type="General"
                edit={dropdownlist}
                template={props => {
                  if (props["ownerUserObj"] == null) return <label></label>
                  else
                    return (
                      <NameAvatar
                        fullName={props["ownerUserObj"].text}
                        showName={true}
                        base64Image={
                          props?.taskData?.ownerUserPhotoBase64 || ""
                        }
                      />
                    )
                }}
              />

              <ColumnDirective
                field="ownerUser"
                headerText="ownerUser"
                visible={false}
              />
              <ColumnDirective
                field="ownerUserID"
                headerText="ownerUserID"
                visible={false}
              />
            </ColumnsDirective>

            <AddDialogFieldsDirective>
              <AddDialogFieldDirective
                type="General"
                headerText="General"
                fields={[
                  "percComplete",
                  "taskName",
                  "durationDays",
                  "startDT",
                  "endDT",
                  "ownerUserObj",
                ]}
              />
              <AddDialogFieldDirective type="Dependency" />
            </AddDialogFieldsDirective>

            <EditDialogFieldsDirective>
              <EditDialogFieldDirective
                type="General"
                headerText="General"
                fields={[
                  "percComplete",
                  "taskName",
                  "durationDays",
                  "startDT",
                  "endDT",
                  "ownerUserObj",
                ]}
              ></EditDialogFieldDirective>
              <EditDialogFieldDirective type="Dependency"></EditDialogFieldDirective>
            </EditDialogFieldsDirective>

            <Inject
              services={[
                Filter,
                Sort,
                Toolbar,
                Selection,
                RowDD,
                Edit,
                Resize,
                DayMarkers,
              ]}
            />
          </GanttComponent>
        )}
    </React.Fragment>
  )
}

export default GanttTaskTableNew
