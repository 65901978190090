import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import { TextareaAutosize } from "@material-ui/core"
import useModal from "hooks/useModalHook"
import api from "services/api.service"
import CopyScenarioModal from "pages/Projects/Projects/Modals/CopyScenarioModal"
import isNullOrEmpty from "utils/isNullOrEmpty"
import RollupModal from "./Modals/RollupModal"
import { userService } from "services/user.service"
import useFetcher from "hooks/useFetcher"

const GeneralSection = ({
  scenario,
  setScenario,
  alternativeScoreLabels,
  alternativeLabels,
  objectiveLabels,
  measureLabels,
  costLabels,
  poolTypes,
  inputRatingScale,
  outputRatingScale,
  parentScenarios,
  combinedScoreTypes,
  criteriaPrioritizationStates,
  ahpMeans,
  ahpRatings,
  scenarioTypes,
  enterprise,
  props,
  scenarioChildTypeOptions,
  createSecondaryScenario,
  showToastChanges,
  userList,
  changeScenarioProp
}) => {
  const currentUser = userService.getLoggedInUser()
  const [copyTeam, setCopyTeam] = useState(false)
  const [selectedScenarioChildType, setSelectedScenarioChildType] = useState(-1)
  const [attributeName, setAttributeName] = useState("")
  const [attributeNameError, setAttributeNameError] = useState(false)
  const { show: showSecondaryModelModal, toggle: toggleSecondaryModelModal } =
    useModal()
  const { show: showCopyScenarioModal, toggle: toggleCopyScenarioModal } =
    useModal()
  const { show: showRollupModal, toggle: toggleRollupModal } = useModal()

  useEffect(() => {
    setAttributeName("")
    setCopyTeam(false)
    setSelectedScenarioChildType(-1)
  }, [showSecondaryModelModal])

  const setRollupAlternative = alternative => {
    let newScen = {
      ...scenario,
      rollupAlternative: alternative.alternativeName,
      rollupAlternativeID: alternative.alternativeID,
    }
    setScenario(newScen)
    api.updateScenario(currentUser.userID, newScen).then(r => {
      toast.success("Rollup saved")
    })
  }

  const saveSecondaryModel = async () => {
    if (
      attributeName == undefined ||
      attributeName == null ||
      attributeName == ""
    ) {
      setAttributeNameError(true)
    } else {
      try {
        await createSecondaryScenario(
          copyTeam,
          attributeName,
          selectedScenarioChildType
        )
        toggleSecondaryModelModal()
      } catch {
        toggleSecondaryModelModal()
      }
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col sm="12">
          <div className="mb-3">
            <Label className="form-label">Type</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.scenarioTypeID}
              onChange={e => {
                changeScenarioProp("scenarioTypeID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {scenarioTypes != undefined &&
                scenarioTypes.length > 0 &&
                scenarioTypes.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.listItemID}`}>
                      {a.listItemName}
                    </option>
                  )
                })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              name="scenarioName"
              type="text"
              onChange={e => changeScenarioProp("scenarioName", e.target.value)}
              value={scenario.scenarioName}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Description</Label>
            <TextareaAutosize
              className="form-control"
              minRows={2}
              onChange={e => changeScenarioProp("description", e.target.value)}
              value={scenario.description}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Decision Goal</Label>
            <TextareaAutosize
              className="form-control"
              minRows={2}
              onChange={e => changeScenarioProp("decisionGoal", e.target.value)}
              value={scenario.decisionGoal}
            />
          </div>
          <div
            className="mb-3"
            style={{ display: "flex", alignItems: "center", gap: "30px" }}
          >
            <div>
              <Label className="form-label">Organization Name</Label>
              <Input
                name="orgName"
                type="text"
                style={{ width: "300px" }}
                onChange={e =>
                  changeScenarioProp("organizationName", e.target.value)
                }
                value={scenario.organizationName}
              />
            </div>
            <div>
              <Label className="form-label">Organization Abbreviation</Label>
              <Input
                name="orgabb"
                type="text"
                onChange={e =>
                  changeScenarioProp("organizationAbbreviation", e.target.value)
                }
                value={scenario.organizationAbbreviation}
              />
            </div>
            <div>
              <Label className="form-label">
                {enterprise?.modelLabel} Owner
              </Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={scenario.userID}
                style={{width: "300px"}}
                onChange={e => {
                  changeScenarioProp("userID", e.target.value)
                }}
              >
                <option value={-1}>Select...</option>
                {userList.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.userID}`}>
                      {a.firstName} {a.lastName}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className="mb-3">
            <Label className="form-label">Mission Statement</Label>
            <TextareaAutosize
              className="form-control"
              minRows={2}
              onChange={e =>
                changeScenarioProp("organizationMission", e.target.value)
              }
              value={scenario.organizationMission}
            />
          </div>
          <div className="d-flex-row jc-space-between">
            <div style={{ width: "100%" }}>
              {enterprise != null && (
                <div className="d-flex-col">
                  {!isNullOrEmpty(scenario?.secondaryScenarios) &&
                    scenario.secondaryScenarios.length > 0 &&
                    scenario.secondaryScenarios.map((s, idx) => (
                      <a
                        key={idx}
                        href="#"
                        onClick={e => {
                          e && e.preventDefault()
                          props.history.push(`/editModel/${s.scenarioID}`)
                        }}
                      >
                        Go to Secondary Model - {s.scenarioName}
                      </a>
                    ))}

                  {scenario.scenarioID > -1 &&
                    scenario.masterScenarioID == 0 &&
                    scenarioChildTypeOptions.find(
                      x =>
                        scenario?.secondaryScenarios?.find(
                          s => s.scenarioChildTypeID == x.listItemID
                        ) == undefined
                    ) != undefined && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ width: "300px" }}
                        onClick={toggleSecondaryModelModal}
                      >
                        Add Secondary Model
                      </button>
                    )}
                </div>
              )}
            </div>
            <div className="d-flex-row ai-center jc-space-between w-100">
              <div>
                <Label className="form-label">Rollup to</Label>
                <p style={{ marginBottom: 0, fontWeight: "bold" }}>
                  {scenario?.rollupAlternative}
                </p>
              </div>
              <button className="btn  btn-primary" onClick={toggleRollupModal}>
                Set Rollup
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <div className="divider"></div>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Alternative Label</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.alternativeName || -1}
              onChange={e => {
                changeScenarioProp("alternativeName", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {alternativeLabels.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Criteria Label</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.objectiveName || -1}
              onChange={e => {
                changeScenarioProp("objectiveName", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {objectiveLabels.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Dollar Label</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.costName || -1}
              onChange={e => {
                changeScenarioProp("costName", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {costLabels.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Pool Type</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.poolType || -1}
              onChange={e => {
                changeScenarioProp("poolType", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {poolTypes.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Alternative Score Label</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.scoreName || -1}
              onChange={e => {
                changeScenarioProp("scoreName", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {alternativeScoreLabels.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Sub-Criteria Label</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.subCriteriaName || -1}
              onChange={e => {
                changeScenarioProp("subCriteriaName", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {measureLabels.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Pool</Label>
            <CurrencyInput
              id="budgetCurrencyInput"
              name="budget"
              className="form-control"
              value={scenario.budget}
              onValueChange={(value, name, values) => {
                changeScenarioProp("budget", value)
              }}
              placeholder="Please enter a budget"
              prefix={"$"}
              decimalScale={2}
              decimalsLimit={2}
            />
          </div>
        </Col>
      </Row>
      <div className="divider"></div>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Criteria Prioritization State</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.pairwiseStatusID || -1}
              onChange={e => {
                changeScenarioProp("pairwiseStatusID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {criteriaPrioritizationStates.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">AHP Ratings</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.ahpScaleID || -1}
              onChange={e => {
                changeScenarioProp("ahpScaleID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {ahpRatings.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Input Rating Scale</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.inputRatingScaleID || -1}
              onChange={e => {
                changeScenarioProp("inputRatingScaleID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {inputRatingScale.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Attribute Name</Label>
            <Input
              name="attrName"
              type="text"
              onChange={e =>
                changeScenarioProp("attributeName", e.target.value)
              }
              value={scenario.attributeName}
            />
          </div>
          {/* <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="option1"
            />
            <label className="form-check-label" htmlFor="exampleRadios1">
              Form Radio
            </label>
          </div> */}
        </Col>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Alternative Scoring State</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.alternativeStatusID || -1}
              onChange={e => {
                changeScenarioProp("alternativeStatusID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {criteriaPrioritizationStates.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">AHP Mean Calculation</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.meanTypeID || -1}
              onChange={e => {
                changeScenarioProp("meanTypeID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {ahpMeans.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Output Rating Scale</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.outputRatingScaleID || -1}
              onChange={e => {
                changeScenarioProp("outputRatingScaleID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {outputRatingScale.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
      </Row>
      <div className="divider" />
      {/* <Row>
        <Col sm="8">
          <div className="mb-3">
            <Label className="form-label">Parent Decision</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.parentScenarioID || -1}
              onChange={e => {
                changeScenarioProp("parentScenarioID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {parentScenarios.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
      </Row> */}
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Combined Score Type</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.combinedScoreTypeID || -1}
              onChange={e => {
                changeScenarioProp("combinedScoreTypeID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {combinedScoreTypes.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Combined Score Name</Label>
            <Input
              name="combScoreName"
              type="text"
              onChange={e =>
                changeScenarioProp("combinedScoreName", e.target.value)
              }
              value={scenario.combinedScoreName}
            />
          </div>
        </Col>
      </Row>
      <div className="divider" />
      <Row>
        <Col>
          <div className="mb-3">
            <Input
              type="checkbox"
              className="form-check-input"
              id="customCheck1"
              checked={scenario.backupBit}
              onClick={e => {
                changeScenarioProp("backupBit", !scenario.backupBit)
              }}
            />
            <Label
              className="form-check-label"
              for="customCheck1"
              style={{ marginLeft: "10px" }}
            >
              Declare as a Backup
            </Label>
          </div>
          <div className="mb-3">
            <Input
              type="checkbox"
              className="form-check-input"
              id="customCheck2"
              checked={scenario.lockBit}
              onClick={e => {
                changeScenarioProp("lockBit", !scenario.lockBit)
              }}
            />
            <Label
              className="form-check-label"
              for="customCheck2"
              style={{ marginLeft: "10px" }}
            >
              Lock Portfolio
            </Label>
          </div>
          <div className="mb-3">
            <Input
              type="checkbox"
              className="form-check-input"
              id="customCheck3"
              checked={scenario.inactive}
              onClick={e => {
                changeScenarioProp("inactive", !scenario.inactive)
              }}
            />
            <Label
              className="form-check-label"
              for="customCheck3"
              style={{ marginLeft: "10px" }}
            >
              Inactive
            </Label>
          </div>
          {scenario.scenarioID > -1 && (
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={toggleCopyScenarioModal}
              >
                <i className="fas fa-copy"></i>&nbsp; Copy Decision
              </button>
            </div>
          )}
        </Col>
      </Row>
      <Modal
        backdrop="static"
        isOpen={showSecondaryModelModal}
        size="md"
        toggle={() => {
          toggleSecondaryModelModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Secondary Model
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleSecondaryModelModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            className="mb-3"
            style={{ display: "flex", flexDirection: "row", gap: "20px" }}
          >
            <label>Copy Team</label>
            <div>
              <input
                className="form-check-input"
                type="radio"
                name="anon111"
                id="anon111"
                checked={copyTeam}
                onClick={e => {
                  setCopyTeam(true)
                }}
              />
              <label
                className="form-check-label"
                htmlFor="anon111"
                style={{ marginLeft: "10px" }}
              >
                Yes
              </label>
            </div>
            <div>
              <input
                className="form-check-input"
                type="radio"
                name="anon222"
                id="anon222"
                checked={!copyTeam}
                onClick={e => {
                  setCopyTeam(false)
                }}
              />
              <label
                className="form-check-label"
                htmlFor="anon222"
                style={{ marginLeft: "10px" }}
              >
                No
              </label>
            </div>
          </div>
          <div className="mb-3">
            <Label className="form-label">Attribute Name</Label>
            <Input
              name="attrName"
              type="text"
              onChange={e => {
                setAttributeName(e.target.value)
                setAttributeNameError(false)
              }}
              value={attributeName}
            />
            {attributeNameError && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                Attribute name is required
              </div>
            )}
          </div>
          <div className="mb-3">
            <Label className="form-label">Secondary Scenario Type</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedScenarioChildType}
              onChange={e => {
                setSelectedScenarioChildType(e.target.value)
              }}
            >
              <option value="-1">Select...</option>
              {scenarioChildTypeOptions
                .filter(
                  x =>
                    scenario?.secondaryScenarios?.find(
                      s => s.scenarioChildTypeID == x.listItemID
                    ) == undefined
                )
                .map((s, i) => (
                  <option key={i} value={s.listItemID}>
                    {s.listItemName}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleSecondaryModelModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={saveSecondaryModel}
          >
            Add {enterprise != null && enterprise.modelLabel}
          </button>
        </div>
      </Modal>
      <CopyScenarioModal
        isOpen={showCopyScenarioModal}
        toggleModal={toggleCopyScenarioModal}
        scenario={scenario}
      />
      <RollupModal
        isOpen={showRollupModal}
        toggleModal={toggleRollupModal}
        setRollupAlternative={setRollupAlternative}
      />
    </React.Fragment>
  )
}

export default GeneralSection
