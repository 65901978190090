import React, { useEffect, useRef, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import {
  withScriptjs,
  withGoogleMap,
  InfoWindow,
  GoogleMap,
  Marker,
  Polyline,
  DirectionsRenderer,
  Polygon,
  Circle,
} from "react-google-maps"
import Geocode from "react-geocode"
import api from "services/api.service"
import { userService } from "services/user.service"
import { toast } from "react-toastify"
import colors from "utils/colors"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import DynamicForm from "components/custom/DynamicForm/View/DynamicForm"
import isNullOrEmpty from "utils/isNullOrEmpty"
import EditLocationModal from "./Location/EditLocationModal"

function MapDirectionsRenderer(props) {
  const [directions, setDirections] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    const { places, travelMode } = props

    if (places.length >= 2) {
      const waypoints = places.map(p => ({
        location: { lat: p.lat, lng: p.lng },
        stopover: true,
      }))
      const origin = waypoints.shift().location
      const destination = waypoints.pop().location

      const directionsService = new google.maps.DirectionsService()
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: travelMode,
          waypoints: waypoints,
        },
        (result, status) => {
          console.log(result)
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result)
          } else {
            setError(result)
          }
        }
      )
    }
  })

  if (error) {
    return <h1>{error}</h1>
  }
  return directions && <DirectionsRenderer directions={directions} />
}

function Map({
  defaultCenter,
  mapRef,
  bounds,
  markers,
  setMarkers,
  circles,
  setCircles,
  onMapClick,
  polygons,
  editable,
}) {
  const [selectedMarker, setSelectedMarker] = useState(null)

  const removeMarker = marker => {
    setMarkers(markers =>
      markers.filter(x => !(x.lat == marker.lat && x.lng == marker.lng))
    )
  }

  // const removeCircle = circle => {
  //   setCircles(circles =>
  //     circles.filter(
  //       x =>
  //         !(
  //           x.center.lat == circle.latLng.lat() &&
  //           x.center.lng == circle.latLng.lng()
  //         )
  //     )
  //   )
  // }

  return (
    <GoogleMap
      defaultZoom={9}
      defaultCenter={defaultCenter}
      //   ref={props.ref}
      ref={map => {
        if (mapRef != undefined) {
          mapRef.current = map
        }
      }}
      bounds={bounds}
      // {...props}
      onClick={onMapClick}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyA9GCVC7tKsdhlBU0HLkar9mlVSLKyWrgY&v=3.exp&libraries=geometry,drawing,places}`}
      loadingElement={<div style={{ height: "500px", width: "100%" }} />}
      containerElement={<div style={{ height: `500px`, width: "100%" }} />}
      mapElement={<div style={{ height: `500px`, width: "100%" }} />}
    >
      {circles &&
        circles.map((c, i) => (
          <Circle
            key={i}
            center={c.center}
            editable={editable}
            draggable={editable}
            radius={c.radius}
            // fillColor={c.color ?? '#ffffff'}
            // strokeColor={c.color ?? '#ffffff'}
            onRadiusChanged={function () {
              let index = circles.indexOf(c)
              let copy = [...circles]
              let item = copy[index]
              item.radius = this.radius
              setCircles(copy)
            }}
            onCenterChanged={function () {
              let index = circles.indexOf(c)
              let copy = [...circles]
              let item = copy[index]
              item.center = { lat: this.center.lat(), lng: this.center.lng() }
              setCircles(copy)
            }}
          />
        ))}
      {!editable &&
        !isNullOrEmpty(polygons) &&
        polygons.map((p, i) => (
          <Polygon
            key={i}
            paths={p}
            options={{
              fillColor: "gray",
              fillOpacity: 0.5,
              strokeColor: "black",
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
        ))}
      {editable && markers && markers.length > 0 && (
        <Polygon
          paths={markers}
          options={{
            fillColor: "gray",
            fillOpacity: 0.5,
            strokeColor: "black",
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
        />
      )}

      {markers &&
        markers.map((m, i) => (
          <Marker
            key={i}
            position={m}
            onClick={() => {
              setSelectedMarker(m)
            }}
            options={{
              strokeColor: "#66009a",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: `#66009a`,
              fillOpacity: 0.35,
              zIndex: 1,
            }}
          >
            {editable &&
              !isNullOrEmpty(selectedMarker?.lat) &&
              !isNullOrEmpty(m?.lat) &&
              selectedMarker.lat == m.lat &&
              selectedMarker.lng == m.lng && (
                <InfoWindow onCloseClick={() => setSelectedMarker(null)}>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => removeMarker(selectedMarker)}
                  >
                    Remove
                  </button>
                </InfoWindow>
              )}
          </Marker>
        ))}
      {/* <Polyline
        path={markers.map(marker => marker)} // Array of LatLng coordinates
        options={{
          strokeColor: "#FF0000", // Red line color
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }}
      /> */}
      {/* <MapDirectionsRenderer
        places={markers}
        travelMode={window.google.maps.TravelMode.DRIVING}
      /> */}
    </GoogleMap>
  )
}

export const MapWrapped = withScriptjs(withGoogleMap(Map))

const LocationSection = ({
  alternativeId,
  scenarioId,
  activeTab,
  sectionFormId,
  showToastChangesCallback,
  triggerFormSave,
  setTriggerFormSave,
  enterprise
}) => {
  const newLocation = {
    locationID: -1,
    address: "",
    city: "",
    zip: "",
    state: "",
    lat: "",
    lon: "",
    locationTypeID: "1",
    color: "#ffffff",
  }
  const mapRef = useRef(null)
  const [markers, setMarkers] = useState([])
  const [circles, setCircles] = useState([])
  const [polygons, setPolygons] = useState([])
  const [locationToEdit, setLocationToEdit] = useState(newLocation)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const [locations, setLocations] = useState([])
  const [bounds, setBounds] = useState(null)

  const [defaultCenter, setDefaultCenter] = useState(null)

  // const locationTypes = ["Line", "Route"]
  // const [selectedLocationType, setSelectedLocationType] = useState("Line")

  useEffect(() => {
    if (activeTab == "Location" && alternativeId > 0) {
      loadLocations()
      loadDefaultCenter()
    }
  }, [activeTab, alternativeId])

  const loadDefaultCenter = () => {
    if (!isNullOrEmpty(navigator?.geolocation)) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords
          setDefaultCenter({ lat: latitude, lng: longitude })
        },
        error => {
          console.error("Error getting location", error)
          setDefaultCenter({ lat: 38.897, lng: -77.03985 }) // Default: Washington DC
        }
      )
    } else {
      setDefaultCenter({ lat: 38.897, lng: -77.03985 }) // Default: Washington DC
    }
  }

  useEffect(() => {
    if (locations.length > 0) {
      let addressLocations = locations.filter(x => x.locationTypeID == 1)
      let polygonLocations = locations.filter(x => x.locationTypeID == 2)
      let circleLocations = locations.filter(x => x.locationTypeID == 3)

      let circles = circleLocations
        .map(x => JSON.parse(x?.locationData))
        .filter(x => !isNullOrEmpty(x?.circles))
        .map(x => x.circles)
        .flat()
      let polygons = polygonLocations.map(
        x => JSON.parse(x.locationData)?.markers
      )

      setMarkers(
        addressLocations.map(l => {
          return {
            lat: l.lat,
            lng: l.lon,
          }
        })
      )
      setCircles(circles)
      setPolygons(polygons)

      loadBounds(addressLocations, circles, polygons)

      // var b = new google.maps.LatLngBounds()
      // for (var i = 0; i < locations.length; i++) {
      //   b.extend({ lat: locations[i].lat, lng: locations[i].lon })
      // }
      // b.extend({
      //   lat: locations[locations.length - 1].lat + 0.5,
      //   lng: locations[locations.length - 1].lon - 0.5,
      // })
      // setTimeout(() => {
      //   mapRef.current.fitBounds(b)
      // }, 1500)
    }
  }, [locations])

  const loadBounds = (addressLocations, circles, polygons) => {
    var boundsLocations = []
    if (!isNullOrEmpty(addressLocations) && addressLocations.length > 0) {
      for (var i = 0; i < addressLocations.length; i++) {
        boundsLocations.push({
          lat: addressLocations[i].lat,
          lng: addressLocations[i].lon,
        })
        //  b.extend({ lat: addressLocations[i].lat, lng: addressLocations[i].lon })
      }
    }

    if (!isNullOrEmpty(circles) && circles.length > 0) {
    }

    if (!isNullOrEmpty(polygons) && polygons.length > 0) {
    }

    setTimeout(() => {
      if (mapRef?.current != undefined) {
        if (boundsLocations.length > 1) {
          var b = new google.maps.LatLngBounds()
          for (var i = 0; i < boundsLocations.length; i++) {
            b.extend(boundsLocations[i])
          }

          mapRef.current.fitBounds(b)
          mapRef.current.panTo(b.getCenter())

          // mapRef.current.setCenter(b.getCenter())
        } else if (boundsLocations.length == 1) {
          mapRef.current.panTo(boundsLocations[0])
        } else {
          mapRef.current.panTo({ lat: 38.897, lng: -77.03985 })
        }
      }
    }, 1000)
  }

  const loadLocations = async () => {
    let locs = await api.getAlternativeLocations(alternativeId)
    setLocations(locs)
  }

  const onLocationClick = (e, location) => {
    e && e.preventDefault()
    setLocationToEdit(location)
    // setMarkers([{ lat: location.lat, lng: location.lon }])
    toggleModal()
  }

  const addNewLocation = () => {
    setLocationToEdit({ ...newLocation, alternativeID: alternativeId })
    // setMarkers([])
    toggleModal()
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <button className="btn btn-primary" onClick={addNewLocation}>
            <i className="fas fa-map-marker-alt"> </i> Add Location
          </button>
          {locations.length > 0 &&
            locations.map((l, idx) => (
              <div
                key={idx}
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
              >
                <h5
                  style={{
                    padding: "10px",
                    backgroundColor: "lightgray",
                    marginBottom: "0px",
                  }}
                >
                  Location
                </h5>
                <p
                  onClick={e => onLocationClick(e, l)}
                  style={{
                    padding: "10px",
                    marginBottom: "0px",
                    color: colors.primary,
                    cursor: "pointer",
                  }}
                >
                  {l.address}
                </p>
              </div>
            ))}
          {/* <div className="mb-3">
            <label>Join type</label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedLocationType}
              onChange={e => {
                setSelectedLocationType(e.target.value)
              }}
            >
              {locationTypes.map((l, i) => (
                <option key={i} value={l}>
                  {l}
                </option>
              ))}
            </select>
          </div> */}
        </Col>
        <Col md="9">
          {defaultCenter != null && (
            <MapWrapped
              mapRef={mapRef}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyA9GCVC7tKsdhlBU0HLkar9mlVSLKyWrgY&v=3.exp&libraries=geometry,drawing,places}`}
              loadingElement={
                <div style={{ height: "500px", width: "100%" }} />
              }
              containerElement={
                <div style={{ height: `500px`, width: "100%" }} />
              }
              mapElement={<div style={{ height: `500px`, width: "100%" }} />}
              defaultCenter={defaultCenter}
              bounds={bounds}
              markers={markers}
              setMarkers={setMarkers}
              circles={circles}
              setCircles={setCircles}
              polygons={polygons}
              editable={false}
            />
          )}
        </Col>
      </Row>
      {sectionFormId != 0 && activeTab == "Location" &&  (
        <>
          <Row>
            <Col md="12">
              <div className="divider"></div>
              <DynamicForm
                formId={sectionFormId}
                itemId={alternativeId}
                scenarioId={scenarioId}
                setTriggerFormSave={setTriggerFormSave}
                triggerFormSave={triggerFormSave}
                showToastChangesCallback={showToastChangesCallback}
                useAi={enterprise?.useAI ?? false}
              />
            </Col>
          </Row>
        </>
      )}
      <EditLocationModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        location={locationToEdit}
        reloadLocations={loadLocations}
      />
    </React.Fragment>
  )
}

export default LocationSection
