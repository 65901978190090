import { TextareaAutosize } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import colors from "utils/colors"
import ResourceManagerModal from "./Modals/ResourceManagerModal"
import useModal from "hooks/useModalHook"
import { userService } from "services/user.service"
import isNullOrEmpty from "utils/isNullOrEmpty"
import api from "services/api.service"
import moment from "moment"
import resourceService from "services/resource.service"

const ResourceBudgetingPlanTab = ({
  isActive,
  filters,
  resourceGroups,
  fromDate,
  toDate,

  selectedResourceGroupId,
  selectedBCCostPoolId,

  alternative,
  alternativeId,

  triggerSave,
  setTriggerSave,
  showToastChanges,
  maxYear,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const currentUser = userService.getLoggedInUser()
  const [resourceTemplates, setResourceTemplates] = useState([])
  const [altResourceGroups, setAltResourceGroups] = useState([])
  const [altResourceGroupToEdit, setAltResourceGroupToEdit] = useState(null)
  const [gridMonthProps, setGridMonthProps] = useState([])

  const { show: showManagerModal, toggle: toggleManagerModal } = useModal()
  const { show: isEditModalVisible, toggle: toggleEditModal } = useModal()

  const [gridData, setGridData] = useState([])

  useEffect(() => {
    if (isActive && fromDate != null && toDate != null) {
      loadTableData(selectedResourceGroupId)
    }
  }, [
    isActive,
    selectedResourceGroupId,
    selectedBCCostPoolId,
    fromDate,
    toDate,
  ])

  useEffect(() => {
    if (isActive && triggerSave) {
      saveGroups()
    }
  }, [isActive, triggerSave])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    var rt = await api.getList(currentUser.userID, "ResourceTemplates")
    setResourceTemplates(rt)
  }

  const loadTableData = async rgId => {
    setIsLoading(true)

    let data = await api.getAlternativeResourceGroupMonths(
      alternativeId,
      rgId,
      selectedBCCostPoolId,
      moment(fromDate).format("YYYY-MM-DDT00:00:00"),
      moment(toDate).format("YYYY-MM-DDT00:00:00")
    )
    setGridData(data)
    if (data.length > 0) {
      setGridMonthProps(resourceService.getGridMonthProps(data[0]))
    } else {
      setGridMonthProps([])
    }

    setIsLoading(false)
  }

  const changeGridValue = (rgeid, prop, value) => {
    showToastChanges()
    setGridData(prev =>
      prev.map(p =>
        p.rgeid === rgeid
          ? {
              ...p,
              [prop]: value,
              isDirty: true,
              modifiedProps: [...(p.modifiedProps ?? []), prop],
            }
          : p
      )
    )
  }

  const saveGroups = async () => {
    let tasks1 = []
    let tasks2 = []
    gridData
      .filter(x => x.isDirty)
      .forEach(x => {
        let modifiedProps = x.modifiedProps ?? []
        modifiedProps = [...new Set(modifiedProps)]

        modifiedProps.forEach(prop => {
          tasks1.push(async () => {
            return await api.updateAlternativeResourcePlan(currentUser.userID, {
              resourceGroupID: x.resourceGroupID,
              costPoolID: x.costPoolID,
              alternativeID: x.alternativeID,
              monthNumber: prop.split("-")[1],
              numUnits: x[prop],
            })
          })
        })

        tasks2.push(async () => {
          return await api.updateResourceGroupFromPlan(
            x.resourceGroupID,
            x.alternativeID,
            x.costPoolID
          )
        })
      })
    if (tasks1.length > 0) await Promise.all(tasks1.map(t => t()))
    if (tasks2.length > 0) await Promise.all(tasks2.map(t => t()))

    await loadTableData(selectedResourceGroupId)

    setTriggerSave(false)
    toast.success("Resources saved successfully")
  }

  const editAltResourceGroup = (e, altResourceGroup) => {
    e && e.preventDefault()
    altResourceGroup.plannedFinish = alternative.plannedFinish
    altResourceGroup.desiredStart = alternative.desiredStart
    setAltResourceGroupToEdit(altResourceGroup)
    toggleEditModal()
  }

  const changeAltResourceGroupProp = (prop, value) => {
    setAltResourceGroupToEdit({
      ...altResourceGroupToEdit,
      [prop]: value,
    })
  }

  const saveAltResourceGroup = async () => {
    await api.updateAlternativeResourceGroup(
      currentUser.userID,
      altResourceGroupToEdit
    )
    await api.generateAlternativeResourcePlans(
      alternativeId,
      altResourceGroupToEdit.resourceGroupID,
      altResourceGroupToEdit.resourceTemplateID || 0,
      altResourceGroupToEdit.costPoolID,
      altResourceGroupToEdit.altRGValue,
      altResourceGroupToEdit.desiredStart,
      altResourceGroupToEdit.plannedFinish
    )
    await loadTableData(selectedResourceGroupId)
    toggleEditModal()
  }

  const clearAltResourceGroupValues = async () => {
    let itemsToUpdate = []
    altResourceGroupToEdit.months.forEach(mth => {
      itemsToUpdate.push({
        ...mth.plan,
        alternativeID: alternativeId,
        numUnits: 0,
      })
    })
    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternativeResourcePlan(currentUser.userID, {
          ...x,
          costPoolID: selectedBCCostPoolId,
        })
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      await loadData()
    }
    await api.updateAlternativeResourceGroup(currentUser.userID, {
      ...altResourceGroupToEdit,
      altRGValue: 0,
    })
  }

  return (
    <React.Fragment>
      <Row style={{ marginTop: "20px" }}>
        <Col>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}

          {!isLoading && (
            <table
              className="table table-bordered lower-padding-table"
              style={{ backgroundColor: "white", padding: "0px" }}
            >
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 100,
                  backgroundColor: "white",
                }}
              >
                <tr>
                  <th className="sticky-th">Resource</th>
                  <th style={{ minWidth: "200px" }}>Cost Pool</th>
                  <th style={{ minWidth: "100px" }}>Unit</th>
                  {gridMonthProps.map((m, idx) => (
                    <th
                      key={idx}
                      style={{
                        minWidth: "100px",
                        backgroundColor:
                          resourceService.isMonthPropInDateInterval(
                            new Date(alternative.fyStart, 0, 1),
                            m,
                            alternative.desiredStart,
                            alternative.plannedFinish
                          )
                            ? "#cce7c9"
                            : "#ededed",
                      }}
                    >
                      {resourceService.getMonthAndYearTextByMonthProp(
                        new Date(alternative.fyStart, 0, 1),
                        m
                      )}
                    </th>
                  ))}
                  {/* <th>Total</th> */}
                  {/* <th style={{ minWidth: "300px" }}>Estimation Notes</th> */}
                </tr>
              </thead>
              <tbody style={{ overflowX: "auto" }}>
                {gridData
                  .filter(
                    x =>
                      selectedResourceGroupId == -99 ||
                      selectedResourceGroupId == -1 ||
                      x.resourceGroupID == selectedResourceGroupId
                  )
                  .map((a, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="sticky-td">
                          <a href="#" onClick={e => editAltResourceGroup(e, a)}>
                            {a["c-Resource"]}
                          </a>
                        </td>
                        <td>{a["c-Cost Pool"]}</td>
                        <td>{a.resourceUnit}</td>
                        {gridMonthProps.map((m, idx) => (
                          <td key={idx}>
                            <CurrencyInput
                              className="form-control"
                              value={a[m]}
                              disabled={
                                parseInt(resourceService
                                  .getMonthAndYearTextByMonthProp(
                                    new Date(alternative.fyStart, 0, 1),
                                    m
                                  )
                                  .split(" ")[1]) < maxYear
                              }
                              style={{
                                textAlign: "right",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                              onValueChange={(value, name, values) => {
                                changeGridValue(a.rgeid, m, values.float)
                              }}
                              placeholder=""
                              prefix={""}
                            />
                          </td>
                        ))}

                        {/* <td className="text-right">
                          {a.months.reduce(
                            (acc, item) =>
                              acc +
                              (item.plan.numUnits !== undefined &&
                              item.plan.numUnits !== null
                                ? item.plan.numUnits
                                : 0),
                            0
                          )}
                        </td> */}
                        {/* <td>
                          <Input
                            type="text"
                            style={{ width: "300px" }}
                            value={a.argNotes}
                            onChange={e => {
                              let copy = [...altResourceGroups]
                              let g = copy.find(
                                x => x.resourceGroupID == a.resourceGroupID
                              )
                              g.argNotes = e.target.value
                              g.isDirty = true
                              showToastChanges()
                              setAltResourceGroups(copy)
                            }}
                          />
                        </td> */}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
      <Modal
        backdrop="static"
        isOpen={isEditModalVisible}
        size="lg"
        toggle={() => {
          toggleEditModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Distribute Resources
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleEditModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        {altResourceGroupToEdit != null && (
          <div className="modal-body">
            <div className="mb-3">
              <Label>{altResourceGroupToEdit["c-Resource"]}</Label>
              <br />
              <Label>{altResourceGroupToEdit["c-Cost Pool"]}</Label>
            </div>
            <div className="mb-3">
              <Label>Start Date</Label>
              <Input
                name="rgstartDate"
                type="date"
                onChange={e =>
                  changeAltResourceGroupProp(
                    "desiredStart",
                    moment(e.target.value, "YYYY-MM-DD").format(
                      "YYYY-MM-DDThh:mm:ss"
                    )
                  )
                }
                value={moment(altResourceGroupToEdit.desiredStart).format(
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="mb-3">
              <Label>End Date</Label>
              <Input
                name="altrgendDate"
                type="date"
                onChange={e =>
                  changeAltResourceGroupProp(
                    "plannedFinish",
                    moment(e.target.value, "YYYY-MM-DD").format(
                      "YYYY-MM-DDThh:mm:ss"
                    )
                  )
                }
                value={moment(altResourceGroupToEdit.plannedFinish).format(
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Template</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={altResourceGroupToEdit.resourceTemplateID || 0}
                onChange={e => {
                  changeAltResourceGroupProp(
                    "resourceTemplateID",
                    e.target.value
                  )
                }}
              >
                {resourceTemplates.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.listItemID}`}>
                      {a.listItemName}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="mb-3">
              <Label className="form-label">Total Hours</Label>
              <CurrencyInput
                id="atlcostConstraint"
                name="altcostConstraint"
                className="form-control"
                value={altResourceGroupToEdit.altRGValue}
                onValueChange={(value, name, values) => {
                  changeAltResourceGroupProp("altRGValue", values.float)
                }}
                placeholder=""
                prefix={""}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Notes</Label>
              <TextareaAutosize
                className="form-control"
                minRows={2}
                onChange={e =>
                  changeAltResourceGroupProp("argNotes", e.target.value)
                }
                value={altResourceGroupToEdit.argNotes}
              />
            </div>
          </div>
        )}
        <div className="modal-footer">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={clearAltResourceGroupValues}
            >
              Clear Units
            </button>
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={toggleEditModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary save-user"
                onClick={saveAltResourceGroup}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ResourceManagerModal
        isOpen={showManagerModal}
        toggleModal={toggleManagerModal}
        scenarioId={alternative.scenarioID}
      />
    </React.Fragment>
  )
}

export default ResourceBudgetingPlanTab
