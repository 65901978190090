import { TextareaAutosize } from "@material-ui/core"
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor"
import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import useFetcher from "hooks/useFetcher"
import moment from "moment"
import Multiselect from "multiselect-react-dropdown"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { extractInnerTextFromHtml } from "utils/formatters"
import isNullOrEmpty from "utils/isNullOrEmpty"

const InAppNotificationModal = ({ isOpen, toggleModal }) => {
  const currentUser = userService.getLoggedInUser()

  const [scenarios, loadingScenarios, loadScenarios] = useFetcher(
    () => api.getScenariosByUser(currentUser.userID, 0),
    null,
    true
  )
  const [selectedScenarioId, setSelectedScenarioId] = useState(-1)
  const [alternatives, setAlternatives] = useState([])
  const [selectedAlternativeId, setSelectedAlternativeId] = useState(-1)

  const [dashboards, loadingDashboards, loadDashobards] = useFetcher(
    () => api.getDashboards(currentUser.userID, true),
    [],
    true
  )
  const [selectedDashboardsId, setSelectedDashboardId] = useState(-1)

  const [roadmaps, loadingRoadmaps, loadRoadmaps] = useFetcher(
    () => api.getRoadmaps(currentUser.userID),
    [],
    true
  )
  const [selectedRoadmapId, setSelectedRoadmapId] = useState(-1)

  const [users, loadingUsers, loadUsers] = useFetcher(
    () => api.getUsersByUserNotifications(currentUser.userID),
    [],
    true
  )
  const [selectedUsers, setSelectedUsers] = useState([])

  const [selectedScenarios, setSelectedScenarios] = useState([])

  const [roles, loadingRoles, loadRoles] = useFetcher(
    () => api.getList(currentUser.userID, "Role"),
    [],
    true
  )
  const [selectedRoles, setSelectedRoles] = useState([])

  const [message, setMessage] = useState("")
  const [subject, setSubject] = useState("")

  const selectorOptions = ["Project", "Dashboard", "Roadmap"]
  const [selectedSelectorOption, setSelectedSelectorOption] = useState("")

  const [sendToTeamMembersOfScenario, setSendToTeamMembersOfScenario] =
    useState(false)

  const [sendToRole, setSendToRole] = useState(false)

  const isLoading =
    loadingDashboards ||
    loadingRoadmaps ||
    loadingRoadmaps ||
    loadingScenarios ||
    loadingUsers ||
    loadingRoles

  useEffect(() => {
    if (isOpen) {
      resetValues()
      loadData()
    }
  }, [isOpen])

  const resetValues = () => {
    setMessage("")
    setSubject("")
    setSelectedUsers([])
    setSelectedAlternativeId(-1)
    setSelectedScenarioId(-1)
    setSelectedRoadmapId(-1)
    setSelectedDashboardId(-1)
    setSelectedSelectorOption("")
    setSelectedScenarios([])
    setSelectedRoles([])
    setSendToTeamMembersOfScenario(false)
  }

  const loadData = async () => {
    // setIsLoading(true)
    loadScenarios()
    loadDashobards()
    loadRoadmaps()
    loadUsers()
    loadRoles()
    setAlternatives([])
    // setIsLoading(false)
  }

  const loadAlternatives = async scenarioId => {
    if (scenarioId <= 0) {
      setAlternatives([])
    } else {
      let a = await api.getAlternatives(currentUser.userID, scenarioId)
      setAlternatives(a.sort((x, y) => y.alternativeNum - x.alternativeNum))
    }
  }

  const save = async () => {
    let error = ""
    if (isNullOrEmpty(subject)) {
      error += "Subject is required. "
    }
    if (isNullOrEmpty(message)) {
      error += "Message is required. "
    }
    if (
      selectedUsers.length == 0 &&
      !sendToRole &&
      !sendToTeamMembersOfScenario
    ) {
      error += "Receivers must be selected. "
    }

    if (error != "") {
      swalWithConfirmButton.fire({
        title: error,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      let receivers = []
      if (!sendToRole && !sendToTeamMembersOfScenario) {
        selectedUsers.forEach(u => {
          let usr = users.find(x => x.userID == u.id)
          if (usr != undefined) receivers.push(usr)
        })
      }

      let link = ""
      if (
        selectedSelectorOption == "Project" &&
        selectedScenarioId > -1 &&
        selectedAlternativeId > -1
      ) {
        link = `/editProject/${selectedScenarioId}/${selectedAlternativeId}`
      } else if (
        selectedSelectorOption == "Roadmap" &&
        selectedRoadmapId > -1
      ) {
        link = `/roadmap?roadmapId=${selectedRoadmapId}`
      } else if (
        selectedSelectorOption == "Dashboard" &&
        selectedDashboardsId > -1
      ) {
        link = `/dashboard/${selectedDashboardsId}`
      }

      await api.sendInAppNotification({
        subject,
        message,
        users: receivers,
        link,
        type:
          !sendToRole && !sendToTeamMembersOfScenario
            ? "user"
            : sendToRole
            ? "role"
            : "scenario",
        ids: sendToRole
          ? selectedRoles.map(x => x.id)
          : sendToTeamMembersOfScenario
          ? selectedScenarios.map(x => x.id)
          : [],
      })

      toggleModal()
      toast.success("Message sent")
    }
  }

  const alternativeLabel =
    selectedScenarioId == -1
      ? "Alternatives"
      : scenarios.find(x => x.scenarioID == selectedScenarioId)?.alternativeName

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Send Message
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {/* {!isLoading && ( */}
        <>
          {!sendToTeamMembersOfScenario && !sendToRole && (
            <div className="mb-3">
              <Label className="form-label">Select Recipients</Label>
              <Multiselect
                options={users.map(x => {
                  return {
                    name: x.firstName + " " + x.lastName,
                    id: x.userID,
                  }
                })}
                selectedValues={selectedUsers}
                onSelect={setSelectedUsers}
                onRemove={setSelectedUsers}
                displayValue="name"
                showCheckbox={true}
              />
            </div>
          )}
          {(currentUser.roleID == 10 || currentUser.roleID > 100) && (
            <div className="mb-3">
              <div className="form-check">
                <Input
                  type="checkbox"
                  id="toScen"
                  checked={sendToTeamMembersOfScenario}
                  onClick={() => {
                    if (!sendToTeamMembersOfScenario) {
                      setSendToRole(false)
                    }
                    setSendToTeamMembersOfScenario(!sendToTeamMembersOfScenario)
                  }}
                />
                <Label style={{ marginLeft: "10px" }} for="toScen">
                  Send to all portfolio team members of:{" "}
                </Label>
              </div>
              {sendToTeamMembersOfScenario && (
                <Multiselect
                  options={[
                    //{ scenarioID: 0, scenarioName: "All" },
                    ...scenarios,
                  ].map(x => {
                    return { name: x.scenarioName, id: x.scenarioID }
                  })}
                  selectedValues={selectedScenarios}
                  onSelect={setSelectedScenarios}
                  onRemove={setSelectedScenarios}
                  displayValue="name"
                  showCheckbox={true}
                />
              )}
            </div>
          )}
          {currentUser.roleID > 100 && (
            <div className="mb-3">
              <div className="form-check">
                <Input
                  type="checkbox"
                  id="toRole"
                  checked={sendToRole}
                  onClick={() => {
                    if (!sendToRole) {
                      setSendToTeamMembersOfScenario(false)
                    }
                    setSendToRole(!sendToRole)
                  }}
                />
                <Label style={{ marginLeft: "10px" }} for="toRole">
                  Send to all users by role:
                </Label>
              </div>
              {sendToRole && (
                <Multiselect
                  options={[
                    //{ listItemID: 0, listItemName: "All" },
                    ...roles,
                  ].map(x => {
                    return { name: x.listItemName, id: x.listItemID }
                  })}
                  selectedValues={selectedRoles}
                  onSelect={setSelectedRoles}
                  onRemove={setSelectedRoles}
                  displayValue="name"
                  showCheckbox={true}
                />
              )}
            </div>
          )}
          <div className="mb-3">
            <Label className="form-label">Subject</Label>
            <Input
              className="form-control"
              minRows={2}
              onChange={e => setSubject(e.target.value)}
              value={subject}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Message</Label>
            <RichTextEditorComponent
              toolbarSettings={{
                items: [
                  "Bold",
                  "Italic",
                  "Underline",
                  "StrikeThrough",
                  "FontName",
                  "FontSize",
                  "FontColor",
                  "BackgroundColor",
                  "LowerCase",
                  "UpperCase",
                  "|",
                  "Formats",
                  "Alignments",
                  "OrderedList",
                  "UnorderedList",
                  "Outdent",
                  "Indent",
                  "|",
                  "CreateLink",
                  "Image",
                  "|",
                  "ClearFormat",
                  "Print",
                  "SourceCode",
                  "FullScreen",
                  "|",
                  "Undo",
                  "Redo",
                ],
                type: "Expand",
              }}
              value={message}
              change={e => {
                setMessage(e.value)
              }}
            >
              <Inject services={[Toolbar, Link, HtmlEditor, QuickToolbar]} />
            </RichTextEditorComponent>
          </div>
          <div className="mb-3">
            <Label>Include a link to:</Label>
            {selectorOptions.map((s, idx) => (
              <div key={idx}>
                <input
                  className="form-check-input"
                  type="radio"
                  name={s}
                  id={s}
                  checked={selectedSelectorOption == s}
                  onClick={e => {
                    setSelectedSelectorOption(s)
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor={s}
                  style={{ marginLeft: "10px" }}
                >
                  {s}
                </label>
              </div>
            ))}
          </div>

          {selectedSelectorOption == "Project" && (
            <>
              <div className="mb-3">
                <Label className="form-label">Portfolio</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={selectedScenarioId}
                  onChange={e => {
                    loadAlternatives(e.target.value)
                    setSelectedScenarioId(e.target.value)
                  }}
                >
                  <option value="-1">Select...</option>
                  {scenarios.map((s, i) => (
                    <option key={i} value={s.scenarioID}>
                      {s.scenarioName}
                    </option>
                  ))}
                </select>
              </div>
              {selectedScenarioId > -1 && (
                <div className="mb-3">
                  <Label className="form-label">{alternativeLabel}</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={selectedAlternativeId}
                    onChange={e => {
                      setSelectedAlternativeId(e.target.value)
                    }}
                  >
                    <option value="-1">Select...</option>
                    {alternatives.map((s, i) => (
                      <option key={i} value={s.alternativeID}>
                        {s.alternativeNum} - {s.alternativeName} -{" "}
                        {extractInnerTextFromHtml(s.version)}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </>
          )}
          {selectedSelectorOption == "Roadmap" && (
            <div className="mb-3">
              <Label className="form-label">Roadmap</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={selectedRoadmapId}
                onChange={e => {
                  setSelectedRoadmapId(e.target.value)
                }}
              >
                <option value="-1">Select...</option>
                {roadmaps.map((s, i) => (
                  <option key={i} value={s.roadmapID}>
                    {s.roadmapName}
                  </option>
                ))}
              </select>
            </div>
          )}
          {selectedSelectorOption == "Dashboard" && (
            <div className="mb-3">
              <Label className="form-label">Dashboard</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={selectedDashboardsId}
                onChange={e => {
                  setSelectedDashboardId(e.target.value)
                }}
              >
                <option value="-1">Select...</option>
                {dashboards.map((s, i) => (
                  <option key={i} value={s.dashboardID}>
                    {s.dashboardName}
                  </option>
                ))}
              </select>
            </div>
          )}
        </>
        {/* )} */}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Send
        </button>
      </div>
    </Modal>
  )
}

export default InAppNotificationModal
