import download from "downloadjs"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Modal } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const ProjectArtifactsModal = ({
  isOpen,
  toggleModal,
  alternativeId,
  alternativeName,
}) => {
  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen])

  const downloadProjectCharterDocument = () => {
    setIsDownloading(true)
    api.getAlternativeWordDocument(alternativeId, "ProjectCharter").then(blob => {
      setIsDownloading(false)
      download(blob, `${alternativeName} - Project Charter.docx`)
    })
  }

  const downloadRiskDocument = () => {
    setIsDownloading(true)
    api.getAlternativeWordDocument(alternativeId, "RiskDocument").then(blob => {
      setIsDownloading(false)
      download(blob, `${alternativeName} - Risk Document.docx`)
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Project Artifacts
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <button
            className={`btn btn-primary`}
            onClick={() => downloadProjectCharterDocument()}
            disabled={isDownloading}
          >
            Project Charter
          </button>
        </div>
        <div className="mb-3">
          <button
            className={`btn btn-primary`}
            onClick={() => downloadRiskDocument()}
            disabled={isDownloading}
          >
            Risk Management
          </button>
        </div>
        {isDownloading && (
          <>
            <p style={{ textAlign: "center" }}>
              Generating document - this may take a minute
            </p>
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          </>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default ProjectArtifactsModal
