import moment from "moment"
import React, { useEffect, useState } from "react"
import { Tooltip } from "@material-ui/core"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import anychart from "anychart"
import { toast } from "react-toastify"

const ResourceCompare = props => {
  const currentUser = userService.getLoggedInUser()
  const scenarioId = props.match.params.scenarioId
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [isHeatmapLoading, setIsHeatmapLoading] = useState(false)
  const [isDrilldownLoading, setIsDrilldownLoading] = useState(false)
  const [alternatives, setAlternatives] = useState([])
  const [scenario, setScenario] = useState(null)
  const [decisions, setDecisions] = useState([])
  const [selectedDecision, setSelectedDecision] = useState(-1)
  const [alternativesVisible, setAlternativesVisible] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [allSelected, setAllSelected] = useState(false)

  const toggleModal = () => {
    if (isModalOpen) {
      document.getElementById("drilldiv").innerHTML = ""
    }
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "resourceCompare",
      })
    )
    loadScenario()
    loadData()
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    await loadAlternatives()
    let items = await api.getList(currentUser.userID, "Decisions")
    setDecisions(items)
    setIsLoading(false)
  }

  const loadAlternatives = async () => {
    let alts = await api.getAlternatives(currentUser.userID, scenarioId)
    setAlternatives(
      alts.map(x => {
        if (x.tempStartDate == null) {
          x.tempStartDate = x.desiredStart
        }
        return x
      })
    )
  }

  const loadScenario = async () => {
    let sc = await api.getScenario(scenarioId)
    setScenario(sc)
  }

  const changeAltProp = (alt, prop, value, markDirty) => {
    let copy = [...alternatives]
    let item = copy.find(x => x.alternativeID == alt.alternativeID)
    item[prop] = value
    if (markDirty) {
      item.isDirty = true
    }
    setAlternatives(copy)
  }

  const updateActualDates = async () => {
    let itemsToUpdate = alternatives.filter(x => x.isDirty)
    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternative(currentUser.userID, {
          ...x,
          desiredStart: x.tempStartDate,
        })
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      toast.success("Alternatives saved successfully")
    }
  }

  const loadHeatmap = async () => {
    setIsHeatmapLoading(true)
    let chartData = await api.getResourceHeatmap(
      1,
      scenario.resourceStartDT,
      scenario.resourceEndDT,
      scenarioId,
      alternatives
        .filter(x => x.isSelected)
        .map(x => x.alternativeID)
        .join(",")
    )
    document.getElementById("heatdiv").innerHTML = ""

    let data = JSON.parse(
      chartData.chartArray.replaceAll("'", '"').replaceAll(",]", "]")
    )
    var dataSet = anychart.data.set(data)

    var heatMapData = dataSet.mapAs({ x: 0, y: 1, heat: 2, custom: 3, url: 4 })

    // Creates Heat Map
    var chart = anychart.heatMap(heatMapData)

    // Sets chart title
    chart.title("Resource Heat Map")

    // Sets selection mode for single selection.
    chart.interactivity().selectionMode("none")
    chart.labelsDisplayMode("drop")

    // Sets range for colorScale
    var colorScale = chart.colorScale()
    // Sets colors for all points
    colorScale.ranges([
      {
        from: -10000,
        to: -10,
        name: "Over Allocated",
        color: "#fa6b71",
      },
      {
        from: -10,
        to: 10,
        name: "At Capacity",
        color: "#69bce8",
      },
      {
        from: 10,
        to: 10000,
        name: "Available",
        color: "#3ba158",
      },
    ])
    chart.colorScale(colorScale)

    // Sets labels and maximum FontSize
    chart.labels().enabled(true).maxFontSize(14)

    // Sets chart settings and hover chart settings
    chart.stroke("#fff")
    chart
      .hovered()
      .stroke("6 #fff")
      .fill("#545f69")
      .labels({ fontColor: "#fff" })

    // Sets legend
    chart
      .legend()
      .enabled(true)
      .align("center")
      .position("center-top")
      .itemsLayout("horizontal")

    // Sets Axes TextFormatter
    chart.yAxis().stroke(null)
    chart
      .yAxis()
      .labels()
      .padding([0, 15, 0, 0])
      .format(function () {
        return this.tickValue
      })
    chart.xAxis().stroke(null)
    chart
      .xAxis()
      .labels()
      .format(function () {
        return this.tickValue
      })
    chart.yAxis().ticks(false)
    chart.xAxis().ticks(false)
    chart.tooltip().format(function () {
      var heat = this.heat
      if (heat < 0)
        return this.y + "- Over Allocated: " + this.getData("custom")
      if (heat > 0) return this.y + "- Available: " + this.getData("custom")
    })

    chart.xScroller().enabled(true)
    chart.xZoom().setToPointsCount(18)
    chart.yScroller().enabled(true)
    chart.yZoom().setToPointsCount(20)
    //add a listener
    chart.listen("pointClick", function (e) {
      var new_value = e.iterator.get("url")

      loadDrillDown(new_value)
    })
    chart.container("heatdiv")
    chart.credits().enabled(false)
    setIsHeatmapLoading(false)
    chart.draw()
  }

  const loadDrillDown = async queryString => {
    setIsDrilldownLoading(true)
    toggleModal()

    const params = new Proxy(
      new URLSearchParams(`www.site.com${queryString}`),
      {
        get: (searchParams, prop) => searchParams.get(prop),
      }
    )
    let month = params.mid
    let year = params.yid
    let rgId = params.rgid

    let chartData = await api.getResourceDrilldown(
      1,
      scenario.resourceStartDT,
      scenario.resourceEndDT,
      scenarioId,
      alternatives
        .filter(x => x.isSelected)
        .map(x => x.alternativeID)
        .join(","),
      year,
      month,
      rgId
    )

    let data = JSON.parse(
      chartData.chartArray.replaceAll("'", '"').replaceAll(",]", "]")
    )

    var dataSet = anychart.data.set(data)

    var maxSet = anychart.data.set(data)

    var maxSeriesData = maxSet.mapAs({ x: 0, value: 1 })

    // helper function to setup label settings for all series
    var setupSeriesLabels = function (series, name) {
      series.name(name).stroke("3 #fa6b71 1")
      series.hovered().stroke("3 #fa6b71 1")
    }

    // create bar chart
    var chart = anychart.area()

    var alts = [
      JSON.parse(
        chartData.chartArray2.replaceAll("'", '"').replaceAll(",]", "]")
      ),
    ]

    var numseries = parseInt(chartData.chartData, 10)
    var j = 0

    for (var i = 0; i < numseries; i++) {
      j = i + 1

      chart.addSeries(
        dataSet.mapAs({
          x: 0,
          value: j + 1,
          //'tooltip': 4
        })
      )

      chart.getSeriesAt(i).name(alts[i])
    }

    // set X scale mode to continuous
    chart.xScale().mode("continuous")

    let series = chart.line(maxSeriesData)
    setupSeriesLabels(series, "Capacity")
    // turn on chart animation
    chart.animation(true)

    // force chart to stack values by Y scale.
    chart.yScale().stackMode("value")

    // set chart title text settings
    chart.title(chartData.chartTitle)
    chart.title().padding([0, 0, 5, 0])
    var xAxisLabels = chart.xAxis().labels()
    xAxisLabels.rotation(90)
    // turn on legend
    chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0])
    // set yAxis labels formatter
    chart.yAxis().labels().format("{%Value}{groupsSeparator: }")
    // set titles for axes
    chart.xAxis().title("Period")
    chart.yAxis().title("Units")
    var tooltip = chart.tooltip()
    tooltip.displayMode("single")
    // set interactivity hover
    chart.interactivity().hoverMode("bySpot")
    chart.interactivity().spotRadius(10)

    chart.xScroller().enabled(true)
    chart.xZoom().setToPointsCount(12)
    chart.container("drilldiv")
    chart.credits().enabled(false)
    chart.draw()
    setIsDrilldownLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Start Date</Label>
                        {scenario !== null && (
                          <Input
                            name="startDate"
                            type="date"
                            onChange={e => {
                              setScenario({
                                ...scenario,
                                resourceStartDT: moment(
                                  e.target.value,
                                  "YYYY-MM-DD"
                                ).format("YYYY-MM-DDThh:mm:ss"),
                              })
                            }}
                            value={moment(scenario.resourceStartDT).format(
                              "YYYY-MM-DD"
                            )}
                          />
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>End Date</Label>
                        {scenario !== null && (
                          <Input
                            name="endDate"
                            type="date"
                            onChange={e => {
                              setScenario({
                                ...scenario,
                                resourceEndDT: moment(
                                  e.target.value,
                                  "YYYY-MM-DD"
                                ).format("YYYY-MM-DDThh:mm:ss"),
                              })
                            }}
                            value={moment(scenario.resourceEndDT).format(
                              "YYYY-MM-DD"
                            )}
                          />
                        )}
                      </div>
                    </Col>

                    <Col md="3" style={{ display: "flex" }}>
                      <button
                        className="btn btn-primary"
                        style={{ alignSelf: "center" }}
                        onClick={() => loadHeatmap()}
                      >
                        <i className="fas fa-temperature-low"></i> View /
                        Refresh Heatmap
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <button
                        className="btn  btn-primary"
                        onClick={() =>
                          setAlternativesVisible(!alternativesVisible)
                        }
                      >
                        <i className="fas fa-exchange-alt"></i>
                        {alternativesVisible ? " Hide " : " Show "} Alternatives
                      </button>
                      <button
                        className="btn  btn-primary save-user"
                        onClick={() => updateActualDates()}
                      >
                        <i className="fas fa-save"></i> Update Actual Start
                        Dates
                      </button>
                    </Col>
                  </Row>
                  <Row className="mt-3" style={{ overflowY: "auto" }}>
                    {alternativesVisible && (
                      <Col md="3">
                        <Label style={{ marginBottom: "0px" }}>View</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={selectedDecision}
                          onChange={e => {
                            setSelectedDecision(e.target.value)
                          }}
                        >
                          <option value="-1">All</option>
                          {decisions.map((r, idx) => {
                            return (
                              <option key={idx} value={`${r.listItemID}`}>
                                {r.listItemName}
                              </option>
                            )
                          })}
                        </select>
                        <table
                          className="table table-bordered lower-padding-table"
                          style={{ backgroundColor: "white" }}
                        >
                          <thead>
                            <tr style={{ fontSize: "12px" }}>
                              <th>
                                <Input
                                  type="checkbox"
                                  checked={allSelected}
                                  onClick={() => {
                                    let copy = [...alternatives]
                                    copy.forEach(c => {
                                      c.isSelected = !allSelected
                                    })
                                    setAlternatives(copy)
                                    setAllSelected(!allSelected)
                                  }}
                                />
                              </th>
                              <th>Business Need</th>
                              <th>Version</th>
                              <th>Start</th>
                            </tr>
                          </thead>
                          <tbody>
                            {alternatives
                              .filter(
                                x =>
                                  selectedDecision == -1 ||
                                  x.decisionID == selectedDecision
                              )
                              .map((a, idx) => (
                                <tr key={idx}>
                                  <td style={{ textAlign: "center" }}>
                                    <Input
                                      type="checkbox"
                                      checked={a.isSelected}
                                      onClick={() => {
                                        changeAltProp(
                                          a,
                                          "isSelected",
                                          !a.isSelected
                                        )
                                      }}
                                    />
                                  </td>

                                  <td className="one-line-elipsis">
                                    <Tooltip
                                      title={
                                        <h6>
                                          {a.alternativeNum} -{" "}
                                          {a.alternativeName}
                                        </h6>
                                      }
                                    >
                                      <span>
                                        {" "}
                                        <b>
                                          {a.alternativeNum} -{" "}
                                          {a.alternativeName}
                                        </b>
                                      </span>
                                    </Tooltip>
                                  </td>
                                  <td>
                                    <div
                                      style={{ textAlign: "center" }}
                                      dangerouslySetInnerHTML={{
                                        __html: a.version,
                                      }}
                                    ></div>
                                  </td>
                                  <td>
                                    <Input
                                      id={a.alternativeID}
                                      name={a.alternativeID}
                                      type="date"
                                      onChange={e => {
                                        changeAltProp(
                                          a,
                                          "tempStartDate",
                                          moment(
                                            e.target.value,
                                            "YYYY-MM-DD"
                                          ).format("YYYY-MM-DDThh:mm:ss"),
                                          true
                                        )
                                      }}
                                      value={
                                        a.tempStartDate == null
                                          ? ""
                                          : moment(a.tempStartDate).format(
                                              "YYYY-MM-DD"
                                            )
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </Col>
                    )}
                    <Col md={alternativesVisible ? "9" : "12"}>
                      {isHeatmapLoading && (
                        <Loader
                          type="line-scale-pulse-out"
                          color={colors.primary}
                          style={{ textAlign: "center" }}
                        />
                      )}
                      <div
                        id="heatdiv"
                        style={{
                          minHeight: "600px",
                          height: "100%",
                          width: "95%",
                          marginLeft: "5px",
                        }}
                      ></div>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal
        backdrop="static"
        isOpen={isModalOpen}
        size="xl"
        //fullscreen={true}
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Drilldown
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div id="drilldiv" style={{ height: "600px" }}></div>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  )
}

export default ResourceCompare
