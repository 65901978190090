import { Tooltip } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Card, CardBody, Col, Container, Row, Label, Input } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import useModal from "hooks/useModalHook"
import CriteriaScenarioModal from "./CriteriaScenarioModal"
import isNullOrEmpty from "utils/isNullOrEmpty"

const Sensitivity = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [objectives, setObjectives] = useState([])
  const [alternatives, setAlternatives] = useState([])
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [hasChange, setHasChange] = useState(false)
  const {
    show: showCriteriaScenarioModal,
    toggle: toggleCriteriaScenarioModal,
  } = useModal()
  const [objModels, setObjModels] = useState([])
  const [selectedObjModel, setSelectedObjModel] = useState(0)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "analysisSensitivity",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    await loadObjectives()
    await loadAlternatives()
    await loadObjModels()
    setIsLoading(false)
  }

  const loadObjModels = async () => {
    let om = await api.getObjModels(scenarioId)
    setObjModels(om)
  }

  const loadAlternatives = async () => {
    let alts = await api.getAlternativeSensitivity(scenarioId)
    setAlternatives(alts)
  }

  const loadObjectives = async () => {
    let obj = await api.getObjectives(scenarioId)
    let allZeros = true
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].evTemp != null && obj[i].evTemp != "" && obj[i].evTemp > 0) {
        allZeros = false
        break
      }
      if (obj[i].measures.length > 0) {
        let measuresWithTemp = obj[i].measures.filter(
          x =>
            x.measureTempPerc != null &&
            x.measureTempPerc != "" &&
            x.measureTempPerc > 0
        )
        if (measuresWithTemp.length > 0) {
          allZeros = false
          break
        }
      }
    }
    if (allZeros) {
      obj.forEach(o => {
        o.evTemp = o.evFinal
        if (o.measures.length > 0) {
          o.measures.forEach(m => {
            m.measureTempPerc = m.measurePercent
          })
        }
      })
    }

    setObjectives(
      obj.map(x => {
        x.expanded = false
        return x
      })
    )
  }

  const changeObjectiveProp = (obj, prop, value, objOrMeasures) => {
    let copy = [...objectives]
    let item = copy.find(x => x.objectiveID == obj.objectiveID)
    item[prop] = value
    item.isDirty = true
    setObjectives(copy)
    setHasChange(true)
  }

  const saveWeights = async () => {
    setIsRefreshing(true)
    let objectivesToUpdate = []
    let measuresToUpdate = []
    objectives.forEach(o => {
      if (o.isDirty) {
        objectivesToUpdate.push(o)
      }
      o.measures.forEach(m => {
        if (m.isDirty) {
          measuresToUpdate.push(m)
        }
      })
    })
    if (objectivesToUpdate.length > 0 || measuresToUpdate.length > 0) {
      let tasks = []
      let objTasks = objectivesToUpdate.map(x => async () => {
        return await api.updateObjective(currentUser.userID, x)
      })
      let measuresTasks = measuresToUpdate.map(x => async () => {
        return await api.updateMeasure(currentUser.userID, x)
      })
      if (objTasks.length > 0) {
        tasks = objTasks
      }
      if (measuresTasks.length > 0) {
        tasks = [...tasks, ...measuresTasks]
      }

      await Promise.all(tasks.map(t => t()))
      
      setHasChange(false)
      toast.success("Weights updated successfully")
    }
    
    await loadObjModels()
    await loadObjectives()
    await loadAlternatives()
    setIsRefreshing(false)
  }

  const resetSensitivity = async () => {
    await api.resetObjectiveSensitivity(scenarioId)
    await loadObjectives()
    await loadAlternatives()
  }

  const saveAsCriteriaScenario = async scenarioName => {
    let objModelWeights = []
    objectives.forEach(o => {
      objModelWeights.push({
        ev: isNullOrEmpty(o.evTemp) ? 0 : o.evTemp,
        objMeasID: o.objectiveID,
        level: 1,
      })
      if (o.measures != null) {
        o.measures.forEach(m => {
          objModelWeights.push({
            ev: isNullOrEmpty(m.measureTempPerc) ? 0 : m.measureTempPerc,
            objMeasID: m.measureID,
            level: 2,
          })
        })
      }
    })
    await api.createObjModel(currentUser.userID, {
      scenarioID: scenarioId,
      objModelName: scenarioName,
      objModelWeights: objModelWeights,
    })
    toast.success("Criteria scenario saved.")
  }

  const loadSensitivityWeights = objModelId => {
    if (objModelId >= 0) {
      let objModel = objModels.find(x => x.objModelID == objModelId)
      if (objModel != undefined) {
        let objectivesCopy = [...objectives]
        objectivesCopy.forEach(o => {
          let objWeight = objModel.objModelWeights.find(
            x => x.objMeasID == o.objectiveID
          )
          let newObjEvVal = objWeight == undefined ? 0 : objWeight.ev
          if (o.evTemp != newObjEvVal) {
            o.isDirty = true
          }
          o.evTemp = newObjEvVal
          o.measures.forEach(m => {
            let measureWeight = objModel.objModelWeights.find(
              x => x.objMeasID == m.measureID
            )
            let newMeasEvVal = measureWeight == undefined ? 0 : measureWeight.ev
            if (m.measureTempPerc != newMeasEvVal) {
              m.isDirty = true
            }
            m.measureTempPerc = newMeasEvVal
          })
        })
        setObjectives(objectivesCopy)
      }
    }
  }

  const objEvTempSum = parseInt(
    objectives.reduce((acc, item) => acc + (item.evTemp || 0), 0)
  )

  const objEvFinalSum = parseInt(
    objectives.reduce((acc, item) => acc + (item.evFinal || 0), 0)
  )

  const getSum = (arr, prop) => {
    var sum = arr
      .filter(x => !isNaN(parseFloat(x[prop])))
      .reduce(function (a, b) {
        return a + parseFloat(b[prop])
      }, 0)
    return sum.toFixed(2)
  }

  const getMeasuresPercentSum = o =>
    parseInt(getSum(o.measures, "measurePercent"))
  const getMeasuresTempSum = o =>
    parseInt(getSum(o.measures, "measureTempPerc"))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col md="4">
                  <div className="d-flex-col">
                    <div className="mb-3">
                      <Label className="form-label">
                        Load Sensitivity Weights from:
                      </Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={selectedObjModel}
                        onChange={e => {
                          setSelectedObjModel(e.target.value)
                          loadSensitivityWeights(e.target.value)
                          if (e.target.value != -1) setHasChange(true)
                        }}
                      >
                        <option value="-1">Select ...</option>
                        {objModels.map((om, idx) => (
                          <option key={idx} value={om.objModelID}>
                            {om.objModelName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="d-flex-row jc-space-between ai-center">
                      <button
                        className="btn btn-primary"
                        onClick={resetSensitivity}
                      >
                        <i className="fa fa-undo"></i> Reset Sensitivity Weights
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={toggleCriteriaScenarioModal}
                      >
                        Save as Criteria Scenario
                      </button>
                    </div>
                  </div>
                </Col>
                <Col md="8">
                  <div className="d-flex-row ai-center" style={{ gap: "20px" }}>
                    <button
                      className="btn btn-primary save-user"
                      onClick={saveWeights}
                      // disabled={!hasChange}
                    >
                      <i className="fa fa-redo"></i> Refresh
                    </button>
                    {isRefreshing && (
                      <Loader
                        type="line-scale-pulse-out"
                        color={colors.primary}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <Row>
                  <Col md="4">
                    <table
                      className="table table-bordered low-padding-table"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ width: "60%" }}>Criteria</th>
                          {/* <th style={{ width: "20%" }}>AHP Weight</th> */}
                          <th style={{ width: "20%" }}>Preference Weight</th>
                          <th style={{ width: "20%" }}>Sensitivity Weight</th>
                        </tr>
                      </thead>
                      <tbody>
                        {objectives.map((o, idx) => (
                          <React.Fragment key={idx}>
                            <tr>
                              <td>
                                {o.measures && o.measures.length > 0 && (
                                  <i
                                    className={
                                      o.expanded
                                        ? "fas fa-chevron-up"
                                        : "fas fa-chevron-down"
                                    }
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      let objs = [...objectives]
                                      let obj = objs.find(
                                        x => x.objectiveID == o.objectiveID
                                      )
                                      obj.expanded = !obj.expanded
                                      setObjectives(objs)
                                    }}
                                  />
                                )}
                              </td>
                              <td className="one-line-elipsis">
                                <Tooltip
                                  arrow="true"
                                  title={<h6> {o.objectiveName}</h6>}
                                >
                                  <span>
                                    <b> {o.objectiveName}</b>
                                  </span>
                                </Tooltip>
                              </td>
                              {/* <td className="text-right">{o.evPercent}</td> */}
                              {o.objTypeID != 10 && (
                                <td className="text-right">{o.evFinal}</td>
                              )}
                              {o.objTypeID != 10 && (
                                <td>
                                  <CurrencyInput
                                    id={o.objectiveID}
                                    name={o.objectiveID}
                                    className="form-control align-end"
                                    style={{ padding: "1px", fontSize: "12px" }}
                                    value={o.evTemp}
                                    onValueChange={(value, name, values) => {
                                      changeObjectiveProp(
                                        o,
                                        "evTemp",
                                        values.float
                                      )
                                    }}
                                    prefix={""}
                                  />
                                </td>
                              )}
                              {o.objTypeID == 10 && (
                                <td colSpan={2}>
                                  <div className="form-check">
                                    <Input
                                      id={`usemult-${o.objectiveID}`}
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={o.useMultTemp}
                                      onClick={e => {
                                        changeObjectiveProp(
                                          o,
                                          "useMultTemp",
                                          !o.useMultTemp
                                        )
                                      }}
                                    />
                                    <Label
                                      className="form-check-label"
                                      for={`usemult-${o.objectiveID}`}
                                    >
                                      Use Multiplier
                                    </Label>
                                  </div>
                                </td>
                              )}
                            </tr>
                            {o.measures && o.measures.length > 0 && o.expanded && (
                              <tr>
                                <td colSpan={4}>
                                  <table
                                    className="table table-bordered"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th style={{ width: "60%" }}>
                                          Measure
                                        </th>
                                        <th style={{ width: "20%" }}>
                                          Preference Weight
                                        </th>
                                        <th style={{ width: "20%" }}>
                                          Sensitivity Weight
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {o.measures.map(m => {
                                        return (
                                          <tr key={m.measureID}>
                                            <td colSpan={2}>{m.measureName}</td>
                                            <td>
                                              {m.measurePercent.toFixed(2)}
                                            </td>
                                            <td>
                                              <CurrencyInput
                                                id={m.measureID}
                                                name={m.measureID}
                                                className="form-control align-end"
                                                style={{
                                                  padding: "1px",
                                                  fontSize: "12px",
                                                }}
                                                value={m.measureTempPerc}
                                                onValueChange={(
                                                  value,
                                                  name,
                                                  values
                                                ) => {
                                                  let objs = [...objectives]
                                                  let obj = objs.find(
                                                    x =>
                                                      x.objectiveID ==
                                                      o.objectiveID
                                                  )
                                                  let measure =
                                                    obj.measures.find(
                                                      x =>
                                                        x.measureID ==
                                                        m.measureID
                                                    )
                                                  measure.measureTempPerc =
                                                    values.float
                                                  measure.isDirty = true
                                                  changeObjectiveProp(
                                                    o,
                                                    "measures",
                                                    obj.measures
                                                  )
                                                  //   setObjectives(objs)
                                                }}
                                                prefix={""}
                                              />
                                            </td>
                                          </tr>
                                        )
                                      })}
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td
                                          style={{
                                            color:
                                              getMeasuresPercentSum(o) > 100
                                                ? "red"
                                                : "initial",
                                          }}
                                        >
                                          <b>{getMeasuresPercentSum(o)}</b>
                                        </td>
                                        <td
                                          style={{
                                            color:
                                              getMeasuresTempSum(o) > 100
                                                ? "red"
                                                : "initial",
                                          }}
                                        >
                                          <b>{getMeasuresTempSum(o)}</b>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                        <tr>
                          <td></td>
                          <td></td>
                          {/* <td className="text-right">
                            {parseInt(
                              objectives.reduce(
                                (acc, item) => acc + (item.evPercent || 0),
                                0
                              )
                            )}
                          </td> */}
                          <td
                            className="text-right"
                            style={{
                              color: objEvFinalSum > 100 ? "red" : "initial",
                            }}
                          >
                            <b>{objEvFinalSum}</b>
                          </td>
                          <td
                            className="text-right"
                            style={{
                              color: objEvTempSum > 100 ? "red" : "initial",
                            }}
                          >
                            <b>{objEvTempSum}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col md="8">
                    <table
                      className="table table-bordered low-padding-table"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Project</th>
                          <th>Version</th>
                          <th>Benefit Score</th>
                          <th>Group Rating</th>
                          <th>Normalized Score</th>
                          <th>Cost</th>
                          <th style={{ textAlign: "center" }}>Rank Order</th>
                          <th style={{ textAlign: "center" }}>
                            Revised Rank Order
                          </th>
                          <th style={{ textAlign: "center" }}>Difference</th>
                        </tr>
                      </thead>
                      <tbody>
                        {alternatives.map((a, idx) => (
                          <tr key={idx}>
                            <td>{a.alternativeNum}</td>
                            <td className="one-line-elipsis">
                              <Tooltip title={<h6>{a.alternative}</h6>}>
                                <span>
                                  <b>{a.alternative}</b>
                                </span>
                              </Tooltip>
                            </td>
                            <td>
                              <div
                                style={{ textAlign: "center" }}
                                dangerouslySetInnerHTML={{
                                  __html: a.version,
                                }}
                              ></div>
                            </td>
                            <td>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: a.saaScore,
                                }}
                              ></div>
                            </td>
                            <td>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: a.saaRating,
                                }}
                              ></div>
                            </td>
                            <td style={{ textAlign: "right" }}>
                            {parseFloat(a.saaScoreNormal).toFixed(2)}
                            </td> 
                            <td className="text-right">
                              {currencyFormatter.format(a.cost)}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {a.rankOrder}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {a.tempRankOrder}
                            </td>
                            <td>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: a.rankDiff,
                                }}
                              ></div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <CriteriaScenarioModal
        isOpen={showCriteriaScenarioModal}
        toggleModal={toggleCriteriaScenarioModal}
        onSave={saveAsCriteriaScenario}
      />
    </React.Fragment>
  )
}

export default Sensitivity
