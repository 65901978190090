import React from "react";
import { Col, Label, Input } from "reactstrap";

export const DynamicFormCheckbox = React.memo(function dynamicFormCheckbox({
  formFieldId, label, value, placeholder, changeFormFieldValue, col, fullWidth, alwaysEditable, alwaysReadOnly,
}) {

  const isChecked = value == '1' || value == true

  return (
    <Col md={fullWidth ? "12" : col}>
      <div className="form-check mb-3">
        <Input
          type="checkbox"
          className={`form-check-input ${alwaysReadOnly ? 'always-disabled' : ''}`}
          placeholder={placeholder || ""}
          checked={isChecked}
          onClick={e => {
            let newValue = value == '1' ? '0' : value == '0' ? '1' : value == true ? false : true
            changeFormFieldValue(formFieldId, newValue);
          }}
          disabled={alwaysReadOnly} />
        <Label className="form-check-label">{label}</Label>
      </div>
    </Col>
  );
});
