import { CHANGE_CHATGPT_SETTINGS, CHANGE_WIDGETS_SETTINGS } from "./actionTypes"

const INIT_STATE = {
  isGptDrawerOpen: false,
  showWidgets: localStorage.getItem('showWidgets') === 'false' ? false : true,
}

const appSettings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_CHATGPT_SETTINGS:
      return {
        ...state,
        isGptDrawerOpen: action.payload,
      }
    case CHANGE_WIDGETS_SETTINGS:
      localStorage.setItem('showWidgets', action.payload)
      return {
        ...state,
        showWidgets: action.payload,
      }
    default:
      return state
  }
}

export default appSettings
