import { swalWithConfirmButton } from "components/custom/swal"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const MoveProjectsModal = ({
  isOpen,
  toggleModal,
  scenario,
  selectedAlternatives,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [otherScenarios, setOtherScenarios] = useState([])
  const [selectedScenarioId, setSelectedScenarioId] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setSelectedScenarioId(-1)
    let sc = await api.getScenariosByUser(
      currentUser.userID,
      scenario.scenarioTypeID
    )
    setOtherScenarios(sc.filter(x => x.scenarioID != scenario.scenarioID))
  }

  const save = async () => {
    if (selectedScenarioId > -1) {
      setIsSubmitting(true)
      let tasks = selectedAlternatives.map(x => async () => {
        return await api.moveAlternative({
          alternativeID: x.alternativeid,
          newScenarioID: selectedScenarioId,
          userID: currentUser.userID,
        })
      })
      await Promise.all(tasks.map(t => t()))
      toast.success(scenario.alternativeName + "s moved successfully")
      setIsSubmitting(false)
      toggleModal()
      reload && reload()
    } else {
      swalWithConfirmButton.fire({
        title: `Please select a Scenario first.`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Move {scenario && scenario.alternativeName}(s)
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Scenario</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={selectedScenarioId}
            onChange={e => {
              setSelectedScenarioId(e.target.value)
            }}
          >
            <option value="0">Select ...</option>
            {otherScenarios.map((s, i) => (
              <option key={i} value={s.scenarioID}>
                {s.scenarioName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        {isSubmitting && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
          disabled={isSubmitting}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default MoveProjectsModal
