import React, { useEffect } from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

import Users from "pages/Users"
import Objectives from "pages/Objectives"
import Participants from "pages/Participants"
import AdminObjectiveScore from "pages/AdminObjectiveScore"
import PwcResults from "pages/PwcResults"
import AdminAlternativeScore from "pages/AdminAlternativeScore"
import Ratings from "pages/Ratings"
import PwcConsensus from "pages/PwcConsensus"
import PwcHierarchy from "pages/PwcHierarchy"
import PwcGroupResponses from "pages/PwcGroupResponses"
import PwcIndividual from "pages/PwcIndividual"
import AltScoreRatings from "pages/AltScoreResults"
import AltScoreConsensus from "pages/AltScoreConsensus"
import AltGroupResponses from "pages/AltGroupResponses"
import AdminObjectiveFacilitate from "pages/AdminObjectiveFacilitate"
import AdminAlternativeFacilitate from "pages/AdminAlternativeFacilitate"
import Models from "pages/Models/Models"
import EditModel from "pages/Models/EditModel"
import { Container } from "reactstrap"
import Enterprises from "pages/Enterprises/Enterprises"
import Contracts from "pages/Contracts"
import EditEnterprise from "pages/Enterprises/EditEnterprise"
import Projects from "pages/Projects/Projects"
import EditProject from "pages/Projects/EditProject"
import Optimizations from "pages/Optimizations"
import OptimizationSingle from "pages/Optimizations/OptimizationSingle/index.js"
import OptimizationMulti from "pages/Optimizations/OptimizationMulti"
import EnterpriseResources from "pages/Resources/EnterpriseResources"
import ResourceCapacity from "pages/Resources/ResourceCapacity"
import ResourceCompare from "pages/Resources/ResourceCompare"
import ResourceCharts from "pages/Resources/ResourceCharts"
import ResourcePlanning from "pages/Resources/ResourcePlanning"
import Charts from "pages/Analysis/Charts"
import SetBubbleChart from "pages/Analysis/SetBubbleChart"
import ScenarioComparison from "pages/Analysis/ScenarioComparison"
import Decision from "pages/Analysis/Decision/index"
import Sensitivity from "pages/Analysis/Sensitivity"
import UserCharts from "pages/Reporting/UserCharts"
import ManageDashboards from "pages/Reporting/ManageDashboards"
import ManageViews from "pages/Reporting/ManageViews"
import Dashboard from "pages/Reporting/Dashboard"
import Entities from "pages/Entities"
import EditProjectsLists from "pages/Projects/Projects/EditProjectsLists"
import ManageDocuments from "pages/ManageDocuments"
import ObjFin from "pages/ObjFin"
import NoAccess from "pages/Home/NoAccess"
import MobileLogin from "pages/Mobile/MobileLogin"
import MobileLoginCode from "pages/Mobile/MobileLogin/MobileLoginCode"
import ObjectiveScore from "pages/Mobile/ObjectiveScore"
import AlternativeScore from "pages/Mobile/AlternativeScore"
import Exit from "pages/Mobile/Exit"
import ObjectiveOverview from "pages/Mobile/ObjectiveScore/ObjectiveOverview"
import { LoginCallback } from "@okta/okta-react"
import Loader from "react-loaders"
import colors from "utils/colors"
import LoginOkta from "pages/Authentication/LoginOkta"
import api from "services/api.service"
import { userService } from "services/user.service"
import CalculatedFields from "pages/CalculatedFields"
import Roadmap from "pages/Roadmap"
import Ideas from "pages/Projects/Ideas"
import Configure, { SignInStart } from "pages/Authentication/Teams/Configure"
import ReportDesigner from "pages/Reporting/Telerik/ReportDesigner"
import DashboardList from "pages/Reporting/DashboardList"
import ManageForms from "components/custom/DynamicForm/ManageForms"
import ManageScoring from "pages/ManageScoring"
import ManageResources from "pages/ManageResources"
import PwcScore from "pages/PwcScore"
import ManageGptFiles from "pages/ManageGptFiles"
import TaskTemplatesAndWorkflows from "pages/TaskTemplatesAndWorkflows"
import ManageTasks from "pages/ManageTasks"
import MyData from "pages/MyData"
import Plans from "pages/Plans"
import EditPlan from "pages/Plans/EditPlan"
import ModelGroups from "pages/Models/ModelGroups"
import ReportDataSources from "pages/Enterprises/ReportDataSources"
import {
  AlternativeSignalRProvider,
  SignalRProvider,
} from "signalR/AlternativeSignalRProvider"

const getAllRoutesRoute = () => {
  return {
    path: "/allRoutes",
    component: () => {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {authProtectedRoutes.map((r, idx) => {
                let path = r.path
                if (path.indexOf("scenarioId") > -1) {
                  path = path.replace(":scenarioId", "5")
                }

                return (
                  <div key={idx}>
                    <a href={path}>{path}</a>
                  </div>
                )
              })}
            </Container>
          </div>
        </React.Fragment>
      )
    },
  }
}

const authProtectedRoutes = [
  { path: "/projects/:scenarioId", component: Projects },
  { path: "/ideas", component: Ideas },
  { path: "/enterpriseProjects", component: Projects },
  {
    path: "/editProject/:scenarioId/:alternativeId",
    component: props => (
      <AlternativeSignalRProvider>
        <EditProject {...props} />
      </AlternativeSignalRProvider>
    ),
  },
  { path: "/users", component: Users },
  { path: "/objectives/:scenarioId", component: Objectives },
  { path: "/participants/:scenarioId", component: Participants },
  { path: "/adminObjectiveScore/:scenarioId", component: AdminObjectiveScore },
  {
    path: "/adminObjectiveFacilitate/:scenarioId",
    component: AdminObjectiveFacilitate,
  },
  {
    path: "/adminAlternativeFacilitate/:scenarioId",
    component: AdminAlternativeFacilitate,
  },
  {
    path: "/adminAlternativeScore/:scenarioId",
    component: AdminAlternativeScore,
  },
  { path: "/pwcResults/:scenarioId", component: PwcResults },
  { path: "/performPWC/:scenarioId", component: PwcScore },
  { path: "/ratings/:scenarioId", component: Ratings },
  { path: "/altScoreRatings/:scenarioId", component: AltScoreRatings },
  { path: "/pwcConsensus/:scenarioId", component: PwcConsensus },
  { path: "/altScoreConsensus/:scenarioId", component: AltScoreConsensus },
  { path: "/pwcHierarchy/:scenarioId", component: PwcHierarchy },
  { path: "/pwcGroupResponses/:scenarioId", component: PwcGroupResponses },
  { path: "/reportDesigner", component: ReportDesigner },
  { path: "/altGroupResponses/:scenarioId", component: AltGroupResponses },
  { path: "/pwcIndividual/:scenarioId", component: PwcIndividual },
  { path: "/scenarioGroups", component: ModelGroups },
  { path: "/models", component: Models },
  { path: "/models/:scenarioTypeId", component: Models },
  { path: "/editModel/:scenarioId", component: EditModel },
  { path: "/enterprises", component: Enterprises },
  { path: "/contracts", component: Contracts },
  { path: "/editEnterprise/:enterpriseId", component: EditEnterprise },
  { path: "/optimizations/:scenarioId", component: Optimizations },
  { path: "/optimizationSingle/:scenarioId", component: OptimizationSingle },
  { path: "/optimizationMulti/:scenarioId", component: OptimizationMulti },
  { path: "/enterpriseResources/:scenarioId", component: EnterpriseResources },
  { path: "/resourceCapacity/:scenarioId", component: ResourceCapacity },
  { path: "/manageGptFiles", component: ManageGptFiles },
  { path: "/manageResources/:scenarioId", component: ManageResources },
  { path: "/resourceCompare/:scenarioId", component: ResourceCompare },
  { path: "/resourceCharts/:scenarioId", component: ResourceCharts },
  { path: "/manageScoring/:scenarioId", component: ManageScoring },
  {
    path: "/resourcePlanning/:scenarioId",
    component: props => <ResourcePlanning {...props} budgetOrPlan="plan" />,
  },
  {
    path: "/resourceBudgeting/:scenarioId",
    component: props => <ResourcePlanning {...props} budgetOrPlan="budget" />,
  },
  { path: "/azureredirect", component: props => <p>Azure Redirect</p> },
  { path: "/analysisCharts/:scenarioId", component: Charts },
  { path: "/myData", component: MyData },
  { path: "/setBubbleChart/:scenarioId", component: SetBubbleChart },
  { path: "/scenarioComparison/:scenarioId", component: ScenarioComparison },
  { path: "/analysisDecision/:scenarioId", component: Decision },
  { path: "/analysisSensitivity/:scenarioId", component: Sensitivity },
  { path: "/userCharts", component: UserCharts },
  { path: "/roadmap", component: Roadmap },
  { path: "/plans/:scenarioId", component: Plans },
  { path: "/editPlan/:scenarioId/:planId", component: EditPlan },
  { path: "/manageDashboards", component: ManageDashboards },
  { path: "/manageForms", component: ManageForms },
  { path: "/taskTemplatesAndWorkflows", component: TaskTemplatesAndWorkflows },
  { path: "/dashboards", component: DashboardList },
  { path: "/manageViews", component: ManageViews },
  { path: "/calculatedFields", component: CalculatedFields },
  { path: "/dashboard/:dashboardId", component: Dashboard },
  { path: "/dashboard/:dashboardId/:scenarioId", component: Dashboard },
  { path: "/entities", component: Entities },
  { path: "/editProjects/:scenarioId", component: EditProjectsLists },
  { path: "/reportDataSources", component: ReportDataSources },
  { path: "/manageTasks/:scenarioId/:taskTypeId", component: ManageTasks },

  { path: "/manageDocuments/:scenarioId", component: ManageDocuments },
  { path: "/objFin/:scenarioId", component: ObjFin },

  { path: "/noAccess", component: NoAccess },

  getAllRoutesRoute(),
  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => {
      const currentUser = userService.getLoggedInUser()
      if (
        currentUser == undefined ||
        currentUser == null ||
        currentUser.homePagePath == undefined
      ) {
        return <Redirect to="/login" />
      } else {
        return <Redirect to={currentUser.homePagePath} />
      }
    },
  },
]

const mobileRoutes = [
  { path: "/mobile/mlogindirect", component: MobileLogin, exact: false },
  { path: "/mobileCode", component: MobileLoginCode, exact: false },
  {
    path: "/mobile/objective/:scenarioId/:mode",
    component: ObjectiveScore,
    exact: false,
  },
  {
    path: "/mobile/abcobjective/overview/:scenarioId",
    component: ObjectiveOverview,
    exact: false,
  },
  {
    path: "/mobile/alternative/:scenarioId/:mode",
    component: AlternativeScore,
    exact: false,
  },
  { path: "/mobile/exit", component: Exit, exact: false },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/login/:enterpriseCode?", component: Login },
  { path: "/loginokta", component: LoginOkta },
  { path: "/login/teams/configure", component: Configure },
  { path: "/login/teams/startsignin", component: SignInStart },
  {
    path: "/loginokta/redirect",
    component: props => {
      return (
        <LoginCallback
          loadingElement={
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center", marginTop: "30%" }}
            />
          }
        />
      )
    },
  },
  {
    path: "/login:id",
    component: props => {
      const params = new URLSearchParams(window.location.search)
      const list = params.get("list")
      const dash = params.get("dash")
      const bc = params.get("bc")
      const pl = params.get("pl")

      const listId = list == null ? null : atob(list)
      const dashId = dash == null ? null : atob(dash)
      const bcId = bc == null ? null : atob(bc)
      const plId = pl == null ? null : atob(pl)

      let from = ""
      if (dashId != null) {
        from = `/dashboard/${dashId}`
      } else if (listId != null) {
        from = `/redirectList?list=${listId}`
      } else if (bcId != null) {
        from = `/redirectBusinessCase?bcId=${bcId}`
      } else if (plId != null) {
        from = `/plans/${plId}`
      }

      if (!localStorage.getItem("currentData"))
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: from },
            }}
          />
        )
      else {
        if (from != "") {
          if (from.indexOf("redirectList") > -1) {
            let user = userService.getLoggedInUser()
            api.getAlternativeLists(user.userID, 0).then(listData => {
              let listObject = listData.find(x => x.alternativeListID == listId)
              if (listObject != undefined) {
                window.location.replace(
                  `/projects/${listObject.scenarioID}?list=${listId}`
                )
              } else {
                window.location.replace(`/`)
              }
            })
          } else if (from.indexOf("redirectBusinessCase") > -1) {
            api.getAlternativeByBusinessCaseId(bcId).then(alternativeData => {
              if (alternativeData != undefined) {
                window.location.replace(
                  `/editProject/${alternativeData.scenarioID}/${alternativeData.alternativeID}`
                )
              } else {
                window.location.replace(`/`)
              }
            })
          } else {
            return (
              <Redirect
                to={{
                  pathname: from,
                }}
              />
            )
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          )
        }
      }
    },
    exact: false,
  },
]

export { authProtectedRoutes, publicRoutes, mobileRoutes }
