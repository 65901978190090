import { authenticationService } from "services/authentication.service"

export function handleResponse(response) {
  if(response.status === 500){
    throw '500 Internal server error'
  }
  if(response.status === 400){
    throw '400 Internal server error'
  }
  if(response.status === 404){
    throw '404 Internal server error'
  }
  return response.text().then(text => {
    try{
      const jsonData = text && JSON.parse(text)
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          authenticationService.logout()
          window.location.reload()
        }
  
        const error = (data && data.error_description) || response.statusText
        throw 'Error on request'
      }
  
      return jsonData
    }catch(err){
      return text
    }
    // const data = text && JSON.parse(text)
    // if (!response.ok) {
    //   if ([401, 403].indexOf(response.status) !== -1) {
    //     // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    //     authenticationService.logout()
    //     window.location.reload()
    //   }

    //   const error = (data && data.error_description) || response.statusText
    //   throw 'Error on request'
    // }

    // return data
  })
}
