import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core"
import React, { useState } from "react"
import { Col, Label, Row } from "reactstrap"
import isNullOrEmpty from "utils/isNullOrEmpty"

const EditUserChartFiltersSection = ({
  chartToEdit,
  handleMultiChange,
  advancedPlanningLabel,
  governanceBoardLabel,
  investmentCategoryLabel,
  ldoLabel,
  planningCategoryLabel,
  sponsoringBusinessUnitLabel,
  brmLabel,

  advancedPlannings,
  selectedAdvancedPlanning,
  bcStatuses,
  selectedBcStatus,
  priorities,
  selectedPriorities,
  brmList,
  selectedBrm,
  CPICPhases,
  selectedCPICPhase,
  complete,
  selectedComplete,
  decisions,
  selectedDecision,
  excludeList,
  selectedExclude,
  governanceBoards,
  selectedGovernanceBoard,
  years,
  selectedYears,
  investmentCategories,
  selectedInvestment,
  ldoList,
  selectedLdo,
  pocList,
  selectedPoc,
  planningCategories,
  selectedPlanningCategory,
  pmList,
  selectedPm,
  projectStatuses,
  selectedProjectStatus,
  sponsorList,
  selectedSponsor,
  submitterList,
  selectedSubmitter,
  workflows,
  selectedWorkflow,
}) => {
  const [showFilters, setShowFilters] = useState(false)

  const ChartFilter = ({
    name,
    label,
    options,
    value,
    disabled = false,
    optionsKeyForValue = "listItemID",
  }) => {
    const renderSelectedName = (selected, list) => {
      try {
        return selected
          .map(id =>
            id == "Any"
              ? "Any"
              : list.find(l => l.listItemID === id)?.listItemName
          )
          .filter(x => x != undefined)
          .join(", ")
      } catch (err) {
        console.log(err)
      }
    }

    const hasValueDifferentThanAny = values => {
      if (isNullOrEmpty(values) || values.length == 0) {
        return false
      }

      return values.find(x => x !== "Any") != undefined
    }

    const getFilterText = values => {
      if (isNullOrEmpty(values) || values.length == 0) {
        return ""
      }
      if (optionsKeyForValue != "listItemID") {
        return values.join(",")
      }

      let ret = []
      values.forEach(v => {
        let item = options.find(x => x.listItemID == v)
        if (item != undefined) {
          ret.push(item.listItemName)
        }
      })
      return ret.join(", ")
    }

    return (
      <>
        {!disabled && showFilters && (
          <Col md="4">
            <div className="mb-3">
              <Label className="form-label" style={{ marginBottom: "10px" }}>
                {label}
              </Label>
              <Select
                name={name}
                disabled={disabled}
                multiple
                value={value}
                onChange={handleMultiChange}
                input={<OutlinedInput />}
                renderValue={selected => renderSelectedName(selected, options)}
              >
                <MenuItem value={"Any"}>
                  <Checkbox
                    checked={value.indexOf("Any") > -1}
                    color="primary"
                  />
                  <ListItemText primary={"Any"} />
                </MenuItem>
                {options.map(s => (
                  <MenuItem key={s.listItemName} value={s[optionsKeyForValue]}>
                    <Checkbox
                      checked={value.indexOf(s[optionsKeyForValue]) > -1}
                      color="primary"
                    />
                    <ListItemText primary={s.listItemName} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Col>
        )}
        {!disabled && !showFilters && hasValueDifferentThanAny(value) && (
          <Col md="12">
            <p style={{ marginBottom: "1px" }}>
              <Label>{label}:</Label> {getFilterText(value)}
            </p>
          </Col>
        )}
      </>
    )
  }

  return (
    <React.Fragment>
      <button
        className="btn btn-primary mb-3"
        onClick={() => setShowFilters(!showFilters)}
      >
        {showFilters ? "Hide filters" : "Add / Update filters"}
      </button>
      <Row>
        <ChartFilter
          name="advancedplanning"
          label={advancedPlanningLabel}
          chartToEdit={chartToEdit}
          options={advancedPlannings}
          value={selectedAdvancedPlanning}
          disabled={
            chartToEdit.generalChartScope != "Scenario" ||
            chartToEdit.scenarioID == 0
          }
        />
        <ChartFilter
          name="bcstatus"
          label={"Business Case Status"}
          chartToEdit={chartToEdit}
          options={bcStatuses}
          value={selectedBcStatus}
          optionsKeyForValue="listItemName"
        />
        <ChartFilter
          name="priority"
          label={"Business Priority"}
          chartToEdit={chartToEdit}
          options={priorities}
          value={selectedPriorities}
          optionsKeyForValue="listItemName"
        />
        <ChartFilter
          name="brm"
          label={brmLabel}
          chartToEdit={chartToEdit}
          options={brmList}
          value={selectedBrm}
          disabled={
            chartToEdit.generalChartScope != "Scenario" ||
            chartToEdit.scenarioID == 0
          }
        />

        <ChartFilter
          name="cpicphase"
          label={"CPIC Phase"}
          chartToEdit={chartToEdit}
          options={CPICPhases}
          value={selectedCPICPhase}
          disabled={
            chartToEdit.generalChartScope != "Scenario" ||
            chartToEdit.scenarioID == 0
          }
        />

        <ChartFilter
          name="comp"
          label={"Completed"}
          chartToEdit={chartToEdit}
          options={complete}
          value={selectedComplete}
        />

        <ChartFilter
          name="decision"
          label={"Decision (Field)"}
          chartToEdit={chartToEdit}
          options={decisions}
          value={selectedDecision}
        />

        <ChartFilter
          name="excluded"
          label={"Excluded from Decision"}
          chartToEdit={chartToEdit}
          options={excludeList}
          value={selectedExclude}
        />

        <ChartFilter
          name="gov"
          label={governanceBoardLabel}
          chartToEdit={chartToEdit}
          options={governanceBoards}
          value={selectedGovernanceBoard}
          disabled={
            chartToEdit.generalChartScope != "Scenario" ||
            chartToEdit.scenarioID == 0
          }
        />

        <ChartFilter
          name="years"
          label={"Years"}
          chartToEdit={chartToEdit}
          options={years}
          value={selectedYears}
        />

        <ChartFilter
          name="invcat"
          label={investmentCategoryLabel}
          chartToEdit={chartToEdit}
          options={investmentCategories}
          value={selectedInvestment}
        />

        <ChartFilter
          name="ldo"
          label={ldoLabel}
          chartToEdit={chartToEdit}
          options={ldoList}
          value={selectedLdo}
        />

        <ChartFilter
          name="ldopoc"
          label={"Lead Delivery Organization POC"}
          chartToEdit={chartToEdit}
          options={pocList}
          value={selectedPoc}
          disabled={
            chartToEdit.generalChartScope != "Scenario" ||
            chartToEdit.scenarioID == 0
          }
        />

        <ChartFilter
          name="planningcat"
          label={planningCategoryLabel}
          chartToEdit={chartToEdit}
          options={planningCategories}
          value={selectedPlanningCategory}
          disabled={
            chartToEdit.generalChartScope != "Scenario" ||
            chartToEdit.scenarioID == 0
          }
        />

        <ChartFilter
          name="pm"
          label={"Project Manager"}
          chartToEdit={chartToEdit}
          options={pmList}
          value={selectedPm}
          disabled={
            chartToEdit.generalChartScope != "Scenario" ||
            chartToEdit.scenarioID == 0
          }
        />

        <ChartFilter
          name="status"
          label={"Project Status"}
          chartToEdit={chartToEdit}
          options={projectStatuses}
          value={selectedProjectStatus}
          optionsKeyForValue="listItemName"
        />

        <ChartFilter
          name="sponsor"
          label={sponsoringBusinessUnitLabel}
          chartToEdit={chartToEdit}
          options={sponsorList}
          value={selectedSponsor}
          disabled={
            chartToEdit.generalChartScope != "Scenario" ||
            chartToEdit.scenarioID == 0
          }
        />

        <ChartFilter
          name="submitter"
          label={"Submitter"}
          chartToEdit={chartToEdit}
          options={submitterList}
          value={selectedSubmitter}
        />

        <ChartFilter
          name="workflow"
          label={"Workflow Status"}
          chartToEdit={chartToEdit}
          options={workflows}
          value={selectedWorkflow}
          disabled={
            chartToEdit.generalChartScope != "Scenario" ||
            chartToEdit.scenarioID == 0
          }
        />
      </Row>
      {/* {!showFilters && } */}
    </React.Fragment>
  )
}

export default EditUserChartFiltersSection
