import PropTypes from "prop-types"
import Widgets from "components/Widgets"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import { changeNavbarParams } from "store/navbar/actions"
import colors from "utils/colors"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AddEditContractModal from "./AddEditContractModal"
import ContractGrid from "./ContractGrid"
import { useUserRight } from "services/role.service"

const Contracts = props => {
  //meta title
  document.title = "Definitive Pro"

  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  useUserRight(pageItem, props)
  const newContract = {
    contractID: -1,
    enterpriseID: currentUser.enterpriseID,
  }
  let [contracts, setContracts] = useState([])  
  let [isLoading, setIsLoading] = useState(false)
  let [contract, setContract] = useState(null)
  let [funds, setFunds] = useState([])
  let [years, setYears] = useState([])
  let [enterprise, setEnterprise] = useState(null)
  let [showEditModal, setShowEditModal] = useState(false)
  let [widgets, setWidgets] = useState([])

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "contracts",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "contracts",
      })
    )
    loadEnterprise()
    loadContracts(true)
    loadFunds()
    loadYears()
    loadWidgets()
  }, [])


  const loadEnterprise = async () => {
    let e = await api.getEnterprise(currentUser.enterpriseID)
    setEnterprise(e)
  }


  const addContract = () => {
    setContract(newContract)
    toggleEditModal()
  }

  const toggleEditModal = () => {
    if (showEditModal) {
      setContract(newContract)
    }
    setShowEditModal(!showEditModal)
  }

  const loadContracts = async toggleLoading => {
    try {
      if (toggleLoading) setIsLoading(true)
      let contractList = await api.getContractsForEnterprise(currentUser.enterpriseID)
      setContracts(
        contractList.map(x => {
          return x
        })
      )
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const loadWidgets = async () => {
    try {
      let w = await api.getWidgetsByView("Contracts", currentUser.userID)
      setWidgets(w)
    } catch (error) {
      console.log(error)
    }
  }

  const loadFunds = async () => {
    try {
      let fundList = await api.getList(currentUser.userID, "Fund")
      setFunds(fundList)
    } catch (error) {
      console.log(error)
    }
  }

  const loadYears = async () => {
    try {
      let yearList = await api.getYears(currentUser.userID)
      setYears(yearList)
    } catch (error) {
      console.log(error)
    }
  } 

/*   const loadEnterprises = async () => {
    try {
      let list = await api.getEnterprises(currentUser.userID)
      setEnterprises(list)
    } catch (error) {
      console.log(error)
    }
  } */

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : "Contracts"}
          />

          <Widgets widgets={widgets} marginTop="0px" />
          <Row style={{ marginBottom: "10px", marginTop: "10px" }}>
            <Col xs="12">
              <button className="btn btn-primary" onClick={addContract}>
              <i className="fas fa-plus"></i> Add {enterprise?.contractLabel ?? 'Contract'}
              </button>
            </Col>
          </Row>
          <ContractGrid
            contracts={contracts}
            isLoading={isLoading}
            enterprise={enterprise}
            setIsLoading={setIsLoading}
            setContract={setContract}
            toggleEditModal={toggleEditModal}
            reloadData={loadContracts}
          />

          <AddEditContractModal
            contract={contract}
            setcontract={setContract}
            isOpen={showEditModal}
            toggleModal={toggleEditModal}
            funds={funds}
            years={years}
            //enterprises={enterprises}
            reloadGrid={loadContracts}
            props={props}
            enterprise={enterprise}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Contracts
