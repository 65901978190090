import React, { useEffect, useState } from "react"
import { Col, Input, Label, Row } from "reactstrap"

const FieldLabelsSection = ({
  enterprise,
  changeEnterpriseProp,
  //   shouldSave,
  //   saveEnterprise,
}) => {
  return (
    <React.Fragment>
      {enterprise != null && (
        <Row>
          <Col sm="6">
            <div className="mb-3">
              <Label className="form-label">
                Business Relationship Manager Label
              </Label>
              <Input
                type="text"
                onChange={e => changeEnterpriseProp("brmLabel", e.target.value)}
                value={enterprise.brmLabel}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Advanced Planning Label</Label>
              <Input
                type="text"
                onChange={e =>
                  changeEnterpriseProp("advancedPlanningLabel", e.target.value)
                }
                value={enterprise.advancedPlanningLabel}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Governance Board Label</Label>
              <Input
                type="text"
                onChange={e =>
                  changeEnterpriseProp("governanceBoardLabel", e.target.value)
                }
                value={enterprise.governanceBoardLabel}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Investment Category Label</Label>
              <Input
                type="text"
                onChange={e =>
                  changeEnterpriseProp(
                    "investmentCategoryLabel",
                    e.target.value
                  )
                }
                value={enterprise.investmentCategoryLabel}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">
                Lead Delivery Organization Label
              </Label>
              <Input
                type="text"
                onChange={e => changeEnterpriseProp("ldoLabel", e.target.value)}
                value={enterprise.ldoLabel}
              />
            </div>

            <div className="mb-3">
              <Label className="form-label">Business Problem Label</Label>
              <Input
                type="text"
                onChange={e =>
                  changeEnterpriseProp("businessProblemLabel", e.target.value)
                }
                value={enterprise.businessProblemLabel}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Business Requirements Label</Label>
              <Input
                type="text"
                onChange={e =>
                  changeEnterpriseProp(
                    "businessRequirementsLabel",
                    e.target.value
                  )
                }
                value={enterprise.businessRequirementsLabel}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Business Value Label</Label>
              <Input
                type="text"
                onChange={e =>
                  changeEnterpriseProp("businessValueLabel", e.target.value)
                }
                value={enterprise.businessValueLabel}
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="mb-3">
              <Label className="form-label">
                Lead Business Representative Label
              </Label>
              <Input
                type="text"
                onChange={e => changeEnterpriseProp("lbrLabel", e.target.value)}
                value={enterprise.lbrLabel}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Planning Category Label</Label>
              <Input
                type="text"
                onChange={e =>
                  changeEnterpriseProp("planningCategoryLabel", e.target.value)
                }
                value={enterprise.planningCategoryLabel}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">
                Sponsoring Business Unit Label
              </Label>
              <Input
                type="text"
                onChange={e => changeEnterpriseProp("sbuLabel", e.target.value)}
                value={enterprise.sbuLabel}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">System Retirements Label</Label>
              <Input
                type="text"
                onChange={e =>
                  changeEnterpriseProp("systemRetirementsLabel", e.target.value)
                }
                value={enterprise.systemRetirementsLabel}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Planning Stage Label</Label>
              <Input
                type="text"
                onChange={e =>
                  changeEnterpriseProp("planningStageLabel", e.target.value)
                }
                value={enterprise.planningStageLabel}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Business Outcomes Label</Label>
              <Input
                type="text"
                onChange={e =>
                  changeEnterpriseProp("businessOutcomesLabel", e.target.value)
                }
                value={enterprise.businessOutcomesLabel}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Business Risk Label</Label>
              <Input
                type="text"
                onChange={e =>
                  changeEnterpriseProp("businessRiskLabel", e.target.value)
                }
                value={enterprise.businessRiskLabel}
              />
            </div>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

export default FieldLabelsSection
