import { TextareaAutosize } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"

const AddCostPoolLineItemModal = ({
  isOpen,
  toggleModal,
  alternative,
  scenario,
  costPoolLineItemToEdit,
  setCostPoolLineItemToEdit,
  reload,
}) => {
  const [costPools, setCostPools] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    let costPools = await api.getCostPools(
      scenario.enterpriseID,
      alternative.costPoolTemplateID
    )
    setCostPools(costPools)
  }

  const save = async () => {
    if (costPoolLineItemToEdit.costPoolID == -1) {
      toast.error("Please select a cost pool")
      return
    }
    if (costPoolLineItemToEdit.appendix == "") {
      toast.error("Please enter the appendix")
      return
    }
    if (
      costPoolLineItemToEdit.notes == "" &&
      costPoolLineItemToEdit.bcLineItemID == undefined
    ) {
      toast.error("Please enter the notes")
      return
    }
    if (costPoolLineItemToEdit.bcLineItemID != undefined) {
      await api.updateBusinessCaseLineItem(
        alternative.alternativeID,
        costPoolLineItemToEdit
      )
    } else {
      await api.createBusinessCaseLineItem(
        alternative.alternativeID,
        costPoolLineItemToEdit
      )
    }
    toggleModal()
    toast.success("Cost Pool Line Item updated successfully")
    reload && reload()
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {costPoolLineItemToEdit?.bcLineItemID != undefined ? "Edit" : "Add"}{" "}
          Cost Pool Line Item
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {costPoolLineItemToEdit != null && (
        <div className="modal-body">
          <p>
            The appendix is required and will show after the name. Notes are
            required to indicate the things that are covered in this extra cost
            pool line
          </p>
          <div className="mb-3">
            <Label className="form-label">Cost Pool</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={costPoolLineItemToEdit.costPoolID}
              disabled={costPoolLineItemToEdit.bcLineItemID != undefined}
              onChange={e => {
                setCostPoolLineItemToEdit({
                  ...costPoolLineItemToEdit,
                  costPoolID: e.target.value,
                })
              }}
            >
              <option value="-1">Select Cost Pool</option>
              {costPools.map((x, idx) => (
                <option key={idx} value={x.costPoolID}>
                  {x.costPoolType} - {x.costPoolName}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Appendix</Label>
            <Input
              type="text"
              onChange={e =>
                setCostPoolLineItemToEdit({
                  ...costPoolLineItemToEdit,
                  appendix: e.target.value,
                })
              }
              value={costPoolLineItemToEdit.appendix}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Notes</Label>
            <TextareaAutosize
              minRows={3}
              className="form-control always-disabled"
              value={costPoolLineItemToEdit.notes}
              onChange={e =>
                setCostPoolLineItemToEdit({
                  ...costPoolLineItemToEdit,
                  notes: e.target.value,
                })
              }
            />
          </div>
        </div>
      )}

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default AddCostPoolLineItemModal
