import React, { useCallback, useEffect, useState } from "react"
import api from "services/api.service"
import { Row, Col } from "reactstrap"
import { toast } from "react-toastify"
import Loader from "react-loaders"
import colors from "utils/colors"
import { DynamicFormMultiSelect } from "./DynamicFormMultiSelect"
import { DynamicFormRadioButtons } from "./DynamicFormRadioButtons"
import { DynamicFormCurrency } from "./DynamicFormCurrency"
import { DynamicFormSelect } from "./DynamicFormSelect"
import { DynamicFormTextarea } from "./DynamicFormTextarea"
import { DynamicFormCheckbox } from "./DynamicFormCheckbox"
import { DynamicFormDateTime } from "./DynamicFormDateTime"
import { DynamicFormInput } from "./DynamicFormInput"
import { DynamicFormText } from "./DynamicFormText"
import { DynamicFormLabel } from "./DynamicFormLabel"
import { DynamicFormDivider } from "./DynamicFormDivider"
import { DynamicFormRichText } from "./DynamicFormRichText"
import { userService } from "services/user.service"
import DynamicFormDocuments from "./DynamicFormDocuments"
import isNullOrEmpty from "utils/isNullOrEmpty"

const DynamicForm = ({
  formId,
  itemId,
  scenarioId,
  triggerFormSave,
  setTriggerFormSave,
  showToastChangesCallback,
  useAi,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [form, setForm] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    if (formId !== undefined && formId != -1 && itemId != undefined) {
      loadForm(signal)
    }

    return () => {
      controller.abort() // Cancels the previous request when effect runs again
    }
  }, [formId, itemId])

  useEffect(() => {
    if (triggerFormSave == true && itemId > -1) {
      save()
    }
  }, [triggerFormSave, itemId])

  const loadForm = async signal => {
    setIsLoading(true)

    try {
      let f = await api.getFormWithValues(formId, itemId ?? 0, scenarioId)
      if (!signal.aborted) {
        setForm(f)
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Failed to load form", error)
      }
    } finally {
      if (!signal.aborted) {
        setIsLoading(false)
      }
    }
  }

  const changeFormFieldValue = useCallback(
    (formFieldId, value) => {
      showToastChangesCallback && showToastChangesCallback()
      setForm(prev => {
        let copy = JSON.parse(JSON.stringify(prev))
        let formField = copy.formFields.find(x => x.formFieldId == formFieldId)
        formField.fieldData.value = value

        return copy
      })
    },
    [setForm]
  )

  const save = async () => {
    let formFieldIdsWithErrors = validateForm(form)
    setForm(prevForm => {
      let copy = JSON.parse(JSON.stringify(prevForm))
      copy.formFields = copy.formFields.map(f => {
        let found = formFieldIdsWithErrors.find(id => f.formFieldId == id)
        return {
          ...f,
          hasError: found ? true : false,
        }
      })
      return copy
    })

    if (formFieldIdsWithErrors.length == 0) {
      await api.updateFormWithValues(itemId, form, currentUser.userID)
      await loadForm(new AbortController().signal)

      if (!triggerFormSave) {
        toast.success("Form saved")
      }
    }
    setTriggerFormSave(false)
  }

  const validateForm = form => {
    let formFieldIdsWithErrors = []
    form.formFields.forEach(formField => {
      if (
        formField.required &&
        (formField.fieldData?.value == "" || formField.fieldData?.value == -1)
      ) {
        formFieldIdsWithErrors.push(formField.formFieldId)
      }
    })
    return formFieldIdsWithErrors
  }

  const col =
    form == null ? 12 : form.numColumns == 1 ? 12 : form.numColumns == 2 ? 6 : 4

  return (
    <React.Fragment>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {form != null && (
        <Row>
          {form.formFields.map((ff, idx) => {
            return (
              <React.Fragment key={idx}>
                {ff.formFieldType && (
                  <>
                    {ff.formFieldType.hasOptions && (
                      <>
                        {ff.formFieldType.type == "Radio Button" && (
                          <DynamicFormRadioButtons
                            formFieldId={ff.formFieldId}
                            label={ff.formFieldLabel}
                            value={ff.fieldData.value}
                            formFieldListItems={ff.formFieldListItems}
                            changeFormFieldValue={changeFormFieldValue}
                            col={col}
                            fullWidth={ff.fullWidth}
                            alwaysReadOnly={ff.alwaysReadOnly}
                            alwaysEditable={ff.alwaysEditable}
                            hasError={ff.hasError}
                            requiredMessage={ff.requiredMessage}
                          />
                        )}
                        {(ff.formFieldType.type == "Multi Select (System)" ||
                          ff.formFieldType.type == "Multi Select (Custom)") && (
                          <DynamicFormMultiSelect
                            formFieldId={ff.formFieldId}
                            label={ff.formFieldLabel}
                            value={ff.fieldData.value}
                            formFieldListItems={ff.formFieldListItems}
                            changeFormFieldValue={changeFormFieldValue}
                            col={col}
                            fullWidth={ff.fullWidth}
                            alwaysReadOnly={ff.alwaysReadOnly}
                            alwaysEditable={ff.alwaysEditable}
                            hasError={ff.hasError}
                            requiredMessage={ff.requiredMessage}
                          />
                        )}
                        {(ff.formFieldType.type == "Drop Down (Custom)" ||
                          ff.formFieldType.type == "Drop Down (System)") && (
                          <DynamicFormSelect
                            formFieldId={ff.formFieldId}
                            label={ff.formFieldLabel}
                            value={ff.fieldData.value}
                            formFieldListItems={ff.formFieldListItems}
                            changeFormFieldValue={changeFormFieldValue}
                            col={col}
                            fullWidth={ff.fullWidth}
                            alwaysReadOnly={ff.alwaysReadOnly}
                            alwaysEditable={ff.alwaysEditable}
                            hasError={ff.hasError}
                            requiredMessage={ff.requiredMessage}
                          />
                        )}
                      </>
                    )}
                    {!ff.formFieldType.hasOptions && (
                      <>
                        {(ff.formFieldType.type == "Short Text" ||
                          ff.formFieldType.type == "Signature" ||
                          ff.formFieldType.type == "Integer") && (
                          <DynamicFormInput
                            formFieldId={ff.formFieldId}
                            label={ff.formFieldLabel}
                            value={ff.fieldData.value}
                            type={
                              ff.formFieldType.type == "Short Text"
                                ? "text"
                                : "number"
                            }
                            placeholder={ff.formFieldPlaceholder}
                            changeFormFieldValue={changeFormFieldValue}
                            col={col}
                            fullWidth={ff.fullWidth}
                            alwaysReadOnly={ff.alwaysReadOnly}
                            alwaysEditable={ff.alwaysEditable}
                            hasError={ff.hasError}
                            requiredMessage={ff.requiredMessage}
                          />
                        )}

                        {ff.formFieldType.type == "Long Text" && (
                          <DynamicFormTextarea
                            itemId={itemId}
                            useAi={useAi && !isNullOrEmpty(ff.aiPrompt)}
                            formFieldId={ff.formFieldId}
                            label={ff.formFieldLabel}
                            value={ff.fieldData.value}
                            placeholder={ff.formFieldPlaceholder}
                            changeFormFieldValue={changeFormFieldValue}
                            col={col}
                            fullWidth={ff.fullWidth}
                            alwaysReadOnly={ff.alwaysReadOnly}
                            alwaysEditable={ff.alwaysEditable}
                            hasError={ff.hasError}
                            requiredMessage={ff.requiredMessage}
                          />
                        )}

                        {(ff.formFieldType.type == "Decimal" ||
                          ff.formFieldType.type == "Percentage" ||
                          ff.formFieldType.type == "Currency") && (
                          <DynamicFormCurrency
                            formFieldId={ff.formFieldId}
                            label={ff.formFieldLabel}
                            value={ff.fieldData.value}
                            changeFormFieldValue={changeFormFieldValue}
                            placeholder={ff.formFieldPlaceholder}
                            prefix={
                              ff.formFieldType.type == "Decimal"
                                ? ""
                                : ff.formFieldType.type == "Currency"
                                ? "$"
                                : ""
                            }
                            suffix={
                              ff.formFieldType.type == "Percentage" ? "%" : ""
                            }
                            col={col}
                            fullWidth={ff.fullWidth}
                            alwaysReadOnly={ff.alwaysReadOnly}
                            alwaysEditable={ff.alwaysEditable}
                            hasError={ff.hasError}
                            requiredMessage={ff.requiredMessage}
                          />
                        )}

                        {ff.formFieldType.type == "CheckBox" && (
                          <DynamicFormCheckbox
                            formFieldId={ff.formFieldId}
                            label={ff.formFieldLabel}
                            value={ff.fieldData.value}
                            changeFormFieldValue={changeFormFieldValue}
                            placeholder={ff.formFieldPlaceholder}
                            col={col}
                            fullWidth={ff.fullWidth}
                            alwaysReadOnly={ff.alwaysReadOnly}
                            alwaysEditable={ff.alwaysEditable}
                          />
                        )}

                        {(ff.formFieldType.type == "Date" ||
                          ff.formFieldType.type == "Time" ||
                          ff.formFieldType.type == "Date Time") && (
                          <DynamicFormDateTime
                            formFieldId={ff.formFieldId}
                            label={ff.formFieldLabel}
                            value={ff.fieldData.value}
                            changeFormFieldValue={changeFormFieldValue}
                            placeholder={ff.formFieldPlaceholder}
                            type={
                              ff.formFieldType.type == "Date"
                                ? "date"
                                : ff.formFieldType.type == "Time"
                                ? "time"
                                : "datetime"
                            }
                            col={col}
                            fullWidth={ff.fullWidth}
                            alwaysReadOnly={ff.alwaysReadOnly}
                            alwaysEditable={ff.alwaysEditable}
                            hasError={ff.hasError}
                            requiredMessage={ff.requiredMessage}
                          />
                        )}

                        {ff.formFieldType.type == "Header" && (
                          <DynamicFormLabel
                            label={ff.formFieldLabel}
                            col={col}
                            fullWidth={ff.fullWidth}
                          />
                        )}

                        {ff.formFieldType.type == "Help Text" && (
                          <DynamicFormText
                            text={ff.formFieldLabel}
                            col={col}
                            fullWidth={ff.fullWidth}
                          />
                        )}

                        {ff.formFieldType.type == "Divider" && (
                          <DynamicFormDivider
                            col={col}
                            fullWidth={ff.fullWidth}
                          />
                        )}

                        {ff.formFieldType.type == "Rich Text" && (
                          <DynamicFormRichText
                            formFieldId={ff.formFieldId}
                            label={ff.formFieldLabel}
                            value={ff.fieldData.value}
                            changeFormFieldValue={changeFormFieldValue}
                            placeholder={ff.formFieldPlaceholder}
                            col={col}
                            fullWidth={ff.fullWidth}
                            alwaysReadOnly={ff.alwaysReadOnly}
                            alwaysEditable={ff.alwaysEditable}
                            hasError={ff.hasError}
                            requiredMessage={ff.requiredMessage}
                          />
                        )}
                        {ff.formFieldType.type == "Documents" && (
                          <DynamicFormDocuments
                            label={ff.formFieldLabel}
                            itemId={itemId}
                            scenarioId={scenarioId}
                            col={col}
                            fullWidth={ff.fullWidth}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            )
          })}
          {setTriggerFormSave == undefined && (
            <Row>
              <Col>
                <button className="btn btn-primary" onClick={save}>
                  Save
                </button>
              </Col>
            </Row>
          )}
        </Row>
      )}
    </React.Fragment>
  )
}

export const DynamicFormFieldRenderer = ({
  ff,
  changeFormFieldValue,
  col,
  itemId,
  scenarioId,
}) => {
  return (
    <>
      {ff.formFieldType && (
        <>
          {ff.formFieldType.hasOptions && (
            <>
              {ff.formFieldType.type == "Radio Button" && (
                <DynamicFormRadioButtons
                  formFieldId={ff.formFieldId}
                  label={ff.formFieldLabel}
                  value={ff.fieldData.value}
                  formFieldListItems={ff.formFieldListItems}
                  changeFormFieldValue={changeFormFieldValue}
                  col={col}
                  fullWidth={ff.fullWidth}
                  alwaysReadOnly={ff.alwaysReadOnly}
                  alwaysEditable={ff.alwaysEditable}
                  hasError={ff.hasError}
                  requiredMessage={ff.requiredMessage}
                />
              )}
              {(ff.formFieldType.type == "Multi Select (System)" ||
                ff.formFieldType.type == "Multi Select (Custom)") && (
                <DynamicFormMultiSelect
                  formFieldId={ff.formFieldId}
                  label={ff.formFieldLabel}
                  value={ff.fieldData.value}
                  formFieldListItems={ff.formFieldListItems}
                  changeFormFieldValue={changeFormFieldValue}
                  col={col}
                  fullWidth={ff.fullWidth}
                  alwaysReadOnly={ff.alwaysReadOnly}
                  alwaysEditable={ff.alwaysEditable}
                  hasError={ff.hasError}
                  requiredMessage={ff.requiredMessage}
                />
              )}
              {(ff.formFieldType.type == "Drop Down (Custom)" ||
                ff.formFieldType.type == "Drop Down (System)") && (
                <DynamicFormSelect
                  formFieldId={ff.formFieldId}
                  label={ff.formFieldLabel}
                  value={ff.fieldData.value}
                  formFieldListItems={ff.formFieldListItems}
                  changeFormFieldValue={changeFormFieldValue}
                  col={col}
                  fullWidth={ff.fullWidth}
                  alwaysReadOnly={ff.alwaysReadOnly}
                  alwaysEditable={ff.alwaysEditable}
                  hasError={ff.hasError}
                  requiredMessage={ff.requiredMessage}
                />
              )}
            </>
          )}
          {!ff.formFieldType.hasOptions && (
            <>
              {(ff.formFieldType.type == "Short Text" ||
                ff.formFieldType.type == "Signature" ||
                ff.formFieldType.type == "Integer") && (
                <DynamicFormInput
                  formFieldId={ff.formFieldId}
                  label={ff.formFieldLabel}
                  value={ff.fieldData.value}
                  type={
                    ff.formFieldType.type == "Short Text" ? "text" : "number"
                  }
                  placeholder={ff.formFieldPlaceholder}
                  changeFormFieldValue={changeFormFieldValue}
                  col={col}
                  fullWidth={ff.fullWidth}
                  alwaysReadOnly={ff.alwaysReadOnly}
                  alwaysEditable={ff.alwaysEditable}
                  hasError={ff.hasError}
                  requiredMessage={ff.requiredMessage}
                />
              )}

              {ff.formFieldType.type == "Long Text" && (
                <DynamicFormTextarea
                  formFieldId={ff.formFieldId}
                  label={ff.formFieldLabel}
                  value={ff.fieldData.value}
                  placeholder={ff.formFieldPlaceholder}
                  changeFormFieldValue={changeFormFieldValue}
                  col={col}
                  fullWidth={ff.fullWidth}
                  alwaysReadOnly={ff.alwaysReadOnly}
                  alwaysEditable={ff.alwaysEditable}
                  hasError={ff.hasError}
                  requiredMessage={ff.requiredMessage}
                />
              )}

              {(ff.formFieldType.type == "Decimal" ||
                ff.formFieldType.type == "Percentage" ||
                ff.formFieldType.type == "Currency") && (
                <DynamicFormCurrency
                  formFieldId={ff.formFieldId}
                  label={ff.formFieldLabel}
                  value={ff.fieldData.value}
                  changeFormFieldValue={changeFormFieldValue}
                  placeholder={ff.formFieldPlaceholder}
                  prefix={
                    ff.formFieldType.type == "Decimal"
                      ? ""
                      : ff.formFieldType.type == "Currency"
                      ? "$"
                      : ""
                  }
                  suffix={ff.formFieldType.type == "Percentage" ? "%" : ""}
                  col={col}
                  fullWidth={ff.fullWidth}
                  alwaysReadOnly={ff.alwaysReadOnly}
                  alwaysEditable={ff.alwaysEditable}
                  hasError={ff.hasError}
                  requiredMessage={ff.requiredMessage}
                />
              )}

              {ff.formFieldType.type == "CheckBox" && (
                <DynamicFormCheckbox
                  formFieldId={ff.formFieldId}
                  label={ff.formFieldLabel}
                  value={ff.fieldData.value}
                  changeFormFieldValue={changeFormFieldValue}
                  placeholder={ff.formFieldPlaceholder}
                  col={col}
                  fullWidth={ff.fullWidth}
                  alwaysReadOnly={ff.alwaysReadOnly}
                  alwaysEditable={ff.alwaysEditable}
                />
              )}

              {(ff.formFieldType.type == "Date" ||
                ff.formFieldType.type == "Time" ||
                ff.formFieldType.type == "Date Time") && (
                <DynamicFormDateTime
                  formFieldId={ff.formFieldId}
                  label={ff.formFieldLabel}
                  value={ff.fieldData.value}
                  changeFormFieldValue={changeFormFieldValue}
                  placeholder={ff.formFieldPlaceholder}
                  type={
                    ff.formFieldType.type == "Date"
                      ? "date"
                      : ff.formFieldType.type == "Time"
                      ? "time"
                      : "datetime"
                  }
                  col={col}
                  fullWidth={ff.fullWidth}
                  alwaysReadOnly={ff.alwaysReadOnly}
                  alwaysEditable={ff.alwaysEditable}
                  hasError={ff.hasError}
                  requiredMessage={ff.requiredMessage}
                />
              )}

              {ff.formFieldType.type == "Header" && (
                <DynamicFormLabel
                  label={ff.formFieldLabel}
                  col={col}
                  fullWidth={ff.fullWidth}
                />
              )}

              {ff.formFieldType.type == "Help Text" && (
                <DynamicFormText
                  text={ff.formFieldLabel}
                  col={col}
                  fullWidth={ff.fullWidth}
                />
              )}

              {ff.formFieldType.type == "Divider" && (
                <DynamicFormDivider col={col} fullWidth={ff.fullWidth} />
              )}

              {ff.formFieldType.type == "Rich Text" && (
                <DynamicFormRichText
                  formFieldId={ff.formFieldId}
                  label={ff.formFieldLabel}
                  value={ff.fieldData.value}
                  changeFormFieldValue={changeFormFieldValue}
                  placeholder={ff.formFieldPlaceholder}
                  col={col}
                  fullWidth={ff.fullWidth}
                  alwaysReadOnly={ff.alwaysReadOnly}
                  alwaysEditable={ff.alwaysEditable}
                  hasError={ff.hasError}
                  requiredMessage={ff.requiredMessage}
                />
              )}
              {ff.formFieldType.type == "Documents" && (
                <DynamicFormDocuments
                  label={ff.formFieldLabel}
                  itemId={itemId}
                  scenarioId={scenarioId}
                  col={col}
                  fullWidth={ff.fullWidth}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default DynamicForm
