import { TextareaAutosize, Tooltip } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { ButtonGroup, Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import ResourceManagerModal from "./Modals/ResourceManagerModal"
import { getAlternativeYears } from "utils/alternativeHelper"
import ResourceEstimateRequestNotesModal from "./Modals/ResourceEstimateRequestNotesModal"
import ResourceEstimateLogModal from "./Modals/ResourceEstimateLogModal"
import useModal from "hooks/useModalHook"
import ClearEstimatesModal from "./Modals/ClearEstimatesModal"
import TShirtHoursAllocationModal from "./Modals/TShirtHoursAllocationModal"
import ResourceBudgetingPlanTab from "./ResourceBudgetingPlanTab"
import ResourceBudgetingEstimateTab from "./ResourceBudgetingEstimateTab"
import isNullOrEmpty from "utils/isNullOrEmpty"

const ResourceBudgetingSection = ({
  activeTab,
  alternative,
  alternativeId,
  scenario,
  triggerSave,
  setTriggerSave,
  showToastChanges,
  changeAlternativeProp,
  setAlternative,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [isEditMode, setIsEditMode] = useState(true)

  const [resourceGroups, setResourceGroups] = useState([])
  const [selectedResourceGroupId, setSelectedResourceGroupId] = useState(-1)
  const [selectedBCCostPoolId, setSelectedBCCostPoolId] = useState(-99)
  const [bcLineItems, setBcLineItems] = useState([])
  const [selectedBcLineItemId, setSelectedBcLineItemId] = useState(-99)
  const [altResourceGroupsView, setAltResourceGroupsView] = useState([])
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false)
  const [emailNotes, setEmailNotes] = useState("")

  const { show: showManagerModal, toggle: toggleManagerModal } = useModal()
  const {
    show: showResourceEstimateLogModal,
    toggle: toggleResourceEstimateLogModal,
  } = useModal()
  const [resourceEstimateLogModel, setResourceEstimateLogModel] = useState(null)

  const { show: showClearEstimatesModal, toggle: toggleClearEstimatesModal } =
    useModal()

  const toggleEmailModal = () => setIsEmailModalVisible(!isEmailModalVisible)

  const [
    resourceGroupCostPoolsForTShirtModal,
    setResourceGroupCostPoolsForTShirtModal,
  ] = useState(null)

  // 0- Estimate (Year), 1- Plan (Month)
  const [displayAs, setDisplayAs] = useState(0)

  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  const maxYear = alternative?.lockYearData ? moment().year() : 0

  useEffect(() => {
    if (activeTab == "ResourceBudget") {
      loadData()
      setFromDate(
        moment(alternative.desiredStart).format("YYYY-MM-DDT00:00:00")
      )
      setToDate(moment(alternative.plannedFinish).format("YYYY-MM-DDT00:00:00"))
    }
  }, [activeTab, alternativeId])

  const loadData = async () => {
    let rg = await api.getResourceGroups(alternative.scenarioID, 0)
    setResourceGroups(rg)
    let bcitems = await api.getScenarioList(
      alternative.scenarioID,
      "BCLineItems"
    )
    setBcLineItems(bcitems)
  }

  const alternativeYears = getAlternativeYears(alternative)

  return (
    <React.Fragment>
      <div>
        {scenario?.resourceTabID != 0 && (
          <Row className="mb-2">
            <Col md="12">
              <div className="w-100 d-flex-row jc-space-between ai-center">
                <ButtonGroup>
                  <button
                    className={`btn  ${
                      displayAs == 0 ? " btn-primary" : " btn-outline-secondary"
                    }`}
                    onClick={() => setDisplayAs(0)}
                  >
                    Estimate (Year)
                  </button>
                  <button
                    className={`btn  ${
                      displayAs == 1 ? " btn-primary" : " btn-outline-secondary"
                    }`}
                    onClick={() => setDisplayAs(1)}
                  >
                    Plan (Month)
                  </button>
                </ButtonGroup>

                <div
                  className="d-flex-row ai-center gap-3"
                  style={{ backgroundColor: "#cce7c9", padding: "10xp" }}
                >
                  <Label>
                    Planned Project Start:{" "}
                    <u>
                      {!isNullOrEmpty(alternative?.desiredStart) &&
                        moment(alternative.desiredStart).format("MM-DD-YYYY")}
                    </u>
                  </Label>
                  <Label>
                    Planned Project End:{" "}
                    <u>
                      {!isNullOrEmpty(alternative?.plannedFinish) &&
                        moment(alternative.plannedFinish).format("MM-DD-YYYY")}
                    </u>
                  </Label>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <div className="d-flex-row jc-space-between ai-center">
          {displayAs == 0 && (
            <div>
              <div
                className="form-check"
                style={{ display: "inline-block" }}
                onClick={e => {
                  setIsEditMode(!isEditMode)
                  loadTableData(
                    selectedResourceGroupId,
                    selectedBCCostPoolId,
                    resourceGroups
                  )
                }}
              >
                <input
                  className="form-check-input always-enabled"
                  type="radio"
                  name="anon111"
                  id="anon111"
                  value="0"
                  checked={isEditMode}
                />
                <label
                  className="form-check-label always-enabled"
                  style={{ marginLeft: "10px" }}
                >
                  Edit Data
                </label>
              </div>

              <div
                className="form-check"
                style={{ display: "inline-block" }}
                onClick={e => {
                  setIsEditMode(!isEditMode)
                  // loadViewTableData(
                  //   selectedResourceGroupId,
                  //   selectedBcLineItemId
                  // )
                }}
              >
                <input
                  className="form-check-input always-enabled"
                  type="radio"
                  name="anon222"
                  id="anon222"
                  value="1"
                  style={{ marginLeft: "10px" }}
                  checked={!isEditMode}
                />
                <label
                  className="form-check-label always-enabled"
                  style={{ marginLeft: "10px" }}
                >
                  View Results
                </label>
              </div>
            </div>
          )}
          {displayAs == 1 && fromDate != null && toDate != null && (
            <>
              <div className="always-enabled">
                <Label>View Data From</Label>
                <Input
                  name="startDate"
                  type="month"
                  onChange={e => {
                    if (
                      moment(e.target.value, "YYYY-MM").isBefore(moment(toDate))
                    ) {
                      setFromDate(
                        moment(e.target.value, "YYYY-MM").format(
                          "YYYY-MM-DDT00:00:00"
                        )
                      )
                    }
                  }}
                  value={moment(fromDate).format("YYYY-MM")}
                />
              </div>

              <div className="always-enabled">
                <Label>To</Label>
                <Input
                  name="endDate"
                  type="month"
                  onChange={e => {
                    if (
                      moment(e.target.value, "YYYY-MM").isAfter(
                        moment(fromDate)
                      )
                    ) {
                      setToDate(
                        moment(e.target.value, "YYYY-MM").format(
                          "YYYY-MM-DDT00:00:00"
                        )
                      )
                    }
                  }}
                  value={moment(toDate).format("YYYY-MM")}
                />
              </div>
            </>
          )}

          {/* </Row>
        <div style={{ paddingTop: "10px" }}></div>
        <Row> */}
          <div className="always-enabled">
            <Label className="form-label">Show Resource(s)</Label>
            <select
              className="form-control form-select select2 mb-xxl-0 always-enabled"
              value={selectedResourceGroupId}
              style={{ minWidth: "250px" }}
              onChange={e => {
                setSelectedResourceGroupId(e.target.value)
                // if (isEditMode) {
                //   loadTableData(
                //     e.target.value,
                //     selectedBCCostPoolId,
                //     resourceGroups
                //   )
                // } else {
                //   loadViewTableData(e.target.value, selectedBcLineItemId)
                // }
              }}
            >
              <option value={-1}>All My</option>
              <option value={-99}>All</option>
              <option value={-10}>All Completed</option>
              <option value={-20}>All Not Completed</option>
              <option value={-30}>All With Zero Total</option>
              <option value={-40}>All With Non-Zero Total</option>
              {resourceGroups.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.resourceGroupID}`}>
                    {a.resourceGroupName}
                  </option>
                )
              })}
            </select>
          </div>

          {(isEditMode || displayAs == 1) && (
            <div className="always-enabled">
              <Label className="form-label">Show Cost Pool(s)</Label>
              <select
                className="form-control form-select select2 mb-xxl-0 always-enabled"
                value={selectedBCCostPoolId}
                style={{ minWidth: "150px" }}
                onChange={e => {
                  setSelectedBCCostPoolId(e.target.value)
                  loadTableData(
                    selectedResourceGroupId,
                    e.target.value,
                    resourceGroups
                  )
                }}
              >
                <option value="-99">All</option>
                {bcLineItems.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.listItemID}`}>
                      {a.listItemName}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          {!isEditMode && (
            <div className="always-enabled">
              <Label className="form-label">Show Cost Pool(s)</Label>
              <select
                className="form-control form-select select2 mb-xxl-0 always-enabled"
                value={selectedBCCostPoolId}
                onChange={e => {
                  setSelectedBCCostPoolId(e.target.value)
                  // loadViewTableData(selectedResourceGroupId, e.target.value)
                }}
              >
                <option value="-99">All</option>
                <option value="0">General</option>
                {bcLineItems.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.listItemID}`}>
                      {a.listItemName}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          {currentUser.roleID > 8 && (
            <div style={{ float: "right" }}>
              <Input
                id="flowCb"
                type="checkbox"
                className="form-check-input"
                checked={alternative.useFlowThrough}
                onClick={() => {
                  changeAlternativeProp(
                    "useFlowThrough",
                    !alternative.useFlowThrough
                  )
                }}
              />

              <Label
                for="flowCb"
                className="form-check-label"
                style={{ marginLeft: "10px" }}
              >
                Use Flow Through
              </Label>
            </div>
          )}
          {(currentUser.roleID > 1 ||
            alternative.brmUserID == currentUser.userID ||
            alternative.pmUserID == currentUser.userID) &&
            displayAs == 0 && (
              <button
                className="btn btn-primary"
                onClick={() => {
                  setEmailNotes("")
                  toggleEmailModal()
                }}
              >
                <i className="fas fa-envelope"></i> Request Estimate
              </button>
            )}
          <button
            className="btn btn-primary"
            onClick={() => {
              toggleManagerModal()
            }}
          >
            View Resource Managers
          </button>
          {(currentUser.roleID > 1 ||
            alternative.pmUserID == currentUser.userID) &&
            displayAs == 0 && (
              <button
                className="btn btn-primary"
                onClick={() => {
                  toggleClearEstimatesModal()
                }}
              >
                <i className="fas fa-eraser"></i> Clear Estimates
              </button>
            )}
        </div>

        {displayAs == 0 && (
          <ResourceBudgetingEstimateTab
            isActive={displayAs == 0}
            isEditMode={isEditMode}
            alternative={alternative}
            alternativeId={alternativeId}
            selectedResourceGroupId={selectedResourceGroupId}
            selectedBCCostPoolId={selectedBCCostPoolId}
            selectedBcLineItemId={selectedBcLineItemId}
            resourceGroups={resourceGroups}
            setTriggerSave={setTriggerSave}
            triggerSave={triggerSave}
            showToastChanges={showToastChanges}
            maxYear={maxYear}
            setResourceEstimateLogModel={setResourceEstimateLogModel}
            toggleResourceEstimateLogModal={toggleResourceEstimateLogModal}
          />
        )}
        {displayAs == 1 && (
          <ResourceBudgetingPlanTab
            isActive={displayAs == 1}
            selectedResourceGroupId={selectedResourceGroupId}
            selectedBCCostPoolId={selectedBCCostPoolId}
            alternative={alternative}
            alternativeId={alternativeId}
            setTriggerSave={setTriggerSave}
            triggerSave={triggerSave}
            showToastChanges={showToastChanges}
            fromDate={fromDate}
            toDate={toDate}
            maxYear={maxYear}
          />
        )}

        <ResourceEstimateRequestNotesModal
          isOpen={isEmailModalVisible}
          toggleModal={toggleEmailModal}
          scenarioId={alternative.scenarioID}
          alternative={alternative}
        />

        <ClearEstimatesModal
          isOpen={showClearEstimatesModal}
          toggleModal={toggleClearEstimatesModal}
          alternativeId={alternativeId}
          alternativeYears={alternativeYears}
          // reload={loadData}
        />
        <ResourceManagerModal
          isOpen={showManagerModal}
          toggleModal={toggleManagerModal}
          scenarioId={alternative.scenarioID}
        />
        <ResourceEstimateLogModal
          isOpen={showResourceEstimateLogModal}
          toggleModal={toggleResourceEstimateLogModal}
          resourceEstimateLogModel={resourceEstimateLogModel}
        />
      </div>
    </React.Fragment>
  )
}

export default ResourceBudgetingSection
