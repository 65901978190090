import useModal from "hooks/useModalHook"
import React, { useEffect, useState } from "react"
import { Col, Input, Label, Row, Alert } from "reactstrap"
import api from "services/api.service"
import ApplyTaskTypeModal from "./Modals/ApplyTaskTypeModal"
import Loader from "react-loaders"
import colors from "utils/colors"
import { toast } from "react-toastify"

const TrackingOptionsSection = ({
  activeTab,
  scenario,
  triggerSave,
  setTriggerSave,
}) => {
  const [taskTypeToApply, setTaskTypeToApply] = useState(null)
  const { show: showApplyModal, toggle: toggleApplyModal } = useModal()
  const [scenarioTaskTypes, setScenarioTaskTypes] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const dueDaysOptions = Array.from({ length: 91 }, (_, index) => index)

  useEffect(() => {
    if (activeTab == "8") {
      loadData()
    }
  }, [activeTab])

  useEffect(() => {
    if (triggerSave) {
      save()
    }
  }, [triggerSave])

  const loadData = async () => {
    setIsLoading(true)
    let tt = await api.getScenarioTaskTypes(scenario.scenarioID)
    setScenarioTaskTypes(tt)
    setIsLoading(false)
  }

  const applyWorkflow = taskType => {
    setTaskTypeToApply(taskType)
    toggleApplyModal()
  }

  const changeSelection = findPredicate => {
    let copy = [...scenarioTaskTypes]
    let item = copy.find(findPredicate)
    item.isSelected = !item.isSelected
    setScenarioTaskTypes(copy)
  }

  const changeDueDays = (findPredicate, value) => {
    let copy = [...scenarioTaskTypes]
    let item = copy.find(findPredicate)
    item.defaultDueDays = value
    setScenarioTaskTypes(copy)
  }

  const save = async () => {
    setTriggerSave(false)
    await api.updateScenarioTaskTypes(scenario.scenarioID, scenarioTaskTypes)
    toast.success("Tracking options saved")
  }

  return (
    <React.Fragment>
      <Row>
        <Alert
          id="helpView"
          variant="light"
          className="always-enabled"
          style={{ marginBottom: "10px", paddingBottom: "0px" }}
        >
          <div>
            Select the types of things you need to track in this portfolio.
            Items tracked at the project level will show up in the Actions menu
            when editing a project. Those at the portfolio level on on the menu
            under the "Manage Portfolio" option.
            <ol>
              <li>
                <b>General</b> - These are tasks that can be tracked at the
                project or portfolio level.
              </li>
              <li>
                <b>Workflows</b> - If you want to setup a workflow to track for
                each project in the portfolio, select it here.
              </li>
              <li>
                <b>Task Lists</b> - These are pre-set list of project tasks that
                can be setup to populate your projects. Selecting it here makes
                it available for the projects in this portfolio.
              </li>
            </ol>
          </div>
        </Alert>
      </Row>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && (
        <Row>
          <Col>
            <div className="d-flex-row ai-center" style={{ gap: "15px" }}>
              <Label style={{ width: "300px" }}>
                <h4>General</h4>
              </Label>
              <Label>Default # of days until due</Label>
            </div>
            {scenarioTaskTypes
              .filter(x => x.workflowType == null || x.workflowType == "")
              .map((tt, idx) => (
                <div
                  key={idx}
                  className="d-flex-row ai-center"
                  style={{ gap: "15px", padding: "5px" }}
                  // style={{ marginLeft: "10px" }}
                >
                  <div className="form-check" style={{ width: "300px" }}>
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id={`tt-cbx-${idx}`}
                      name={`tt-cbx-${idx}`}
                      checked={tt.isSelected}
                      onClick={e => {
                        changeSelection(
                          x =>
                            x.taskTypeID == tt.taskTypeID &&
                            (x.workflowType == null || x.workflowType == "")
                        )
                      }}
                    />
                    <Label className="form-check-label" for={`tt-cbx-${idx}`}>
                      {tt.selectionLabel}
                    </Label>
                  </div>
                  {tt.isSelected && (
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      style={{ width: "100px" }}
                      value={tt.defaultDueDays}
                      onChange={e => {
                        changeDueDays(
                          x =>
                            x.taskTypeID == tt.taskTypeID &&
                            (x.workflowType == null || x.workflowType == ""),
                          e.target.value
                        )
                      }}
                    >
                      {dueDaysOptions.map((s, i) => (
                        <option key={i} value={s}>
                          {s}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              ))}
            <Label className="mt-3">
              <h4>Workflows</h4>
            </Label>

            {scenarioTaskTypes
              .filter(x => x.workflowType == "Workflow")
              .map((tt, idx) => (
                <div
                  key={idx}
                  className="d-flex-row ai-center"
                  style={{ gap: "15px", padding: "5px" }}
                  // style={{ marginLeft: "10px" }}
                >
                  <div
                    className="d-flex-row ai-center"
                    style={{ width: "300px" }}
                  >
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id={`tt2-cbx-${idx}`}
                        name={`tt2-cbx-${idx}`}
                        checked={tt.isSelected}
                        onClick={e => {
                          changeSelection(
                            x =>
                              x.taskTypeID == tt.taskTypeID &&
                              x.workflowType == "Workflow"
                          )
                        }}
                      />
                      <Label
                        className="form-check-label"
                        for={`tt2-cbx-${idx}`}
                      >
                        {tt.workflowName}
                      </Label>
                    </div>
                    {tt.isSelected && (
                      <button
                        className="btn btn-primary"
                        style={{ marginLeft: "20px" }}
                        onClick={() => applyWorkflow(tt)}
                      >
                        Apply...
                      </button>
                    )}
                  </div>
                  {tt.isSelected && (
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      style={{ width: "100px" }}
                      value={tt.defaultDueDays}
                      onChange={e => {
                        changeDueDays(
                          x =>
                            x.taskTypeID == tt.taskTypeID &&
                            x.workflowType == "Workflow",
                          e.target.value
                        )
                      }}
                    >
                      {dueDaysOptions.map((s, i) => (
                        <option key={i} value={s}>
                          {s}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              ))}
            <Label className="mt-3">
              <h4>Task Lists</h4>
            </Label>

            {scenarioTaskTypes
              .filter(x => x.workflowType == "Task List")
              .map((tt, idx) => (
                <div
                  key={idx}
                  className="d-flex-row ai-center"
                  style={{ gap: "15px", padding: "5px" }}
                  // style={{ marginLeft: "10px" }}
                >
                  <div className="form-check" style={{ width: "300px" }}>
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id={`tt3-cbx-${idx}`}
                      name={`tt3-cbx-${idx}`}
                      checked={tt.isSelected}
                      onClick={e => {
                        changeSelection(
                          x =>
                            x.taskTypeID == tt.taskTypeID &&
                            x.workflowID == tt.workflowID &&
                            x.workflowType == "Task List"
                        )
                      }}
                    />
                    <Label className="form-check-label" for={`tt3-cbx-${idx}`}>
                      {tt.workflowName}
                    </Label>
                  </div>
                  {tt.isSelected && (
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      style={{ width: "100px" }}
                      value={tt.defaultDueDays}
                      onChange={e => {
                        changeDueDays(
                          x =>
                            x.taskTypeID == tt.taskTypeID &&
                            x.workflowID == tt.workflowID &&
                            x.workflowType == "Task List",
                          e.target.value
                        )
                      }}
                    >
                      {dueDaysOptions.map((s, i) => (
                        <option key={i} value={s}>
                          {s}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              ))}
          </Col>
        </Row>
      )}
      <ApplyTaskTypeModal
        isOpen={showApplyModal}
        toggleModal={toggleApplyModal}
        taskType={taskTypeToApply}
        scenario={scenario}
      />
    </React.Fragment>
  )
}

export default TrackingOptionsSection
