import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import { Field, Formik, useFormik } from "formik"
import Multiselect from "multiselect-react-dropdown"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Form, FormFeedback, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import * as Yup from "yup"

const AddEditParticipantModal = ({
  participant,
  setParticipant,
  isOpen,
  toggleModal,
  scenarioRoles,
  reviewerRoles,
  criteriaAlternativeModes,
  changeRequestRoles,
  reloadGrid,
  scenarioId,
  scenario,
}) => {
  const currentUser = userService.getLoggedInUser()
  let [email, setEmail] = useState("")
  let [isEmailSet, setIsEmailSet] = useState(false)
  const [alternativeSelectedOptions, setAlternativeSelectedOptions] = useState(
    []
  )
  const [alternativeOptions, setAlternativeOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    validation.resetForm()
    setEmail("")
  }, [participant])

  useEffect(() => {
    if (isOpen) {
      setIsEmailSet(false)
      if (
        participant !== undefined &&
        participant !== null &&
        participant.limitedAccess &&
        participant.userID > -1
      ) {
        loadAltUserAccess(participant.userID)
      }
    }
  }, [isOpen])

  const getUserByEmail = async email => {
    try {
      let part = await api.getParticipantByEmail(scenarioId, email)
      if (part != null) {
        setParticipant(
          part.user == null ? { user: { userID: -1, email: email } } : part
        )
        validation.resetForm()
      } else {
        setParticipant({ user: { userID: -1, email: email } })
        validation.resetForm()
      }
      setIsEmailSet(true)
    } catch (error) {
      console.log(error)
    }
  }

  const loadAltUserAccess = async userId => {
    setIsLoading(true)
    try {
      let alts = await api.getAlternativesForAccess(
        currentUser.userID,
        scenarioId
      )
      setAlternativeOptions(
        alts.map(a => {
          return {
            name: a.alternativeNum + " - " + a.alternativeName,
            id: a.alternativeID,
          }
        })
      )
      if (userId <= 0) {
        setAlternativeSelectedOptions([])
      } else {
        let userAlts = await api.getUserAccessAlternatives(userId)
        setAlternativeSelectedOptions(
          userAlts.map(a => {
            return {
              name: a.alternativeNum + " - " + a.alternativeName,
              id: a.alternativeID,
            }
          })
        )
      }
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  const saveUserAccessAlternatives = async userId => {
    if (alternativeSelectedOptions.length > 0) {
      await api.saveUserAccessAlternatives(
        userId,
        alternativeSelectedOptions.map(x => x.id).join(",")
      )
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: (participant && participant.user && participant.user.email) || "",
      firstName:
        (participant && participant.user && participant.user.firstName) || "",
      lastName:
        (participant && participant.user && participant.user.lastName) || "",
      password:
        (participant && participant.user && participant.user.password) || "",
      modelRoleID: (participant && participant.modelRoleID) || "",
      reviewerRoleID: (participant && participant.reviewerRoleID) || 0,
      votingWeight: (participant && participant.votingWeight * 100) || 100,
      altTypeID: (participant && participant.altTypeID) || 0,
      crApproverID: (participant && participant.crApproverID) || 0,
      pwcTypeID: (participant && participant.pwcTypeID) || 0,
      inactive: (participant && participant.inactive) || false,
      participantLink: (participant && participant.participantLink) || "",
      alternativeScoring:
        (participant && participant.alternativeScoring) || false,
      criteriaPrioritization:
        (participant && participant.criteriaPrioritization) || false,
      limitedAccess: (participant && participant.limitedAccess) || false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      firstName: Yup.string().required("Please Enter Your First name"),
      lastName: Yup.string().required("Please Enter Your Last name"),
      password: Yup.string().required("Please Enter password"),
      altTypeID: Yup.number(),
      crApproverID: Yup.number(),
      inactive: Yup.boolean(),
      pwcTypeID: Yup.number(),
      modelRoleID: Yup.number().required("Please Enter Your Role").moreThan(0),
      reviewerRoleID: Yup.number(),
      votingWeight: Yup.number()
        .typeError("Must be a number")
        .min(0, "at least 0")
        .max(100, "at most 100")
        .required("Please Enter Your Weight"),
    }),
    onSubmit: async values => {
      console.log(values)
      console.log(participant)

      let updatedObj = {
        ...participant,
        user: {
          ...participant.user,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          email: values.email,
          inactive: values.inactive,
        },
        modelRoleID: values.modelRoleID,
        pwcTypeID: values.pwcTypeID,
        altTypeID: values.altTypeID,
        reviewerRoleID: values.reviewerRoleID,
        inactive: values.inactive,
        crApproverID: values.crApproverID,
        votingWeight: values.votingWeight == 0 ? 0 : values.votingWeight / 100,
        scenarioID: scenarioId,
        limitedAccess: values.limitedAccess,
      }
      try {
        if (values.alternativeScoring) {
          await api.sendParticipantInvite("alternative", updatedObj)
        }
        if (values.criteriaPrioritization) {
          await api.sendParticipantInvite("objective", updatedObj)
        }
        if (updatedObj.participantID > 0) {
          await api.updateParticipant(currentUser.userID, updatedObj)
          if (
            updatedObj.limitedAccess &&
            alternativeSelectedOptions.length > 0
          ) {
            await saveUserAccessAlternatives(updatedObj.userID)
          }
          toast.success("Participant updated successfully")
        } else {
          let pId = await api.createParticipant(currentUser.userID, updatedObj)
          if (
            updatedObj.limitedAccess &&
            alternativeSelectedOptions.length > 0
          ) {
            let updatedParticipant = await api.getParticipant(pId)
            await saveUserAccessAlternatives(updatedParticipant.userID)
          }
          toast.success("Participant created successfully")
        }
        toggleModal()
        reloadGrid(false)
      } catch (err) {
        console.log(err)
        alert(err)
      }
    },
  })

  const deleteParticipant = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: "Are you sure you want to delete this participant?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          try {
            await api.deleteParticipant(
              currentUser.userID,
              participant.participantID
            )
            toggleModal()
            reloadGrid(false)
          } catch (err) {
            console.log(err)
            alert(err)
          }
        }
      })
  }

  const clearScores = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title:
          "This will remove the criteria comparison judgments this participant has made for this decision. Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deletePwcUserScoresScenario(
            participant.user.userID,
            scenarioId
          )
          toast.success("Judgements cleared successfully")
          toggleModal()
          reloadGrid(false)
        }
      })
  }

  const clearAltScores = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `This will remove the ${
          scenario && scenario.alternativeName
        } judgments this participant has made for this decision. Are you sure?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteUserAltScores(participant.user.userID, scenarioId)
          toast.success("Judgements cleared successfully")
          toggleModal()
          reloadGrid(false)
        }
      })
  }

  const performCriteriaPrioritization = async () => {
    await api.performCriteriaPrioritizationAI(
      participant.user.userID,
      scenarioId
    )

    swalWithConfirmButton.fire({
      title: `AI Critieria Prioritization started - Check the Pairwise Comparison (Admin) screen to see the results in a few minutes`,
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "Ok",
    })
  }

  const performAlternativePrioritization = async () => {
    await api.performAlternativePrioritizationAI(
      participant.user.userID,
      scenarioId
    )

    swalWithConfirmButton.fire({
      title: `AI ${scenario && scenario.alternativeName} started - Check the ${
        scenario && scenario.alternativeName
      } scoring (Admin) screen to see the results in a few minutes`,
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "Ok",
    })
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      {participant && (
        <>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {participant.user && participant.user.userID > 0
                ? "Edit Participant"
                : "Add Participant"}
            </h5>
            <button
              type="button"
              onClick={() => {
                toggleModal()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {participant.user && participant.user.userID <= 0 && !isEmailSet && (
              <Row>
                <Label className="form-label">Email</Label>
                <Col xs="8">
                  <Input
                    name="emails"
                    type="text"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                  />
                </Col>
                <Col xs="4">
                  <button
                    className="btn btn-primary save-user"
                    onClick={() => getUserByEmail(email)}
                  >
                    {"Next  >"}
                  </button>
                </Col>
              </Row>
            )}
            {((participant.user && participant.user.userID > 0) ||
              isEmailSet) && (
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row form="true">
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">First Name</Label>
                      <Input
                        name="firstName"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstName || ""}
                        invalid={
                          validation.touched.firstName &&
                          validation.errors.firstName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.firstName &&
                      validation.errors.firstName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.firstName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Last Name</Label>
                      <Input
                        name="lastName"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastName || ""}
                        invalid={
                          validation.touched.lastName &&
                          validation.errors.lastName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lastName &&
                      validation.errors.lastName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.lastName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Password</Label>
                      <Input
                        name="password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Voting Weight</Label>
                      <Input
                        name="votingWeight"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.votingWeight || ""}
                        invalid={
                          validation.touched.votingWeight &&
                          validation.errors.votingWeight
                            ? true
                            : false
                        }
                      />
                      {/* <input type="number" name="votingWeight" onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.votingWeight || ""}
                        invalid={
                          validation.touched.votingWeight &&
                          validation.errors.votingWeight
                            ? true
                            : false
                        } /> */}
                      {validation.touched.votingWeight &&
                      validation.errors.votingWeight ? (
                        <FormFeedback type="invalid">
                          {validation.errors.votingWeight}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {scenarioRoles && scenarioRoles.length > 0 && (
                      <div className="mb-3">
                        <Label className="form-label">Decision Role</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={validation.values.modelRoleID || ""}
                          onChange={e => {
                            console.log(e.target.value)
                            validation.setFieldValue(
                              "modelRoleID",
                              e.target.value
                            )
                          }}
                        >
                          <option value={0}>Select role</option>
                          {scenarioRoles.map((r, idx) => {
                            return (
                              <option key={idx} value={`${r.listItemID}`}>
                                {r.listItemName}
                              </option>
                            )
                          })}
                        </select>
                        {validation.touched.modelRoleID &&
                        validation.errors.modelRoleID ? (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {validation.errors.modelRoleID}
                          </div>
                        ) : null}
                      </div>
                    )}

                    {reviewerRoles && reviewerRoles.length > 0 && (
                      <div className="mb-3">
                        <Label className="form-label">Reviewer Role</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={validation.values.reviewerRoleID || ""}
                          onChange={e => {
                            validation.setFieldValue(
                              "reviewerRoleID",
                              e.target.value
                            )
                          }}
                        >
                          <option value={0}>Select role</option>
                          {reviewerRoles.map((r, idx) => {
                            return (
                              <option key={idx} value={`${r.listItemID}`}>
                                {r.listItemName}
                              </option>
                            )
                          })}
                        </select>
                        {validation.touched.reviewerRoleID &&
                        validation.errors.reviewerRoleID ? (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {validation.errors.reviewerRoleID}
                          </div>
                        ) : null}
                      </div>
                    )}

                    {changeRequestRoles && changeRequestRoles.length > 0 && (
                      <div className="mb-3">
                        <Label className="form-label">
                          Change Request Approver
                        </Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={validation.values.crApproverID || ""}
                          onChange={e => {
                            validation.setFieldValue(
                              "crApproverID",
                              e.target.value
                            )
                          }}
                        >
                          {changeRequestRoles.map((r, idx) => {
                            return (
                              <option key={idx} value={`${r.listItemID}`}>
                                {r.listItemName}
                              </option>
                            )
                          })}
                        </select>
                        {validation.touched.crApproverID &&
                        validation.errors.crApproverID ? (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {validation.errors.crApproverID}
                          </div>
                        ) : null}
                      </div>
                    )}

                    {criteriaAlternativeModes &&
                      criteriaAlternativeModes.length > 0 && (
                        <div className="mb-3">
                          <Label className="form-label">
                            Criteria Prioritization Mode
                          </Label>
                          <select
                            className="form-control form-select select2 mb-xxl-0"
                            value={validation.values.pwcTypeID || ""}
                            onChange={e => {
                              validation.setFieldValue(
                                "pwcTypeID",
                                e.target.value
                              )
                            }}
                          >
                            {criteriaAlternativeModes.map((r, idx) => {
                              return (
                                <option key={idx} value={`${r.listItemID}`}>
                                  {r.listItemName}
                                </option>
                              )
                            })}
                          </select>
                          {validation.touched.pwcTypeID &&
                          validation.errors.pwcTypeID ? (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {validation.errors.pwcTypeID}
                            </div>
                          ) : null}
                        </div>
                      )}

                    {criteriaAlternativeModes &&
                      criteriaAlternativeModes.length > 0 && (
                        <div className="mb-3">
                          <Label className="form-label">
                            Alternative Scoring Mode
                          </Label>
                          <select
                            className="form-control form-select select2 mb-xxl-0"
                            value={validation.values.altTypeID || ""}
                            onChange={e => {
                              validation.setFieldValue(
                                "altTypeID",
                                e.target.value
                              )
                            }}
                          >
                            {criteriaAlternativeModes.map((r, idx) => {
                              return (
                                <option key={idx} value={`${r.listItemID}`}>
                                  {r.listItemName}
                                </option>
                              )
                            })}
                          </select>
                          {validation.touched.altTypeID &&
                          validation.errors.altTypeID ? (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {validation.errors.altTypeID}
                            </div>
                          ) : null}
                        </div>
                      )}

                    <div className="form-check mb-3">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck4"
                        checked={validation.values.inactive}
                        onChange={e => {
                          validation.setFieldValue("inactive", e.target.checked)
                        }}
                      />
                      <Label className="form-check-label" for="customCheck4">
                        Inactive
                      </Label>
                    </div>

                    {validation.values.participantLink != undefined &&
                      validation.values.participantLink != null &&
                      validation.values.participantLink != "" && (
                        <div className="mb-3" style={{ marginTop: "10px" }}>
                          <Label className="form-check-label">
                            Participant link
                          </Label>
                          <div>
                            <a href={validation.values.participantLink}>
                              {validation.values.participantLink}
                            </a>
                          </div>
                        </div>
                      )}
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck7"
                        value={validation.values.criteriaPrioritization}
                        onChange={e => {
                          validation.setFieldValue(
                            "criteriaPrioritization",
                            e.target.checked
                          )
                        }}
                      />
                      <Label className="form-check-label" for="customCheck7">
                        Send a criteria prioritization invitation
                      </Label>
                    </div>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck6"
                        value={validation.values.alternativeScoring}
                        onChange={e => {
                          validation.setFieldValue(
                            "alternativeScoring",
                            e.target.checked
                          )
                        }}
                      />
                      <Label className="form-check-label" for="customCheck6">
                        Send an alternative scoring invitation
                      </Label>
                    </div>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck100"
                        checked={validation.values.limitedAccess}
                        onClick={e => {
                          if (!validation.values.limitedAccess) {
                            loadAltUserAccess(participant.userID)
                          }
                          validation.setFieldValue(
                            "limitedAccess",
                            !validation.values.limitedAccess
                          )
                        }}
                      />
                      <Label className="form-check-label" for="customCheck100">
                        Limit Access to Business Cases
                      </Label>
                    </div>
                    {validation.values.limitedAccess && (
                      <>
                        {isLoading && (
                          <Loader
                            type="line-scale-pulse-out"
                            color={colors.primary}
                            style={{ textAlign: "center" }}
                          />
                        )}
                        {!isLoading && (
                          <div className="mb-3">
                            <Label className="form-label">Business Cases</Label>
                            <Multiselect
                              options={alternativeOptions}
                              selectedValues={alternativeSelectedOptions}
                              onSelect={setAlternativeSelectedOptions}
                              onRemove={setAlternativeSelectedOptions}
                              displayValue="name"
                              showCheckbox={true}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={clearScores}
                    >
                      Clear Criteria Judgments
                    </button>
                    {participant &&
                      participant.user &&
                      participant.user.aiUser &&
                      currentUser.useAI && (
                        <button
                          type="button"
                          className="btn btn-primary save-user"
                          onClick={performCriteriaPrioritization}
                        >
                          <i className="mdi mdi-brain"></i> AI - Run Criteria
                          Prioritization
                        </button>
                      )}
                    <button
                      type="button"
                      className="btn btn-danger save-user"
                      onClick={clearAltScores}
                    >
                      Clear {scenario != null && scenario.alternativeName}{" "}
                      Scores
                    </button>
                    {participant &&
                      participant.user &&
                      participant.user.aiUser &&
                      currentUser.useAI && (
                        <button
                          type="button"
                          className="btn btn-primary save-user"
                          onClick={performAlternativePrioritization}
                        >
                          <i className="mdi mdi-brain"></i> AI - Run{" "}
                          {scenario != null && scenario.alternativeName} Scoring
                        </button>
                      )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    {participant && participant.participantID > 0 && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={deleteParticipant}
                      >
                        Delete
                      </button>
                    )}

                    <div>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{ marginRight: "10px" }}
                        onClick={toggleModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary save-user"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Row>
              </Form>
            )}
          </div>
        </>
      )}
      {/* <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="button" className="btn btn-primary ">
          Save changes
        </button>
      </div> */}
    </Modal>
  )
}

export default AddEditParticipantModal
