import { Tooltip } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import useModal from "hooks/useModalHook"
import EditResourceModal from "pages/Resources/ResourceCapacity/EditResourceModal"
import EditTShirtModal from "./EditTShirtModal"
import ResourceAlternativesModal from "./ResourceAlternativesModel"

const ManageResources = props => {
  const currentUser = userService.getLoggedInUser()
  const scenarioId = props.match.params.scenarioId
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)

  const [resourceToEditId, setResourceToEditId] = useState(-1)
  const [resourceGroups, setResourceGroups] = useState([])
  const [scenario, setScenario] = useState(null)

  const { show: showEditModal, toggle: toggleEditModal } = useModal()

  const { show: showTShirtModal, toggle: toggleTShirtModal } = useModal()
  const [tShirtModalData, setTShirtModalData] = useState(null) // {resourceGroupID, tShirtBundleID}

  const [
    resourceGroupForAlternativeDetails,
    setResourceGroupForAlternativeDetails,
  ] = useState(-1)
  const {
    show: showResourceAlternativesModal,
    toggle: toggleResourceAlternativesModal,
  } = useModal()

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "manageResources",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    await loadResourceGroups()
    var s = await api.getScenario(scenarioId)
    setScenario(s)
    setIsLoading(false)
    // loadModalData(sc)
  }

  const loadResourceGroups = async () => {
    var rg = await api.getResourceGroupsByUser(currentUser.userID, scenarioId)
    setResourceGroups(rg)
  }

  const addResource = () => {
    setResourceToEditId(-1)
    toggleEditModal()
  }

  const editResource = rg => {
    setResourceToEditId(rg.resourceGroupID)
    toggleEditModal()
  }

  const loadTShirtModal = (resourceGroupId, tShirtBundleId) => {
    setTShirtModalData({ resourceGroupId, tShirtBundleId })
    toggleTShirtModal()
  }

  const showResourceAlternatives = resourceGroupId => {
    setResourceGroupForAlternativeDetails(resourceGroups.find(x => x.resourceGroupID == resourceGroupId))
    toggleResourceAlternativesModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              <button className={`btn btn-primary mb-3`} onClick={addResource}>
                + Add Resource
              </button>
              <Row>
                <Col md="12">
                  {isLoading && (
                    <Loader
                      type="line-scale-pulse-out"
                      color={colors.primary}
                      style={{ textAlign: "center" }}
                    />
                  )}
                  <table
                    className="table table-bordered low-padding-table"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <tr>
                        <th>Resource Name</th>
                        <th>Short Name</th>
                        <th>{scenario?.alternativeName}s</th>
                        <th>T-Shirts Set Up</th>
                        <th>PM-BRM Rights</th>
                        <th>Current Rate</th>
                        <th>Manager(s)</th>
                        <th>Cost Pool(s)</th>
                        <th>Contract(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resourceGroups.map((r, idx) => (
                        <tr key={idx}>
                          <td>
                            <a
                              href="#"
                              onClick={e => {
                                e.preventDefault()
                                editResource(r)
                              }}
                            >
                              {r.resourceGroupName}
                            </a>
                          </td>
                          <td>{r.resourceGroupAbb}</td>
                          <td>
                            <a
                              href="#"
                              onClick={e => {
                                e.preventDefault()
                                showResourceAlternatives(r.resourceGroupID)
                              }}
                            >
                              {scenario?.alternativeName}s
                            </a>
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <span>{r.hasTShirts ? "Yes" : "No"}</span>
                              {r.projectCostPoolID > 0 && (
                                <div
                                  style={{
                                    margin: "0",
                                    textAlign: "center",
                                    display: "inline-block",
                                    padding: "4px 10px",
                                    borderRadius: "15px",
                                    borderColor: "lightgray",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    loadTShirtModal(
                                      r.resourceGroupID,
                                      r.tShirtBundleID
                                    )
                                  }}
                                >
                                  <i className="fas fa-tshirt"></i>&nbsp;T-Shirt
                                </div>
                              )}
                            </div>
                          </td>
                          <td>{r.pmComplete ? "Can Estimate" : ""}</td>
                          <td>{r.rateSummary}</td>
                          <td>{r.managerSummary}</td>
                          <td>{r.costPoolSummary}</td>
                          <td>{r.contractSummary}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      <EditResourceModal
        resourceToEditId={resourceToEditId}
        isOpen={showEditModal}
        toggleModal={toggleEditModal}
        scenarioId={scenarioId}
        reloadResources={loadResourceGroups}
      />
      <EditTShirtModal
        isOpen={showTShirtModal}
        toggleModal={toggleTShirtModal}
        data={tShirtModalData}
        scenarioId={scenarioId}
        reload={loadResourceGroups}
      />
      <ResourceAlternativesModal
        resourceGroup={resourceGroupForAlternativeDetails}
        isOpen={showResourceAlternativesModal}
        toggleModal={toggleResourceAlternativesModal}
        alternativeName={scenario?.alternativeName}
      />
    </React.Fragment>
  )
}

export default ManageResources
