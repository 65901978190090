import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { getYears } from "./EditEntityModal"
import download from "downloadjs"

const EntityReportModal = ({ isOpen, toggleModal }) => {
  const currentUser = userService.getLoggedInUser()
  const [selectedYear, setSelectedYear] = useState(0)
  const [selectedEntity, setSelectedEntity] = useState(0)
  const [selectedField, setSelectedField] = useState(0)
  const [entities, setEntities] = useState([])
  const [fields, setFields] = useState([])
  const years = getYears()
  const [isReportLoading, setIsReportLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setSelectedYear(new Date().getFullYear())
    }
  }, [isOpen])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    let e = await api.getEntities(currentUser.userID, 1)
    setEntities(e)
    let f = await api.getEntityFields(1)
    setFields(f)
  }

  const run10yearReport = () => {
    setIsReportLoading(true)
    api
      .runReport({
        reportPath: "reports/NM_DataFieldReport.trdp",
        outputType: "Excel",
        userID: currentUser.userID,
        title: "10 Year Data Report",
        subTitle: fields.find(x => x.entityFieldID == selectedField)
          .entityFieldName,
        entityFieldID: selectedField,
        yearID: moment().year()
      })
      .then(blob => {
        download(blob, "10 Year Data Report.xlsx")
        setIsReportLoading(false)
      })
  }

  const runIgReport = () => {
    setIsReportLoading(true)
    api
      .runReport({
        reportPath: "reports/NM_IGReport.trdp",
        outputType: "Excel",
        userID: currentUser.userID,
        title: "IG Report",
        subTitle: selectedYear,
      })
      .then(blob => {
        download(blob, "IG Report.xlsx")
        setIsReportLoading(false)
      })
  }

  const runVerificationReport = () => {
    setIsReportLoading(true)
    api
      .runReport({
        reportPath: "reports/NM_VerificationReport.trdp",
        outputType: "Excel",
        userID: currentUser.userID,
        title: "Institution Report",
        subTitle: entities.find(x => x.entityID == selectedEntity)
          .entityName,
        entityID: selectedEntity,
      })
      .then(blob => {
        download(blob, "Institution Report.xlsx")
        setIsReportLoading(false)
      })
  }

  const save = async () => {}

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Report
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Years</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={selectedYear}
            onChange={e => {
              setSelectedYear(e.target.value)
            }}
          >
            {years.map((x, idx) => (
              <option key={idx} value={x}>
                {x}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <button className="btn btn-primary" onClick={runIgReport} disabled={isReportLoading}>
            IG Report
          </button>
        </div>
        <div className="mb-3">
          <Label className="form-label">Institution</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={selectedEntity}
            onChange={e => {
              setSelectedEntity(e.target.value)
            }}
          >
            {entities.map((x, idx) => (
              <option key={idx} value={x.entityID}>
                {x.entityName}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <button className="btn btn-primary" onClick={runVerificationReport} disabled={isReportLoading}>
            Verification Report
          </button>
        </div>
        <div className="mb-3">
          <Label className="form-label">Field</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={selectedField}
            onChange={e => {
              setSelectedField(e.target.value)
            }}
          >
            {fields.map((x, idx) => (
              <option key={idx} value={x.entityFieldID}>
                {x.entityFieldName}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <button className="btn btn-primary" onClick={run10yearReport} disabled={isReportLoading}>
            10 Year Data Report
          </button>
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default EntityReportModal
