import useModal from "hooks/useModalHook"
import React, { useEffect, useState } from "react"
import { Col, Input, Label, Modal, Row, Alert } from "reactstrap"
import CreateFieldModal from "./CreateFieldModal"
import ManageFormFieldListItems from "./ManageFormFieldListItems"
import { swalWithConfirmButton } from "components/custom/swal"
import { TextareaAutosize } from "@material-ui/core"

const EditFormFieldModal = ({
  isOpen,
  toggleModal,
  formFieldToEdit,
  setFormFieldToEdit,
  changeFormFieldProp,
  changeMultipleFormFieldProps,
  formFieldTypes,
  fields,
  onFormFieldSave,
  scope,
  onFieldSave,
  formFieldSelectLists,
  enterprise,
}) => {
  const { show: showCreateFieldModal, toggle: toggleCreateFieldModal } =
    useModal()
  const [hasLabelError, setHasLabelError] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setHasLabelError(false)
    }
  }, [isOpen])

  //   const loadData = async () => {}

  const save = () => {
    if (
      formFieldToEdit.formFieldLabel == undefined ||
      formFieldToEdit.formFieldLabel == null ||
      formFieldToEdit.formFieldLabel == ""
    ) {
      setHasLabelError(true)
    } else {
      let selectedFormFieldType = formFieldTypes.find(
        x => x.formFieldTypeId == formFieldToEdit.formFieldTypeId
      )
      if (
        selectedFormFieldType != undefined &&
        selectedFormFieldType.hasOptions &&
        !selectedFormFieldType.getOptionsFromList
      ) {
        if (
          formFieldToEdit.formFieldListItems == undefined ||
          formFieldToEdit.formFieldListItems == null ||
          formFieldToEdit.formFieldListItems.length == 0
        ) {
          swalWithConfirmButton.fire({
            title: "You need to add list items to this field.",
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Ok",
          })
          return
        }
      }
      setHasLabelError(false)
      onFormFieldSave()
      toggleModal()
    }
  }

  const showAiPrompt =
    enterprise?.useAI &&
    (formFieldTypes
      .find(x => x.formFieldTypeId == formFieldToEdit?.formFieldTypeId)
      ?.type?.toLowerCase() == "long text" ||
      formFieldTypes
        .find(x => x.formFieldTypeId == formFieldToEdit?.formFieldTypeId)
        ?.dataType?.toLowerCase() == "html")

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="lg"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add / Edit Form Field
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          <Alert
            id="helpView"
            variant="light"
            className="always-enabled"
            style={{ marginBottom: "10px", paddingBottom: "0px" }}
          >
            <div>
              First select the type of control you want to add (most controls
              will be tied to a field in the database or that you will create.)
              Then follow the steps below to create or edit the controls on the
              form.
              <ol>
                <li>
                  If the control type is tied to a field, select it from the
                  current list of fields.
                </li>
                <li>
                  If you are creating a new field, click that it is "Not on the
                  list" and give the field a unique name and select the type of
                  data it contains.
                </li>
                <li>
                  The Label is what is shown next to the field on the form (it
                  can be the same name as the field, but doesnt have to be).
                </li>
                <li>
                  If the type of field supports it, you may see a Placholder
                  entry. This is information the user will see in the field
                  before they fill it out.
                </li>
                <li>
                  Select the options for full width (the field is the full width
                  of the form no matter how many columns), Always-Read Only,
                  Always-Editable and Required are other options. If the field
                  is required enter a message to be shown if it is not filled
                  out.
                </li>
              </ol>
            </div>
          </Alert>
        </div>
        {formFieldToEdit != null && (
          <div className="modal-body">
            <div className="mb-3">
              <Label className="form-label">Field Type</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={formFieldToEdit.formFieldTypeId}
                onChange={e => {
                  if (e.target.value != "-1") {
                    let selectedType = formFieldTypes.find(
                      x => x.formFieldTypeId == e.target.value
                    )
                    changeMultipleFormFieldProps(
                      ["formFieldTypeId", "formFieldType"],
                      [e.target.value, selectedType]
                    )
                  } else {
                    changeFormFieldProp("formFieldTypeId", e.target.value)
                  }
                }}
              >
                <option value="-1">Select type...</option>
                {formFieldTypes.map((s, i) => (
                  <option key={i} value={s.formFieldTypeId}>
                    {s.type}
                  </option>
                ))}
              </select>
            </div>
            {formFieldToEdit.formFieldType?.type !== "Header" &&
              formFieldToEdit.formFieldType?.type !== "Divider" &&
              formFieldToEdit.formFieldType?.type !== "Help Text" && (
                <>
                  <div className="mb-3">
                    <Label className="form-label">Field</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={formFieldToEdit.fieldId}
                      onChange={e => {
                        let selectedField = fields.find(
                          x => x.fieldId == e.target.value
                        )
                        if (selectedField != undefined) {
                          setFormFieldToEdit({
                            ...formFieldToEdit,
                            fieldId: e.target.value,
                            formFieldLabel: selectedField.fieldName,
                          })
                        } else {
                          changeFormFieldProp("fieldId", e.target.value)
                        }
                      }}
                      disabled={formFieldToEdit.formFieldTypeId == -1}
                    >
                      <option value="-1">Select field...</option>
                      {fields
                        .filter(
                          x =>
                            formFieldToEdit.formFieldTypeId == -1 ||
                            formFieldToEdit.formFieldType == undefined ||
                            formFieldToEdit.formFieldType == null ||
                            (formFieldToEdit.formFieldType.hasOptions &&
                              (x.dataType == "Text" ||
                                x.dataType == "Integer")) ||
                            (!formFieldToEdit.formFieldType.hasOptions &&
                              x.dataType ==
                                formFieldToEdit.formFieldType.dataType)
                        )
                        .map((s, i) => (
                          <option key={i} value={s.fieldId}>
                            {s.fieldName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        toggleCreateFieldModal()
                      }}
                    >
                      Not in the list?
                    </a>
                    &nbsp;
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={e => {
                        e.preventDefault()
                        toggleCreateFieldModal()
                      }}
                    >
                      Create a new field
                    </button>
                  </div>
                </>
              )}

            {formFieldToEdit.formFieldType != null &&
              formFieldToEdit.formFieldType !== undefined &&
              formFieldToEdit.formFieldType.hasOptions && (
                <>
                  {formFieldToEdit.formFieldType.getOptionsFromList ? (
                    <div className="mb-3">
                      <Label className="form-label">List</Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={formFieldToEdit.selectList}
                        onChange={e => {
                          changeFormFieldProp("selectList", e.target.value)
                        }}
                      >
                        <option value="-1">Select list...</option>
                        {formFieldSelectLists.map((s, i) => (
                          <option key={i} value={s.endpoint}>
                            {s.list}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <ManageFormFieldListItems
                      changeFormFieldProp={changeFormFieldProp}
                      formFieldListItems={formFieldToEdit.formFieldListItems}
                    />
                  )}
                </>
              )}
            <div className="mb-3">
              <Label className="form-label">Label</Label>
              <Input
                type="text"
                onChange={e =>
                  changeFormFieldProp("formFieldLabel", e.target.value)
                }
                value={formFieldToEdit.formFieldLabel}
              />
              {hasLabelError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Label is required
                </div>
              )}
            </div>
            <div className="mb-3">
              <Label className="form-label">Order</Label>
              <Input
                type="number"
                onChange={e => changeFormFieldProp("orderId", e.target.value)}
                value={formFieldToEdit.orderId}
              />
            </div>
            {formFieldToEdit.formFieldType != null &&
              formFieldToEdit.formFieldType !== undefined &&
              formFieldToEdit.formFieldType.hasPlaceholder && (
                <div className="mb-3">
                  <Label className="form-label">Placeholder</Label>
                  <Input
                    type="text"
                    onChange={e =>
                      changeFormFieldProp(
                        "formFieldPlaceholder",
                        e.target.value
                      )
                    }
                    value={formFieldToEdit.formFieldPlaceholder}
                  />
                </div>
              )}
            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-input"
                id="ff-fullwidth"
                checked={formFieldToEdit.fullWidth}
                onClick={e => {
                  changeFormFieldProp("fullWidth", !formFieldToEdit.fullWidth)
                }}
              />
              <Label className="form-check-label" for="ff-fullwidth">
                Full Width
              </Label>
            </div>
            {formFieldToEdit.formFieldType?.type !== "Header" &&
              formFieldToEdit.formFieldType?.type !== "Divider" &&
              formFieldToEdit.formFieldType?.type !== "Help Text" && (
                <>
                  <div className="form-check mb-3">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="ff-alwaysreadonly"
                      checked={formFieldToEdit.alwaysReadOnly}
                      onClick={e => {
                        changeFormFieldProp(
                          "alwaysReadOnly",
                          !formFieldToEdit.alwaysReadOnly
                        )
                      }}
                    />
                    <Label className="form-check-label" for="ff-alwaysreadonly">
                      Always Read-Only
                    </Label>
                  </div>
                  <div className="form-check mb-3">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="ff-alwaysEditable"
                      checked={formFieldToEdit.alwaysEditable}
                      onClick={e => {
                        changeFormFieldProp(
                          "alwaysEditable",
                          !formFieldToEdit.alwaysEditable
                        )
                      }}
                    />
                    <Label className="form-check-label" for="ff-alwaysEditable">
                      Always Editable
                    </Label>
                  </div>
                  <div className="form-check mb-3">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="ff-required"
                      checked={formFieldToEdit.required}
                      onClick={e => {
                        changeFormFieldProp(
                          "required",
                          !formFieldToEdit.required
                        )
                      }}
                    />
                    <Label className="form-check-label" for="ff-required">
                      Required
                    </Label>
                  </div>
                  {formFieldToEdit.required && (
                    <div className="mb-3">
                      <Label className="form-label">Required Message</Label>
                      <Input
                        type="text"
                        onChange={e =>
                          changeFormFieldProp("requiredMessage", e.target.value)
                        }
                        value={formFieldToEdit.requiredMessage}
                      />
                    </div>
                  )}
                  {showAiPrompt && (
                    <div className="mb-3">
                      <Label className="form-label">AI Prompt</Label>
                      <TextareaAutosize
                        className="form-control"
                        placeholder="Prompt..."
                        minRows={3}
                        onChange={e =>
                          changeFormFieldProp("aiPrompt", e.target.value)
                        }
                        value={formFieldToEdit.aiPrompt}
                      />
                    </div>
                  )}
                </>
              )}
          </div>
        )}
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={save}
          >
            Save
          </button>
        </div>
      </Modal>
      {formFieldToEdit !== undefined &&
        formFieldToEdit !== null &&
        formFieldToEdit.formFieldType != undefined &&
        formFieldToEdit.formFieldType != null && (
          <CreateFieldModal
            isOpen={showCreateFieldModal}
            toggleModal={toggleCreateFieldModal}
            scope={scope}
            onFieldSave={onFieldSave}
            formFieldTypes={formFieldTypes}
            selectedDataType={formFieldToEdit.formFieldType.dataType}
            existingFields={fields}
          />
        )}
    </>
  )
}

export default EditFormFieldModal
