import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import moment from "moment"
import { Tooltip } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import anychart from "anychart"
import { toast } from "react-toastify"
import isNullOrEmpty from "utils/isNullOrEmpty"

const ResourceCharts = props => {
  const currentUser = userService.getLoggedInUser()
  const scenarioId = props.match.params.scenarioId
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [isChartLoading, setIsChartLoading] = useState(false)
  const [isDrilldownLoading, setIsDrilldownLoading] = useState(false)
  const [alternatives, setAlternatives] = useState([])
  const [scenario, setScenario] = useState(null)
  const [decisions, setDecisions] = useState([])
  const [resourceClasses, setResourceClasses] = useState([])
  const [selectedResourceClass, setSelectedResourceClass] = useState(0)
  const [resourceGroups, setResourceGroups] = useState([])
  const [selectedDecision, setSelectedDecision] = useState("-1")
  const [alternativesVisible, setAlternativesVisible] = useState(true)
  const [selectedChart, setSelectedChart] = useState("0")
  const [classesVisible, setClassesVisible] = useState(true)
  const [allAltsSelected, setAllAltsSelected] = useState(false)
  const [allClassesSelected, setAllClassesSelected] = useState(false)

  const [startDate, setStartDate] = useState(
    moment(new Date(moment().year(), 0, 1, 0, 0, 0)).format(
      "YYYY-MM-DDT00:00:00"
    )
  )
  const [endDate, setEndDate] = useState(
    moment(new Date(moment().year(), 0, 1, 0, 0, 0))
      .add(2, "years")
      .format("YYYY-MM-DDT00:00:00")
  )

  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => {
    if (isModalOpen) {
      document.getElementById("drilldiv").innerHTML = ""
    }
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "resourceCharts",
      })
    )
    loadScenario()
    loadData()
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    await loadAlternatives()
    await loadResourceGroups(0)
    let items = await api.getList(currentUser.userID, "Decisions")
    setDecisions(items)
    let rc = await api.getEnterpriseList(
      currentUser.enterpriseID,
      "ResourceClass"
    )
    setResourceClasses(rc)
    setIsLoading(false)
  }

  const loadResourceGroups = async rgId => {
    let rg = await api.getResourceGroups(scenarioId, rgId)
    setResourceGroups(rg)
  }

  const loadAlternatives = async () => {
    let alts = await api.getAlternatives(currentUser.userID, scenarioId)
    setAlternatives(
      alts.map(a => {
        if (isNullOrEmpty(a.tempStartDate)) {
          a.tempStartDate = a.desiredStart
        }
        return a
      })
    )
  }

  const loadScenario = async () => {
    let sc = await api.getScenario(scenarioId)
    setScenario(sc)
  }

  const updateActualDates = async () => {
    let itemsToUpdate = alternatives.filter(x => x.isDirty)
    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternative(currentUser.userID, {
          ...x,
          desiredStart: x.tempStartDate,
        })
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      toast.success("Alternatives saved successfully")
    }
  }

  const resetDates = async () => {
    let alts = [...alternatives]
    let itemsToUpdate = alts.filter(x => x.tempStartDate != x.desiredStart)
    if (itemsToUpdate.length > 0) {
      itemsToUpdate.forEach(x => {
        x.tempStartDate = x.desiredStart
      })
      setAlternatives(alts)

      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternative(currentUser.userID, x)
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      toast.success("Alternatives saved successfully")
    }
  }

  const changeAltProp = (alt, prop, value, markDirty) => {
    let copy = [...alternatives]
    let item = copy.find(x => x.alternativeID == alt.alternativeID)
    item[prop] = value
    if (markDirty) {
      item.isDirty = true
    }
    setAlternatives(copy)
  }

  const changeResourceGroupProp = (rg, prop, value) => {
    let copy = [...resourceGroups]
    let item = copy.find(x => x.resourceGroupID == rg.resourceGroupID)
    item[prop] = value
    setResourceGroups(copy)
  }

  const loadChart = async chartType => {
    setIsChartLoading(true)
    if (
      alternatives.filter(x => x.isSelected).length == 0 ||
      resourceGroups.filter(x => x.isSelected).length == 0
    ) {
      swalWithConfirmButton.fire({
        title: `You have to select at least one project and at least one resource.`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      document.getElementById("chartdiv").innerHTML = ""
      var chartResponse = null
      if (chartType == "0") {
        chartResponse = await api.getResourceColumnChart(
          scenarioId,
          startDate,
          endDate,
          alternatives
            .filter(x => x.isSelected)
            .map(x => x.alternativeID)
            .join(","),
          resourceGroups
            .filter(x => x.isSelected)
            .map(x => x.resourceGroupID)
            .join(",")
        )
        let chartData = JSON.parse(
          chartResponse.chartArray.replaceAll("'", '"')
        )
        var dataSet = anychart.data.set(chartData)

        // map data for the second series, take x from the zero column and value from the second column of data set
        var firstSeriesData = dataSet.mapAs({ x: 0, value: 1 })

        // map data for the second series, take x from the zero column and value from the third column of data set
        var secondSeriesData = dataSet.mapAs({ x: 0, value: 3 })

        // map data for the second series, take x from the zero column and value from the third column of data set
        var thirdSeriesData = dataSet.mapAs({ x: 0, value: 4 })
        var setupSeriesLabels = function (series, name) {
          series.name(name).stroke("3 #fff 1")
          series.hovered().stroke("3 #fff 1")
        }
        var chart = anychart.column()
        chart.animation(true)
        let series = chart.column(firstSeriesData)
        setupSeriesLabels(series, "Excess Capacity")

        function coloringFunction() {
          // color the maximal value
          if (this.value > 0) return "#3ba158"
          if (this.value < 0) return "#fa6b71"

          return "#3ba158"
        }

        series.fill(coloringFunction)
        series = chart.column(thirdSeriesData)
        setupSeriesLabels(series, "New")
        series.fill("#ADD8E6")

        // create second series with mapped data
        series = chart.column(secondSeriesData)
        setupSeriesLabels(series, "Approved")
        series.fill("#00008B")

        // force chart to stack values by Y scale.
        chart.yScale().stackMode("value")

        // set chart title text settings
        chart.title(chartResponse.chartTitle)
        chart.title().padding([0, 0, 5, 0])

        var xAxisLabels = chart.xAxis().labels()
        xAxisLabels.rotation(90)

        chart.barGroupsPadding(0.5)
        chart.barsPadding(0.5)

        // turn on legend
        chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0])
        // set yAxis labels formatter
        chart.yAxis().labels().format("{%Value}{groupsSeparator: }")

        // set titles for axes
        chart.xAxis().title("Months")
        chart.yAxis().title("Units")

        chart.xScroller().enabled(true)
        chart.xZoom().setToPointsCount(18)

        var tooltip = chart.tooltip()
        tooltip.displayMode("union")

        // set interactivity hover
        chart.interactivity().hoverMode("byX")

        // set container id for the chart
        chart.container("chartdiv")
        chart.credits().enabled(false)
        chart.draw()
      } else if (chartType == "1" || chartType == "2" || chartType == "3") {
        if (chartType == "1") {
          chartResponse = await api.getResourceAreaRgChart(
            scenarioId,
            startDate,
            endDate,
            alternatives
              .filter(x => x.isSelected)
              .map(x => x.alternativeID)
              .join(","),
            resourceGroups
              .filter(x => x.isSelected)
              .map(x => x.resourceGroupID)
              .join(",")
          )
        } else {
          let aggregate = chartType == "3"
          chartResponse = await api.getResourceAreaAltChart(
            scenarioId,
            startDate,
            endDate,
            alternatives
              .filter(x => x.isSelected)
              .map(x => x.alternativeID)
              .join(","),
            resourceGroups
              .filter(x => x.isSelected)
              .map(x => x.resourceGroupID)
              .join(","),
            aggregate
          )
        }
        let obj1 = eval(
          "(" + chartResponse.chartArray.replaceAll("'", '"') + ")"
        )
        let json1 = JSON.stringify(obj1)
        let chartData = JSON.parse(json1)
        // let obj2 = eval("(" + chartResponse.chartArray2.replaceAll("'", '"') + ")")
        // let json2 = JSON.stringify(obj2)
        // let altData = JSON.parse(json2)
        var dataSet = anychart.data.set(chartData)

        var maxSet = anychart.data.set(chartData)

        var maxSeriesData = maxSet.mapAs({ x: 0, value: 1 })

        // helper function to setup label settings for all series
        var setupSeriesLabels = function (series, name) {
          series.name(name).stroke("3 #fa6b71 1")
          series.hovered().stroke("3 #fa6b71 1")
        }

        // create bar chart
        var chart = anychart.area()

        var alts = chartResponse.chartArray2.split(",").filter(x => x !== "")

        var numseries = parseInt(chartResponse.chartData, 10)
        var j = 0

        for (var i = 0; i < numseries; i++) {
          j = i + 1

          chart.addSeries(
            dataSet.mapAs({
              x: 0,
              value: j + 1, //,
              //'tooltip': 4
            })
          )

          chart.getSeriesAt(i).name(alts[i])
        }

        var tooltip = chart.tooltip()
        tooltip.displayMode("single")
        // set X scale mode to continuous
        chart.xScale().mode("continuous")

        let series = chart.line(maxSeriesData)
        setupSeriesLabels(series, "Capacity")

        // turn on chart animation
        chart.animation(true)

        // force chart to stack values by Y scale.
        chart.yScale().stackMode("value")

        // set chart title text settings
        chart.title(chartResponse.chartTitle)
        chart.title().padding([0, 0, 5, 0])

        var xAxisLabels = chart.xAxis().labels()
        xAxisLabels.rotation(90)

        // turn on legend
        chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0])
        // set yAxis labels formatter
        chart.yAxis().labels().format("{%Value}{groupsSeparator: }")

        // set titles for axes
        chart.xAxis().title("Months")
        chart.yAxis().title("Units")

        // set interactivity hover
        chart.interactivity().hoverMode("bySpot")
        chart.interactivity().spotRadius(10)

        chart.xScroller().enabled(true)
        chart.xZoom().setToPointsCount(12)
        chart.container("chartdiv")
        chart.credits().enabled(false)
        chart.draw()
      } else {
        // 4 - Heatmap

        let chartData = await api.getResourceHeatmap(
          1,
          startDate,
          endDate,
          scenarioId,
          alternatives
            .filter(x => x.isSelected)
            .map(x => x.alternativeID)
            .join(","),
          undefined,
          undefined,
          resourceGroups
            .filter(x => x.isSelected)
            .map(x => x.resourceGroupID)
            .join(",")
        )

        let data = JSON.parse(
          chartData.chartArray.replaceAll("'", '"').replaceAll(",]", "]")
        )
        var dataSet = anychart.data.set(data)

        var heatMapData = dataSet.mapAs({
          x: 0,
          y: 1,
          heat: 2,
          custom: 3,
          url: 4,
        })

        // Creates Heat Map
        var chart = anychart.heatMap(heatMapData)

        // Sets chart title
        chart.title("Resource Heat Map")

        // Sets selection mode for single selection.
        chart.interactivity().selectionMode("none")
        chart.labelsDisplayMode("drop")

        // Sets range for colorScale
        var colorScale = chart.colorScale()
        // Sets colors for all points
        colorScale.ranges([
          {
            from: -10000,
            to: -10,
            name: "Over Allocated",
            color: "#fa6b71",
          },
          {
            from: -10,
            to: 10,
            name: "At Capacity",
            color: "#69bce8",
          },
          {
            from: 10,
            to: 10000,
            name: "Available",
            color: "#3ba158",
          },
        ])
        chart.colorScale(colorScale)

        // Sets labels and maximum FontSize
        chart.labels().enabled(true).maxFontSize(14)

        // Sets chart settings and hover chart settings
        chart.stroke("#fff")
        chart
          .hovered()
          .stroke("6 #fff")
          .fill("#545f69")
          .labels({ fontColor: "#fff" })

        // Sets legend
        chart
          .legend()
          .enabled(true)
          .align("center")
          .position("center-top")
          .itemsLayout("horizontal")

        // Sets Axes TextFormatter
        chart.yAxis().stroke(null)
        chart
          .yAxis()
          .labels()
          .padding([0, 15, 0, 0])
          .format(function () {
            return this.tickValue
          })
        chart.xAxis().stroke(null)
        chart
          .xAxis()
          .labels()
          .format(function () {
            return this.tickValue
          })
        chart.yAxis().ticks(false)
        chart.xAxis().ticks(false)
        chart.tooltip().format(function () {
          var heat = this.heat
          if (heat < 0)
            return this.y + "- Over Allocated: " + this.getData("custom")
          if (heat > 0) return this.y + "- Available: " + this.getData("custom")
        })

        chart.xScroller().enabled(true)
        chart.xZoom().setToPointsCount(18)
        chart.yScroller().enabled(true)
        chart.yZoom().setToPointsCount(20)
        //add a listener
        chart.listen("pointClick", function (e) {
          var new_value = e.iterator.get("url")

          loadDrillDown(new_value)
        })
        chart.container("chartdiv")
        chart.credits().enabled(false)
        chart.draw()
      }
    }
    setIsChartLoading(false)
  }

  const loadDrillDown = async queryString => {
    toggleModal()
    const params = new Proxy(
      new URLSearchParams(`www.site.com${queryString}`),
      {
        get: (searchParams, prop) => searchParams.get(prop),
      }
    )
    let month = params.mid
    let year = params.yid
    let rgId = params.rgid

    let chartData = await api.getResourceDrilldown(
      1,
      startDate,
      endDate,
      scenarioId,
      alternatives
        .filter(x => x.isSelected)
        .map(x => x.alternativeID)
        .join(","),
      year,
      month,
      rgId
    )

    let data = JSON.parse(
      chartData.chartArray.replaceAll("'", '"').replaceAll(",]", "]")
    )

    var dataSet = anychart.data.set(data)

    var maxSet = anychart.data.set(data)

    var maxSeriesData = maxSet.mapAs({ x: 0, value: 1 })

    // helper function to setup label settings for all series
    var setupSeriesLabels = function (series, name) {
      series.name(name).stroke("3 #fa6b71 1")
      series.hovered().stroke("3 #fa6b71 1")
    }

    // create bar chart
    var chart = anychart.area()

    var alts = [
      JSON.parse(
        chartData.chartArray2.replaceAll("'", '"').replaceAll(",]", "]")
      ),
    ]

    var numseries = parseInt(chartData.chartData, 10)
    var j = 0

    for (var i = 0; i < numseries; i++) {
      j = i + 1

      chart.addSeries(
        dataSet.mapAs({
          x: 0,
          value: j + 1,
          //'tooltip': 4
        })
      )

      chart.getSeriesAt(i).name(alts[i])
    }

    // set X scale mode to continuous
    chart.xScale().mode("continuous")

    let series = chart.line(maxSeriesData)
    setupSeriesLabels(series, "Capacity")
    // turn on chart animation
    chart.animation(true)

    // force chart to stack values by Y scale.
    chart.yScale().stackMode("value")

    // set chart title text settings
    chart.title(chartData.chartTitle)
    chart.title().padding([0, 0, 5, 0])
    var xAxisLabels = chart.xAxis().labels()
    xAxisLabels.rotation(90)
    // turn on legend
    chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0])
    // set yAxis labels formatter
    chart.yAxis().labels().format("{%Value}{groupsSeparator: }")
    // set titles for axes
    chart.xAxis().title("Period")
    chart.yAxis().title("Units")
    var tooltip = chart.tooltip()
    tooltip.displayMode("single")
    // set interactivity hover
    chart.interactivity().hoverMode("bySpot")
    chart.interactivity().spotRadius(10)

    chart.xScroller().enabled(true)
    chart.xZoom().setToPointsCount(12)
    chart.container("drilldiv")
    chart.credits().enabled(false)
    chart.draw()
  }

  const changeAndSaveTempStartDate = async (alt, value) => {
    let date = moment(value, "YYYY-MM-DD").format("YYYY-MM-DDT00:00:00")
    changeAltProp(alt, "tempStartDate", date, false)
    await api.updateAlternative(currentUser.userID, {
      ...alt,
      tempStartDate: date,
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Start Date</Label>
                        {scenario !== null && (
                          <Input
                            name="startDate"
                            type="month"
                            onChange={e => {
                              setStartDate(
                                moment(e.target.value, "YYYY-MM").format(
                                  "YYYY-MM-DDT00:00:00"
                                )
                              )
                            }}
                            value={moment(startDate).format("YYYY-MM")}
                          />
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>End Date</Label>
                        {scenario !== null && (
                          <Input
                            name="endDate"
                            type="month"
                            onChange={e => {
                              setEndDate(
                                moment(e.target.value, "YYYY-MM").format(
                                  "YYYY-MM-DDT00:00:00"
                                )
                              )
                            }}
                            value={moment(endDate).format("YYYY-MM")}
                          />
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label style={{ marginBottom: "0px" }}>Chart</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={selectedChart}
                          onChange={e => {
                            setSelectedChart(e.target.value)
                            loadChart(e.target.value)
                          }}
                        >
                          <option value="0">Column Chart - Resources</option>
                          <option value="1">Area Chart - By Resource</option>
                          <option value="2">Area Chart - By Project</option>
                          <option value="3">
                            Area Chart - By Project (Approved Aggregated)
                          </option>
                          <option value="4">Heatmap</option>
                        </select>
                      </div>
                    </Col>
                    <Col md="3" style={{ display: "flex" }}>
                      <button
                        className="btn btn-primary"
                        style={{ alignSelf: "center" }}
                        onClick={() => loadChart(selectedChart)}
                      >
                        <i className="fas fa-chart-bar"></i> View / Refresh
                        Chart
                      </button>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          setAlternativesVisible(!alternativesVisible)
                        }
                      >
                        <i className="fas fa-exchange-alt"></i>{" "}
                        {alternativesVisible ? "Hide " : "Show "} Alternatives
                      </button>
                      <button
                        className="btn btn-primary save-user"
                        onClick={() => setClassesVisible(!classesVisible)}
                      >
                        <i className="fas fa-exchange-alt"></i>{" "}
                        {classesVisible ? "Hide " : "Show "} Resources
                      </button>
                      {alternativesVisible && (
                        <>
                          <button
                            className="btn  btn-primary save-user"
                            onClick={() => updateActualDates()}
                          >
                            <i className="fas fa-save"></i> Update Actual Start
                            Dates
                          </button>
                          <button
                            className="btn  btn-primary save-user"
                            onClick={() => resetDates()}
                          >
                            <i className="fas fa-clear"></i> Reset Dates
                          </button>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {alternativesVisible && (
                      <Col md="3">
                        <div className="mb-3">
                          <Label style={{ marginBottom: "0px" }}>Include</Label>
                          <select
                            className="form-control form-select select2 mb-xxl-0"
                            value={selectedDecision}
                            onChange={e => {
                              setSelectedDecision(e.target.value)
                            }}
                          >
                            <option value="-1">All</option>
                            {decisions.map((r, idx) => {
                              return (
                                <option key={idx} value={`${r.listItemID}`}>
                                  {r.listItemName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <table
                          className="table table-bordered lower-padding-table"
                          style={{ backgroundColor: "white" }}
                        >
                          <thead>
                            <tr style={{ fontSize: "12px" }}>
                              <th>
                                <Input
                                  type="checkbox"
                                  checked={allAltsSelected}
                                  onClick={() => {
                                    let copy = [...alternatives]
                                    copy.forEach(c => {
                                      c.isSelected = !allAltsSelected
                                    })
                                    setAlternatives(copy)
                                    setAllAltsSelected(!allAltsSelected)
                                  }}
                                />
                              </th>
                              <th>Business Need</th>
                              <th>Version</th>
                              <th>Start</th>
                            </tr>
                          </thead>
                          <tbody>
                            {alternatives
                              .filter(
                                x =>
                                  selectedDecision == "-1" ||
                                  x.decisionID == selectedDecision
                              )
                              .map((a, idx) => (
                                <tr key={idx}>
                                  <td>
                                    <Input
                                      type="checkbox"
                                      checked={a.isSelected}
                                      onClick={() => {
                                        changeAltProp(
                                          a,
                                          "isSelected",
                                          !a.isSelected
                                        )
                                      }}
                                    />
                                  </td>
                                  {/*                                   <td>
                                    {a.alternativeNum} - {a.alternativeName}
                                  </td> */}
                                  <td className="one-line-elipsis">
                                    <Tooltip
                                      title={
                                        <h6>
                                          {a.alternativeNum} -{" "}
                                          {a.alternativeName}{" "}
                                          {isNullOrEmpty(a.desiredStart)
                                            ? ""
                                            : moment(a.desiredStart).format(
                                                "MM/DD/YYYY"
                                              )}{" "}
                                          -{" "}
                                          {isNullOrEmpty(a.plannedFinish)
                                            ? ""
                                            : moment(a.plannedFinish).format(
                                                "MM/DD/YYYY"
                                              )}
                                        </h6>
                                      }
                                    >
                                      <span>
                                        <b>
                                          {a.alternativeNum} -{" "}
                                          {a.alternativeName}
                                        </b>
                                      </span>
                                    </Tooltip>
                                  </td>
                                  <td>
                                    <div
                                      style={{ textAlign: "center" }}
                                      dangerouslySetInnerHTML={{
                                        __html: a.version,
                                      }}
                                    ></div>
                                  </td>
                                  <td>
                                    <Input
                                      id={a.alternativeID}
                                      name={a.alternativeID}
                                      type="date"
                                      style={{
                                        backgroundColor:
                                          a.tempStartDate != a.desiredStart
                                            ? "lightcoral"
                                            : "white",
                                      }}
                                      onChange={e => {
                                        changeAndSaveTempStartDate(
                                          a,
                                          e.target.value
                                        )
                                      }}
                                      value={
                                        a.tempStartDate == null
                                          ? ""
                                          : moment(a.tempStartDate).format(
                                              "YYYY-MM-DD"
                                            )
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </Col>
                    )}
                    {classesVisible && (
                      <Col md="2">
                        <div className="mb-3">
                          <Label style={{ marginBottom: "0px" }}>
                            Show Resources
                          </Label>
                          <select
                            className="form-control form-select select2 mb-xxl-0"
                            value={selectedResourceClass}
                            onChange={e => {
                              setSelectedResourceClass(e.target.value)
                            }}
                          >
                            <option value="0">All</option>
                            {resourceClasses.map((r, idx) => {
                              return (
                                <option key={idx} value={`${r.listItemID}`}>
                                  {r.listItemName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <table
                          className="table table-bordered lower-padding-table"
                          style={{ backgroundColor: "white" }}
                        >
                          <thead>
                            <tr style={{ fontSize: "12px" }}>
                              <th>
                                <Input
                                  type="checkbox"
                                  checked={allClassesSelected}
                                  onClick={() => {
                                    let copy = [...resourceGroups]
                                    copy.forEach(c => {
                                      c.isSelected = !allClassesSelected
                                    })
                                    setResourceGroups(copy)
                                    setAllClassesSelected(!allClassesSelected)
                                  }}
                                />
                              </th>
                              <th>Resource</th>
                            </tr>
                          </thead>
                          <tbody>
                            {resourceGroups
                              .filter(
                                x =>
                                  selectedResourceClass <= 0 ||
                                  x.resourceClasses.find(
                                    rcl =>
                                      rcl.resourceClassID ==
                                      selectedResourceClass
                                  ) != undefined
                              )
                              .map((r, idx) => (
                                <tr key={idx}>
                                  <td>
                                    <Input
                                      type="checkbox"
                                      checked={r.isSelected}
                                      onClick={() => {
                                        changeResourceGroupProp(
                                          r,
                                          "isSelected",
                                          !r.isSelected
                                        )
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <b>{r.resourceGroupName}</b>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </Col>
                    )}
                    <Col
                      md={
                        alternativesVisible
                          ? classesVisible
                            ? "6"
                            : "9"
                          : classesVisible
                          ? "10"
                          : "12"
                      }
                    >
                      {isChartLoading && (
                        <Loader
                          type="line-scale-pulse-out"
                          color={colors.primary}
                          style={{ textAlign: "center" }}
                        />
                      )}
                      <div
                        id="chartdiv"
                        style={{
                          minHeight: "600px",
                          height: "100%",
                          width: "100%",
                        }}
                      ></div>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>

      <Modal
        backdrop="static"
        isOpen={isModalOpen}
        size="xl"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Drilldown
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div id="drilldiv" style={{ height: "600px" }}></div>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  )
}

export default ResourceCharts
