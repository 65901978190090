export const sortData = (data, prop, ascDesc) => {
  if (ascDesc === undefined || ascDesc === "asc") {
    return sortAsc(data, prop)
  } else {
    return sortDesc(data, prop)
  }
}

export const sortAsc = (data, prop) => {
  function compare(a, b) {
    const aValue = a[prop]
    const bValue = b[prop]

    if (!isNaN(parseFloat(aValue)) && !isNaN(parseFloat(bValue))) {
      return parseFloat(aValue) - parseFloat(bValue) // Numeric comparison for numbers
    }

    // Fallback to string comparison for other types
    if (aValue < bValue) {
      return -1
    }
    if (aValue > bValue) {
      return 1
    }
    return 0
  }

  return [...data].sort(compare)
}
export const sortDesc = (data, prop) => {
  function compare(a, b) {
    const aValue = a[prop]
    const bValue = b[prop]

    if (!isNaN(parseFloat(aValue)) && !isNaN(parseFloat(bValue))) {
      return parseFloat(bValue) - parseFloat(aValue) // Numeric comparison for numbers
    }

    // Fallback to string comparison for other types
    if (bValue < aValue) {
      return -1
    }
    if (bValue > aValue) {
      return 1
    }
    return 0
  }

  return [...data].sort(compare)
}
