import React from "react"
import { Col, Label } from "reactstrap"
import { TextareaAutosize } from "@material-ui/core"
import ChatGPTIconWithModal from "components/custom/chatGPTIconWithModal"

export const DynamicFormTextarea = React.memo(function dynamicFormTextarea({
  formFieldId,
  label,
  value,
  placeholder,
  changeFormFieldValue,
  rows,
  col,
  fullWidth,
  alwaysEditable,
  alwaysReadOnly,
  hasError,
  requiredMessage,
  itemId,
  useAi,
}) {
  return (
    <Col md={fullWidth ? "12" : col}>
      <div className="mb-3">
        <Label className="form-label">{label}</Label>
        {useAi && (
          <ChatGPTIconWithModal
            scope="FormField"
            itemId={itemId}
            field={formFieldId}
            appendToField={text =>
              changeFormFieldValue(formFieldId, value + "\n\n" + text)
            }
            replaceField={text => changeFormFieldValue(formFieldId, text)}
          />
        )}
        <TextareaAutosize
          minRows={rows || 3}
          className="form-control"
          placeholder={placeholder || ""}
          onChange={e => changeFormFieldValue(formFieldId, e.target.value)}
          value={value}
          disabled={alwaysReadOnly}
        />
        {hasError && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {requiredMessage}
          </div>
        )}
      </div>
    </Col>
  )
})
