import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import api from "services/api.service"
import colors from "utils/colors"
import { userService } from "services/user.service"
import { Popover } from "@material-ui/core"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import Constants from "utils/constants"
import OpenaiService from "services/openai.service"

const ChatGPTIconWithModal = ({
  scope,
  itemId,
  field,
  appendToField,
  replaceField,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [helpItem, setHelpItem] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [prompt, setPrompt] = useState("")
  const [response, setResponse] = useState("")
  const toggleModal = () => setShowModal(!showModal)
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  useEffect(() => {
    console.log(currentUser)
    if (showModal) {
      setResponse("")
    }
  }, [showModal])

  const loadData = async e => {
    if (scope.toLowerCase() == "formfield") {
      let r = await api.getAIPromptFormField(itemId, field)
      setPrompt(r)
    } else {
      let r = await api.getAIPrompt(scope, itemId, field)
      setPrompt(r)
    }
  }

  // const { Configuration, OpenAIApi } = require("openai")

  // const configuration = new Configuration({
  //   apiKey: Constants.OPENAI_API_KEY,
  // })

  // const openai = new OpenAIApi(configuration)

  const openaiService = new OpenaiService()

  const processAI = async () => {
    try {
      setIsLoading(true)

      let resp = await openaiService.askAssistantAsync(
        "In 2000 characters or less. " + prompt
      )
      setResponse(resp)
      // let r = await openai.createCompletion({
      //   model: "gpt-3.5-turbo-instruct",
      //   prompt: "In 2000 characters or less. " + prompt,
      //   temperature: 1,
      //   max_tokens: 625,
      //   top_p: 1,
      //   frequency_penalty: 0,
      //   presence_penalty: 0,
      // })

      // setResponse(r.data.choices[0].text.replace("\n\n", ""))
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  const replace = () => {
    replaceField(response)
    toggleModal()
  }
  const append = () => {
    appendToField(response)
    toggleModal()
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const handleChange = event => {
    setPrompt(event.target.value)
  }

  return (
    <React.Fragment>
      {currentUser.useAI && (
        <button
          // id="aibutton1"
          type="button"
          className="btn always-white"
          style={{ margin: "5px" }}
          onClick={() => {
            toggleModal()
            loadData()
          }}
        >
          <i className="mdi mdi-brain" style={{ fontSize: "18px" }} /> Ask AI
        </button>
      )}
      <Modal
        backdrop="static"
        isOpen={showModal}
        size="lg"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Ask AI
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col>
              <div style={{ marginBottom: "5px" }}>
                <Label className="form-label">Prompt</Label>
                <Input
                  name="desc"
                  type="textarea"
                  rows={5}
                  value={prompt}
                  onChange={handleChange}
                />
              </div>

              <div style={{ marginBottom: "15px" }}>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary save-user"
                  onClick={processAI}
                >
                  Ask AI
                </button>
              </div>
            </Col>
          </Row>

          {response != "" && (
            <Row>
              <Col>
                <div style={{ marginBottom: "5px" }}>
                  <Label className="form-label">Response</Label>
                  <Input
                    name="desc"
                    type="textarea"
                    rows={10}
                    value={response}
                  />
                </div>

                <div style={{ marginBottom: "15px" }}>
                  <button
                    type="button"
                    className="btn btn-primary save-user"
                    onClick={replace}
                  >
                    Replace Field
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary save-user"
                    onClick={append}
                  >
                    Append to Field
                  </button>
                </div>
              </Col>
            </Row>
          )}
        </div>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => {
              toggleModal()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ChatGPTIconWithModal
