import { TextareaAutosize } from "@material-ui/core"
import HelpIconWithModal from "components/custom/helpIconWithModal"
import ChatGPTIconWithModal from "components/custom/chatGPTIconWithModal"
import React from "react"
import { Col, Input, Label, Row } from "reactstrap"
import DynamicForm from "components/custom/DynamicForm/View/DynamicForm"
import api from "services/api.service"
import useFetcher from "hooks/useFetcher"

const OccApproachSection = ({
  activeTab,
  alternative,
  scenario,
  changeAlternativeProp,
  sectionFormId,
  showToastChangesCallback,
  triggerFormSave,
  setTriggerFormSave,
  systemRetirementsLabel,
  getPlaceholder,
  enterprise
}) => {
  if (activeTab != "OCC_Approach") {
    return null
  } else if (scenario?.approachTabFormID > 0) {
    return (
      <Row>
        <Col md="12">
          <DynamicForm
            formId={scenario.approachTabFormID}
            itemId={alternative?.alternativeID}
            scenarioId={alternative?.scenarioID}
            setTriggerFormSave={setTriggerFormSave}
            triggerFormSave={triggerFormSave}
            showToastChangesCallback={showToastChangesCallback}
            useAi={enterprise?.useAI ?? false}
          />
        </Col>
      </Row>
    )
  } else
    return (
      <React.Fragment>
        <Row>
          <Col sm="12">
            <div style={{ float: "right" }}>
              {/* <HelpIconWithModal itemId={2} /> */}
            </div>
            <div className="mb-3">
              <Label className="form-label">Proposed Solution</Label>
              <ChatGPTIconWithModal
                scope="Alternative"
                itemId={alternative.alternativeID}
                field="ProposedSolution"
                appendToField={text =>
                  changeAlternativeProp(
                    "proposedSolution",
                    alternative.customer + "\n\n" + text
                  )
                }
                replaceField={text =>
                  changeAlternativeProp("proposedSolution", text)
                }
              />
              <TextareaAutosize
                minRows={2}
                className="form-control"
                placeholder={getPlaceholder(
                  "ProposedSoultion",
                  "Describe the proposed approach to meeting the business requirements and satisfying the business need. Avoid the use of acronyms and technical jargon."
                )}
                onChange={e =>
                  changeAlternativeProp("proposedSolution", e.target.value)
                }
                value={alternative.proposedSolution}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Alternative Analysis Summary</Label>
              <ChatGPTIconWithModal
                scope="Alternative"
                itemId={alternative.alternativeID}
                field="AnalysisSummary"
                appendToField={text =>
                  changeAlternativeProp(
                    "analysisSummary",
                    alternative.customer + "\n\n" + text
                  )
                }
                replaceField={text =>
                  changeAlternativeProp("analysisSummary", text)
                }
              />
              <TextareaAutosize
                minRows={2}
                className="form-control"
                placeholder={getPlaceholder(
                  "AnalysisSummary",
                  "If an analysis of alternative solution approaches has been conducted, summarize the results. If none, state “None”."
                )}
                onChange={e =>
                  changeAlternativeProp("analysisSummary", e.target.value)
                }
                value={alternative.analysisSummary}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Deliverables</Label>
              <ChatGPTIconWithModal
                scope="Alternative"
                itemId={alternative.alternativeID}
                field="Deliverables"
                appendToField={text =>
                  changeAlternativeProp(
                    "deliverables",
                    alternative.customer + "\n\n" + text
                  )
                }
                replaceField={text =>
                  changeAlternativeProp("deliverables", text)
                }
              />
              <TextareaAutosize
                minRows={2}
                className="form-control"
                placeholder={getPlaceholder(
                  "Deliverables",
                  "Describe the business capabilities, at a high level, that will be delivered."
                )}
                onChange={e =>
                  changeAlternativeProp("deliverables", e.target.value)
                }
                value={alternative.deliverables}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Dependencies</Label>
              <ChatGPTIconWithModal
                scope="Alternative"
                itemId={alternative.alternativeID}
                field="Dependencies"
                appendToField={text =>
                  changeAlternativeProp(
                    "dependencies",
                    alternative.customer + "\n\n" + text
                  )
                }
                replaceField={text =>
                  changeAlternativeProp("dependencies", text)
                }
              />
              <TextareaAutosize
                minRows={2}
                className="form-control"
                placeholder={getPlaceholder(
                  "Dependencies",
                  "Describe the key internal and/or external dependencies, particularly those that are outside the control of the project."
                )}
                onChange={e =>
                  changeAlternativeProp("dependencies", e.target.value)
                }
                value={alternative.dependencies}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Delivery Risk</Label>
              <ChatGPTIconWithModal
                scope="Alternative"
                itemId={alternative.alternativeID}
                field="DeliveryRisk"
                appendToField={text =>
                  changeAlternativeProp(
                    "deliveryRisk",
                    alternative.customer + "\n\n" + text
                  )
                }
                replaceField={text =>
                  changeAlternativeProp("deliveryRisk", text)
                }
              />
              <TextareaAutosize
                minRows={2}
                className="form-control"
                placeholder={getPlaceholder(
                  "DeliveryRisk",
                  "Describe any significant risks that could impact the ability to successfully deliver the project, to include the scope, schedule, cost, or quality."
                )}
                onChange={e =>
                  changeAlternativeProp("deliveryRisk", e.target.value)
                }
                value={alternative.deliveryRisk}
              />
            </div>
            <div className="mb-3">
              <div className="d-flex-row ai-center">
                <Label className="form-label">{systemRetirementsLabel}</Label>
                <div className="form-check" style={{ marginLeft: "20px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="ff-hasSystemRetirements"
                    checked={alternative.hasSystemRetirements}
                    onClick={e => {
                      changeAlternativeProp(
                        "hasSystemRetirements",
                        !alternative.hasSystemRetirements
                      )
                    }}
                  />
                  <Label
                    className="form-check-label"
                    for="ff-hasSystemRetirements"
                  >
                    Check if applicable
                  </Label>
                </div>
              </div>
              <TextareaAutosize
                minRows={2}
                className="form-control"
                placeholder={getPlaceholder(
                  "SystemRetirements",
                  "Identify any systems, to include platforms, products, and applications, that will be retired after implementing this solution. The cost savings associated with these system retirements should be included as a benefit in the cost reduction section on the Forecast tab."
                )}
                onChange={e =>
                  changeAlternativeProp("systemRetirements", e.target.value)
                }
                value={alternative.systemRetirements}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Assumptions</Label>
              <TextareaAutosize
                minRows={2}
                className="form-control"
                placeholder={getPlaceholder(
                  "Assumptions",
                  "Describe the factors that are not currently in-place, but that are expected to be during the project. This could include events or circumstances that are expected to occur."
                )}
                onChange={e =>
                  changeAlternativeProp("assumptions", e.target.value)
                }
                value={alternative.assumptions}
              />
            </div>
            {/* <div className="mb-3">
            <Label className="form-label">Cost vs. Benefit Summary</Label>
            <Input
              name="altstrategicRationale"
              type="textarea"
              rows={3}
              onChange={e =>
                changeAlternativeProp("strategicRationale", e.target.value)
              }
              value={alternative.strategicRationale}
            />
          </div> */}
          </Col>
        </Row>
        {sectionFormId != 0 && activeTab == "OCC_Approach" && (
          <>
            <Row>
              <Col md="12">
                <div className="divider"></div>
                <DynamicForm
                  formId={sectionFormId}
                  itemId={alternative?.alternativeID}
                  scenarioId={alternative?.scenarioID}
                  setTriggerFormSave={setTriggerFormSave}
                  triggerFormSave={triggerFormSave}
                  showToastChangesCallback={showToastChangesCallback}
                  useAi={enterprise?.useAI ?? false}
                />
              </Col>
            </Row>
          </>
        )}
      </React.Fragment>
    )
}

export default OccApproachSection
