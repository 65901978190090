const { default: jwtDecode } = require("jwt-decode")

const msalHelper = {
  getUserEmail: azureIdToken => {
    let decoded = jwtDecode(azureIdToken)
  
    return decoded?.preferred_username?.toLowerCase() || ""
  }
}

export default msalHelper
