import React from "react"
import { useSelector } from "react-redux"
import { Row } from "reactstrap"

const Widgets = ({ widgets, marginTop, marginBottom }) => {
  const { showWidgets } = useSelector(state => ({
    showWidgets: state.appSettings.showWidgets,
  }))

  return (
    <React.Fragment>
      {widgets.length > 0 && showWidgets && (
        <Row
          className="mb-3"
          style={{
            marginTop: marginTop || "0px",
            marginBottom: marginBottom || "0px",
          }}
        >
          <div className="widget-container">
            {widgets.map((w, idx) => RenderWidget(w, idx))}
          </div>
        </Row>
      )}
    </React.Fragment>
  )
}

export const RenderWidget = (widget, idx) => {
  return (
    <div
      className="widgCard"
      key={idx}
      style={{
        minHeight:
          widget.height !== null && widget.height !== ""
            ? widget.height
            : "initial",
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: widget.title,
        }}
      ></div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{ verticalAlign: "top", paddingTop: "0px" }}
          dangerouslySetInnerHTML={{
            __html: widget.main,
          }}
        ></div>
        <div
          style={{ marginLeft: "10px" }}
          dangerouslySetInnerHTML={{
            __html: widget.secondary,
          }}
        ></div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: widget.footer,
        }}
      ></div>
    </div>
  )
}

export default Widgets
