import moment from "moment"
import isNullOrEmpty from "utils/isNullOrEmpty"

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const resourceService = {
  getGridMonthProps: referenceElement => {
    if (isNullOrEmpty(referenceElement)) {
      return []
    }

    return Object.keys(referenceElement)
      .filter(
        key => key.toLowerCase().startsWith("c-") && !isNaN(key.split("-")[1])
      )
      .sort((a, b) => {
        const numA = parseInt(a.split("-")[1], 10)
        const numB = parseInt(b.split("-")[1], 10)
        return numA - numB
      })
  },
  getMonthAndYearTextByMonthProp: (startDate, monthProp) => {
    if (isNullOrEmpty(monthProp)) {
      return ""
    }

    let month = parseInt(monthProp.split("-")[1]) // 1 - 12
    let date = moment(startDate).add(month - 1, "months")

    return `${monthNames[date.month()].substring(0, 3)} ${date.year()}`
  },
  isMonthPropInDateInterval: (startDate, monthProp, from, to) => {
    if (isNullOrEmpty(monthProp)) {
      return false
    }

    let month = parseInt(monthProp.split("-")[1]) // 1 - 12
    let monthDate = moment(startDate).add(month - 1, "months")

    return monthDate.isBetween(moment(from), moment(to))
  }
}

export default resourceService
