import { disableAll } from "components/custom/disableActions"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import SelectAlternativeModal from "pages/AdminAlternativeScore/SelectAlternativeModal"
import React, { useCallback, useEffect, useState } from "react"
import Widgets from "components/Widgets"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import {
  Alert,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap"
import api from "services/api.service"
import { useUserRight } from "services/role.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ContractSection from "./ContractSection"
import TaskDrawer from "./Drawer/TaskDrawer"
import DocumentDrawer from "./Drawer/DocumentDrawer"
import NoteDrawer from "./Drawer/NoteDrawer"
import ScoreDrawer from "./Drawer/ScoreDrawer"
import ForecastSection from "./ForecastSection"
import ForecastSummarySection from "./ForecastSummarySection"
import GeneralNeedSection from "./GeneralNeedSection"
import GovernanceSection from "./GovernanceSection"
import LocationSection from "./LocationSection"
import LookbackSection from "./LookbackSection"
import BusinessCaseReportModal from "./Modals/BusinessCaseReportModal"
import NmSection from "./NmSection"
import OccApproachSection from "./OccApproachSection"
import OccNeedAssesmentSection from "./OccNeedAssesmentSection"
import OccNeedSection from "./OccNeedSection"
import ProjectPlanningSection from "./ProjectPlanningSection"
import ResourceBudgetingSection from "./ResourceBudgetingSection"
import ResultSection from "./ResultSection"
import ScoringResultSection from "./ScoringResultSection"
import MinNeedSection from "./MinNeedSection"
import { TaskTypes } from "./Modals/EditTaskModal"
import ProjectPlanSection from "./ProjectPlanSection"
import useModal from "hooks/useModalHook"
import AlternativeCompareModal from "./Modals/AlternativeCompareModal"
import BaselineComparisonsModal from "./Modals/BaselineComparisonsModal"
import BenefitRealizationSection from "./BenefitRealizationSection"
import LogModal from "./Modals/LogModal"
import StageUncertaintyModal from "./Modals/StageUncertaintyModal"
import ProjectArtifactsModal from "components/custom/ProjectArtifactsModal"
import ProjectSummaryModal from "./Modals/ProjectSummaryModal"
import isNullOrEmpty from "utils/isNullOrEmpty"
import useFetcher from "hooks/useFetcher"
import FinancingSection from "./FinancingSection"
import { useAlternativeSignalR } from "signalR/AlternativeSignalRProvider"

const EditProject = props => {
  const currentUser = userService.getLoggedInUser()
  const [activeTab, setActiveTab] = useState("1")
  const [activeTabFormId, setActiveTabFormId] = useState(0)
  const [activeTabSectionFormId, setActiveTabSectionFormId] = useState(0)
  const [tabs, setTabs] = useState([])
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [scenarioId, setScenarioId] = useState(-1)
  const [alternativeId, setAlternativeId] = useState()
  const [systemRetirementsLabel, setSystemRetirementsLabel] = useState("")
  const [governanceBoardLabel, setGovernanceBoardLabel] = useState("")
  const [sbuLabel, setSbuLabel] = useState("")
  const [planningCategoryLabel, setPlanningCategoryLabel] = useState("")
  const [lbrLabel, setLbrLabel] = useState("")
  const [investmentCategoryLabel, setInvestmentCategoryLabel] = useState("")
  const [advancedPlanningLabel, setAdvancedPlanningLabel] = useState("")
  const [ldoLabel, setLdoLabel] = useState("")
  const [planningStageLabel, setPlanningStageLabel] = useState("")
  const [brmLabel, setBrmLabel] = useState("")
  const [scenario, setScenario] = useState(null)
  const [alternative, setAlternative] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [governanceOptions, setGovernanceOptions] = useState([])
  const [planningOptions, setPlanningOptions] = useState([])
  const [sponsoringOptions, setSponsoringOptions] = useState([])
  const [showRoadMap, setShowRoadMap] = useState(false)
  const [showAiSummary, setShowAiSummary] = useState(false)
  const [priorityOptions, setPriorityOptions] = useState([])
  const [alternatives, setAlternatives] = useState([])
  const [showActionsDropdown, setShowActionsDropdown] = useState(false)
  const [showAltModal, setShowAltModal] = useState(false)
  const [triggerForecastSectionSave, setTriggerForecastSectionSave] =
    useState(false)
  const [triggerContractSectionSave, setTriggerContractSectionSave] =
    useState(false)
  const [triggerFinanceSectionSave, setTriggerFinanceSectionSave] =
    useState(false)
  const [triggerNmSectionSave, setTriggerNmSectionSave] = useState(false)
  const [
    triggerProjectPlanningSectionReload,
    setTriggerProjectPlanningSectionReload,
  ] = useState(false)
  const [resourceBudgetSectionSave, setResourceBudgetSectionSave] =
    useState(false)
  const [projectPlanningSectionSave, setProjectPlanningSectionSave] =
    useState(false)
  const [activeDrawer, setActiveDrawer] = useState("")
  const [scoringScenarioId, setScoringScenarioId] = useState(-1)
  const [occAssesmentSectionSave, setOccAssesmentSectionSave] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)
  const toggleReportModal = () => setShowReportModal(!showReportModal)
  const {
    show: showProjectArtifactsModal,
    toggle: toggleProjectArtifactsModal,
  } = useModal()
  const { show: showProjectSummaryModal, toggle: toggleProjectSummaryModal } =
    useModal()

  const [tabPageItem, setTabPageItem] = useState(null)
  const [helpVisible, setHelpVisible] = useState(true)
  useUserRight(tabPageItem, props)

  const [occNeedErrorProps, setOccNeedErrorProps] = useState([])
  const [generalNeedErrorProps, setGeneralNeedErrorProps] = useState([])

  const [enterprise, setEnterprise] = useState(null)
  const [widgets, setWidgets] = useState([])
  const [entities, setEntities] = useState([])

  const {
    show: showAlternativeCompareModal,
    toggle: toggleAlternativeCompareModal,
  } = useModal()

  const {
    show: showBaselineComparisonModal,
    toggle: toggleBaselineComparisonModal,
  } = useModal()

  const { show: showLogModal, toggle: toggleLogModal } = useModal()
  const {
    show: showStageUncertaintyModal,
    toggle: toggleStageUncertaintyModal,
  } = useModal()

  const [triggerDynamicFormSave, setTriggerDynamicFormSave] = useState(false)
  const [hasProjectPlanError, setHasProjectPlanError] = useState(false)

  const [fieldConfigItems, setFieldConfigItems] = useState([])
  const [actionItems, setActionItems] = useState([])
  const [selectedActionItem, setSelectedActionItem] = useState(null)
  const [fieldHelpItems, setFieldHelpItems] = useState([])

  const { isConnected, alternativeUpdates, subscribe } = useAlternativeSignalR()
  const [
    alternativeMetadata,
    loadingAlternativeMetadata,
    loadAlternativeMetadata,
  ] = useFetcher(id => api.getAlternativeMetadata(id), [], true)

  useEffect(() => {
    console.log(props)
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: props.match.params.scenarioId,
        alternativeId: props.match.params.alternativeId,
        viewName: "editProject",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: props.match.params.scenarioId,
        viewName: "editProject",
      })
    )

    setScenarioId(props.match.params.scenarioId)
    setAlternativeId(props.match.params.alternativeId)
  }, [])

  useEffect(() => {
    if (!isNullOrEmpty(scenarioId)) {
      loadScenario()
      loadData()
      loadAlternatives()
    }
  }, [scenarioId])

  useEffect(() => {
    if (!isNullOrEmpty(alternativeId)) {
      loadAlternative()
      // loadTabInfo(alternativeId)
      loadActionItems()
      if (alternativeId > -1) {
        loadAlternativeMetadata(alternativeId)
      }
    }
  }, [alternativeId])

  useEffect(() => {
    if (isConnected && alternativeId > -1) {
      subscribe(`Alternative-${alternativeId}`)
    }
  }, [isConnected, alternativeId])

  useEffect(() => {
    if (activeTab !== "" && !isNullOrEmpty(alternativeId)) {
      toast.dismiss()
      loadTabInfo(alternativeId)
      setHelpVisible(true)
    }
  }, [activeTab])

  useEffect(() => {
    if (alternativeUpdates.length > 0) {
      if (alternativeId > -1) loadAlternativeMetadata(alternativeId)
    }
  }, [alternativeUpdates])

  const loadEnterprise = async enterpriseId => {
    let e = await api.getEnterprise(enterpriseId)
    setEnterprise(e)
  }

  const loadWidgets = async () => {
    let w = await api.getWidgetsByView("EditProject", alternativeId)
    setWidgets(w)
  }

  const loadTabInfo = async altId => {
    let data = await api.getPageItem(
      currentUser.userID,
      scenarioId,
      altId,
      activeTab
    )

    setTabPageItem(data)
    if (altId > -1) {
      let userRight = data.userRight.toLowerCase()

      if (userRight == "full" || userRight == "full_np") {
        let div = document.getElementById("main-div")
        div.classList.remove("read-only-access")
      } else if (userRight == "read") {
        let div = document.getElementById("main-div")
        div.classList.add("read-only-access")
      } else if ("none") {
        props.history.push("/noAccess")
      }
    }
  }

  const loadData = async () => {
    setIsLoading(true)
    api.getEntitiesByParent(currentUser.userID, 0).then(e => {
      setEntities(e)
    })
    if (scenarioId > -1) {
      await loadGeneralDropdownData()
      await loadAlternativeTabs()
      await loadFieldConfigItems()
    }
    setIsLoading(false)
  }

  const loadActionItems = async () => {
    let a = await api.getActionItems(
      "Alternative",
      alternativeId,
      currentUser.userID
    )
    setActionItems(a)
  }

  const loadFieldConfigItems = async () => {
    let f = await api.getFieldConfigItems(scenarioId)
    setFieldConfigItems(f)
  }

  const loadScenario = async () => {
    if (scenarioId > -1) {
      try {
        let s = await api.getScenario(scenarioId)
        setScenario(s)

        let f = await api.getFieldHelp(s.enterpriseID)
        setFieldHelpItems(f)

        await loadLabels(s)

        await loadEnterprise(s.enterpriseID)
      } catch (err) {
        console.log(err)
      }
    } else {
      setScenario({ scenarioID: -1 })
    }
  }

  const loadLabels = async s => {
    const sr = await api.getEnterpriseLabel(
      s.enterpriseID,
      "system retirements"
    )
    setSystemRetirementsLabel(sr)
    const gb = await api.getEnterpriseLabel(s.enterpriseID, "Governance Board")
    setGovernanceBoardLabel(gb)
    const sbu = await api.getEnterpriseLabel(
      s.enterpriseID,
      "Sponsoring business unit"
    )
    setSbuLabel(sbu)

    const pc = await api.getEnterpriseLabel(s.enterpriseID, "Planning Category")
    setPlanningCategoryLabel(pc)
    const lbr = await api.getEnterpriseLabel(
      s.enterpriseID,
      "Lead Business Representative"
    )
    setLbrLabel(lbr)
    const ic = await api.getEnterpriseLabel(
      s.enterpriseID,
      "Investment Category"
    )
    setInvestmentCategoryLabel(ic)

    const ap = await api.getEnterpriseLabel(s.enterpriseID, "Advanced Planning")
    setAdvancedPlanningLabel(ap)
    const brm = await api.getEnterpriseLabel(
      s.enterpriseID,
      "Business Relationship Manager"
    )
    setBrmLabel(brm)
    const ldo = await api.getEnterpriseLabel(
      s.enterpriseID,
      "Lead Delivery Organization"
    )
    setLdoLabel(ldo)
    const ps = await api.getEnterpriseLabel(s.enterpriseID, "Planning Stage")
    setPlanningStageLabel(ps)
  }

  const loadAlternative = async () => {
    if (alternativeId > -1) {
      try {
        let a = await api.getAlternative(alternativeId)
        setAlternative(a)
      } catch (err) {
        console.log(err)
      }
    } else {
      setAlternative({ alternativeID: -1, scenarioID: scenarioId })
    }
    await loadWidgets()
  }

  const loadAlternatives = async () => {
    if (scenarioId > -1) {
      try {
        let alts = await api.getAlternatives(currentUser.userID, scenarioId)
        setAlternatives(alts)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const loadAlternativeTabs = async () => {
    try {
      let t = await api.getAlternativeTabs(
        currentUser.userID,
        scenarioId,
        alternativeId
      )
      let firstNeedTab = t.find(
        x => x.displayAs == "tab" && x.tabType == "Need"
      )
      setActiveTabFormId(firstNeedTab?.formID)
      setActiveTabSectionFormId(firstNeedTab?.sectionFormID)
      setActiveTab(firstNeedTab?.tabView)
      setTabs(t.filter(x => x.displayAs == "tab"))
    } catch (err) {
      console.log(err)
    }
  }

  const loadGeneralDropdownData = async () => {
    api.getScenarioList(scenarioId, "GovernanceBoard").then(g => {
      setGovernanceOptions(g)
    })
    api.getScenarioList(scenarioId, "PlanningCategory").then(p => {
      setPlanningOptions(p)
    })
    api.getScenarioList(scenarioId, "SponsoringUnit").then(su => {
      setSponsoringOptions(su)
    })
    api.getList(currentUser.userID, "Priorities").then(pr => {
      setPriorityOptions(pr)
    })
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTabFormId(tab?.formID)
      setActiveTabSectionFormId(tab?.sectionFormID)
      setActiveTab(tab?.tabView)
    }
  }

  const promoteIdeaToAlternative = async () => {
    await api.updateAlternative(currentUser.userID, {
      ...alternative,
      ideaOnly: false,
      limitAccess: false,
    })
    loadAlternative()
    loadAlternativeTabs()
  }

  const saveAlternative = async () => {
    try {
      toast.dismiss()
      setHasProjectPlanError(false)
      if (activeTab == "OCC_Need") {
        let errorProps = []
        if (
          alternative.strategicRationale == undefined ||
          alternative.strategicRationale == null ||
          alternative.strategicRationale == ""
        )
          errorProps.push("strategicRationale")
        if (
          alternative.businessRisk == undefined ||
          alternative.businessRisk == null ||
          alternative.businessRisk == ""
        )
          errorProps.push("businessRisk")
        if (
          alternative.businessOutcomes == undefined ||
          alternative.businessOutcomes == null ||
          alternative.businessOutcomes == ""
        )
          errorProps.push("businessOutcomes")
        if (
          alternative.businessRequirements == undefined ||
          alternative.businessRequirements == null ||
          alternative.businessRequirements == ""
        )
          errorProps.push("businessRequirements")
        if (
          alternative.businessProblem == undefined ||
          alternative.businessProblem == null ||
          alternative.businessProblem == ""
        )
          errorProps.push("businessProblem")
        if (
          alternative.customer == undefined ||
          alternative.customer == null ||
          alternative.customer == ""
        )
          errorProps.push("customer")
        if (
          alternative.description == undefined ||
          alternative.description == null ||
          alternative.description == ""
        )
          errorProps.push("description")
        if (
          alternative.alternativeName == undefined ||
          alternative.alternativeName == null ||
          alternative.alternativeName == ""
        )
          errorProps.push("alternativeName")
        if (
          alternative.sponsoringUnitID == undefined ||
          alternative.sponsoringUnitID == null ||
          alternative.sponsoringUnitID < 0
        )
          errorProps.push("sponsoringUnitID")
        if (
          alternative.businessPriority == undefined ||
          alternative.businessPriority == null ||
          alternative.businessPriority < 0
        )
          errorProps.push("businessPriority")
        if (
          alternative.governanceBoardID == undefined ||
          alternative.governanceBoardID == null ||
          alternative.governanceBoardID < 0
        )
          errorProps.push("governanceBoardID")

        setOccNeedErrorProps(errorProps)
        if (errorProps.length > 0) {
          toast.error("Some Required fields are missing")
          return
        }
      }
      if (activeTab == "General_Need") {
        if (activeTabFormId && activeTabFormId > 0) {
          setTriggerDynamicFormSave(true)
        } else {
          let errorProps = []
          if (
            alternative.alternativeName == undefined ||
            alternative.alternativeName == null ||
            alternative.alternativeName == ""
          )
            errorProps.push("alternativeName")
          if (
            alternative.description == undefined ||
            alternative.description == null ||
            alternative.description == ""
          )
            errorProps.push("description")
          if (
            entities.length > 0 &&
            (alternative.entityID == undefined ||
              alternative.entityID == null ||
              alternative.entityID <= 0)
          )
            errorProps.push("entityID")

          setGeneralNeedErrorProps(errorProps)
          if (errorProps.length > 0) {
            toast.error("Some Required fields are missing")
            return
          }
        }
      }

      if (
        activeTab == "ProjectPlan" &&
        isNullOrEmpty(alternative.projectHealthComments)
      ) {
        let props = [
          "projectScope",
          "projectSchedule",
          "projectCost",
          "projectQuality",
          "projectStaffing",
        ]
        let propWithRedOrYellowRating = props.find(
          x => alternative[x] == 1 || alternative[x] == 2
        )
        if (propWithRedOrYellowRating != undefined) {
          toast.error(
            "Please provide the cause and plan to address each yellow or red rating."
          )
          setHasProjectPlanError(true)
          return
        }
      }

      if (activeTabSectionFormId > 0) {
        setTriggerDynamicFormSave(true)
      }

      if (alternative.isDirty && alternative.alternativeID > -1) {
        if (alternativeId == -1) {
          await api.updateAlternative(currentUser.userID, alternative)
          toast.success(`Project updated successfully`)
          props.history.goBack()
        } else {
          let retId = await api.updateAlternative(
            currentUser.userID,
            alternative
          )
          setTriggerProjectPlanningSectionReload(true)

          if (retId == 2) {
            toast.success(
              `Project updated successfully, business rules were triggered.`
            )
            loadAlternative()
          } else {
            toast.success(`Project updated successfully`)
            setAlternative({ ...alternative, isDirty: false })
          }
        }
        if (showRoadMap) {
          loadChart2()
        }
      }

      if (
        alternative.alternativeID == -1 &&
        (alternative.isDirty == true || alternative.isDirty == undefined)
      ) {
        await handleAlternativeCreate()
      }

      if (activeTab == "Forecast") {
        setTriggerForecastSectionSave(true)
      }

      if (activeTab == "Contract") {
        setTriggerContractSectionSave(true)
      }

      if (activeTab == "ResourceBudget") {
        setResourceBudgetSectionSave(true)
      }

      if (activeTab == "OCC_Assessment") {
        setOccAssesmentSectionSave(true)
      }

      if (activeTab == "NM_Need") {
        setTriggerNmSectionSave(true)
      }

      if (activeTab == "ResourcePlan") {
        setProjectPlanningSectionSave(true)
      }

      if (activeTab == "Finance") {
        setTriggerFinanceSectionSave(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleAlternativeCreate = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Do you want to submit a ${scenario.alternativeName.toLowerCase()} or do you want to create an idea that only you can see and edit?
      Later, you can promote the idea to a ${scenario.alternativeName.toLowerCase()}.`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: `Create ${scenario.alternativeName}`,
        confirmButtonText: "Idea for now",
        denyButtonText: "Cancel",
        reverseButtons: true,
        buttonsStyling: true,
        customClass: {
          confirmButton: "swal2-cancel btn btn-outline-secondary normal-button",
          cancelButton: "swal2-cancel btn btn-outline-secondary normal-button",
          denyButton: "swal2-cancel btn btn-outline-secondary normal-button",
        },
      })
      .then(async result => {
        if (result.isConfirmed) {
          let altId = await api.createAlternative(currentUser.userID, {
            ...alternative,
            scenarioID: scenarioId,
            ideaOnly: true,
            alternativeName:
              alternative.alternativeName == undefined
                ? "Project"
                : alternative.alternativeName,
          })
          setAlternative({
            ...alternative,
            isDirty: false,
            alternativeID: altId,
          })
          toast.success(`Project created successfully`)
          setTimeout(() => {
            props.history.goBack()
          }, 1000)
        } else {
          let altId = await api.createAlternative(currentUser.userID, {
            ...alternative,
            scenarioID: scenarioId,
            alternativeName:
              alternative.alternativeName == undefined
                ? "Project"
                : alternative.alternativeName,
          })

          if (activeTab == "OCC_Need") {
            let alt = await api.getAlternative(altId)
            setAlternativeId(altId)
            setAlternative({ ...alt, isDirty: false })
            setActiveTabFormId(
              tabs.find(x => x.tabView == "OCC_Assessment")?.formID
            )
            setActiveTab("OCC_Assessment")
          } else {
            setAlternative({
              ...alternative,
              alternativeID: altId,
              isDirty: false,
            })
            toast.success(`Project created successfully`)
            setTimeout(() => {
              props.history.goBack()
            }, 1000)
          }
        }
      })
  }

  const deleteAlternative = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this project?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteAlternative(alternativeId)
          cancel()
        }
      })
  }

  const cancel = () => {
    toast.dismiss()
    props.history.goBack()
  }

  const changeAlternativeProp = (altProp, value, silent) => {
    if (Array.isArray(altProp)) {
      let alt = { ...alternative }
      for (let i = 0; i < altProp.length; i++) {
        alt[altProp[i]] = value[i]
      }
      alt.isDirty = silent === true ? false : true
      setAlternative(alt)
    } else {
      setAlternative({
        ...alternative,
        isDirty: silent === true ? false : true,
        [altProp]: value,
      })
    }
    setOccNeedErrorProps([])
    setGeneralNeedErrorProps([])

    if (silent !== true) showToastChanges()
  }

  const showToastChanges = () => {
    toast.success(
      <div>
        <div className="mb-0">
          You have unsaved changes, don't forget to save them.
        </div>
        <div style={{ textAlign: "center" }}>
          <button
            className="btn btn-primary"
            //style={{ width: "100%" }}
            onClick={() => {
              document.getElementById("saveAlternative").click()
            }}
          >
            <i className="fas fa-save"></i> Save
          </button>
        </div>
      </div>,
      {
        toastId: "unsavedChanges",
        autoClose: false,
      }
    )
  }

  const showToastChangesCallback = useCallback(() => {
    toast.success(
      <div>
        <div className="mb-0">
          You have unsaved changes, don't forget to save them.
        </div>
        <div style={{ textAlign: "center" }}>
          <button
            className="btn btn-primary"
            //style={{ width: "100%" }}
            onClick={() => {
              document.getElementById("saveAlternative").click()
            }}
          >
            <i className="fas fa-save"></i> Save
          </button>
        </div>
      </div>,
      {
        toastId: "unsavedChanges",
        autoClose: false,
      }
    )
  }, [])

  const selectAlternative = altId => {
    setAlternativeId(altId)
    props.history.replace({
      pathname: `/editProject/${scenarioId}/${altId}`,
    })

    //loadAlternativeTabs()
    //loadTabInfo()
  }

  const toggleAltModal = () => {
    setShowAltModal(!showAltModal)
  }

  const toggleLock = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to ${
          !alternative.locked ? "lock" : "unlock"
        } this project?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.lockAlternative(
            alternative.alternativeID,
            !alternative.locked ? "lock" : "unlock"
          )
          toast.success(
            `Project ${!alternative.locked ? "locked" : "unlocked"}`
          )
          loadAlternative()
          loadTabInfo(alternativeId)
        }
      })
  }

  const showHelpBanner = async () => {
    setHelpVisible(true)
    await api.setHelpStatus(currentUser.userID, activeTab, "show")
    loadTabInfo(alternativeId)
  }

  const onHelpDismiss = async () => {
    setHelpVisible(false)
    await api.setHelpStatus(currentUser.userID, activeTab, "hide")
    loadTabInfo(alternativeId)
  }

  const loadChart2 = async () => {
    let altIds = alternative.alternativeID
    api.getAlternativeListChart(altIds, "BCD_Milestone_Task", 40).then(resp => {
      renderGanttChart2(resp, "gantt")
    })
  }

  const renderGanttChart2 = (response, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    var data = JSON.parse(json)
    var treeData = anychart.data.tree(data, "as-tree")
    var chart = anychart.ganttProject()
    chart.data(treeData)
    var ganttDiv = document.getElementById(divId)
    var items = data.length
    //var gh = 100 + 95 * items
    var gh = 200
    ganttDiv.style.height = gh + "px"

    var newColumn = chart.dataGrid().column(0)
    newColumn.width(50)
    newColumn.title("ID")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().useHtml(true)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("<div style='text-align: center'>{%id}</div>")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    var newColumn = chart.dataGrid().column(1)
    newColumn.width(400)
    newColumn.title("Name")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("{%name}")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    var newColumn = chart.dataGrid().column(2)
    newColumn.width(250)
    newColumn.title(planningCategoryLabel)
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("{%planningcategory}")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    var newColumn = chart.dataGrid().column(3)
    newColumn.width(150)
    newColumn.title("Project Manager")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("{%projectmanager}")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    var newColumn = chart.dataGrid().column(4)
    newColumn.width(150)
    newColumn.title("BRM")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("{%brm}")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    var newColumn = chart.dataGrid().column(5)
    newColumn.width(90)
    newColumn.title("Start")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().useHtml(true)
    newColumn.labels().fontColor("#000000")
    newColumn
      .labels()
      .format(
        "<div style='text-align: center'>{%actualStart}{dateTimeFormat:MM/dd/yyyy}</div>"
      )
    newColumn.depthPaddingMultiplier(20)
    newColumn.collapseExpandButtons(true)

    var newColumn2 = chart.dataGrid().column(6)
    newColumn2.width(90)
    newColumn2.title("Finish")
    newColumn2.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn2.title().fontWeight(600)
    newColumn2.labels().useHtml(true)
    newColumn2.labels().fontColor("#000000")
    newColumn2
      .labels()
      .format(
        "<div style='text-align: center'>{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</div>"
      )
    newColumn2.depthPaddingMultiplier(20)
    newColumn2.collapseExpandButtons(true)

    var dataGrid = chart.dataGrid()
    var dgTooltip = dataGrid.tooltip()
    dgTooltip.useHtml(true)
    /*     dgTooltip.format(
      "<span>" +
        "{%actualStart}{dateTimeFormat:MM/dd/yyyy} – " +
        "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
        "% Complete: {%progress}<br><br>" 
    ) */
    chart.getTimeline().tooltip().useHtml(true)
    chart.getTimeline().tooltip()
    /*       .format(
        "<span>" +
          "{%actualStart}{dateTimeFormat:MM/dd/yyyy} – " +
          "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
          "% Complete: {%progress}<br><br>"
      ) */
    var header = chart.getTimeline().header()
    var mlevel = header.level(2)

    chart
      .dataGrid()
      .tooltip()
      .format(function () {
        var duration = (this.actualEnd - this.actualStart) / 1000 / 3600 / 24
        var startDate = anychart.format.dateTime(this.actualStart, "dd MMM")
        var endDate = anychart.format.dateTime(this.actualEnd, "dd MMM")
        var progress = Math.round(this.progress * 100 * 100) / 100 + "%"

        var parentText =
          "<span>" +
          startDate +
          " - " +
          endDate +
          "<br><br>" +
          "% Complete: " +
          progress +
          "</span>"

        var milestoneText = "<span>" + startDate + "</span>"

        // identify the task type and display the corresponding text
        if (duration == 0) {
          return milestoneText
        } else {
          return parentText
        }
      })

    chart
      .getTimeline()
      .tooltip()
      .format(function () {
        var duration = (this.actualEnd - this.actualStart) / 1000 / 3600 / 24
        var startDate = anychart.format.dateTime(
          this.actualStart,
          "dd MMM yyyy"
        )
        var endDate = anychart.format.dateTime(this.actualEnd, "dd MMM yyyy")
        var progress = Math.round(this.progress * 100 * 100) / 100 + "%"

        var parentText =
          "<span>" +
          startDate +
          " - " +
          endDate +
          "<br><br>" +
          "% Complete: " +
          progress +
          "</span>"

        var milestoneText = "<span>" + startDate + "</span>"

        // identify the task type and display the corresponding text
        if (duration == 0) {
          return milestoneText
        } else {
          return parentText
        }
      })

    chart.rowStroke("0.2 #64b5f6")
    chart.columnStroke("0.2 #64b5f6")

    chart.dataGrid().column(1).depthPaddingMultiplier(20)
    //mlevel.enabled(false);
    // set zoom levels of the scale
    chart
      .getTimeline()
      .scale()
      .zoomLevels([
        [
          { unit: "month", count: 1 },
          { unit: "quarter", count: 1 },
          { unit: "year", count: 1 },
        ],
      ])
    // header.level(0).format("{%tickValue}{dateTimeFormat:MMM}");

    if (scenario.fyMonth > 6) {
      var header = chart.getTimeline().header()
      header.level(2).format(function () {
        return +this.value + 1
      })
    }

    chart.rowStroke("0.2 #64b5f6")
    chart.columnStroke("0.2 #64b5f6")
    const thisdate = new Date()
    var year = thisdate.getFullYear()
    var month = thisdate.getMonth()
    var day = thisdate.getDate()

    chart.defaultRowHeight(25)
    chart.xScale().fiscalYearStartMonth(scenario.fyMonth)
    chart.container(divId)
    // initiate drawing the chart
    chart.draw()
    chart.credits().enabled(false)
    chart.dataGrid(false)
    chart.fitAll()
    // chart.zoomTo(Date.UTC(year, 1, 1), Date.UTC(year + 2, 11, 30));
  }

  const showScoringForScenario = scenarioId => {
    setScoringScenarioId(scenarioId)
    setActiveDrawer("Score")
  }

  const getPlaceholder = (prop, defaultPlaceholder) => {
    let exists = fieldHelpItems.find(x => x.fieldName == prop)
    if (exists != undefined) {
      return isNullOrEmpty(exists.placeholderText)
        ? defaultPlaceholder
        : exists.placeholderText
    }

    return defaultPlaceholder
  }

  // const SignalRContext = createSignalRContext({
  //   shareConnectionBetweenTab: true,
  // });

  return (
    <React.Fragment>
      <div id="main-div" className="page-content">
        <Container fluid>
          {/* <Breadcrumbs
            title={alternative != null ? alternative.alternativeName : ""}
          /> */}
          {scenarioId > 0 && <Widgets widgets={widgets} marginTop="0px" />}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {props.location.state != undefined &&
                          props.location.state.fromList && (
                            <button
                              type="button"
                              className="btn btn-primary always-enabled"
                              style={{ marginRight: "10px" }}
                              onClick={cancel}
                            >
                              <i className="fas fa-chevron-left"></i>
                              {"  "}
                              Back to List
                            </button>
                          )}
                        {props.location.state != undefined &&
                          props.location.state.fromDashboard && (
                            <button
                              type="button"
                              className="btn btn-primary always-enabled"
                              style={{ marginRight: "10px" }}
                              onClick={cancel}
                            >
                              <i className="fas fa-chevron-left"></i>
                              {"  "}
                              Back to Dashboard
                            </button>
                          )}
                        <div
                          className="page-title-box"
                          style={{
                            paddingBottom: "0px",
                            alignSelf: "center",
                            marginRight: "10px",
                          }}
                        >
                          {alternative != null && (
                            <h4 className="mb-sm-0 font-size-18">
                              {alternative.alternativeNum}
                              {alternative.alternativeName != null &&
                                " - " + alternative.alternativeName}{" "}
                              {alternative.inRevision && (
                                <span style={{ color: "red" }}>Baseline</span>
                              )}
                              {alternative.revisionAltID > 0 && (
                                <span style={{ color: "red" }}>Revision</span>
                              )}
                              {alternative.isRollup && <span>(Rollup)</span>}
                            </h4>
                          )}

                          {alternative != null &&
                            (alternative.locked ||
                              alternative.inRevision == 1) && (
                              <p style={{ marginBottom: "5px", color: "red" }}>
                                {alternative.locked && (
                                  <i className="fas fa-lock"></i>
                                )}{" "}
                                {alternative.lockMessage}
                              </p>
                            )}
                        </div>

                        {alternative != undefined &&
                          alternative != null &&
                          alternative.isRollup &&
                          alternative.rollupScenarioID != undefined &&
                          alternative.rollupScenarioID != null && (
                            <UncontrolledDropdown
                              className="me-2"
                              direction="up"
                            >
                              <DropdownToggle caret color="primary">
                                <i className="fas fa-external-link-square-alt" />
                                &nbsp; Open Rollup Portfolio
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() =>
                                    props.history.push({
                                      pathname: `/projects/${alternative.rollupScenarioID}`,
                                    })
                                  }
                                >
                                  Open in this tab
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    window.open(
                                      `/projects/${alternative.rollupScenarioID}`,
                                      "_blank",
                                      "rel=noopener noreferrer"
                                    )
                                  }
                                >
                                  Open in a new tab
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                      </div>

                      <div>
                        <button
                          id="saveAlternative"
                          type="button"
                          className="btn btn-primary save-user"
                          onClick={saveAlternative}
                        >
                          <i className="fas fa-save"></i>
                          {alternativeId > -1 ? " Update" : " Save"}
                        </button>
                        {scenario !== null &&
                          alternative != null &&
                          alternative.ideaOnly && (
                            <button
                              type="button"
                              className="btn btn-primary save-user"
                              onClick={promoteIdeaToAlternative}
                            >
                              Promote to {scenario.alternativeName}
                            </button>
                          )}
                        <button
                          type="button"
                          className="btn btn-outline-secondary save-user"
                          onClick={cancel}
                        >
                          Cancel
                        </button>
                        {!isNullOrEmpty(alternative) &&
                          alternativeId > -1 &&
                          currentUser.roleID != 3 && (
                            <Dropdown
                              direction="start"
                              style={{ display: "inline-block" }}
                              // className=""
                              isOpen={showActionsDropdown}
                              toggle={() =>
                                setShowActionsDropdown(!showActionsDropdown)
                              }
                            >
                              <DropdownToggle
                                tag="button"
                                className="btn btn-outline-primary save-user always-enabled"
                              >
                                Actions <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>

                              <DropdownMenu>
                                <DropdownItem
                                  className="always-enabled"
                                  onClick={() => setActiveDrawer("Note")}
                                >
                                  <div className="d-flex-row ai-center gap-1">
                                    <i className="mdi mdi-chat-outline"></i>
                                    <span>Comments</span>
                                    <span
                                      className="badge bg-danger rounded-pill"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      {alternativeMetadata?.comments}
                                    </span>
                                  </div>
                                </DropdownItem>

                                {currentUser.roleID != 2 &&
                                  currentUser.roleID != 3 && (
                                    <DropdownItem
                                      className="always-enabled"
                                      onClick={() =>
                                        toggleBaselineComparisonModal()
                                      }
                                    >
                                      <i className="mdi mdi-compare-horizontal"></i>
                                      Compare to
                                    </DropdownItem>
                                  )}

                                <DropdownItem
                                  className="always-enabled"
                                  onClick={() => {
                                    let bcIdBase64 = btoa(
                                      alternative.businessCaseID
                                    )
                                    let txt =
                                      window.location.href.replace(
                                        window.location.pathname,
                                        ""
                                      ) +
                                      "/login.aspx?bc=" +
                                      bcIdBase64

                                    navigator.clipboard.writeText(txt)
                                    toast.success("Link copied to clipboard")
                                  }}
                                >
                                  <i className="fas fa-link"></i> Copy Link
                                </DropdownItem>

                                {(currentUser.roleID > 8 ||
                                  alternative.pmUserID == currentUser.userID ||
                                  alternative.brmUserID ==
                                    currentUser.userID) && (
                                  <DropdownItem
                                    className="always-enabled"
                                    onClick={toggleProjectArtifactsModal}
                                  >
                                    <i className="fas fa-file-word"></i> Create
                                    Project Artifacts
                                  </DropdownItem>
                                )}

                                {(currentUser.roleID > 5 ||
                                  alternative.ideaOnly) && (
                                  <DropdownItem onClick={deleteAlternative}>
                                    <i className="mdi mdi-trash-can-outline"></i>{" "}
                                    Delete
                                  </DropdownItem>
                                )}

                                {currentUser.roleID != 2 && (
                                  <DropdownItem
                                    className="always-enabled"
                                    onClick={() => setActiveDrawer("Document")}
                                  >
                                    <div className="d-flex-row ai-center gap-1">
                                      <i className="mdi mdi-file-document-multiple-outline"></i>{" "}
                                      <span>Documents and Links</span>
                                      <span
                                        className="badge bg-danger rounded-pill"
                                        style={{ marginLeft: "10px" }}
                                      >
                                        {alternativeMetadata?.documents}
                                      </span>
                                    </div>
                                  </DropdownItem>
                                )}

                                <DropdownItem
                                  className="always-enabled"
                                  onClick={toggleAltModal}
                                >
                                  <i className="mdi mdi-arrow-top-right-bold-outline"></i>{" "}
                                  Go To
                                </DropdownItem>

                                {currentUser.roleID > 5 && (
                                  <DropdownItem
                                    className="always-enabled"
                                    onClick={toggleLock}
                                  >
                                    <i className="mdi mdi-lock"></i>{" "}
                                    {alternative && alternative.locked
                                      ? "Unlock Business Case"
                                      : "Lock Business Case"}
                                  </DropdownItem>
                                )}

                                <DropdownItem
                                  className="always-enabled"
                                  onClick={toggleReportModal}
                                >
                                  <i className="fas fa-file-pdf"></i> Run
                                  Business Case Report
                                </DropdownItem>

                                {currentUser.roleID > 0 &&
                                  pageItem &&
                                  pageItem.userAltScoring && (
                                    <DropdownItem
                                      className="always-enabled"
                                      onClick={() => {
                                        showScoringForScenario(
                                          scenario.scenarioID
                                        )
                                      }}
                                    >
                                      <i className="mdi mdi-bullseye"></i> Score{" "}
                                      {scenario.alternativeName}{" "}
                                      {scenario.scoreName}
                                    </DropdownItem>
                                  )}

                                {currentUser.roleID > 0 &&
                                  scenario.secondaryScenarioID_Risk > 0 &&
                                  pageItem &&
                                  pageItem.userSecondaryAltScoring_Risk && (
                                    <DropdownItem
                                      className="always-enabled"
                                      onClick={() => {
                                        showScoringForScenario(
                                          scenario.secondaryScenarioID_Risk
                                        )
                                      }}
                                    >
                                      <i className="mdi mdi-bullseye"></i> Score{" "}
                                      {scenario.alternativeName}{" "}
                                      {scenario.secondaryScoreName_Risk}
                                    </DropdownItem>
                                  )}

                                {currentUser.roleID > 0 &&
                                  scenario.secondaryScenarioID_Benefit > 0 &&
                                  pageItem &&
                                  pageItem.userSecondaryAltScoring_Benefit && (
                                    <DropdownItem
                                      className="always-enabled"
                                      onClick={() => {
                                        showScoringForScenario(
                                          scenario.secondaryScenarioID_Benefit
                                        )
                                      }}
                                    >
                                      <i className="mdi mdi-bullseye"></i> Score{" "}
                                      {scenario.alternativeName}{" "}
                                      {scenario.secondaryScoreName_Benefit}
                                    </DropdownItem>
                                  )}

                                {currentUser.roleID > 0 &&
                                  scenario.secondaryScenarioID_Outcome > 0 &&
                                  pageItem &&
                                  pageItem.userSecondaryAltScoring_Outcome && (
                                    <DropdownItem
                                      className="always-enabled"
                                      onClick={() => {
                                        showScoringForScenario(
                                          scenario.secondaryScenarioID_Outcome
                                        )
                                      }}
                                    >
                                      <i className="mdi mdi-bullseye"></i> Score{" "}
                                      {scenario.alternativeName}{" "}
                                      {scenario.secondaryScoreName_Outcome}
                                    </DropdownItem>
                                  )}

                                {currentUser.useAI &&
                                  alternative != null &&
                                  alternative.chatGPTSummary &&
                                  alternative.chatGPTSummary != "" && (
                                    <DropdownItem
                                      className="always-enabled"
                                      onClick={e => {
                                        setShowAiSummary(!showAiSummary)
                                      }}
                                    >
                                      <i className="mdi mdi-brain"></i>{" "}
                                      {showAiSummary
                                        ? "Hide AI Summary"
                                        : "Show AI Summary"}
                                    </DropdownItem>
                                  )}

                                <DropdownItem
                                  className="always-enabled"
                                  onClick={e => {
                                    setShowRoadMap(!showRoadMap)
                                    loadChart2()
                                  }}
                                >
                                  <i className="fas fa-road"></i>{" "}
                                  {showRoadMap
                                    ? "Hide Roadmap"
                                    : "Show Roadmap"}
                                </DropdownItem>

                                <DropdownItem
                                  className="always-enabled"
                                  onClick={() => toggleStageUncertaintyModal()}
                                >
                                  <i className="fas fa-funnel-dollar"></i> View
                                  Cone of Uncertainty
                                </DropdownItem>

                                {(currentUser.roleID >= 10 ||
                                  currentUser.userID == alternative.brmUserID ||
                                  currentUser.userID == alternative.pmUserID ||
                                  currentUser.userID ==
                                    alternative.leadUserID ||
                                  currentUser.userID ==
                                    alternative.deliveryPOCID) && (
                                  <DropdownItem
                                    className="always-enabled"
                                    onClick={() => toggleLogModal()}
                                  >
                                    <i className="mdi mdi-history"></i> View Log
                                  </DropdownItem>
                                )}

                                {(scenario == null ||
                                  scenario.projectPlanTabID != 0) && (
                                  <DropdownItem
                                    className="always-enabled"
                                    onClick={toggleProjectSummaryModal}
                                  >
                                    <i className="mdi mdi-table-headers-eye"></i>{" "}
                                    View Project Status
                                  </DropdownItem>
                                )}

                                <div
                                  style={{
                                    paddingTop: "5px",
                                    paddingLeft: "5px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Tracking Items
                                </div>

                                {actionItems.map((a, idx) => (
                                  <DropdownItem
                                    key={idx}
                                    className="always-enabled"
                                    onClick={() => {
                                      setActiveDrawer("manageTasks")
                                      setSelectedActionItem(a)
                                    }}
                                  >
                                    <div className="d-flex-row ai-center gap-1">
                                      <i className={a.iconClass}></i>{" "}
                                      <span>{a.actionName}</span>
                                      {!isNullOrEmpty(alternativeMetadata) &&
                                        alternativeMetadata[
                                          `taskType_${a.actionTypeItemID}`
                                        ] != undefined && (
                                          <span
                                            className="badge bg-danger rounded-pill"
                                            style={{ marginLeft: "10px" }}
                                          >
                                            {
                                              alternativeMetadata[
                                                `taskType_${a.actionTypeItemID}`
                                              ]
                                            }
                                          </span>
                                        )}
                                    </div>
                                  </DropdownItem>
                                ))}

                                {/* <DropdownItem>Information</DropdownItem> */}
                              </DropdownMenu>
                            </Dropdown>
                          )}
                      </div>
                    </div>
                  </Row>

                  {showAiSummary && (
                    <Row>
                      <Col>
                        {alternative.chatGPTSummary &&
                          alternative.chatGPTSummary != "" && (
                            <div
                              style={{
                                width: "100%",
                                borderStyle: "solid",
                                borderWidth: "1px",
                                padding: "10px",
                                marginBottom: "10px",
                                marginTop: "10px",
                              }}
                            >
                              <Label>AI Summary:</Label>&nbsp;&nbsp;
                              <span>{alternative.chatGPTSummary}</span>
                            </div>
                          )}
                      </Col>
                    </Row>
                  )}

                  {showRoadMap && (
                    <>
                      <Row>
                        <Col>
                          <div
                            id="gantt"
                            style={{
                              width: "100%",
                              height: "200px",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              marginBottom: "10px",
                              marginTop: "10px",
                            }}
                          ></div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {alternative.planningComments &&
                            alternative.planningComments != "" && (
                              <div>
                                <Label>Planning Comments:</Label>&nbsp;&nbsp;
                                <span>{alternative.planningComments}</span>
                              </div>
                            )}
                        </Col>
                      </Row>
                    </>
                  )}

                  <Row>
                    {isLoading && (
                      <Loader
                        type="line-scale-pulse-out"
                        color={colors.primary}
                        style={{ textAlign: "center" }}
                      />
                    )}
                  </Row>
                  {alternativeId > -1 && (
                    <Nav tabs>
                      {tabs.length > 0 &&
                        tabs.map((t, idx) => {
                          return (
                            <NavItem key={idx}>
                              <NavLink
                                style={{ cursor: "pointer", color: t.color }}
                                className={
                                  activeTab === t.tabView
                                    ? "active always-enabled"
                                    : "always-enabled"
                                }
                                onClick={() => {
                                  toggleTab(t)
                                }}
                              >
                                <i className={t.icon}></i> <b>{t.tabName}</b>
                              </NavLink>
                            </NavItem>
                          )
                        })}
                    </Nav>
                  )}

                  <Row>
                    <Col
                      md={activeDrawer !== "" ? "9" : "12"}
                      style={{ overflowX: "auto" }}
                    >
                      {tabPageItem != null &&
                        tabPageItem.pageHelpStatus == "Hide" &&
                        tabPageItem.pageHelp != "" && (
                          <Row>
                            <div>
                              <i
                                className="bx bx-help-circle"
                                style={{
                                  cursor: "pointer",
                                  marginRight: "30px",
                                  marginTop: "10px",
                                  float: "right",
                                  fontSize: "18px",
                                }}
                                onClick={() => {
                                  showHelpBanner()
                                }}
                              />
                            </div>
                          </Row>
                        )}
                      {tabPageItem != null &&
                        tabPageItem.pageHelpStatus == "Show" &&
                        tabPageItem.pageHelp != "" && (
                          <Alert
                            id="helpBanner"
                            variant="light"
                            className="always-enabled"
                            isOpen={helpVisible}
                            toggle={onHelpDismiss}
                            style={{ marginBottom: "0px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: tabPageItem.pageHelp,
                              }}
                            ></div>
                          </Alert>
                        )}
                      {alternative != null && (
                        <TabContent
                          activeTab={activeTab}
                          className="m-3 mt-1 text-muted"
                          style={{
                            height: `${window.innerHeight * 0.7}px`,
                            overflow: "auto",
                          }}
                        >
                          <TabPane
                            style={{ width: "98%" }}
                            tabId="General_Need"
                          >
                            <GeneralNeedSection
                              activeTab={activeTab}
                              alternative={alternative}
                              changeAlternativeProp={changeAlternativeProp}
                              governanceOptions={governanceOptions}
                              planningOptions={planningOptions}
                              sponsoringOptions={sponsoringOptions}
                              priorityOptions={priorityOptions}
                              errorProps={generalNeedErrorProps}
                              fieldConfigItems={fieldConfigItems}
                              entities={entities}
                              formId={activeTabFormId}
                              triggerFormSave={triggerDynamicFormSave}
                              setTriggerFormSave={setTriggerDynamicFormSave}
                              showToastChangesCallback={
                                showToastChangesCallback
                              }
                              sbuLabel={sbuLabel}
                              governanceBoardLabel={governanceBoardLabel}
                              getPlaceholder={getPlaceholder}
                              enterprise={enterprise}
                            />
                          </TabPane>

                          <TabPane
                            style={{ width: "98%" }}
                            tabId="OCC_Approach"
                          >
                            <OccApproachSection
                              enterprise={enterprise}
                              activeTab={activeTab}
                              alternative={alternative}
                              scenario={scenario}
                              changeAlternativeProp={changeAlternativeProp}
                              sectionFormId={activeTabSectionFormId}
                              showToastChangesCallback={
                                showToastChangesCallback
                              }
                              triggerFormSave={triggerDynamicFormSave}
                              setTriggerFormSave={setTriggerDynamicFormSave}
                              systemRetirementsLabel={systemRetirementsLabel}
                              getPlaceholder={getPlaceholder}
                            />
                          </TabPane>
                          <TabPane style={{ width: "98%" }} tabId="Forecast">
                            <ForecastSection
                              activeTab={activeTab}
                              alternativeId={alternativeId}
                              alternative={alternative}
                              triggerSave={triggerForecastSectionSave}
                              setTriggerSave={setTriggerForecastSectionSave}
                              changeAlternativeProp={changeAlternativeProp}
                              scenario={scenario}
                              reload={() => {
                                loadScenario()
                                loadWidgets()
                              }}
                            />
                          </TabPane>
                          <TabPane style={{ width: "98%" }} tabId="Min_Need">
                            <MinNeedSection
                              alternative={alternative}
                              changeAlternativeProp={changeAlternativeProp}
                              priorityOptions={priorityOptions}
                              errorProps={generalNeedErrorProps}
                            />
                          </TabPane>
                          <TabPane style={{ width: "98%" }} tabId="Results">
                            <ResultSection
                              activeTab={activeTab}
                              alternativeId={alternativeId}
                              scenario={scenario}
                            />
                          </TabPane>
                          <TabPane style={{ width: "98%" }} tabId="Contract">
                            <ContractSection
                              activeTab={activeTab}
                              alternativeId={alternativeId}
                              alternative={alternative}
                              triggerSave={triggerContractSectionSave}
                              setTriggerSave={setTriggerContractSectionSave}
                              scenario={scenario}
                              enterprise={enterprise}
                            />
                          </TabPane>
                          <TabPane style={{ width: "98%" }} tabId="Location">
                            <LocationSection
                              enterprise={enterprise}
                              alternativeId={alternativeId}
                              scenarioId={scenarioId}
                              activeTab={activeTab}
                              sectionFormId={activeTabSectionFormId}
                              showToastChangesCallback={
                                showToastChangesCallback
                              }
                              triggerFormSave={triggerDynamicFormSave}
                              setTriggerFormSave={setTriggerDynamicFormSave}
                            />
                          </TabPane>
                          <TabPane
                            style={{ width: "98%" }}
                            tabId="Forecast_Min"
                          >
                            <ForecastSummarySection
                              alternativeId={alternativeId}
                              alternative={alternative}
                              activeTab={activeTab}
                              changeAlternativeProp={changeAlternativeProp}
                            />
                          </TabPane>
                          <TabPane style={{ width: "98%" }} tabId="Lookback">
                            <LookbackSection
                              alternativeId={alternativeId}
                              businessCaseID={alternative.businessCaseID}
                              activeTab={activeTab}
                              scenario={scenario}
                            />
                          </TabPane>
                          <TabPane style={{ width: "98%" }} tabId="Governance">
                            <GovernanceSection
                              alternativeId={alternativeId}
                              alternative={alternative}
                              changeAlternativeProp={changeAlternativeProp}
                              activeTab={activeTab}
                              loadAlternative={loadAlternative}
                              tabPageItem={tabPageItem}
                              enterprise={enterprise}
                              scenario={scenario}
                              fieldConfigItems={fieldConfigItems}
                              props={props}
                              saveAlternative={saveAlternative}
                              sectionFormId={activeTabSectionFormId}
                              showToastChangesCallback={
                                showToastChangesCallback
                              }
                              triggerFormSave={triggerDynamicFormSave}
                              setTriggerFormSave={setTriggerDynamicFormSave}
                              brmLabel={brmLabel}
                              advancedPlanningLabel={advancedPlanningLabel}
                              investmentCategoryLabel={investmentCategoryLabel}
                              lbrLabel={lbrLabel}
                              planningCategoryLabel={planningCategoryLabel}
                              ldoLabel={ldoLabel}
                              planningStageLabel={planningStageLabel}
                            />
                          </TabPane>
                          <TabPane
                            style={{ width: "98%" }}
                            tabId="ResourcePlan"
                          >
                            <ProjectPlanningSection
                              alternativeId={alternativeId}
                              alternative={alternative}
                              changeAlternativeProp={changeAlternativeProp}
                              activeTab={activeTab}
                              triggerReload={
                                triggerProjectPlanningSectionReload
                              }
                              setTriggerReload={
                                setTriggerProjectPlanningSectionReload
                              }
                              triggerSave={projectPlanningSectionSave}
                              setTriggerSave={setProjectPlanningSectionSave}
                              showToastChanges={showToastChanges}
                            />
                          </TabPane>
                          <TabPane
                            style={{ width: "98%" }}
                            tabId="ResourceBudget"
                          >
                            <ResourceBudgetingSection
                              alternativeId={alternativeId}
                              activeTab={activeTab}
                              alternative={alternative}
                              scenario={scenario}
                              triggerSave={resourceBudgetSectionSave}
                              setTriggerSave={setResourceBudgetSectionSave}
                              showToastChanges={showToastChanges}
                              changeAlternativeProp={changeAlternativeProp}
                            />
                          </TabPane>
                          <TabPane
                            style={{ width: "98%" }}
                            tabId="OCC_Assessment"
                          >
                            <OccNeedAssesmentSection
                              alternativeId={alternativeId}
                              activeTab={activeTab}
                              alternative={alternative}
                              scenario={scenario}
                              changeAlternativeProp={changeAlternativeProp}
                              triggerSave={occAssesmentSectionSave}
                              setTriggerSave={setOccAssesmentSectionSave}
                              showToastChanges={showToastChanges}
                              handleSave={saveAlternative}
                            />
                          </TabPane>
                          <TabPane style={{ width: "98%" }} tabId="OCC_Need">
                            <OccNeedSection
                              alternative={alternative}
                              changeAlternativeProp={changeAlternativeProp}
                              governanceOptions={governanceOptions}
                              planningOptions={planningOptions}
                              sponsoringOptions={sponsoringOptions}
                              priorityOptions={priorityOptions}
                              errorProps={occNeedErrorProps}
                              governanceBoardLabel={governanceBoardLabel}
                              sbuLabel={sbuLabel}
                              enterprise={enterprise}
                            />
                          </TabPane>

                          <TabPane style={{ width: "98%" }} tabId="NM_Need">
                            <NmSection
                              scenario={scenario}
                              alternative={alternative}
                              alternativeId={alternativeId}
                              activeTab={activeTab}
                              triggerSave={triggerNmSectionSave}
                              setTriggerSave={setTriggerNmSectionSave}
                              showToastChanges={showToastChanges}
                              loadAlternative={setAlternativeId}
                              props={props}
                            />
                          </TabPane>

                          <TabPane
                            style={{ width: "98%" }}
                            tabId="ScoringResults"
                          >
                            <ScoringResultSection
                              alternativeId={alternativeId}
                              activeTab={activeTab}
                              scenarioId={scenarioId}
                              scenario={scenario}
                            />
                          </TabPane>

                          <TabPane style={{ width: "98%" }} tabId="ProjectPlan">
                            <ProjectPlanSection
                              alternative={alternative}
                              alternativeId={alternativeId}
                              revisionAlternativeId={alternative.revisionAltID}
                              activeTab={activeTab}
                              scenarioId={scenarioId}
                              scenario={scenario}
                              fieldConfigItems={fieldConfigItems}
                              changeAlternativeProp={changeAlternativeProp}
                              hasProjectPlanError={hasProjectPlanError}
                              enterprise={enterprise}
                              loadAlternative={loadAlternative}
                            />
                          </TabPane>

                          <TabPane
                            tabId="BenefitRealization"
                            style={{ minHeight: "300px" }}
                          >
                            <BenefitRealizationSection
                              enterprise={enterprise}
                              alternative={alternative}
                              alternativeId={alternativeId}
                              activeTab={activeTab}
                              scenario={scenario}
                              changeAlternativeProp={changeAlternativeProp}
                              sectionFormId={activeTabSectionFormId}
                              showToastChangesCallback={
                                showToastChangesCallback
                              }
                              triggerFormSave={triggerDynamicFormSave}
                              setTriggerFormSave={setTriggerDynamicFormSave}
                              props={props}
                              showScoringForScenario={showScoringForScenario}
                            />
                          </TabPane>
                          <TabPane style={{ width: "98%" }} tabId="Finance">
                            {scenario != null && (
                              <FinancingSection
                                activeTab={activeTab}
                                alternativeId={alternativeId}
                                enterpriseId={scenario.enterpriseID}
                                triggerSave={triggerFinanceSectionSave}
                                setTriggerSave={setTriggerFinanceSectionSave}
                              />
                            )}
                          </TabPane>
                        </TabContent>
                      )}
                    </Col>
                    {activeDrawer !== "" && (
                      <Col
                        md="3"
                        style={{
                          boxShadow: "-5px 0 10px 0px lightgrey",
                          borderLeft: "1px solid lightgray",
                          height: `${window.innerHeight * 0.7}px`,
                          overflow: "auto",
                        }}
                      >
                        <div className="always-enabled">
                          <button
                            type="button "
                            onClick={() => {
                              setActiveDrawer("")
                              setSelectedActionItem(null)
                            }}
                            className="close"
                            aria-label="Close"
                            style={{ color: "black" }}
                          >
                            <span style={{ color: "black" }} aria-hidden="true">
                              &times;
                            </span>
                          </button>
                        </div>
                        {activeDrawer == "manageTasks" &&
                          selectedActionItem != null &&
                          selectedActionItem.actionType == "TaskType" && (
                            <TaskDrawer
                              taskTypeId={selectedActionItem.actionTypeItemID}
                              alternative={alternative}
                              alternatives={alternatives}
                              scenarioId={scenarioId}
                            />
                          )}
                        {activeDrawer == "Note" && (
                          <NoteDrawer
                            alternative={alternative}
                            activeDrawer={activeDrawer}
                          />
                        )}
                        {activeDrawer == "Document" && (
                          <DocumentDrawer
                            alternative={alternative}
                            activeDrawer={activeDrawer}
                          />
                        )}
                        {activeDrawer == "Score" && scenario != null && (
                          <ScoreDrawer
                            activeDrawer={activeDrawer}
                            alternativeId={alternativeId}
                            scoringScenarioId={scoringScenarioId}
                          />
                        )}
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <SelectAlternativeModal
        isOpen={showAltModal}
        toggleModal={toggleAltModal}
        scenarioId={scenarioId}
        scenario={scenario}
        onSelect={selectAlternative}
      />
      <BusinessCaseReportModal
        alternative={alternative}
        toggleModal={toggleReportModal}
        isOpen={showReportModal}
      />
      <AlternativeCompareModal
        isOpen={showAlternativeCompareModal}
        toggleModal={toggleAlternativeCompareModal}
        alternative={alternative}
      />
      <BaselineComparisonsModal
        isOpen={showBaselineComparisonModal}
        toggleModal={toggleBaselineComparisonModal}
        alternative={alternative}
      />
      {scenario != null && (
        <LogModal
          isOpen={showLogModal}
          toggleModal={toggleLogModal}
          enterpriseId={scenario.enterpriseID}
          alternative={alternative}
        />
      )}
      <StageUncertaintyModal
        alternative={alternative}
        scenarioId={scenarioId}
        toggleModal={toggleStageUncertaintyModal}
        isOpen={showStageUncertaintyModal}
      />
      {alternative != undefined && alternative != null && (
        <ProjectArtifactsModal
          isOpen={showProjectArtifactsModal}
          toggleModal={toggleProjectArtifactsModal}
          alternativeId={alternativeId}
          alternativeName={alternative.alternativeName}
        />
      )}
      {alternativeId > 0 && (
        <ProjectSummaryModal
          isOpen={showProjectSummaryModal}
          toggleModal={toggleProjectSummaryModal}
          alternativeId={alternativeId}
        />
      )}
    </React.Fragment>
  )
}

export default EditProject
