import { TextareaAutosize } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import isNullOrEmpty from "utils/isNullOrEmpty"

const JiraProjectsModal = ({ isOpen, toggleModal }) => {
  const currentUser = userService.getLoggedInUser()
  const [projects, setProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState(null)
  const [epics, setEpics] = useState(null)
  const [tasks, setTasks] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  useEffect(() => {
    if (selectedProject != null) {
      loadProjectDetails()
    }
  }, [selectedProject])

  const loadData = async () => {
    let projects = await api.getJiraProjects()
    setProjects(projects)
  }

  const loadProjectDetails = async () => {
    setIsLoading(true)
    let epics = await api.getJiraProjectEpics(selectedProject.key)
    setEpics(epics)
    let t = await api.getJiraProjectTasks(selectedProject.key)
    setTasks(t?.issues ?? [])
    setIsLoading(false)
  }

  const save = async () => {}

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Jira Projects
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body"></div>
      <div className="mb-3">
        {projects.map((project, index) => (
          <div key={index} className="form-check">
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                setSelectedProject(project)
              }}
            >
              {project.name}
            </a>
          </div>
        ))}
      </div>
      {selectedProject != null && (
        <>
          <div className="divider"></div>
          <div className="mb-3">
            <h5>{selectedProject.name}</h5>
            {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            )}
            {!isLoading && (
              <>
                {isNullOrEmpty(tasks) && <span>No tasks found</span>}
                {!isNullOrEmpty(tasks) && (
                  <table
                    className="table table-bordered low-padding-table"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <tr>
                        <th>Task</th>
                        <th>Status</th>
                        <th>Priority</th>
                      </tr>
                    </thead>

                    <tbody>
                      {tasks.map((task, idx) => (
                        <tr key={idx}>
                          <td>{task.fields?.summary}</td>
                          <td>{task.fields?.status?.name}</td>
                          <td>{task.fields?.priority?.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default JiraProjectsModal
