import React from "react"
import { Col, Label } from "reactstrap"

export const DynamicFormSelect = React.memo(
  function dynamicFormSelect({
    formFieldId,
    label,
    value,
    placeholder,
    changeFormFieldValue,
    formFieldListItems,
    col,
    fullWidth,
    alwaysEditable,
    alwaysReadOnly,
    hasError,
    requiredMessage,
  }) {
    if (formFieldListItems == undefined || formFieldListItems == null) {
      return <span>Loading</span>
    } else {
      return (
        <Col md={fullWidth ? "12" : col}>
          <div className="mb-3">
            <Label className="form-label">{label}</Label>
            <select
              className={`form-control form-select select2 mb-xxl-0 ${
                alwaysReadOnly ? "always-disabled" : ""
              }`}
              disabled={alwaysReadOnly}
              value={value}
              onChange={e => {
                changeFormFieldValue(formFieldId, e.target.value)
              }}
            >
              <option value="-1">Select item...</option>
              {formFieldListItems.map((s, i) => (
                <option key={i} value={s.listItemValue}>
                  {s.listItemName}
                </option>
              ))}
            </select>
            {hasError && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {requiredMessage}
              </div>
            )}
          </div>
        </Col>
      )
    }
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.formFieldListItems) ==
        JSON.stringify(nextProps.formFieldListItems) &&
      prevProps.value == nextProps.value &&
      prevProps.label == nextProps.label &&
      prevProps.col == nextProps.col &&
      prevProps.requiredMessage == nextProps.requiredMessage &&
      prevProps.hasError == nextProps.hasError &&
      prevProps.placeholder == nextProps.placeholder &&
      prevProps.formFieldId == nextProps.formFieldId &&
      prevProps.fullWidth == nextProps.fullWidth
    )
  }
)
